import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { mastersApi } from '../../_constants/api-list';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class InclusionsService {

    constructor(private http: HttpClient) { }
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        }),
    };

    addInclusions(data: any, iconObject: any) {
        let formData: FormData = new FormData();
        formData.append('name', data.name);
        formData.append('description', data.description);
        if (iconObject) {
            formData.append('icon', iconObject);
        }
        const url = environment.origin + mastersApi.SAVE_INCLUSIONS;
        return this.http.post(url, formData).pipe(retry(1), catchError(this.handleError));
    }

    getInclusions() {
        const url = environment.origin + mastersApi.GET_INCLUSIONS;
        return this.http.get(url, this.httpOptions).pipe(retry(1), catchError(this.handleError));
    }

    editInclusions(id: any, data: any, iconObject: any) {
        let formData: FormData = new FormData();
        formData.append('name', data.name);
        formData.append('description', data.description);
        if (iconObject) {
            formData.append('icon', iconObject);
        } else {
            formData.append('icon', data.icon);
        }
        const url = environment.origin + `${mastersApi.SAVE_INCLUSIONS}/${id}`;
        return this.http.put(url, formData)
            .pipe(retry(1), catchError(this.handleError));
    }

    deleteInclusions(id) {
        const url = environment.origin + `${mastersApi.DELETE_INCLUSIONS}${id}`;
        return this.http.delete(url, this.httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    /**
     * Error handler
     * @param error error object
     * @returns Return response from api
     */
    handleError(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message;
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }
}
