import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ToastrService } from 'ngx-toastr';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { PropertyService } from '../../property/property-service.service';
import { PoliciesService } from '../policies.service';
import { alphabets, HotelItem } from './hotel-item';
import { PolicyTypeService } from '../../_services';

@Component({
  selector: 'app-add-policy',
  templateUrl: './add-policy.component.html',
  styleUrls: ['./add-policy.component.css'],
})
export class AddPolicyComponent implements OnInit {
  mode: string = 'edit';
  isViewMode: boolean = false;
  showLoader = false;
  customEditEnabled: boolean = false;
  policyForm: FormGroup;
  isFormSubmitted = false;
  policyTypes = [];
  selectedPolicyType: any;
  cancellationChargeType: string;
  paymentChargeType: string;
  minDateTo = new Date();;
  maxDateTo = new Date();
  minDateFrom = new Date();
  maxDateFrom = new Date();
  hotels: HotelItem[] = [];
  selectedHotels: HotelItem[] = [];
  selectAllHotels: boolean;
  policyId: string;
  policyDetails: any;
  cancellationCharges: any;
  isAmountChecked: boolean = true;
  isPercentChecked: boolean = false;
  isNightsChecked: boolean = false;
  isAllowChild: boolean = true;
  isCreditCardFullAmountChecked: boolean = true;
  isCreditCardAmountChecked: boolean = false;
  isCreditCardPercentChecked: boolean = false;
  isCreditCardNightsChecked: boolean = false;
  isAmountRefundable: boolean = false;

  public displayHotelList = new MatTableDataSource<HotelItem>();
  displayedColumns = ['checked', 'name', 'cityName', 'countryName'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  alphabetList = alphabets;
  currentAlphabet: { id: string; name: string };
  selectCurrentPageHotels: boolean = true;
  currentPage: PageEvent = {
    pageIndex: 0,
    pageSize: 10,
    length,
  };

  constructor(
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private toaster: ToastrService,
    private policyTypeService: PolicyTypeService,
    private propertyService: PropertyService,
    private policiesService: PoliciesService
  ) { }

  ngOnInit(): void {
    this.policyId = this.activatedRoute.snapshot.paramMap.get('policyId');
    this.mode = this.activatedRoute.snapshot.paramMap.get('mode');
    if (this.mode === 'view') {
      this.isViewMode = true;
    }
    this.getPolicy();
    this.initForm();
    if (!this.policyId) {
      this.getHotels();
      this.getPolicyType();
      this.minDateTo = new Date();
      this.maxDateTo = new Date();
      this.minDateFrom = new Date();
    }
  }

  initForm() {
    this.policyForm = this.formBuilder.group({
      policy_type: new FormControl(null, [Validators.required]),
      from_date: new FormControl(null, [Validators.required]),
      to_date: new FormControl(null, [Validators.required]),
      policy_name: new FormControl(null, [
        Validators.required,
        Validators.pattern(
          new RegExp(/^([^0-9!@#%^&*()_+-=|/,.<`~>]+)([a-zA-Z0-9 %-:$/]+)$/)
        ),
      ]),
      policy_description: new FormControl(null, [Validators.required]),
      cancellation_charges: this.formBuilder.group({
        days_before: [],
        amount: [],
        percent: [],
        nights: [],
      }),
      child_charges: this.formBuilder.group({
        max_child_age: [],
        charge_not_apply_age: [],
      }),
      tax_charges: this.formBuilder.array([]),
      payment_charges: this.formBuilder.group({
        instant_booking_charge: [false],
        credit_card_percent: [],
        credit_card_nights: [],
        credit_card_amount: [],
      }),
    });

    this.policyForm.controls.from_date.valueChanges.subscribe((data) => {
      if (data) {
        this.minDateTo = new Date(data);
      } else {
        this.minDateTo = new Date();
      }
    });
    this.policyForm.controls.to_date.valueChanges.subscribe((data) => {
      if (data) {
        this.maxDateFrom = new Date(data);
      } else {
        this.maxDateFrom = null;
      }
    });

    this.paymentChargesForm.controls.credit_card_percent.disable();
    this.paymentChargesForm.controls.credit_card_nights.disable();
    this.paymentChargesForm.controls.credit_card_amount.disable();
  }

  public get cancellationChargesForm() {
    return this.policyForm.get('cancellation_charges') as FormGroup;
  }

  public get childChargesForm() {
    return this.policyForm.get('child_charges') as FormGroup;
  }

  public get paymentChargesForm() {
    return this.policyForm.get('payment_charges') as FormGroup;
  }

  public get taxChargesForm() {
    return this.policyForm.get('tax_charges') as FormArray;
  }

  addTaxChargesForm(value?) {
    this.taxChargesForm.push(
      this.formBuilder.group({
        tax_description: [
          value ? value.tax_description : null,
          [Validators.required, RxwebValidators.unique()],
        ],
        percent: [
          value ? value.percent : null,
          [Validators.required, Validators.min(1), Validators.max(100)],
        ],
        calculation_order: [
          value ? value.calculation_order : this.taxChargesForm.length + 1,
          [Validators.required, Validators.min(1)],
        ],
      })
    );
  }

  removeTaxChargesFormAtIndex(index: number) {
    this.taxChargesForm.removeAt(index);
    for (let index = this.taxChargesForm.length - 1; index >= 0; index--) {
      this.taxChargesForm
        .at(index)
        .patchValue({ calculation_order: index + 1 });
    }
  }

  removeAllTaxChargesForm() {
    for (let index = this.taxChargesForm.length - 1; index >= 0; index--) {
      this.removeTaxChargesFormAtIndex(index);
    }
  }

  getPolicyType() {
    this.policyTypeService.getPolicyType().subscribe((data) => {
      this.showLoader = false;
      if (data['success'] === true) {
        this.policyTypes = data['data'];
        this.policyForm.patchValue({
          policy_type: this.selectedPolicyType?.id,
        });
      }
    });
  }

  getPolicy() {
    if (this.policyId) {
      this.policiesService
        .getPolicyById(this.policyId)
        .subscribe((response) => {
          this.getPolicyType();
          this.getHotels();
          if (response['success'] === true) {
            this.policyDetails = response['data'];
            this.selectedHotels = this.policyDetails['hotels'];
            this.selectedPolicyType = this.policyDetails['PolicyType'];
            this.cancellationCharges = this.policyDetails[
              'cancellation_charges'
            ];
            if (
              this.policyDetails.cancellation_charges &&
              this.policyDetails.cancellation_charges[0]
            ) {
              if (this.policyDetails.cancellation_charges[0].nights) {
                this.isNightsChecked = true;
                this.onCancellationChargesType('nights');
              }
              if (this.policyDetails.cancellation_charges[0].amount) {
                this.isAmountChecked = true;
                this.onCancellationChargesType('amount');
              }
              if (this.policyDetails.cancellation_charges[0].percent) {
                this.isPercentChecked = true;
                this.onCancellationChargesType('percent');
              }
            }

            if (this.policyDetails.tax_charges.length > 0) {
              for (const tax_charge of this.policyDetails.tax_charges) {
                this.addTaxChargesForm(tax_charge);
              }
            }

            if (
              this.policyDetails.child_charges &&
              this.policyDetails.child_charges[0]
            ) {
              this.isAllowChild = !this.policyDetails.child_charges[0]
                .allow_children;
              this.onToggleAllowChild();
            }

            if (
              this.policyDetails.payment_charges &&
              this.policyDetails.payment_charges[0]
            ) {
              this.isAmountRefundable = this.policyDetails.payment_charges[0]
                .amount_refundable
                ? this.policyDetails.payment_charges[0].amount_refundable
                : false;

              if (
                this.policyDetails.payment_charges[0].credit_card_full_payment
              ) {
                this.isCreditCardFullAmountChecked = true;
                this.onPaymentChargesType('fullAmount');
              } else {
                this.isCreditCardFullAmountChecked = false;
              }

              if (this.policyDetails.payment_charges[0].credit_card_nights) {
                this.isCreditCardNightsChecked = true;
                this.onPaymentChargesType('nights');
              }
              if (this.policyDetails.payment_charges[0].credit_card_amount) {
                this.isCreditCardAmountChecked = true;
                this.onPaymentChargesType('amount');
              }
              if (this.policyDetails.payment_charges[0].credit_card_percent) {
                this.isCreditCardPercentChecked = true;
                this.onPaymentChargesType('percent');
              }
            }

            this.policyForm.patchValue({
              from_date: new Date(this.policyDetails.from_date),
              to_date: new Date(this.policyDetails.to_date),
              policy_name: this.policyDetails.policy_name,
              policy_description: this.policyDetails.policy_description,
            });

            // disable policy type selection
            this.policyForm.controls.policy_type.disable();

            if (
              this.policyDetails.cancellation_charges &&
              this.policyDetails.cancellation_charges[0]
            ) {
              this.policyForm.patchValue({
                cancellation_charges: this.policyDetails
                  .cancellation_charges[0],
              });
            }

            if (this.policyDetails.tax_charges) {
              this.policyForm.patchValue({
                tax_charges: this.policyDetails.tax_charges,
              });
            }

            if (
              this.policyDetails.child_charges &&
              this.policyDetails.child_charges[0]
            ) {
              this.policyForm.patchValue({
                child_charges: this.policyDetails.child_charges[0],
              });
            }

            if (
              this.policyDetails.payment_charges &&
              this.policyDetails.payment_charges[0]
            ) {
              this.policyForm.patchValue({
                payment_charges: this.policyDetails.payment_charges[0],
              });
            }
          }
        });
    }
  }

  policyTypeChanged(policyTypeId) {
    this.selectedPolicyType = this.policyTypes.find(
      (policyType) => policyType.id === policyTypeId
    );

    // reset cancellation charges form
    this.cancellationChargesForm.reset();
    this.cancellationChargesForm.controls.days_before.clearValidators();
    this.cancellationChargesForm.controls.amount.clearValidators();
    this.cancellationChargesForm.controls.percent.clearValidators();
    this.cancellationChargesForm.controls.nights.clearValidators();

    // reset tax charges form
    this.taxChargesForm.reset();
    this.removeAllTaxChargesForm();

    // reset child charges form
    this.childChargesForm.reset();
    this.childChargesForm.controls.max_child_age.clearValidators();
    this.childChargesForm.controls.charge_not_apply_age.clearValidators();

    switch (this.selectedPolicyType.name) {
      case 'Cancellation Policy':
        this.cancellationChargesForm.controls.days_before.setValidators([
          Validators.required,
          Validators.min(1),
          Validators.pattern(new RegExp(/^[0-9]+$/)),
        ]);
        this.cancellationChargesForm.controls.percent.disable();
        this.cancellationChargesForm.controls.nights.disable();

        break;

      case 'Tax Policy':
        this.addTaxChargesForm();
        break;

      case 'Child Policy':
        this.childChargesForm.controls.max_child_age.setValidators([
          Validators.required,
          Validators.min(1),
        ]);
        this.childChargesForm.controls.charge_not_apply_age.setValidators([
          Validators.required,
          Validators.min(1),
        ]);
        break;

      default:
        break;
    }
  }

  onCancellationChargesType(cancellationChargeType) {
    this.isAmountChecked = false;
    this.isPercentChecked = false;
    this.isNightsChecked = false;
    this.cancellationChargeType = cancellationChargeType;

    this.cancellationChargesForm.controls.amount.reset();
    this.cancellationChargesForm.controls.amount.disable();
    this.cancellationChargesForm.controls.amount.clearValidators();
    this.cancellationChargesForm.controls.percent.reset();
    this.cancellationChargesForm.controls.percent.disable();
    this.cancellationChargesForm.controls.percent.clearValidators();
    this.cancellationChargesForm.controls.nights.reset();
    this.cancellationChargesForm.controls.nights.disable();
    this.cancellationChargesForm.controls.nights.clearValidators();

    switch (cancellationChargeType) {
      case 'amount':
        this.isAmountChecked = true;
        this.cancellationChargesForm.controls.amount.enable();
        this.cancellationChargesForm.controls.amount.setValidators([
          Validators.min(1),
          Validators.required,
        ]);
        break;
      case 'percent':
        this.isPercentChecked = true;
        this.cancellationChargesForm.controls.percent.enable();
        this.cancellationChargesForm.controls.percent.setValidators([
          Validators.required,
          Validators.min(1),
          Validators.max(100),
        ]);
        break;
      case 'nights':
        this.isNightsChecked = true;
        this.cancellationChargesForm.controls.nights.enable();
        this.cancellationChargesForm.controls.nights.setValidators([
          Validators.required,
          Validators.min(1),
        ]);
        break;
      default:
        break;
    }

    this.cancellationChargesForm.controls.amount.updateValueAndValidity();
    this.cancellationChargesForm.controls.percent.updateValueAndValidity();
    this.cancellationChargesForm.controls.nights.updateValueAndValidity();
  }

  onPaymentChargesType(paymentChargeType) {
    this.isCreditCardAmountChecked = false;
    this.isCreditCardPercentChecked = false;
    this.isCreditCardNightsChecked = false;
    this.isCreditCardFullAmountChecked = false;
    this.paymentChargeType = paymentChargeType;

    this.paymentChargesForm.controls.credit_card_amount.reset();
    this.paymentChargesForm.controls.credit_card_amount.disable();
    this.paymentChargesForm.controls.credit_card_amount.clearValidators();
    this.paymentChargesForm.controls.credit_card_percent.reset();
    this.paymentChargesForm.controls.credit_card_percent.disable();
    this.paymentChargesForm.controls.credit_card_percent.clearValidators();
    this.paymentChargesForm.controls.credit_card_nights.reset();
    this.paymentChargesForm.controls.credit_card_nights.disable();
    this.paymentChargesForm.controls.credit_card_nights.clearValidators();

    switch (paymentChargeType) {
      case 'fullAmount':
        this.isCreditCardFullAmountChecked = true;
        break;

      case 'amount':
        this.isCreditCardAmountChecked = true;
        this.paymentChargesForm.controls.credit_card_amount.enable();
        this.paymentChargesForm.controls.credit_card_amount.setValidators([
          Validators.min(1),
          Validators.required,
        ]);
        break;
      case 'percent':
        this.isCreditCardPercentChecked = true;
        this.paymentChargesForm.controls.credit_card_percent.enable();
        this.paymentChargesForm.controls.credit_card_percent.setValidators([
          Validators.required,
          Validators.min(1),
          Validators.max(100),
        ]);
        break;
      case 'nights':
        this.isCreditCardNightsChecked = true;
        this.paymentChargesForm.controls.credit_card_nights.enable();
        this.paymentChargesForm.controls.credit_card_nights.setValidators([
          Validators.required,
          Validators.min(1),
        ]);
        break;
      default:
        break;
    }

    this.paymentChargesForm.controls.credit_card_amount.updateValueAndValidity();
    this.paymentChargesForm.controls.credit_card_percent.updateValueAndValidity();
    this.paymentChargesForm.controls.credit_card_nights.updateValueAndValidity();
  }

  onToggleAllowChild() {
    this.isAllowChild = !this.isAllowChild;
    if (this.isAllowChild) {
      this.childChargesForm.controls.max_child_age.enable();
      this.childChargesForm.controls.charge_not_apply_age.enable();
      this.childChargesForm.controls.max_child_age.setValidators([
        Validators.required,
        Validators.min(1),
      ]);
      this.childChargesForm.controls.charge_not_apply_age.setValidators([
        Validators.required,
        Validators.min(1),
      ]);
    } else {
      this.childChargesForm.reset();
      this.childChargesForm.controls.max_child_age.clearValidators();
      this.childChargesForm.controls.charge_not_apply_age.clearValidators();
      this.childChargesForm.controls.max_child_age.disable();
      this.childChargesForm.controls.charge_not_apply_age.disable();
    }

    this.childChargesForm.controls.max_child_age.updateValueAndValidity();
    this.childChargesForm.controls.charge_not_apply_age.updateValueAndValidity();
  }

  getHotels() {
    this.propertyService.getPropertyList().subscribe(
      (data) => {
        if (data['success'] === true) {
          this.hotels = data['data'];

          for (const hotel of this.hotels) {
            let selectedHotelIndex = this.selectedHotels.findIndex((htl) => {
              return htl.id === hotel.id;
            });

            if (selectedHotelIndex === -1) hotel.checked = false;
            else hotel.checked = true;
          }

          this.displayHotelList.data = this.hotels;
          this.displayHotelList.paginator = this.paginator;
          this.displayHotelList.sort = this.sort;

          this.selectCurrentPageHotels = false;
        }
      },
      (error) => {
        console.error('Hotel Data', error);
      }
    );
  }

  onSelectAllHotelsChanged() {
    this.selectAllHotels = !this.selectAllHotels;
    this.hotels.map((hotel) => (hotel.checked = this.selectAllHotels));
    // this.hotels.map((hotel) => (hotel.checked = true));
  }

  onSubmit() {
    if (this.policyForm.valid) {
      this.isFormSubmitted = true;
      this.showLoader = true;
      let formValue = this.policyForm.value;
      formValue.policy_name = formValue.policy_name.trim();

      let hotels = [];
      for (const hotel of this.hotels) {
        if (hotel.checked) hotels.push(hotel.id);
      }

      if (hotels.length === 0) {
        this.toaster.error('Please select at least one hotel', 'Error', {
          timeOut: 3000,
        });
        this.showLoader = false;
        return;
      }

      formValue.hotel_ids = hotels;
      formValue.policy_type_id = this.selectedPolicyType.id;
      formValue.from_date = this.datePipe.transform(
        new Date(formValue.from_date),
        'yyyy-MM-dd'
      );
      formValue.to_date = this.datePipe.transform(
        new Date(formValue.to_date),
        'yyyy-MM-dd'
      );

      if (this.selectedPolicyType.name === 'Cancellation Policy') {
        delete formValue.tax_charges;
        delete formValue.child_charges;
        delete formValue.payment_charges;
      }
      if (this.selectedPolicyType.name === 'Tax Policy') {
        delete formValue.cancellation_charges;
        delete formValue.child_charges;
        delete formValue.payment_charges;

        for (let i = 0; i < formValue.tax_charges.length; i++) {
          formValue.tax_charges[i].calculation_order = i + 1;
        }
      }
      if (this.selectedPolicyType.name === 'Child Policy') {
        delete formValue.payment_charges;
        delete formValue.cancellation_charges;
        delete formValue.tax_charges;
        if (formValue.child_charges) {
          formValue.child_charges.allow_children = this.isAllowChild;
        } else {
          formValue.child_charges = {
            allow_children: this.isAllowChild,
          };
        }
      }

      if (this.selectedPolicyType.name === 'Payment Policy') {
        delete formValue.cancellation_charges;
        delete formValue.tax_charges;
        delete formValue.child_charges;

        if (formValue.payment_charges) {
          formValue.payment_charges.amount_refundable = this.isAmountRefundable;
          formValue.payment_charges.credit_card_full_payment = this.isCreditCardFullAmountChecked;
        } else {
          formValue.payment_charges = {
            ...formValue.payment_charges,
            credit_card_full_payment: this.isCreditCardFullAmountChecked,
            amount_refundable: this.isAmountRefundable,
          };
        }
      }

      if (this.policyId) {
        this.policiesService.updatePolicy(this.policyId, formValue).subscribe(
          (response) => {
            this.showLoader = false;
            if (response['success'] === true) {
              this.goToBackButton();
              this.toaster.success(response['message'], 'Success', {
                timeOut: 3000,
              });
            } else {
              this.toaster.error(response['message'], 'Error', {
                timeOut: 3000,
              });
            }
          },
          (error) => {
            this.showLoader = false;
          }
        );
      } else {
        this.policiesService.addPolicy(formValue).subscribe(
          (response) => {
            this.showLoader = false;
            if (response['success'] === true) {
              this.policyForm.reset();
              this.selectedPolicyType = undefined;
              this.selectAllHotels = false;
              this.hotels.map((hotel) => (hotel.checked = false));

              this.toaster.success(response['message'], 'Success', {
                timeOut: 3000,
              });
            } else {
              this.toaster.error(response['message'], 'Error', {
                timeOut: 3000,
              });
            }
          },
          (error) => {
            console.error(error);
            this.showLoader = false;
          }
        );
      }
    } else {
      this.toaster.error('Please fill up the form correctly.', null, {
        timeOut: 3000,
      });
    }
  }

  onHotelCheckChange(hotel) {
    hotel.checked = !hotel.checked;
  }

  goToBackButton(): void {
    if (this.customEditEnabled) {
      this.mode = 'view';
      this.isViewMode = true;
      this.customEditEnabled = false;
    }
    this.location.back();
  }

  editPolicy() {
    this.mode = 'edit';
    this.isViewMode = false;
    this.customEditEnabled = true;
    this.location.go(`policies/view-policy/${this.policyId}/edit`);
  }

  onAmountRefundableChange() {
    this.isAmountRefundable = !this.isAmountRefundable;
  }

  public sortHotels(sort: Sort): void {
    const data = this.hotels;
    if (!sort.active || sort.direction === '') {
      this.hotels = data;
      return;
    }
    this.hotels = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.compare(a.name, b.name, isAsc);
        case 'cityName':
          return this.compare(a.cityName, b.cityName, isAsc);
        case 'countryName':
          return this.compare(a.countryName, b.countryName, isAsc);
        case 'checked':
          return this.compare(a.checked, b.checked, isAsc);
        default:
          return 0;
      }
    });
  }

  private compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onPageChange(page: PageEvent) {
    this.currentPage = page;
    this.selectCurrentPageHotels = false;
    let recordCount = 0;

    for (
      let index = page.pageIndex * page.pageSize;
      index < page.pageIndex * page.pageSize + page.pageSize;
      index++
    ) {
      const hotel = this.hotels[index];
      if (hotel && hotel.checked) recordCount++;
    }

    if (page.pageSize === recordCount) {
      this.selectCurrentPageHotels = true;
    }
  }

  onSelectCurrentPageHotel(isUpdateFlag = true) {
    if (isUpdateFlag)
      this.selectCurrentPageHotels = !this.selectCurrentPageHotels;
    for (
      let index = this.currentPage.pageIndex * this.currentPage.pageSize;
      index <
      this.currentPage.pageIndex * this.currentPage.pageSize +
      this.currentPage.pageSize;
      index++
    ) {
      const hotel = this.hotels[index];
      if (hotel) hotel.checked = this.selectCurrentPageHotels;
    }
  }

  dropTaxForm(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.taxChargesForm.controls,
      event.previousIndex,
      event.currentIndex
    );
    moveItemInArray(
      this.taxChargesForm.value,
      event.previousIndex,
      event.currentIndex
    );
  }


  // New Code
  sortByAlphabet(event: any) {
    if (this.hotels && this.hotels.length > 0) {
      this.displayHotelList.data = this.hotels.filter((x) =>
        event.target.value ? x.name.substr(0, 1).toLowerCase() === event.target.value : x
      );
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.displayHotelList.filter = filterValue.trim().toLowerCase();
  }
}
