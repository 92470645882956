import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class HttpClientService {
    responseData: any;
    returnReponse: any;

    constructor(
        private http: HttpClient,
        private spinner: NgxSpinnerService,
    ) { }

    /**
      * This function is used for post method to get data from api
      * @param url
      * @param data
      * @returns return response from api
    */
    post(url: any, data: any): Observable<any> {
        this.spinner.show();
        let headers = new HttpHeaders();
        return this.http.post(url, data).pipe(map(res => {
            this.spinner.hide();
            return res;
        }), catchError((error) => {
            this.serverConnectionError();
            return throwError(error);
        }));
    }

    put(url: any, data: any): Observable<any> {
        this.spinner.show();
        return this.http.put(url, data).pipe(map(res => {
            this.spinner.hide();
            return res;
        }), catchError((error) => {
            this.serverConnectionError();
            return throwError(error);
        }));
    }

    /**
      * This function is used for get method to get data from api
      * @param url
      * @returns return response from api
    */
    get(url: any): Observable<any> {
        this.spinner.show();
        return this.http.get(url).pipe(map(res => {
            this.spinner.hide();
            return res;
        }), catchError((error) => {
            this.serverConnectionError();
            return throwError(error);
        }));
    }

    /**
      * This function is used for delete method to delete data from api
      * @param url
      * @returns return response from api
    */
    delete(url: any) {
        this.spinner.show();
        this.returnReponse = new Promise((resolve, reject) => {
            this.http.delete(url).subscribe(
                res => {
                    this.spinner.hide();
                    this.responseData = res;
                    if (this.responseData.status === 'fail') {
                        reject(this.responseData.message = 'some error occure');
                    }
                    resolve(this.responseData);
                },
                error => {
                    this.serverConnectionError();
                    reject(error);
                }
            );
        });
        return this.returnReponse;
    }

    serverConnectionError() {
        // if (error.status === 500 || error.status === 502 || error.status === 503 || error.status === 504 || error.status === 0) {
        this.spinner.hide();
        // this.functions.snackMessage('There was a problem communicating with Angage servers, try again later', '', 'error-snackbar');
        // }
    }
}