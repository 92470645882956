import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";

import { PoliciesRoutingModule } from "./policies-routing.module";
import { AddPolicyComponent } from "./add-policy/add-policy.component";
import { MatGoogleMapsAutocompleteModule } from "@angular-material-extensions/google-maps-autocomplete";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { ListPoliciesComponent } from "./list-policies/list-policies.component";
import {DragDropModule} from '@angular/cdk/drag-drop';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';

@NgModule({
  declarations: [AddPolicyComponent, ListPoliciesComponent],
  imports: [
    CommonModule,
    PoliciesRoutingModule,
    MatGoogleMapsAutocompleteModule,
    MatBottomSheetModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    DragDropModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    RxReactiveFormsModule
  ],
  providers: [DatePipe],
  exports: [AddPolicyComponent, ListPoliciesComponent],
})
export class PoliciesModule {}
