import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {

  constructor(private route: Router) { }
  
  ngOnInit() {
    this.smoothScroll();  
  }

  smoothScroll() {
    this.route.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo({
          top: 0,
          left: 100,
          behavior: 'smooth'
        });
      }
    });
  }
}
