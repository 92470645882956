import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { PropertyService } from '../../property-service.service';
import { ChargeableFacilitiesService, HotelMappingService, InterestsService, StyleService } from '../../../_services';
import { InterestModalComponent } from './interest-modal/interest-modal.component';
import { ChargeableFacility, ChildAges, HotelCategory, Style } from 'src/app/_interfaces/property.interface';

@Component({
    selector: 'app-interest-settings',
    templateUrl: './interest-settings.component.html',
    styleUrls: ['./interest-settings.component.scss']
})

export class InterestSettingsComponent implements OnInit {

    @Input() propertyDetails: any = {};
    @Output() changeNextTab: EventEmitter<any> = new EventEmitter<any>();
    mode: string;
    hotelId: any;
    listOfInterest: any = [];
    listOfStyle: any = [];
    styleSettings: Style = {};
    hotelCategorySettings: HotelCategory = {};
    listOfHotelCategory: any = [];
    listOfChargeableFacilities: any = [];
    queryParameter: any;
    childAges: any = [
        { id: 0, years: '0-3 years', checked: false },
        { id: 1, years: '3-10 years', checked: false },
        { id: 2, years: '10-18 years', checked: false }
    ];
    chargeableFacility: ChargeableFacility = {};
    chargeableFacilityId: any = [];
    // childAgeSettings: ChildAges = {};
    // isSelectChildAges: any = [];

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public matDialog: MatDialog,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        private interestService: InterestsService,
        private propertyService: PropertyService,
        private styleService: StyleService,
        private hotelMappingService: HotelMappingService,
        private chargeableFacilitiesService: ChargeableFacilitiesService
    ) { }

    ngOnInit() {
        this.initilization();
    }

    initilization() {
        window.scrollTo(0, 0);
        this.mode = this.activatedRoute.snapshot.params.mode || this.propertyDetails.mode;
        this.hotelId = this.activatedRoute.snapshot.paramMap.get('hotelId') || this.activatedRoute.snapshot.params.propertyId;
        if (this.activatedRoute.snapshot.queryParams.inventory_allocation) {
            this.queryParameter = {
                queryParams: {
                    inventory_allocation: this.activatedRoute.snapshot.queryParams.inventory_allocation
                }
            }
        }
        this.getInterests();
        this.getStyle();
        this.getHotelCategory();
        this.getHotelDetails();
        this.getChargeableFacilities();
    }

    /**
     * Get hotel details
     */
    getHotelDetails() {
        this.spinner.show();
        this.propertyService.getHotelPropertyDetailsStep1(this.hotelId).subscribe((response: any) => {
            this.spinner.hide();
            if (response['success']) {
                this.propertyDetails = response.data;
                if (this.propertyDetails.interests_setting.style.length > 0) {
                    this.styleSettings = this.propertyDetails.interests_setting.style[0];
                }
                if (this.propertyDetails.interests_setting.hotel_category.length > 0) {
                    this.hotelCategorySettings = this.propertyDetails.interests_setting.hotel_category[0];
                }
                if (this.propertyDetails.interests_setting.chargeable_facility.length > 0) {
                    this.chargeableFacility = this.propertyDetails.interests_setting.chargeable_facility[0];
                    this.chargeableFacilityId = this.chargeableFacility.chargeableFacilityId;
                }
                // if (this.propertyDetails.interests_setting.child_ages.length > 0) {
                //     this.isSelectChildAges = this.propertyDetails.interests_setting.child_ages[0].childId;
                //     this.childAgeSettings = this.propertyDetails.interests_setting.child_ages[0];
                //     this.childAgesChecked();
                // }
            } else {
                this.toastr.error(response.message, 'Error');
            }
        });
    }

    /**
     * Get interests
     */
    getInterests() {
        this.spinner.show();
        this.interestService.getInterests().subscribe((response: any) => {
            this.spinner.hide();
            if (response.success) {
                this.listOfInterest = response.data;
            } else {
                this.toastr.error(response.message, 'Error');
            }
        }, error => console.log(error));
    }

    /**
     * Get style
     */
    getStyle() {
        this.spinner.show();
        this.styleService.getStyle().subscribe((response: any) => {
            this.spinner.hide();
            if (response.success) {
                this.listOfStyle = response.data;
            } else {
                this.toastr.error(response.message, 'Error');
            }
        }, error => console.log(error));
    }

    /**
     * Get hotel category
     */
    getHotelCategory() {
        this.hotelMappingService.getHotelCategory().subscribe((response: any) => {
            if (response.success) {
                this.listOfHotelCategory = response.data;
            } else {
                this.toastr.error(response.message, 'Error');
            }
        }, error => console.log(error));
    }

    /**
     * Get chargeable facilities
     */
    getChargeableFacilities() {
        this.chargeableFacilitiesService.getChargeableFacilities().subscribe((response: any) => {
            if (response.success) {
                this.listOfChargeableFacilities = response.data;
            } else {
                this.toastr.error(response.message, 'Error');
            }
        }, error => console.log(error));
    }

    /**
     * Open interest modal
     * @param item items
     */
    interestEditorModal(item: any) {
        const detailsData = this.propertyDetails.interests_setting.interests.filter(data => data.interestId == item.id)[0];
        const dialogRef = this.matDialog.open(InterestModalComponent, {
            width: '800px',
            height: '650px',
            disableClose: true,
            data: {
                item,
                hotelId: this.hotelId,
                details: detailsData,
                mode: this.mode,
                type: 'interest'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && this.mode !== 'view') {
                this.initilization();
            } else {
                this.getHotelDetails();
                this.getInterests();
            }
            this.spinner.hide();
        });
    }

    /**
     * Go to next page
     */
    goToNext() {
        if (this.mode && this.mode === 'edit') {
            this.changeNextTab.emit('roomType');
        } else {

            this.router.navigate(['property', 'step-3-add-property', this.hotelId], this.queryParameter);
        }
    }

    /**
     * Go to previous page
     */
    goToBack() {
        if (this.mode && this.mode === 'edit') {
            this.changeNextTab.emit('hotel-overview');
        } else {
            this.router.navigate(['property', 'hotel-overview', this.hotelId], this.queryParameter);
        }
    }

    /**
     * On button click to save style
     */
    styleSubmit() {
        this.styleSettings.hotelId = this.hotelId;
        this.propertyService.styleSave(this.styleSettings).subscribe((response: any) => {
            if (response.success) {
                this.styleSettings = response.data;
                this.toastr.success(response.message, 'Success');
            } else {
                this.toastr.error(response.message, 'Error');
            }
        });
    }

    /**
     * On button click to save hotel category
     */
    hotelCategorySubmit() {
        this.hotelCategorySettings.hotelId = this.hotelId;
        this.propertyService.hotelCategorySave(this.hotelCategorySettings).subscribe((response: any) => {
            if (response.success) {
                this.hotelCategorySettings = response.data;
                this.toastr.success(response.message, 'Success');
            } else {
                this.toastr.error(response.message, 'Error');
            }
        });
    }

    /**
     * Select Chargeable Facilities 
     * @param event change event object
     */
    changeChargeableFacility(event: any) {
        if (event.target.checked) {
            this.chargeableFacilityId.push(event.target.value);
        } else {
            this.chargeableFacilityId = this.chargeableFacilityId.filter((items: any) => items != event.target.value);
        }
    }

    /**
     * Save Chargeable Facility 
     */
    chargeableFacilitySave() {
        this.chargeableFacility.chargeableFacilityId = this.chargeableFacilityId;
        this.chargeableFacility.hotelId = this.hotelId;
        this.propertyService.chargeableFacilitySave(this.chargeableFacility).subscribe((response: any) => {
            if (response.success) {
                this.chargeableFacility = response.data;
                this.toastr.success(response.message, 'Success');
            } else {
                this.toastr.error(response.message, 'Error');
            }
        });
    }

    // changeChildAges(event: any) {
    //     if (event.target.checked) {
    //         this.isSelectChildAges.push(event.target.value)
    //     } else {
    //         this.isSelectChildAges = this.isSelectChildAges.filter(item => item != event.target.value);
    //     }
    //     this.childAgeSettings.childId = this.isSelectChildAges;
    // }

    // childAgeSubmit() {
    //     this.childAgeSettings.hotelId = this.hotelId;
    //     this.propertyService.childAgeSave(this.childAgeSettings).subscribe((response: any) => {
    //         if (response.success) {
    //             this.childAgeSettings = response.data;
    //             this.childAgesChecked();
    //             this.toastr.success(response.message, 'Success');
    //         } else {
    //             this.toastr.error(response.message, 'Error');
    //         }
    //     });
    // }

    // childAgesChecked() {
    //     this.childAges.forEach(val => {
    //         this.isSelectChildAges.forEach(v => {
    //             if (val.id == v) {
    //                 val.checked = true;
    //             }
    //         });
    //     });
    // }
}
