import { Injectable } from '@angular/core';
import { mastersApi } from '../../_constants/api-list';
import { environment } from '../../../environments/environment';
import { HttpClientService } from '../index';

@Injectable({
    providedIn: 'root',
})
export class RoomTypeService {

    constructor(
        private httpClientService: HttpClientService
    ) { }

    /**
     * Save room type 
     * @param requestData request data
     * @returns Return response from api
     */
    saveRoomType(requestData: any) {
        let url = environment.origin + mastersApi.SAVE_ROOM_TYPE;
        return this.httpClientService.post(url, requestData);
    }

    /**
     * Get room type
     * @param id id 
     * @returns Return response from api
     */
    getRoomType(id?: any, pagination?: any) {
        let url = environment.origin + mastersApi.GET_ROOM_TYPE;
        if (id) {
            url += `/${id}`;
        }
        if (pagination) {
            url += `?currentPage=${pagination.currentPage}&pageSize=${pagination.itemsPerPage}`;
        }
        return this.httpClientService.get(url);
    }

    /**
     * Delete room type
     * @param id id
     * @returns Return response from api
     */
    deleteRoomType(id?: any) {
        let url = environment.origin + mastersApi.DELETE_ROOM_TYPE + id;
        return this.httpClientService.delete(url);
    }
}
