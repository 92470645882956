export const FieldsList = [
  'net_rate_weekday',
  'net_rate_weekend',
  'per_child_net_rate_weekday',
  'per_child_net_rate_weekend',
  'per_infant_net_rate_weekday',
  'per_infant_net_rate_weekend',
  'extra_adult_net_rate_weekday',
  'extra_adult_net_rate_weekend',
  'extra_child_net_rate_weekday',
  'extra_child_net_rate_weekend',
  'extra_infant_net_rate_weekday',
  'extra_infant_net_rate_weekend',
  'per_adult_breakfast_net_rate_weekend',
  'per_adult_breakfast_net_rate_weekday',
  'per_child_breakfast_net_rate_weekend',
  'per_child_breakfast_net_rate_weekday',
  'per_adult_halfboard_net_rate_weekday',
  'per_adult_halfboard_net_rate_weekend',
  'per_child_halfboard_net_rate_weekday',
  'per_child_halfboard_net_rate_weekend'
];
