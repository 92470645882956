<div class="page-bar">
  <div class="page-title-breadcrumb">
    <div class="pull-left">
      <div class="page-title">List Policies</div>
    </div>
    <ol class="breadcrumb page-breadcrumb pull-right">
      <li>
        <i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="index.php"
          >Home</a
        >&nbsp;<i class="fa fa-angle-right"></i>
      </li>
      <li class="active">List Promotions</li>
    </ol>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="mat-elevation-z8">
      <table
        matSortActive="policyName"
        matSortDirection="asc"
        mat-table
        [dataSource]="displayPolicyList"
        matSort
        (matSortChange)="sortData($event)"
      >
        <ng-container matColumnDef="policyName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            title="Sort By Policy Name"
          >
            Policy Name
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.policy_name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="policyType">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            title="Sort By Policy Name"
          >
            Policy Type
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.policy_type.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="fromDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            title="Sort By Start Date"
          >
            Start Date
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.from_date | date: "MM/dd/yyyy" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="toDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            title="Sort By End Date"
          >
            End Date
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.to_date | date: "MM/dd/yyyy" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="viewHotels">
          <th mat-header-cell *matHeaderCellDef>Selected Hotels</th>
          <td mat-cell *matCellDef="let element">
            <a
              style="
                color: #0047ab;
                text-decoration-line: underline;
                font-size: small;
              "
              data-toggle="modal"
              (click)="onViewHotels(element)"
              data-target="#view-rooms"
              data-backdrop="static"
              data-keyboard="false"
            >
              Show Hotels
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
          <td mat-cell *matCellDef="let element" class="text-center pt-2">
            <button
              (click)="editPolicy(element.id, 'view')"
              class="btn btn-outline black-bgcolor btn-circle m-b-10 mr-2"
            >
              <i class="fa fa-eye" aria-hidden="true"></i>
            </button>
            <button
              (click)="editPolicy(element.id, 'edit')"
              type="button"
              class="btn btn-outline blue-bgcolor btn-circle m-b-10 mr-2"
            >
              <i aria-hidden="true" class="fa fa-pencil"></i>
            </button>
            <button
              type="button"
              class="btn red btn-outline btn-circle m-b-10"
              data-toggle="modal"
              data-target="#delete-promotion"
              (click)="setDeletePromotionID(element.id)"
              data-backdrop="static"
              data-keyboard="false"
            >
              <i aria-hidden="true" class="fa fa-trash-o"></i>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 20, 30]"></mat-paginator>
    </div>
    <div class="modal fade" id="delete-promotion">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h2>Are you sure?</h2>
            <button
              type="button"
              id="closeModal"
              #closeBtnDelete
              class="close"
              data-dismiss="modal"
            >
              &times;
            </button>
          </div>
          <div class="modal-body delate-popup text-center">
            <form>
              <div class="form-group">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <h2>It will permanently deleted !</h2>
              </div>
              <div class="form-group">
                <button
                  (click)="deletePolicy()"
                  type="submit"
                  class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn btn-warning mr-2"
                >
                  Yes, delete it!
                </button>
                <button
                  type="button"
                  class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn btn-danger"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="view-rooms">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <button
          type="button"
          id="closeModal"
          #closeBtnAdd
          class="close"
          data-dismiss="modal"
        >
          &times;
        </button>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <table class="table text-left">
          <thead>
            <tr>
              <th class="sr-no" style="min-width: 40px">Sr No</th>
              <th style="min-width: 150px">Hotel Name</th>
              <th>Email</th>
              <th style="min-width: 120px">Contact Number</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of viewHotels?.hotels; index as i">
              <td class="sr-no">{{ i + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.contact_num }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showLoader" class="d-flex justify-content-center position-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
