import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ViewPropertyComponent } from './view-property/view-property.component';
import { ListingPropertyComponent } from './listing-property/listing-property.component';
import { AddPropertyStep1Component } from './view-property/add-property-step1/add-property-step1.component';
import { AddPropertyStep2Component } from "./view-property/add-property-step2/add-property-step2.component";
import { AddPropertyStep3Component } from "./view-property/add-property-step3/add-property-step3.component";
import { AddPropertyStep4Component } from "./view-property/add-property-step4/add-property-step4.component";
import { AddPropertyStep5Component } from "./view-property/add-property-step5/add-property-step5.component";
import { HotelOverviewComponent } from "./view-property/add-property-step6/hotel-overview.component";
import { InterestSettingsComponent, RoomFacilityAndInclusionsComponent } from "./index";

const routes: Routes = [
  {
    path: 'step-1-add-property',
    component: AddPropertyStep1Component
  }, {
    path: 'step-2-add-property/:hotelId',
    component: AddPropertyStep2Component
  }, {
    path: 'step-3-add-property/:hotelId',
    component: AddPropertyStep3Component
  }, {
    path: 'step-4-add-property/:hotelId',
    component: AddPropertyStep4Component
  }, {
    path: 'step-5-add-property/:hotelId',
    component: AddPropertyStep5Component
  }, {
    path: 'hotel-overview/:hotelId',
    component: HotelOverviewComponent
  }, {
    path: 'interests-settings/:hotelId',
    component: InterestSettingsComponent
  }, {
    path: 'room-facilities-and-inclusions/:hotelId',
    component: RoomFacilityAndInclusionsComponent
  }, {
    path: 'view-property/:propertyId/:mode',
    component: ViewPropertyComponent
  }, {
    path: 'list-property',
    component: ListingPropertyComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PropertyRoutingModule { }
