import { Directive, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Directive()
export abstract class AbstractBaseComponent implements OnDestroy {
    protected destroyed$ = new ReplaySubject(1);

    /**
     * generate random color
     * @returns color code
     */
    generateRandomColor() {
        var color = Math.floor(0x1000000 * Math.random()).toString(16);
        return '#' + ('000000' + color).slice(-6);
    }

    /**
       * this function is used for froala editor
       * @param {froalKey} string string
       */
    froalaUploads() {
        const options: Object = {
            // key: froalKey,
            // imageUploadURL: froalaUpload.froalauploadpathimage,
            // videoUploadURL: froalaUpload.froalauploadpathvideo,
            // fileUploadURL: froalaUpload.froalauploadpathfile,
            fileMaxSize: 250 * 1024 * 1024,
            videoMaxSize: 250 * 1024 * 1024,
            imageMaxSize: 250 * 1024 * 1024,
            // scrollableContainer: cssClass,
            attribution: false,
            htmlRemoveTags: [],
            toolbarButtons: {
                moreText: {
                    buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],
                    align: 'left',
                    buttonsVisible: 3
                },
                moreParagraph: {
                    buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                    align: 'left',
                    buttonsVisible: 3
                },
                moreRich: {
                    buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
                    align: 'left',
                    buttonsVisible: 3
                },
                moreMisc: {
                    buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
                    align: 'right',
                    buttonsVisible: 2
                }
            },
            // events: {
            //     'froalaEditor.image.removed': function (e, editor, $img) {
            //         $.ajax({
            //             method: 'POST',
            //             url: froalaUpload.froalapathdeleteimage,
            //             data: {
            //                 src: $img.attr('src')
            //             }
            //         })
            //             .done(function (data) {
            //             })
            //             .fail(function () {
            //             });
            //     },
            //     'froalaEditor.file.unlink': function (e, editor, file) {
            //         $.ajax({
            //             method: 'POST',
            //             url: froalaUpload.froalapathdeletefile,
            //             data: {
            //                 src: file.getAttribute('href')
            //             }
            //         })
            //             .done(function (data) {
            //             })
            //             .fail(function (err) {
            //             });
            //     }
            // },
        };
        return options;
    }

    /**
     * This function is used for froala editor
     */
    froalaEmbedly() {
        const node = document.createElement('script');
        node.src = 'https://cdn.embedly.com/widgets/platform.js';
        node.type = 'text/javascript';
        document.getElementsByTagName('head')[0].appendChild(node);
    }

    ngOnDestroy(): void {
        this.destroyed$.next(null);
        this.destroyed$.complete();
    }
}
