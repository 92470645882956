import { Injectable } from '@angular/core';
import { mastersApi } from '../../_constants/api-list';
import { environment } from '../../../environments/environment';
import { HttpClientService } from '../index';

@Injectable({
    providedIn: 'root',
})
export class PolicyTypeService {

    constructor(
        private httpClientService: HttpClientService
    ) { }

    /**
     * Save policy type 
     * @param requestData request data
     * @returns Return response from api
     */
    savePolicyType(requestData: any) {
        let url = environment.origin + mastersApi.SAVE_POLICY_TYPE;
        return this.httpClientService.post(url, requestData);
    }

    /**
     * Get policy type
     * @param policyTypeId policy type id 
     * @returns Return response from api
     */
    getPolicyType(policyTypeId?: any) {
        let url = environment.origin + mastersApi.GET_POLICY_TYPE;
        if (policyTypeId) {
            url += `/${policyTypeId}`;
        }
        return this.httpClientService.get(url);
    }

    /**
     * Delete policy type
     * @param policyTypeId policy type id
     * @returns Return response from api
     */
    deletePolicyType(policyTypeId?: any) {
        let url = environment.origin + mastersApi.DELETE_POLICY_TYPE + policyTypeId;
        return this.httpClientService.delete(url);
    }
}
