import { Component, EventEmitter, Inject, Injector, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { cloneDeep } from 'lodash';
import { PropertyService } from '../../../../property/property-service.service';
import { ConfirmDialogComponent } from '../../../../shared';
import { message } from '../../../../_constants/constants';

@Component({
    selector: 'app-interest-modal',
    templateUrl: './interest-modal.component.html',
    styleUrls: ['./interest-modal.component.scss']
})
export class InterestModalComponent implements OnInit {

    @Output() changeNextTab: EventEmitter<any> = new EventEmitter<any>();
    generalForm: FormGroup;
    isHovering: boolean;
    selectedImageObj: any = [];
    imagePreview: any = [];
    openImageModal = false;
    deleteImage: any = [];
    toaster: any;
    isMediaRequired: any = false;
    validationmsg = message.requiredmsg;
    currentImageIndex: any;
    updateMediaFiles: any = [];

    constructor(
        public matDialogRef: MatDialogRef<InterestModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private injector: Injector,
        private formBuilder: FormBuilder,
        private spinner: NgxSpinnerService,
        private matDialog: MatDialog,
        public propertyService: PropertyService,
    ) { }

    ngOnInit(): void {
        this.toaster = this.injector.get(ToastrService);
        this.initialFormLoad();
    }

    initialFormLoad() {
        this.generalForm = this.formBuilder.group({
            hotelId: [this.data.hotelId],
            id: [this.data?.details?.id],
            name: [this.data?.details?.name, Validators.required],
            description: [this.data?.details?.description, Validators.required],
            media: [this.data?.details?.media]
        });
        if (this.data?.details?.media?.length > 0) {
            this.imagePreview = cloneDeep(this.data?.details?.media);
        }
    }

    onDrop(files: FileList) {
        for (let i = 0; i < files.length; i++) {
            const extentions = files[i].type.split('/')[1];
            if (extentions === 'jpg' || extentions === 'jpeg' || extentions === 'png' || extentions === 'mp4' || extentions === 'm4v') {
                this.selectedImageObj.push(files[i]);
                const reader = new FileReader();
                reader.readAsDataURL(files[i]);
                reader.onload = (data: any) => {
                    this.imagePreview.push(data.target.result);
                };
            } else {
                this.toaster.error('Only jpg, jpeg, png, mp4, m4v formats allowed', 'Error', {
                    timeOut: 3000,
                });
            }
        }
        this.mediaGalleryValidation();
    }

    onMediaDelete(i, media: string) {
        this.imagePreview.splice(i, 1);
        this.selectedImageObj.splice(i, 1);
        if (this.generalForm.value.media && this.generalForm.value.media.length > 0) {
            this.generalForm.value.media = this.generalForm.value.media.filter(items => items !== media);
        }
        this.mediaGalleryValidation();
    }

    openImageAsModal(index: number): void {
        this.currentImageIndex = index;
        this.openImageModal = true;
    }

    closeImageModal() {
        this.openImageModal = false;
    }

    mediaGalleryValidation() {
        this.isMediaRequired = this.selectedImageObj.length === 0 && (!this.generalForm.value?.media || this.generalForm.value?.media?.length == 0);
    }

    /**
     * On submit click
     * @returns response from api
     */
    onSubmitClick() {
        this.mediaGalleryValidation();
        if (this.isMediaRequired) {
            return false;
        }

        this.spinner.show();
        switch (this.data.type) {
            case 'interest':
                this.interestSave();
                break;
            case 'roomfacility':
                this.roomFacilitySave();
                break;
            case 'inclusions':
                this.inclusionsSave();
                break;
        }
    }

    /**
     * Save interest
     */
    interestSave() {
        this.generalForm.value.interestId = this.data.item.id;
        this.propertyService.interestSave(this.generalForm.value, this.selectedImageObj).subscribe((data: any) => {
            if (data['success']) {
                this.toaster.success(data['message'], 'Success', { timeOut: 3000 });
                this.matDialogRef.close('success');
            } else {
                this.toaster.error(data['message'], 'Error', { timeOut: 3000 });
            }
            this.spinner.hide();
        }, error => console.log(error));
    }

    /**
     * Save room facility
     */
    roomFacilitySave() {
        this.generalForm.value.roomFacilityId = this.data.item.id;
        this.propertyService.saveRoomFacility(this.generalForm.value, this.selectedImageObj).subscribe((data: any) => {
            if (data['success']) {
                this.toaster.success(data['message'], 'Success', { timeOut: 3000 });
                this.matDialogRef.close('success');
            } else {
                this.toaster.error(data['message'], 'Error', { timeOut: 3000 });
            }
            this.spinner.hide();
        }, error => console.log(error));
    }

    /**
     * Save inclusions
     */
    inclusionsSave() {
        this.generalForm.value.inclusionsId = this.data.item.id;
        this.propertyService.saveInclusions(this.generalForm.value, this.selectedImageObj).subscribe((data: any) => {
            if (data['success']) {
                this.toaster.success(data['message'], 'Success', { timeOut: 3000 });
                this.matDialogRef.close('success');
            } else {
                this.toaster.error(data['message'], 'Error', { timeOut: 3000 });
            }
            this.spinner.hide();
        }, error => console.log(error));
    }

    /**
     * confirmation dialog for delete property
     * @param element property id
     */
    confirmationDialog() {
        const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
            width: '350px',
            height: '380px',
            data: {
                title: 'Are you sure?',
                message: 'It will permanently deleted !',
                okBtnLabel: 'Yes, delete it!',
                cancelBtnLabel: 'Cancel'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                switch (this.data.type) {
                    case 'interest':
                        this.deleteInterest();
                        break;
                    case 'roomfacility':
                        this.deleteRoomFacility();
                        break;
                    case 'inclusions':
                        this.deleteInclusions();
                        break;
                }
            }
        });
    }

    /**
     * Delete interest
     */
    deleteInterest() {
        this.spinner.show();
        this.propertyService.deleteInterests(this.data?.details?.id, this.data.hotelId).subscribe((data) => {
            this.showToasterMsg(data);
            this.spinner.hide();
        }, error => console.log(error));
    }

    /**
     * Delete room facility
     */
    deleteRoomFacility() {
        this.spinner.show();
        this.propertyService.deleteRoomFacilities(this.data?.details?.id, this.data.hotelId).subscribe((data) => {
            this.showToasterMsg(data);
            this.spinner.hide();
        }, error => console.log(error));
    }

    /**
     * Delete inclusions
     */
    deleteInclusions() {
        this.spinner.show();
        this.propertyService.deleteInclusion(this.data?.details?.id, this.data.hotelId).subscribe((data) => {
            this.showToasterMsg(data);
            this.spinner.hide();
        }, error => console.log(error));
    }

    showToasterMsg(data: any) {
        if (data['success']) {
            this.toaster.success(data['message'], 'Success', { timeOut: 3000 });
            this.matDialogRef.close('close');
        } else {
            this.toaster.error(data['message'], 'Error', { timeOut: 3000 });
        }
    }
}
