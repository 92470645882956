import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { PromotionsService } from "../promotions.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { RoomTypeService } from "../../_services";

@Component({
  selector: "app-add-promotion",
  templateUrl: "./add-promotion.component.html",
  styleUrls: ["./add-promotion.component.css"],
})
export class AddPromotionComponent implements OnInit {
  promotionId: string;
  mode: string = "edit";
  promotionDetails: any;
  selectedRooms = [];
  isViewMode: boolean = false;
  showLoader = false;
  selectAllRooms: boolean;
  customEditEnabled: boolean = false;

  public isPercentOff = false;
  public isFixedDiscount = false;
  public isFreeNights = false;
  public isValueAdded = false;

  public rooms: any = [];

  promotionForm: FormGroup;
  isFormSubmitted = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private toaster: ToastrService,
    private roomTypeService: RoomTypeService,
    private promotionsServie: PromotionsService
  ) {}

  ngOnInit(): void {
    this.promotionId = this.route.snapshot.paramMap.get("promotionId");
    this.mode = this.route.snapshot.paramMap.get("mode");
    if (this.mode === "view") {
      this.isViewMode = true;
    }
    this.getPromotion();

    this.initForm();
    if (!this.promotionId) this.getRoomType();
  }

  getPromotion() {
    if (this.promotionId) {
      this.promotionsServie
        .getPromotionById(this.promotionId)
        .subscribe((response) => {
          this.getRoomType();
          console.log("respone data: ", response);
          if (response["success"] === true) {
            console.log("successful");
            this.promotionDetails = response["data"];
            this.selectedRooms = this.promotionDetails["roomTypes"];

            if (this.promotionDetails.percent_off)
              this.onDiscountTypeChange("isPercentOff");
            if (this.promotionDetails.free_nights)
              this.onDiscountTypeChange("isFreeNights");
            if (this.promotionDetails.fix_discount)
              this.onDiscountTypeChange("isFixedDiscount");
            if (this.promotionDetails.value_added)
              this.onDiscountTypeChange("isValueAdded");

            this.promotionForm.patchValue({
              promotion_code: this.promotionDetails.promotion_code,
              promotion_name: this.promotionDetails.promotion_name,
              promotion_description: this.promotionDetails
                .promotion_description,
              percent_off: this.promotionDetails.percent_off,
              fix_discount: this.promotionDetails.fix_discount,
              free_nights: this.promotionDetails.free_nights,
              value_added: this.promotionDetails.value_added,
            });
          }
        });
    }
  }

  initForm() {
    this.promotionForm = new FormGroup({
      promotion_code: new FormControl(null, [
        Validators.required,
        Validators.pattern(
          new RegExp(/^([^0-9!@#%^&*()_+-=|/,.<`~>]+)([a-zA-Z0-9 %-:$/]+)$/)
        ),
      ]),
      promotion_name: new FormControl(null, [
        Validators.required,
        Validators.pattern(
          new RegExp(/^([^0-9!@#%^&*()_+-=|/,.<`~>]+)([a-zA-Z0-9 %-:$/]+)$/)
        ),
      ]),
      promotion_description: new FormControl(null, [Validators.required]),
      percent_off: new FormControl(null),
      fix_discount: new FormControl(null),
      free_nights: new FormControl(null),
      value_added: new FormControl(null),
    });
  }

  getRoomType() {
    this.roomTypeService.getRoomType().subscribe((data) => {
      console.log("respone data: ", data);
      if (data["success"] === true) {
        console.log("successful");
        this.rooms = data["data"];
        console.log(this.selectedRooms);

        for (const room of this.rooms) {
          let selectedRoomIndex = this.selectedRooms.findIndex((rm) => {
            return rm.id === room.id;
          });

          if (selectedRoomIndex === -1) room.checked = false;
          else room.checked = true;
        }
      }
    });
  }

  onRoomCheckChange(roomType) {
    roomType.checked = !roomType.checked;
    console.log("Room checked", this.rooms);
  }

  onDiscountTypeChange(discountType) {
    switch (discountType) {
      case "isPercentOff":
        this.isPercentOff = !this.isPercentOff;
        if (this.isPercentOff) {
          this.promotionForm.controls.percent_off.setValidators([
            Validators.required,
            Validators.max(100),
            Validators.min(1),
            Validators.pattern(new RegExp(/^[0-9]+$/)),
          ]);
          this.promotionForm.controls.percent_off.setErrors(null);
        } else {
          this.promotionForm.controls.percent_off.clearValidators();
          this.promotionForm.controls.percent_off.setErrors(null);
        }
        break;
      case "isFixedDiscount":
        this.isFixedDiscount = !this.isFixedDiscount;
        if (this.isFixedDiscount) {
          this.promotionForm.controls.fix_discount.setValidators([
            Validators.required,
            Validators.min(1),
            Validators.pattern(new RegExp(/^[0-9]+$/)),
          ]);
          this.promotionForm.controls.fix_discount.setErrors(null);
        } else {
          this.promotionForm.controls.fix_discount.clearValidators();
          this.promotionForm.controls.fix_discount.setErrors(null);
        }
        break;
      case "isFreeNights":
        this.isFreeNights = !this.isFreeNights;
        if (this.isFreeNights) {
          this.promotionForm.controls.free_nights.setValidators([
            Validators.required,
            Validators.min(1),
            Validators.pattern(new RegExp(/^[0-9]+$/)),
          ]);
          this.promotionForm.controls.free_nights.setErrors(null);
        } else {
          this.promotionForm.controls.free_nights.clearValidators();
          this.promotionForm.controls.free_nights.setErrors(null);
        }
        break;
      case "isValueAdded":
        this.isValueAdded = !this.isValueAdded;
        if (this.isValueAdded) {
          this.promotionForm.controls.value_added.setValidators([
            Validators.required,
          ]);
          this.promotionForm.controls.value_added.setErrors(null);
        } else {
          this.promotionForm.controls.value_added.clearValidators();
          this.promotionForm.controls.value_added.setErrors(null);
        }
        break;
    }
  }

  onSubmit() {
    if (this.promotionForm.valid) {
      let formValue = this.promotionForm.value;

      if (
        !this.isPercentOff &&
        !this.isFixedDiscount &&
        !this.isFreeNights &&
        !this.isValueAdded
      ) {
        this.toaster.error("Please at least one discount value.", null, {
          timeOut: 3000,
        });
        return;
      }
      this.isFormSubmitted = true;
      this.showLoader = true;
      console.log(this.promotionForm.value);
      formValue.percent_off = this.isPercentOff
        ? Number(formValue.percent_off)
        : null;
      formValue.fix_discount = this.isFixedDiscount
        ? Number(formValue.fix_discount)
        : null;
      formValue.free_nights = this.isFreeNights
        ? Number(formValue.free_nights)
        : null;

      formValue.value_added = this.isValueAdded ? formValue.value_added : null;

      let rooms = [];
      for (const room of this.rooms) {
        if (room.checked) rooms.push({ room_id: room.id });
      }
      formValue.rooms = rooms;

      if (this.promotionId) {
        this.promotionsServie
          .updatePromotion(this.promotionId, formValue)
          .subscribe(
            (response) => {
              this.showLoader = false;
              if (response["success"] === true) {
                this.goToBackButton();
                this.toaster.success(response["message"], "Success", {
                  timeOut: 3000,
                });
              } else {
                this.toaster.error(response["message"], "Error", {
                  timeOut: 3000,
                });
              }
            },
            (error) => {
              console.error(error);
              this.showLoader = false;
            }
          );
      } else {
        this.promotionsServie.addPromotion(formValue).subscribe(
          (response) => {
            this.showLoader = false;
            if (response["success"] === true) {
              this.isPercentOff = false;
              this.isFixedDiscount = false;
              this.isFreeNights = false;
              this.isValueAdded = false;
              this.promotionForm.clearAsyncValidators();
              this.promotionForm.patchValue({
                promotion_code: "",
                promotion_name: "",
                promotion_description: "",
                percent_off: null,
                fix_discount: null,
                free_nights: null,
                value_added: "",
              });

              this.promotionForm.reset();

              this.rooms.map((room) => (room.checked = false));
              // // let copy_rooms = Object.assign(
              // //   [],
              // //   JSON.parse(JSON.stringify(rooms))
              // // );

              // this.rooms = copy_rooms;
              this.toaster.success(response["message"], "Success", {
                timeOut: 3000,
              });
            } else {
              this.toaster.error(response["message"], "Error", {
                timeOut: 3000,
              });
            }
          },
          (error) => {
            console.error(error);
            this.showLoader = false;
          }
        );
      }
    } else {
      this.toaster.error("Please fill up the form correctly.", null, {
        timeOut: 3000,
      });
    }
  }

  goToBackButton(): void {
    if (this.customEditEnabled) {
      this.mode = "view";
      this.isViewMode = true;
      this.customEditEnabled = false;
    }
    this.location.back();
  }

  editPromotion() {
    this.mode = "edit";
    this.isViewMode = false;
    this.customEditEnabled = true;
    this.location.go(`promotions/view-promotion/${this.promotionId}/edit`);
  }

  onSelectAllRoomsChanged() {
    this.selectAllRooms = !this.selectAllRooms;
    this.rooms.map((room) => (room.checked = this.selectAllRooms));
  }
}
