export const Pincode = [
    {
        country: "Andorra",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "United Arab Emirates",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Afghanistan",
        regex: "\\d{4}",
        code: "1057"
    },
    {
        country: "Antigua and Barbuda",
        regex: "\\d{5}",
        code: "33901"
    },
    {
        country: "Anguilla",
        regex: "(AI-2640)",
        code: "AI-2640"
    },
    {
        country: "Albania",
        regex: "\\d{4}",
        code: "5300"
    },
    {
        country: "Armenia",
        regex: "(\\d{4})|(\\d{6})",
        code: "0010, 001011"
    },
    {
        country: "Angola",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Antarctica",
        regex: "(7151)",
        code: "7151"
    },
    {
        country: "Argentina",
        regex: "([A-Z]\\d{4}[A-Z]{3})|([A-Z]\\d{4})",
        code: "C1425CLA, U9000"
    },
    {
        country: "American Samoa",
        regex: "\\d{5}",
        code: "96799"
    },
    {
        country: "Austria",
        regex: "\\d{4}",
        code: "1010"
    },
    {
        country: "Australia",
        regex: "\\d{4}",
        code: "2599"
    },
    {
        country: "Aruba",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Azerbaijan",
        regex: "(AZ) (\\d{4})",
        code: "AZ 1000"
    },
    {
        country: "Bosnia and Herzegovina",
        regex: "\\d{5}",
        code: "71000"
    },
    {
        country: "Barbados",
        regex: "(?:BB)(\\d{5})",
        code: "BB15094, 15094"
    },
    {
        country: "Bangladesh",
        regex: "\\d{4}",
        code: "1219"
    },
    {
        country: "Belgium",
        regex: "\\d{4}",
        code: "1049"
    },
    {
        country: "Burkina Faso",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Bulgaria",
        regex: "\\d{4}",
        code: "1000"
    },
    {
        country: "Bahrain",
        regex: "\\d{3}\\d?",
        code: "317, 1216"
    },
    {
        country: "Burundi",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Benin",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Bermuda",
        regex: "[A-Z]{2} \\d{2}",
        code: "CR 03"
    },
    {
        country: "Brunei Darussalam",
        regex: "[A-Z]{2}\\d{4}",
        code: "KB2333"
    },
    {
        country: "Bolivia",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Bonaire, Sint Eustatius and Saba",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Brazil",
        regex: "[0-9]{5}-[0-9]{3}",
        code: "28999-999"
    },
    {
        country: "Bahamas",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Bhutan",
        regex: "\\d{5}",
        code: "31002"
    },
    {
        country: "Bouvet Island",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Botswana",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Belarus",
        regex: "\\d{6}",
        code: "231300"
    },
    {
        country: "Belize",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Canada",
        regex: "(?!.*[DFIOQU])[A-VXY][0-9][A-Z] +?[0-9][A-Z][0-9]",
        code: "K1A 0T6"
    },
    {
        country: "Cocos (Keeling) Islands",
        regex: "(6799)",
        code: "6799"
    },
    {
        country: "Congo, the Democratic Republic of the",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Central African Republic",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Congo",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Switzerland",
        regex: "\\d{4}",
        code: "8050"
    },
    {
        country: "Côte d'Ivoire",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Cook Islands",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Chile",
        regex: "\\d{7}",
        code: "9340000"
    },
    {
        country: "Cameroon",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "China",
        regex: "\\d{6}",
        code: "710000"
    },
    {
        country: "Colombia",
        regex: "\\d{6}",
        code: "111121"
    },
    {
        country: "Costa Rica",
        regex: "\\d{5}",
        code: "10101"
    },
    {
        country: "Cuba",
        regex: "(?:CP)(\\d{5})",
        code: "CP10400, 10400"
    },
    {
        country: "Cabo Verde",
        regex: "\\d{4}",
        code: "5110"
    },
    {
        country: "Curaçao",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Christmas Island",
        regex: "(6798)",
        code: "6798"
    },
    {
        country: "Cyprus",
        regex: "\\d{4}",
        code: "4999"
    },
    {
        country: "Czech Republic",
        regex: "[0-9]{3} [0-9]{2}|[0-9]{5}",
        code: "160 00, 16000"
    },
    {
        country: "Germany",
        regex: "\\d{5}",
        code: "60320"
    },
    {
        country: "Djibouti",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Denmark",
        regex: "\\d{4}",
        code: "2000"
    },
    {
        country: "Dominica",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Dominican Republic",
        regex: "\\d{5}",
        code: "10103"
    },
    {
        country: "Algeria",
        regex: "\\d{5}",
        code: "16000"
    },
    {
        country: "Ecuador",
        regex: "\\d{6}",
        code: "170515"
    },
    {
        country: "Estonia",
        regex: "\\d{5}",
        code: "10111"
    },
    {
        country: "Egypt",
        regex: "\\d{5}",
        code: "12411"
    },
    {
        country: "Eritrea",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Spain",
        regex: "\\d{5}",
        code: "28006"
    },
    {
        country: "Ethiopia",
        regex: "\\d{4}",
        code: "3020"
    },
    {
        country: "Finland",
        regex: "\\d{5}",
        code: "00180"
    },
    {
        country: "Fiji",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Falkland Islands (Malvinas)",
        regex: "(FIQQ 1ZZ)",
        code: "FIQQ 1ZZ"
    },
    {
        country: "Micronesia",
        regex: "\\d{5}",
        code: "96942"
    },
    {
        country: "Faroe Islands",
        regex: "\\d{3}",
        code: "927"
    },
    {
        country: "France",
        regex: "\\d{5}",
        code: "75008"
    },
    {
        country: "Gabon",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "United Kingdom",
        regex: "([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})",
        code: "DT3 6GB, L2 2DP"
    },
    {
        country: "Grenada",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Georgia",
        regex: "\\d{4}",
        code: "0100"
    },
    {
        country: "Guernsey",
        regex: "(GY)([0-9][0-9A-HJKPS-UW]?|[A-HK-Y][0-9][0-9ABEHMNPRV-Y]?) [0-9][ABD-HJLNP-UW-Z]{2}",
        code: "GY1 3HR"
    },
    {
        country: "Ghana",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Gibraltar",
        regex: "(GX11 1AA)",
        code: "GX11 1AA"
    },
    {
        country: "Greenland",
        regex: "\\d{4}",
        code: "3905"
    },
    {
        country: "Gambia",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Guinea",
        regex: "\\d{3}",
        code: "001"
    },
    {
        country: "Equatorial Guinea",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Greece",
        regex: "(\\d{3}) \\d{2}|\\d{5}",
        code: "241 00, 24100"
    },
    {
        country: "South Georgia and the South Sandwich Islands",
        regex: "(SIQQ 1ZZ)",
        code: "SIQQ 1ZZ"
    },
    {
        country: "Guatemala",
        regex: "\\d{5}",
        code: "01002"
    },
    {
        country: "Guam",
        regex: "((969)[1-3][0-2])",
        code: "96911"
    },
    {
        country: "Guinea-Bissau",
        regex: "\\d{4}",
        code: "1000"
    },
    {
        country: "Guyana",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Hong Kong",
        regex: "(999077)",
        code: "999077"
    },
    {
        country: "Heard Island and McDonald Islands",
        regex: "(7151)",
        code: "7151"
    },
    {
        country: "Honduras",
        regex: "\\d{5}",
        code: "34101"
    },
    {
        country: "Croatia",
        regex: "\\d{5}",
        code: "21000"
    },
    {
        country: "Haiti",
        regex: "(?:HT)(\\d{4})",
        code: "HT1440, 1440"
    },
    {
        country: "Hungary",
        regex: "\\d{4}",
        code: "2310"
    },
    {
        country: "Indonesia",
        regex: "\\d{5}",
        code: "15360"
    },
    {
        country: "Ireland",
        regex: "([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})",
        code: "D02 AF30"
    },
    {
        country: "Israel",
        regex: "\\d{7}",
        code: "1029200"
    },
    {
        country: "Isle of Man",
        regex: "(IM)([0-9][0-9A-HJKPS-UW]?|[A-HK-Y][0-9][0-9ABEHMNPRV-Y]?) [0-9][ABD-HJLNP-UW-Z]{2}",
        code: "IM5 1JS"
    },
    {
        country: "India",
        regex: "\\d{6}",
        code: "500012"
    },
    {
        country: "British Indian Ocean Territory",
        regex: "(BB9D 1ZZ)",
        code: "BB9D 1ZZ"
    },
    {
        country: "Iraq",
        regex: "\\d{5}",
        code: "58019"
    },
    {
        country: "Iran, Islamic Republic of",
        regex: "\\d{5}[\\-]?\\d{5}",
        code: "9187158198, 15119-43943"
    },
    {
        country: "Iceland",
        regex: "\\d{3}",
        code: "101"
    },
    {
        country: "Italy",
        regex: "\\d{5}",
        code: "36051"
    },
    {
        country: "Jersey",
        regex: "JE[0-9]{1}[\\s]([\\d][A-Z]{2})",
        code: "JE1 1AG"
    },
    {
        country: "Jamaica",
        regex: "(JM)[A-Z]{3}\\d{2}",
        code: "JMAAW19"
    },
    {
        country: "Jordan",
        regex: "\\d{5}",
        code: "11118"
    },
    {
        country: "Japan",
        regex: "(\\d{3}-\\d{4})",
        code: "408-0307"
    },
    {
        country: "Kenya",
        regex: "\\d{5}",
        code: "40406"
    },
    {
        country: "Kyrgyzstan",
        regex: "\\d{6}",
        code: "720020"
    },
    {
        country: "Cambodia",
        regex: "\\d{5}",
        code: "04253"
    },
    {
        country: "Kiribati",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Comoros",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Saint Kitts and Nevis",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Korea, Democratic People's Republic of",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Korea, Republic of",
        regex: "\\d{5}",
        code: "11962"
    },
    {
        country: "Kuwait",
        regex: "\\d{5}",
        code: "60000"
    },
    {
        country: "Cayman Islands",
        regex: "[K][Y][0-9]{1}[-]([0-9]){4}",
        code: "KY1-1800"
    },
    {
        country: "Kazakhstan",
        regex: "\\d{6}",
        code: "010010"
    },
    {
        country: "Lao People's Democratic Republic",
        regex: "\\d{5}",
        code: "13000"
    },
    {
        country: "Lebanon",
        regex: "\\d{4}( \\d{4})?",
        code: "2038 3054, 1103"
    },
    {
        country: "Saint Lucia",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Liechtenstein",
        regex: "\\d{4}",
        code: "9490"
    },
    {
        country: "Sri Lanka",
        regex: "\\d{5}",
        code: "80212"
    },
    {
        country: "Liberia",
        regex: "\\d{4}",
        code: "1000"
    },
    {
        country: "Lesotho",
        regex: "\\d{3}",
        code: "100"
    },
    {
        country: "Lithuania",
        regex: "((?:LT)[\\-])?(\\d{5})",
        code: "LT-01100, 01100"
    },
    {
        country: "Luxembourg",
        regex: "((?:L)[\\-])?(\\d{4})",
        code: "1019, L-2530"
    },
    {
        country: "Latvia",
        regex: "[L]{1}[V]{1}[-]([0-9]){4}",
        code: "LV-1010"
    },
    {
        country: "Libya",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Morocco",
        regex: "\\d{5}",
        code: "20192"
    },
    {
        country: "Moldova, Republic of",
        regex: "(?:MD)[\\-]?(\\d{4})",
        code: "MD2001, MD-2001"
    },
    {
        country: "Montenegro",
        regex: "\\d{5}",
        code: "81250"
    },
    {
        country: "Madagascar",
        regex: "\\d{3}",
        code: "101"
    },
    {
        country: "Marshall Islands",
        regex: "((969)[6-7][0-9])",
        code: "96960"
    },
    {
        country: "North Macedonia",
        regex: "\\d{4}",
        code: "1045"
    },
    {
        country: "Mali",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Myanmar",
        regex: "\\d{5}",
        code: "11121"
    },
    {
        country: "Mongolia",
        regex: "\\d{5}",
        code: "16080"
    },
    {
        country: "Macao",
        regex: "(999078)",
        code: "999078"
    },
    {
        country: "Northern Mariana Islands",
        regex: "\\d{5}",
        code: "96950"
    },
    {
        country: "Mauritania",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Montserrat",
        regex: "(?:MSR )(\\d{4})",
        code: "MSR 1120, 1120"
    },
    {
        country: "Malta",
        regex: "[A-Z]{3} [0-9]{4}|[A-Z]{2}[0-9]{2}|[A-Z]{2} [0-9]{2}|[A-Z]{3}[0-9]{4}|[A-Z]{3}[0-9]{2}|[A-Z]{3} [0-9]{2}",
        code: "VLT 1117, TP01, TP 01, RBT1676, QRM09, BKR 01"
    },
    {
        country: "Mauritius",
        regex: "([0-9A-Z]\\d{4})",
        code: "A0000, 20101"
    },
    {
        country: "Maldives",
        regex: "\\d{5}",
        code: "20195"
    },
    {
        country: "Malawi",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Mexico",
        regex: "\\d{5}",
        code: "97229"
    },
    {
        country: "Malaysia",
        regex: "\\d{5}",
        code: "50050"
    },
    {
        country: "Mozambique",
        regex: "\\d{4}",
        code: "1104"
    },
    {
        country: "Namibia",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "New Caledonia",
        regex: "\\d{5}",
        code: "98814"
    },
    {
        country: "Niger",
        regex: "\\d{4}",
        code: "8001"
    },
    {
        country: "Norfolk Island",
        regex: "(2899)",
        code: "2899"
    },
    {
        country: "Nigeria",
        regex: "\\d{6}",
        code: "100001"
    },
    {
        country: "Nicaragua",
        regex: "\\d{5}",
        code: "11001"
    },
    {
        country: "Netherlands",
        regex: "[0-9]{4} [A-Z]{2}|[0-9]{4}[A-Z]{2}",
        code: "1011 AC, 1011AC"
    },
    {
        country: "Norway",
        regex: "\\d{4}",
        code: "5262"
    },
    {
        country: "Nepal",
        regex: "\\d{5}",
        code: "44600"
    },
    {
        country: "Nauru",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Niue",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "New Zealand",
        regex: "\\d{4}",
        code: "8041"
    },
    {
        country: "Oman",
        regex: "\\d{3}",
        code: "112"
    },
    {
        country: "Panama",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Peru",
        regex: "\\d{5}",
        code: "15001"
    },
    {
        country: "French Polynesia",
        regex: "((987)\\d{2})",
        code: "98755"
    },
    {
        country: "Papua New Guinea",
        regex: "\\d{3}",
        code: "244"
    },
    {
        country: "Philippines",
        regex: "\\d{4}",
        code: "4104"
    },
    {
        country: "Pakistan",
        regex: "\\d{5}",
        code: "75600"
    },
    {
        country: "Poland",
        regex: "[0-9]{2}[-]([0-9]){3}",
        code: "87-100"
    },
    {
        country: "Pitcairn",
        regex: "(PCR9 1ZZ)",
        code: "PCR9 1ZZ"
    },
    {
        country: "Palestine, State of",
        regex: "(\\d{3}-\\d{3})",
        code: "600-699"
    },
    {
        country: "Portugal",
        regex: "\\d{4}((-)\\d{3})",
        code: "1000-260"
    },
    {
        country: "Palau",
        regex: "(96939|96940)",
        code: " 96939, 96940"
    },
    {
        country: "Paraguay",
        regex: "\\d{4}",
        code: "3180"
    },
    {
        country: "Qatar",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Romania",
        regex: "\\d{6}",
        code: "507085"
    },
    {
        country: "Serbia",
        regex: "\\d{5}",
        code: "24430"
    },
    {
        country: "Russian Federation",
        regex: "\\d{6}",
        code: "385100"
    },
    {
        country: "Rwanda",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Saudi Arabia",
        regex: "\\d{5}([\\-]\\d{4})?",
        code: "11564, 75311-8538"
    },
    {
        country: "Solomon Islands",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Seychelles",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Sudan",
        regex: "\\d{5}",
        code: "13315"
    },
    {
        country: "Sweden",
        regex: "(\\d{3} \\d{2})",
        code: "113 51"
    },
    {
        country: "Singapore",
        regex: "\\d{6}",
        code: "570150"
    },
    {
        country: "Saint Helena, Ascension and Tristan da Cunha",
        regex: "(ASCN 1ZZ|TDCU 1ZZ|STHL 1ZZ)",
        code: "ASCN 1ZZ, TDCU 1ZZ, STHL 1ZZ"
    },
    {
        country: "Slovenia",
        regex: "\\d{4}",
        code: "8341"
    },
    {
        country: "Slovakia",
        regex: "(\\d{3} \\d{2})|\\d{5}",
        code: "811 01, 81101"
    },
    {
        country: "Sierra Leone",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "San Marino",
        regex: "(4789\\d)",
        code: "47894"
    },
    {
        country: "Senegal",
        regex: "\\d{5}",
        code: "10200"
    },
    {
        country: "Somalia",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Suriname",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "South Sudan",
        regex: "\\d{5}",
        code: "11111"
    },
    {
        country: "São Tomé and Príncipe",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "El Salvador",
        regex: "((CP) \\d{4})",
        code: "CP 1201"
    },
    {
        country: "Sint Maarten (Dutch part)",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Syrian Arab Republic",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Swaziland",
        regex: "([A-Z]\\d{3})",
        code: "M201"
    },
    {
        country: "Turks and Caicos Islands",
        regex: "(TKCA 1ZZ)",
        code: "TKCA 1ZZ"
    },
    {
        country: "Chad",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "French Southern Territories",
        regex: "((984)\\d{2})",
        code: "98413"
    },
    {
        country: "Togo",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Thailand",
        regex: "\\d{5}",
        code: "10240"
    },
    {
        country: "Tajikistan",
        regex: "\\d{3}",
        code: "734"
    },
    {
        country: "Tokelau",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Timor-Leste",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Turkmenistan",
        regex: "\\d{6}",
        code: "745180"
    },
    {
        country: "Tunisia",
        regex: "\\d{4}",
        code: "3200"
    },
    {
        country: "Tonga",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Turkey",
        regex: "\\d{5}",
        code: "34000"
    },
    {
        country: "Trinidad and Tobago",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Tuvalu",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Taiwan (Province of China)",
        regex: "(\\d{3}[-]\\d{2})|(\\d{3})",
        code: "237-01, 407"
    },
    {
        country: "Tanzania, United Republic of",
        regex: "\\d{5}",
        code: "31324"
    },
    {
        country: "Ukraine",
        regex: "\\d{5}",
        code: "65000"
    },
    {
        country: "Uganda",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "United States Minor Outlying Islands",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "United States of America",
        regex: "[0-9]{5}(?:-[0-9]{4})?",
        code: "00716, 11550-1234"
    },
    {
        country: "Uruguay",
        regex: "\\d{5}",
        code: "11700"
    },
    {
        country: "Uzbekistan",
        regex: "\\d{6}",
        code: "702100"
    },
    {
        country: "Holy See",
        regex: "(00120)",
        code: "00120"
    },
    {
        country: "Saint Vincent and the Grenadines",
        regex: "(VC)(\\d{4})",
        code: "VC0100"
    },
    {
        country: "Venezuela (Bolivarian Republic of)",
        regex: "\\d{4}",
        code: "1061"
    },
    {
        country: "Virgin Islands (British)",
        regex: "(VG11)[0-6][0]",
        code: "VG1120"
    },
    {
        country: "Virgin Islands (U.S.)",
        regex: "\\d{5}",
        code: "00850"
    },
    {
        country: "Viet Nam",
        regex: "\\d{6}",
        code: "112132"
    },
    {
        country: "Vanuatu",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "Wallis and Futuna",
        regex: "((986)\\d{2})",
        code: "98600"
    },
    {
        country: "Samoa",
        regex: "(96799)",
        code: "96799"
    },
    {
        country: "Yemen",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    },
    {
        country: "South Africa",
        regex: "\\d{4}",
        code: "6001"
    },
    {
        country: "Zambia",
        regex: "\\d{5}",
        code: "50100"
    },
    {
        country: "Zimbabwe",
        regex: "(?:AD)(\\d{3})",
        code: "AD100"
    }
];