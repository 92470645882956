import { Injectable } from '@angular/core';
import { mastersApi } from '../../_constants/api-list';
import { environment } from '../../../environments/environment';
import { HttpClientService } from '../index';

@Injectable({
    providedIn: 'root',
})
export class ChargeableFacilitiesService {

    constructor(private httpClientService: HttpClientService) { }

    /**
     * Add or Edit chargeable facilities
     * @param requestData request data
     * @returns Return response from api
     */
    saveChargeableFacilities(requestData: any) {
        let url = environment.origin + mastersApi.SAVE_CHARGEABLE_FACILITIES;
        return this.httpClientService.post(url, requestData);
    }

    /**
     * Get chargeable facilities list
     * @param chargeableFacilitiesId chargeable facilities id 
     * @returns Return response from api
     */
    getChargeableFacilities(chargeableFacilitiesId?: any) {
        let url = environment.origin + mastersApi.GET_CHARGEABLE_FACILITIES;
        if (chargeableFacilitiesId) {
            url += `/${chargeableFacilitiesId}`;
        }
        return this.httpClientService.get(url);
    }

    /**
     * Delete chargeable facilities
     * @param chargeableFacilitiesId chargeable facilities id
     * @returns Return response from api
     */
    deleteChargeableFacilities(chargeableFacilitiesId?: any) {
        let url = environment.origin + mastersApi.DELETE_CHARGEABLE_FACILITIES + chargeableFacilitiesId;
        return this.httpClientService.delete(url);
    }
}
