<div class="page-bar" *ngIf="!mode">
  <div class="page-title-breadcrumb">
    <div class="pull-left">
      <div class="page-title">Step 7 : Manage Stop Sell</div>
    </div>
    <ol class="breadcrumb page-breadcrumb pull-right">
      <li>
        <a class="parent-item" href="/"><i class="fa fa-home"></i>&nbsp;Home</a>&nbsp;<i class="fa fa-angle-right"></i>
      </li>
      <li class="active">Manage Stop Sell</li>
    </ol>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="card-box" *ngIf="!mode">
      <div class="card-head">
        <header>Progress Bar</header>
      </div>
      <div class="card-body">
        <div class="progressbar-xs progress-rounded progress-striped progress ng-isolate-scope active">
          <div class="progress-bar progress-bar-success width-95" role="progressbar" aria-valuenow="65"
            aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    </div>

    <div class="mt-1">
      <div class="card-head">
        <header>Stop Sell</header>
      </div>
      <div class="card-body date-calender">
        <mat-tab-group>
          <mat-tab>
            <ng-template mat-tab-label>
              <div>
                <span class="mr-1" id="propertyInformation">Calendar View</span>
              </div>
            </ng-template>
            <div class="calender_title container calender_open" [ngClass]="{ 'mt-3': mode }">
              <div class="calnder_popup">
                <!-- <div class="row error-msg pl-3" *ngIf="roomSelectedStatus === true">
                Room already saved , all data will override if saved again
              </div> -->
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="form_label">Select Room</label>
                      <select class="form-control" id="room" (change)="selectedRoom($event.target.value)"
                        [(ngModel)]="stopSalesDetails.room">
                        <option value="" disabled="true">Select Room</option>
                        <option *ngFor="let item of roomData" value="{{ item.id }}">
                          {{ item.name }}
                        </option>
                      </select>
                      <div *ngIf="!roomId && isFormSubmit" class="error-msg">
                        <div>Room Not Selected</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="form_label">Select Year</label>
                      <select class="form-control" id="year" (change)="selectedYear($event.target.value)"
                        [(ngModel)]="stopSalesDetails.year">
                        <option value="" disabled="true">Select Year</option>
                        <option *ngFor="let item of years" value="{{ item }}">
                          {{ item }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4" [ngClass]="{ 'mb-2': mode && mode === 'edit' }">
                    <div class="form-group">
                      <label class="form_label">Select Month</label>
                      <select class="form-control" id="month" [(ngModel)]="stopSalesDetails.month"
                        (change)="selectedMonth($event.target.value)">
                        <option value="" disabled="true">Select Month</option>
                        <option *ngFor="let item of months; let i = index" value="{{ i + 1 }}">
                          {{ item }}
                        </option>
                      </select>
                      <div *ngIf="
                          prevMonthStatus === true && (!mode || mode !== 'view')
                        " class="error-msg pb-2">
                        <div>Previous Month not available</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12" *ngIf="prevMonthStatus === false">
                    <div class="calender_table">
                      <table width="100%" cellpadding="0" cellspacing="0" *ngIf="
                          stopSalesDetails.month &&
                          stopSalesDetails.room &&
                          stopSalesDetails.year
                        ">
                        <tbody>
                          <tr>
                            <ng-container *ngFor="let no of numbers">
                              <td *ngIf="no < 7">
                                {{ calendarData[no].date }}
                                <span class="green" *ngIf="
                                    calendarData[no].day !== 'Sunday' &&
                                    calendarData[no].day !== 'Saturday'
                                  ">
                                  {{ calendarData[no].day }}
                                </span>
                                <span class="red" style="
                                    background-color: transparent !important;
                                  " *ngIf="
                                    calendarData[no].day === 'Sunday' ||
                                    calendarData[no].day === 'Saturday'
                                  ">
                                  {{ calendarData[no].day }}
                                </span>
                                <span>
                                  <input id="chk{{ no }}" type="checkbox" (change)="
                                      onChangeEvent(
                                        $event,
                                        calendarData[no].date,
                                        calendarData[no].day
                                      )
                                    " [disabled]="mode && mode === 'view'" />
                                </span>
                              </td>
                            </ng-container>
                          </tr>
                          <tr>
                            <ng-container *ngFor="let no of numbers">
                              <td *ngIf="no > 6 && no < 14">
                                {{ calendarData[no].date }}
                                <span class="green" *ngIf="
                                    calendarData[no].day !== 'Sunday' &&
                                    calendarData[no].day !== 'Saturday'
                                  ">
                                  {{ calendarData[no].day }}
                                </span>
                                <span class="red" style="
                                    background-color: transparent !important;
                                  " *ngIf="
                                    calendarData[no].day === 'Sunday' ||
                                    calendarData[no].day === 'Saturday'
                                  ">
                                  {{ calendarData[no].day }}
                                </span>
                                <input id="chk{{ no }}" type="checkbox" (change)="
                                    onChangeEvent(
                                      $event,
                                      calendarData[no].date,
                                      calendarData[no].day
                                    )
                                  " [disabled]="mode && mode === 'view'" />
                              </td>
                            </ng-container>
                          </tr>
                          <tr>
                            <ng-container *ngFor="let no of numbers">
                              <td *ngIf="no > 13 && no < 21">
                                {{ calendarData[no].date }}
                                <span class="green" *ngIf="
                                    calendarData[no].day !== 'Sunday' &&
                                    calendarData[no].day !== 'Saturday'
                                  ">
                                  {{ calendarData[no].day }}
                                </span>
                                <span class="red" style="
                                    background-color: transparent !important;
                                  " *ngIf="
                                    calendarData[no].day === 'Sunday' ||
                                    calendarData[no].day === 'Saturday'
                                  ">
                                  {{ calendarData[no].day }}
                                </span>
                                <input id="chk{{ no }}" type="checkbox" (change)="
                                    onChangeEvent(
                                      $event,
                                      calendarData[no].date,
                                      calendarData[no].day
                                    )
                                  " [disabled]="mode && mode === 'view'" />
                              </td>
                            </ng-container>
                          </tr>
                          <tr>
                            <ng-container *ngFor="let no of numbers">
                              <td *ngIf="no > 20 && no < 28">
                                {{ calendarData[no].date }}
                                <span class="green" *ngIf="
                                    calendarData[no].day !== 'Sunday' &&
                                    calendarData[no].day !== 'Saturday'
                                  ">
                                  {{ calendarData[no].day }}
                                </span>
                                <span class="red" style="
                                    background-color: transparent !important;
                                  " *ngIf="
                                    calendarData[no].day === 'Sunday' ||
                                    calendarData[no].day === 'Saturday'
                                  ">
                                  {{ calendarData[no].day }}
                                </span>
                                <input id="chk{{ no }}" type="checkbox" (change)="
                                    onChangeEvent(
                                      $event,
                                      calendarData[no].date,
                                      calendarData[no].day
                                    )
                                  " [disabled]="mode && mode === 'view'" />
                              </td>
                            </ng-container>
                          </tr>
                          <tr>
                            <ng-container *ngFor="let no of numbers">
                              <td *ngIf="no > 27 && no < 35">
                                {{ calendarData[no].date }}
                                <span class="green" *ngIf="
                                    calendarData[no].day !== 'Sunday' &&
                                    calendarData[no].day !== 'Saturday'
                                  ">
                                  {{ calendarData[no].day }}
                                </span>
                                <span class="red" style="
                                    background-color: transparent !important;
                                  " *ngIf="
                                    calendarData[no].day === 'Sunday' ||
                                    calendarData[no].day === 'Saturday'
                                  ">
                                  {{ calendarData[no].day }}
                                </span>
                                <input id="chk{{ no }}" type="checkbox" (change)="
                                    onChangeEvent(
                                      $event,
                                      calendarData[no].date,
                                      calendarData[no].day
                                    )
                                  " [disabled]="mode && mode === 'view'" />
                              </td>
                            </ng-container>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="row mt-3" *ngIf="!mode || mode !== 'view'">
                  <div class="col-md-6">
                    <button type="button" (click)="selectAll()" class="btn btn-round btn-default newCustomBtn"
                      [disabled]="prevMonthStatus">
                      Select All
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button type="button" (click)="unselectAll()" class="btn btn-round btn-default newCustomBtn"
                      [disabled]="prevMonthStatus">
                      Unselect All
                    </button>
                  </div>
                  <div class="col-md-6 mt-2">
                    <div class="text-right">
                      <button type="submit" (click)="saveStep5Single()" class="btn btn-round btn-default custom-btn"
                        [disabled]="prevMonthStatus">
                        Save
                      </button>
                      <div *ngIf="selectedDate.length === 0 && isFormSubmit" class="error-msg">
                        <div>No Date Selected</div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="
                      selectedDate.length > 0 &&
                      stopSalesDetails.month &&
                      stopSalesDetails.room &&
                      stopSalesDetails.year
                    " class="col-md-12 text-red" style="font-size: 12px">
                    NOTE : Stop Sell for this room type and dates are already
                    added.
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab *ngIf="!mode || mode !== 'view'">
            <ng-template mat-tab-label>
              <div>
                <span class="ml-1" id="propertyFacility">Date Range</span>
              </div>
            </ng-template>
            <div>
              <form [formGroup]="step5RoomRange" (ngSubmit)="saveStep5Range()">
                <div class="row" style="padding-left: 20px">
                  <div class="form-group">
                    <label class="form_label">Date</label>
                    <div class="date-input d-flex align-items-center">
                      <div class="pr-4 ml-3 mr-4">
                        <div class="row"><span>From</span></div>
                        <div class="row pr-4">
                          <mat-form-field>
                            <mat-label>Choose a date</mat-label>
                            <input matInput [matDatepicker]="picker" [min]="minformDate" formControlName="date_from" />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field>
                        </div>
                        <div *ngIf="
                            (step5RoomRange.controls['date_from']?.errors &&
                              step5RoomRange.controls['date_from']?.touched &&
                              !step5RoomRange.controls['date_from']?.valid) ||
                            isFormSubmitRange
                          " class="error-msg">
                          <div [hidden]="
                              !step5RoomRange.controls['date_from']?.errors
                                ?.required
                            ">
                            From Date required.
                          </div>
                        </div>
                      </div>
                      <div class="pr-4 ml-3 mr-4">
                        <div class="row"><span>To</span></div>
                        <div class="row">
                          <mat-form-field>
                            <mat-label>Choose a date</mat-label>
                            <input matInput [matDatepicker]="picker1" [min]="minformDate" formControlName="date_to" />
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                          </mat-form-field>
                        </div>
                        <div *ngIf="
                            (step5RoomRange.controls['date_to']?.errors &&
                              step5RoomRange.controls['date_to']?.touched &&
                              !step5RoomRange.controls['date_to']?.valid) ||
                            isFormSubmitRange
                          " class="error-msg">
                          <div [hidden]="
                              !step5RoomRange.controls['date_to']?.errors
                                ?.required
                            ">
                            To Date required.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="error-msg" [hidden]="!step5RoomRange?.errors?.invalidDate">
                      Please select valid dates
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="form_label">Select Room</label>
                      <select class="form-control" (change)="selectedRoom($event.target.value)"
                        [disabled]="mode && mode === 'view'">
                        <option value="">Select Room</option>
                        <option *ngFor="let item of roomData" value="{{ item.id }}">
                          {{ item.name }}
                        </option>
                      </select>
                      <div *ngIf="!roomId && isFormSubmitRange" class="error-msg">
                        <div>Room Not Selected</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="!mode || mode !== 'view'">
                  <div class="col-md-3">
                    <div class="submit_btn text-right">
                      <button type="submit">Stop Sell</button>
                    </div>
                  </div>
                  <!-- <div class="col-md-3">
                      <div class="submit_btn text-right">
                        <button>Reset</button>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="submit_btn text-right">
                        <button>Save</button>
                      </div>
                    </div> -->
                </div>
                <!-- <div class="row error-msg pl-3" *ngIf="roomSelectedStatus === true">
                    Room already saved , all data will override if saved again
                  </div> -->
              </form>
              <!-- <div class="d-flex justify-content-between">
                <a href="/property/step-3-add-property" class="btn yellow btn-outline custom-btn">Back</a>
                <a href="step-2_facilities.php" class="btn btn-round btn-primary  custom-btn">Next</a>
              </div> -->
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    <div class="d-flex justify-content-between" style="padding: 10px">
      <a class="btn yellow btn-outline custom-btn" (click)="gotoBack()">Back</a>
    </div>
  </div>

  <button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#facilities-modal"
    data-backdrop="static" data-keyboard="false">
    Open Modal
  </button>

  <div class="modal fade" id="facilities-modal">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content" style="width: auto">
        <!-- Modal Header -->
        <div class="modal-header">
          <h2>Do you want to manage another room?</h2>
          <button id="closeModal" type="button" class="close hidden" #closeBtnAdd data-dismiss="modal">
            &times;
          </button>
        </div>
        <!-- Modal body -->
        <!-- <form> -->

        <div class="modal-body">
          <div class="row justify-content-end">
            <div class="col-md-6">
              <button type="button" (click)="noFun()" class="float-right btn btn-round btn-default custom-btn">
                No
              </button>
            </div>
            <div class="col-md-6">
              <button type="button" (click)="yesFun()" class="float-left btn btn-round btn-default custom-btn">
                Yes
              </button>
            </div>
          </div>
          <!-- </form> -->
        </div>

        <!-- Modal footer -->
      </div>
    </div>
  </div>

  <button id="showAlertModal" [hidden]="true" data-toggle="modal" data-target="#alert-modal" data-backdrop="static"
    data-keyboard="false">
    Open Modal
  </button>
  <div class="modal fade" id="alert-modal">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content" style="width: auto">
        <!-- Modal Header -->
        <div class="modal-header">
          <h2>{{ alertMessage }}</h2>
          <button id="closeModal" type="button" class="close hidden" #closeBtnAdd data-dismiss="modal">
            &times;
          </button>
        </div>
        <!-- Modal body -->
        <!-- <form> -->

        <div class="modal-body">
          <div class="row justify-content-end">
            <div class="col-md-6">
              <button type="button" data-dismiss="modal" class="float-right btn btn-round btn-default custom-btn">
                Okay
              </button>
            </div>
          </div>
          <!-- </form> -->
        </div>

        <!-- Modal footer -->
      </div>
    </div>
  </div>
</div>