import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {
  InterestsService, StyleService, ChargeableFacilitiesService, MasterServiceService,
  BeddingTypeService, PolicyTypeService, HotelMappingService, MealTypeService, RoomTypeService, PropertyFacilityService
} from '../../_services';

@Component({
  selector: 'app-master-add-edit-modal',
  templateUrl: './master-add-edit-modal.component.html',
  styleUrls: ['./master-add-edit-modal.component.scss']
})
export class MasterAddEditModalComponent implements OnInit {
  masterFormGroup: FormGroup;
  formLabel: string;
  isFileInputShow = false;
  isDropDownShow = false;

  constructor(
    public matDialogRef: MatDialogRef<MasterAddEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private interestsService: InterestsService,
    private styleService: StyleService,
    private chargeableFacilitiesService: ChargeableFacilitiesService,
    private propertyFacilityService: PropertyFacilityService,
    private masterServiceService: MasterServiceService,
    private beddingTypeService: BeddingTypeService,
    private policyTypeService: PolicyTypeService,
    private mealTypeService: MealTypeService,
    private roomTypeService: RoomTypeService,
    private hotelMappingService: HotelMappingService,
  ) { }

  ngOnInit(): void {
    // this.initilization();
    this.initialForm();
    this.formLabel = this.data?.formDetails?.id ? `Update ${this.data?.title}` : `Add ${this.data?.title}`;
  }

  /**
   * initial form for hotel category
   */
  initialForm() {
    this.masterFormGroup = this.formBuilder.group({
      id: [this.data?.formDetails?.id],
      name: [this.data?.formDetails?.name, Validators.required],
      description: [this.data?.formDetails?.description, Validators.required],
    });
  }

  /**
   * Initially call and set value
   */
  initilization() {
    this.isFileInputShow = false;
    this.isDropDownShow = false;
    switch (this.data?.title) {
      case '':
        this.isFileInputShow = false;
        this.isDropDownShow = false;
        break;
    }
  }

  /**
   * On submit to add / update form
   */
  onSubmit() {
    if (!this.masterFormGroup.value.id) {
      delete this.masterFormGroup.value.id;
    }
    this.masterFormGroup.value.name = this.masterFormGroup.value.name.trim();
    switch (this.data?.title) {
      case 'Interests': this.saveInterests(); break;
      case 'Style': this.saveStyle(); break;
      case 'Chargeable Facility': this.saveChargeableFacility(); break;
      case 'Property Facility Category': this.savePropertyFacilityCategory(); break;
      case 'Property Facility': this.savePropertyFacility(); break;
      case 'Hotel Category': this.saveHotelCategory(); break;
      case 'Service': this.saveServices(); break;
      case 'Policy Type': this.savePolicyType(); break;
      case 'Bedding Type': this.saveBeddingType(); break;
      case 'Meal Type': this.saveMealType(); break;
      case 'Room Type': this.saveRoomType(); break;
    }
  }

  /**
   * Add / Edit Interests
   */
  saveInterests() {
    this.spinner.show();
    this.interestsService.saveInterests(this.masterFormGroup.value)
      .subscribe((response: any) => {
        this.showToasterMessage(response);
      }, error => console.log(error));
  }

  /**
   * Add / Edit Style
   */
  saveStyle() {
    this.spinner.show();
    this.styleService.saveStyle(this.masterFormGroup.value)
      .subscribe((response: any) => {
        this.showToasterMessage(response);
      }, error => console.log(error));
  }

  /**
   * Add / Edit Chargeable Facility
   */
  saveChargeableFacility() {
    this.spinner.show();
    this.chargeableFacilitiesService.saveChargeableFacilities(this.masterFormGroup.value)
      .subscribe((response: any) => {
        this.showToasterMessage(response);
      }, error => console.log(error));
  }

  /**
   * Save property facility category
   */
  savePropertyFacilityCategory() {
    this.spinner.show();
    this.propertyFacilityService.savePropertyFacilityCategory(this.masterFormGroup.value)
      .subscribe((response: any) => {
        this.showToasterMessage(response);
      }, error => console.log(error));
  }

  /**
   * Save property facility
   */
  savePropertyFacility() {
    this.spinner.show();
    this.propertyFacilityService.savePropertyFacilityCategory(this.masterFormGroup.value)
      .subscribe((response: any) => {
        this.showToasterMessage(response);
      }, error => console.log(error));
  }

  /**
   * Save services
   */
  saveServices() {
    this.spinner.show();
    this.masterServiceService.saveService(this.masterFormGroup.value)
      .subscribe((response: any) => {
        this.showToasterMessage(response);
      }, error => console.log(error));
  }

  /**
   * Save policy type
   */
  savePolicyType() {
    this.spinner.show();
    this.policyTypeService.savePolicyType(this.masterFormGroup.value)
      .subscribe((response: any) => {
        this.showToasterMessage(response);
      }, error => console.log(error));
  }

  /**
   * Save bedding type
   */
  saveBeddingType() {
    this.spinner.show();
    this.beddingTypeService.saveBeddingType(this.masterFormGroup.value)
      .subscribe((response: any) => {
        this.showToasterMessage(response);
      }, error => console.log(error));
  }

  /**
   * Save hotel category
   */
  saveMealType() {
    this.spinner.show();
    this.mealTypeService.saveMealType(this.masterFormGroup.value)
      .subscribe((response: any) => {
        this.showToasterMessage(response);
      }, error => console.log(error));
  }

  /**
   * Save room type
   */
  saveRoomType() {
    this.spinner.show();
    this.masterFormGroup.value.is_master = true;
    this.roomTypeService.saveRoomType(this.masterFormGroup.value)
      .subscribe((response: any) => {
        this.showToasterMessage(response);
      }, error => console.log(error));
  }

  /**
   * Save hotel category
   */
  saveHotelCategory() {
    this.spinner.show();
    this.hotelMappingService.saveHotelCategory(this.masterFormGroup.value)
      .subscribe((response: any) => {
        this.showToasterMessage(response);
      }, error => console.log(error));
  }


  /**
   * Show Success / Error toaster on response
   * @param response Response of api
   */
  showToasterMessage(response: any) {
    this.spinner.hide();
    if (response.success) {
      this.matDialogRef.close('success');
      this.toastr.success(response['message'], 'Success', { timeOut: 3000 });
    } else {
      this.toastr.error(response['message'], 'Error', { timeOut: 3000 });
    }
  }
}
