import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isDisabledInterest'
})
export class InterestDisabledPipe implements PipeTransform {
    transform(interestDetails: any, interestIds: any, settingType?: any): any {
        switch (settingType) {
            case 'chargeablefacility':
                return interestIds?.filter(item => item === interestDetails.id).length > 0;
            case 'roomfacility':
                return interestIds?.filter(item => item.room_facilities_id == interestDetails.id).length > 0;
            case 'inclusions':
                return interestIds?.filter(item => item.inclusions_id == interestDetails.id).length > 0;
            default:
                return interestIds?.filter(item => item.interestId == interestDetails.id).length > 0;
        }
    }
}
