import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HotelApi, mastersApi } from '../_constants/api-list';
import { environment } from '../../environments/environment';
import { HttpClientService } from './index';

@Injectable({
    providedIn: 'root',
})
export class HotelMappingService {

    showHotelCategoryMapping: EventEmitter<boolean> = new EventEmitter(false);

    constructor(
        private http: HttpClient,
        private httpClientService: HttpClientService
    ) { }
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
    };

    mapHotel(requestData) {
        return this.http.post(
            environment.origin + HotelApi.ROOM_TYPE_MAPPING, requestData, this.httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    getMappedHotel(view_accept_reject_id) {
        return this.http.get(
            environment.origin + HotelApi.GET_ROOM_TYPE_MAPPING + view_accept_reject_id, this.httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    // private prepareReqUrl(param) {
    //     let url = '?is_mapped=false';
    //     if (param.country_code) {
    //         url = url + `&country_code=${param.country_code}`;
    //     }
    //     if (param.city_code) {
    //         url = url + `&city_code=${param.city_code}`;
    //     }
    //     return url;
    // }

    // getHotelListDotw(params: any) {
    //     const param = this.prepareReqUrl(params);
    //     return this.http
    //         .get(
    //             environment.originDST + HotelApi.GET_HOTEL_LIST_DOTW + param,
    //             this.httpOptions
    //         )
    //         .pipe(retry(1), catchError(this.handleError));
    // }

    getHotelCategory() {
        return this.http
            .get(environment.origin + mastersApi.GET_HOTEL_CATEGORY, this.httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    saveHotelCategory(requestData) {
        return this.http
            .post(environment.origin + mastersApi.SAVE_HOTEL_CATEGORY, requestData, this.httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    deleteHotelCategory(hotel_category_id: string) {
        const url = environment.origin + mastersApi.DELETE_HOTEL_CATEGORY + hotel_category_id;
        return this.httpClientService.delete(url);
    }

    getDistance(address, type) {
        let url = `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${address}&destinations=${type}&key=AIzaSyCrzzuTPy7VUtlUtIZuK6JsB8l9hGvN-N0`;
        return this.http.get(url);
    }

    hotelCategoryMapping(requestData) {
        return this.http
            .post(environment.origin + HotelApi.HOTEL_CATEGORY_MAPPING, requestData, this.httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    roomTypeDatasave(requestData) {
        return this.http
            .post(environment.origin + HotelApi.ROOM_TYPE_DATA_SAVE, requestData, this.httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    roomTypeDataGet(masterHotelId: any) {
        return this.http
            .get(environment.origin + HotelApi.ROOM_TYPE_DATA_GET + masterHotelId, this.httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    /**
     * Save room type
     * @param requestData request data
     */
    saveRoomType(requestData: any) {
        return this.http
            .post(environment.origin + HotelApi.SAVE_ROOM_TYPE, requestData, this.httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    saveRoomTypeRateMapping(requestData) {
        return this.http
            .post(environment.origin + HotelApi.ROOM_TYPE_RATE_MAPPING_SAVE, requestData, this.httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    getRoomTypeRateMapping(hotelId) {
        return this.http
            .get(environment.origin + HotelApi.ROOM_TYPE_RATE_MAPPING_GET + hotelId, this.httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    /**
     * Get dotw hotel room details 
     * @param requestData request data
     * @returns res from api
     */
    getDotwHotelRoomDetails(requestData) {
        return this.http
            .post(environment.origin + HotelApi.GET_DOTW_HOTEL_ROOM_DETAILS, requestData, this.httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    /**
     * Save dotw hotel room details 
     * @param requestData request data
     * @returns res from api
     */
    saveDotwHotelRoomDetails(requestData) {
        return this.http
            .post(environment.origin + HotelApi.SAVE_DOTW_HOTEL_ROOM_DETAILS, requestData, this.httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    /**
     * Get scrapping data
     * @param requestData req data
     * @returns res from api
     */
    getScrappingData(requestData: any) {
        return this.http
            .post(environment.origin + HotelApi.GET_SCRAPPED_DATA, requestData, this.httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    getCountryCode(countryName: any) {
        return this.http
            .get(`https://restcountries.com/v3.1/name/${countryName}?fullText=true`, this.httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    private handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message;
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }
}
