<div class="page-bar">
  <div class="page-title-breadcrumb">
    <div class=" pull-left">
      <div class="page-title">Report</div>
    </div>
    <ol class="breadcrumb page-breadcrumb pull-right">
      <li>
        <i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="index.php"
          >Home</a
        >&nbsp;<i class="fa fa-angle-right"></i>
      </li>
      <li class="active">Report</li>
    </ol>
  </div>
</div>
<form>
  <div class="card-box">
    <div class="card-body">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <select class="form-control">
              <option>Report Type</option>
              <option>Daily Arriva</option>
              <option>Reservation Details</option>
              <option>Cancellation</option>
              <option>Billing Detail</option>
              <option>Inventory</option>
              <option>Rate</option>
              <option>Nationally Rate</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-box">
    <div class="card-head">
      <header>Search Parameters</header>
    </div>
    <div class="card-body">
      <div class="date-calender">
        <div class="form-group">
          <label class="form_label">Date</label>
          <div class="date-input d-flex align-items-center">
            <div class="pr-4">
              <span>From</span>
              <input
                type="text"
                name=""
                class="form-control custom_datepicker"
              />
            </div>
            <div>
              <span>To</span>
              <input
                type="text"
                name=""
                class="form-control custom_datepicker"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label class="form_label">Stuts</label>
            <select class="form-control">
              <option>All</option>
              <option>All</option>
              <option>All</option>
            </select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="form_label">Room Type</label>
            <select class="form-control">
              <option>All</option>
              <option>All</option>
              <option>All</option>
            </select>
          </div>
        </div>
      </div>
      <button type="button" class="btn btn-round btn-default custom-btn">
        Save
      </button>
    </div>
  </div>
</form>
