import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PromotionsService {
  constructor(private http: HttpClient) {}
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  addPromotion(data) {
    return this.http
      .post(
        environment.origin + "/promotion/save",
        JSON.stringify(data),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  updatePromotion(id, data) {
    return this.http
      .put(
        environment.origin + "/promotion/save/" + id,
        JSON.stringify(data),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  getPromotion() {
    return this.http
      .get(environment.origin + "/promotion/get", this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  getPromotionById(promotionId: string) {
    return this.http
      .get(
        environment.origin + "/promotion/get/" + promotionId,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  deletePromotion(promotionId) {
    return this.http
      .delete(
        environment.origin + "/promotion/delete/" + promotionId.toString(),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
