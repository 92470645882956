import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { PolicyDataTable } from "./list-policies";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { PoliciesService } from "../policies.service";
import { MastersService } from "src/app/masters/masters.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

@Component({
  selector: "app-list-policies",
  templateUrl: "./list-policies.component.html",
  styleUrls: ["./list-policies.component.css"],
})
export class ListPoliciesComponent implements OnInit {
  displayedColumns = [
    "policyName",
    "policyType",
    "fromDate",
    "toDate",
    "viewHotels",
    "action",
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("closeBtnDelete") closeBtnConfirm: ElementRef;
  public displayPolicyList = new MatTableDataSource<PolicyDataTable>();
  public policyList: PolicyDataTable[];
  public sortedData: PolicyDataTable[];
  public showLoader = false;
  deletedPolicyId: string;
  viewHotels: PolicyDataTable = null;
  currentSort: Sort = {
    active:'policyName',
    direction:'asc'
  };

  constructor(
    private router: Router,
    private policiesService: PoliciesService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.showLoader = true;
    this.getPolicies();
  }

  private getPolicies(): void {
    this.policiesService.getPolicy().subscribe(
      (response: any) => {
        if (response["success"] === true) {
          this.displayPolicyList.data = response.data;
          // to hold propety list
          this.policyList = response.data;
          // for pagination
          this.displayPolicyList.paginator = this.paginator;
          // for sorting
          this.displayPolicyList.sort = this.sort;
          this.sortedData = this.policyList.slice();

          this.sort.sortChange.emit();
          this.toastr.success("Policy List Fetched Successfully.", "Success");
          this.showLoader = false;
        }
      },
      (error) => {
        console.log(error);
        this.showLoader = false;
        this.toastr.error("Getting Error While Fetching Policy List.", "Error");
      }
    );
  }

  public sortData(sort: Sort = this.currentSort): void {
    this.currentSort = sort;

    console.log('[sort]', this.currentSort);

    const data = this.policyList;
    if (!sort.active || sort.direction === "") {
      this.sortedData = data;
      return;
    }
    console.log("Sort", sort, data);
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === "asc";
      console.log("data", a, b);
      switch (sort.active) {
        case "policyName":
          return this.compare(a.policy_name, b.policy_name, isAsc);
        case "policyType":
          return this.compare(a.policy_type.name, b.policy_type.name, isAsc);
        case "fromDate":
          return this.compare(a.from_date, b.from_date, isAsc);
        case "toDate":
          return this.compare(a.to_date, b.to_date, isAsc);
        default:
          return 0;
      }
    });
  }

  public setDeletePromotionID(policyId: string): void {
    console.log("delete clicked", policyId);
    this.deletedPolicyId = policyId;
  }

  public deletePolicy(): void {
    this.policiesService.deletePolicy(this.deletedPolicyId).subscribe(
      (data: any) => {
        if (data["success"] === true) {
          this.closeBtnConfirm.nativeElement.click();
          $("body").removeClass("modal-open");
          $(".modal-backdrop").remove();
          const index = this.policyList.findIndex(
            (x) => x.id === this.deletedPolicyId
          );
          if (index > -1) {
            this.policyList.splice(index, 1);
            this.displayPolicyList.data = this.policyList;
            this.toastr.success("Policy Deleted Successfully.", "Success");
          }
        }
      },
      (error) => {
        console.log(error);
        this.toastr.error("Getting Error While Delete Policy.", "Error");
      }
    );
  }

  editPolicy(policyId: String, mode: string) {
    this.router.navigate(["policies", "view-policy", policyId, mode]);
  }

  private compare(a: number | string, b: number | string, isAsc: boolean) {
    console.log(a, b);

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onViewHotels(element) {
    this.viewHotels = element;
    console.log("element", element);
  }
}
