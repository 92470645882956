import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MasterAddEditModalComponent } from '..';

@Component({
  selector: 'app-master-comman-table',
  templateUrl: './master-comman-table.component.html',
  styleUrls: ['./master-comman-table.component.scss']
})
export class MasterCommanTableComponent implements OnInit {
  @Input() tableData: any = [];
  @Input() formName: any = '';
  @Input() actionBtn: any = { is_edit: true, is_delete: true };
  @Output() deleterecord: EventEmitter<any> = new EventEmitter<any>();
  @Output() recordeditor: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource = new MatTableDataSource<any>();
  displayedColumns = ['name', 'description', 'action'];

  constructor(
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<any>(this.tableData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  /**
   * On click to open add / edit modal
   * @param items details
   */
  addEditModal(items?: any) {
    const dialogRef = this.matDialog.open(MasterAddEditModalComponent, {
      width: '400px',
      // height: '465px',
      height: '470px',
      data: {
        formDetails: items,
        title: this.formName,
        name: 'Name',
        description: 'Description'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.recordeditor.emit(true);
      }
    });
  }

  /**
   * Delete record
   * @param id record id
   */
  deleteRecord(id) {
    this.deleterecord.emit(id);
  }
}
