<ngx-spinner bdColor="rgba(0,0,0,0.5)" size="medium" color="#ffffff" type="timer" [fullScreen]="true"></ngx-spinner>
<router-outlet></router-outlet>

<div *ngIf="token" class="page-wrapper">
  <app-header></app-header>
  <div class="page-container">
      <app-sidebar></app-sidebar>
      <div class="page-content-wrapper">
        <div class="page-content" style="min-height: 1599px">
          <router-outlet></router-outlet>
          </div>
      </div>
      <app-footer></app-footer>
  </div>
</div>