import { Injectable } from '@angular/core';
import { mastersApi } from '../../_constants/api-list';
import { environment } from '../../../environments/environment';
import { HttpClientService } from '../index';

@Injectable({
    providedIn: 'root',
})
export class PropertyFacilityService {

    constructor(
        private httpClientService: HttpClientService
    ) { }

    /**
     * Save property facility category 
     * @param requestData request data
     * @returns Return response from api
     */
    savePropertyFacilityCategory(requestData: any) {
        let url = environment.origin + mastersApi.SAVE_PROPERTY_FACILITY_CATEGORY;
        return this.httpClientService.post(url, requestData);
    }

    /**
     * Get property facility category
     * @param id id 
     * @returns Return response from api
     */
    getPropertyFacilityCategory(id?: any) {
        let url = environment.origin + mastersApi.GET_PROPERTY_FACILITY_CATEGORY;
        if (id) {
            url += `/${id}`;
        }
        return this.httpClientService.get(url);
    }

    /**
     * Delete property facility category
     * @param id id
     * @returns Return response from api
     */
    deletePropertyFacilityCategory(id?: any) {
        let url = environment.origin + mastersApi.DELETE_PROPERTY_FACILITY_CATEGORY + id;
        return this.httpClientService.delete(url);
    }
}
