<div class="page-bar" *ngIf="!mode">
    <div class="page-title-breadcrumb">
        <div class="pull-left">
            <div class="page-title">Step 3 : Interest Settings</div>
        </div>
        <ol class="breadcrumb page-breadcrumb pull-right">
            <li>
                <a class="parent-item" href="/"><i class="fa fa-home"></i>&nbsp;Home</a>&nbsp;<i
                    class="fa fa-angle-right"></i>
            </li>
            <li class="active">Interest Settings</li>
        </ol>
    </div>
</div>
<div class="row">
    <div class="col-sm-12">
        <form id="myform">
            <div class="card-box" *ngIf="!mode">
                <div class="card-head">
                    <header>Progress Bar</header>
                </div>
                <div class="card-body">
                    <div class="progressbar-xs progress-rounded progress-striped progress ng-isolate-scope active">
                        <div class="progress-bar progress-bar-success width-50" role="progressbar" aria-valuenow="65"
                            aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
            </div>
            <div *ngIf="mode" class="card-box">
                <div class="card-head">
                    <header>{{ propertyDetails?.name }}</header>
                </div>
            </div>
            <fieldset [disabled]="mode == 'view'">
                <!-- INTERESTS VIEW -->
                <div class="card-box">
                    <div class="card-body grid-div">
                        <label class="category-label"><b>Interests</b></label><br>
                        <div *ngFor="let interest of listOfInterest">
                            <div class="checkbox checkbox-black d-inline-block"
                                [ngClass]="mode == 'view' ? 'disabled' : ''">
                                <input [id]="'chk' + interest.id" [value]="interest.id" type="checkbox"
                                    (change)="interestEditorModal(interest)"
                                    [checked]="interest | isDisabledInterest: propertyDetails?.interests_setting?.interests"
                                    [disabled]="mode == 'view'" />
                                <label [id]="'lbl' + interest.id" [for]="'chk' + interest.id">
                                    {{ interest?.name }}
                                </label>
                                <a class="pl-2" (click)="interestEditorModal(interest)" [hidden]="mode !== 'view'">
                                    <i class="fa fa-eye" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- STYLE VIEW -->
                <div class="card-box">
                    <div class="card-body grid-div">
                        <label class="category-label"><b>Style</b></label><br>
                        <div class="row ml-1">
                            <div *ngFor="let style of listOfStyle" class="radio"
                                [ngClass]="mode == 'view' ? 'disabled' : ''">
                                <input [id]="'rdo' + style.id" [value]="style.id" type="radio" name="stylename"
                                    [(ngModel)]="styleSettings.styleId" />
                                <label [for]="'rdo' + style.id">
                                    {{ style?.name }}
                                </label>
                            </div>
                        </div>
                        <div *ngIf="mode !== 'view'" class="d-flex justify-content-end mt-2">
                            <button type="submit" (click)="styleSubmit()" class="btn btn-default mr-2"
                                [disabled]="!styleSettings.styleId">Save</button>
                        </div>
                    </div>
                </div>

                <!-- HOTEL CATEGORY VIEW -->
                <div class="card-box">
                    <div class="card-body grid-div">
                        <label class="category-label"><b>Location Settings</b></label><br>
                        <div class="row ml-1">
                            <div *ngFor="let category of listOfHotelCategory" class="radio"
                                [ngClass]="mode == 'view' ? 'disabled' : ''">
                                <input [id]="'hc' + category.id" [value]="category.id" type="radio"
                                    name="hotel_category" [(ngModel)]="hotelCategorySettings.hotelCategoryId" />
                                <label [for]="'hc' + category.id"> {{ category?.name }} </label>
                            </div>
                        </div>
                        <div *ngIf="mode !== 'view'" class="d-flex justify-content-end mt-2">
                            <button type="submit"
                                (click)="hotelCategorySettings.hotelCategoryId && hotelCategorySubmit()"
                                class="btn btn-default mr-2"
                                [disabled]="!hotelCategorySettings.hotelCategoryId">Save</button>
                        </div>
                    </div>
                </div>

                <!-- CHARGEABLE FACILITY VIEW -->
                <div class="card-box">
                    <div class="card-body grid-div">
                        <label class="category-label"><b>Chargeable Facilities</b></label><br>
                        <div class="row ml-1 facilities-list">
                            <div *ngFor="let chargeableFacility of listOfChargeableFacilities">
                                <div class="checkbox checkbox-black d-inline-block"
                                    [ngClass]="mode == 'view' ? 'disabled' : ''">
                                    <input [id]="'cf' + chargeableFacility.id" [value]="chargeableFacility.id"
                                        type="checkbox"
                                        [checked]="chargeableFacility | isDisabledInterest: propertyDetails?.interests_setting?.chargeable_facility[0]?.chargeableFacilityId : 'chargeablefacility'"
                                        (change)="changeChargeableFacility($event)" [disabled]="mode == 'view'" />
                                    <label [id]="'lbl' + chargeableFacility.id" [for]="'cf' + chargeableFacility.id" style="white-space: nowrap;">
                                        {{ chargeableFacility?.name }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="mode !== 'view'" class="d-flex justify-content-end mt-2">
                            <button type="submit" (click)="chargeableFacilityId.length > 0 && chargeableFacilitySave()"
                                class="btn btn-default mr-2"
                                [disabled]="chargeableFacilityId.length === 0">Save</button>
                        </div>
                    </div>
                </div>
            </fieldset>

            <!-- CHILD AGES VIEW -->
            <!-- <div class="card-box">
                <div class="card-body grid-div">
                    <label class="category-label"><b>Please Specify Child Ages Allowed In The Hotel</b></label><br>
                    <div class="row ml-1">
                        <div *ngFor="let ages of childAges" class="checkbox checkbox-black d-inline-block"
                            [ngClass]="mode == 'view' ? 'disabled' : ''">
                            <input [id]="'age' + ages.id" [value]="ages.id" type="checkbox"
                                (change)="changeChildAges($event)" [checked]="ages.checked" disabled="{{mode == 'view'}}" />
                            <label [id]="'agelbl' + ages.id" [for]="'age' + ages.id">
                                {{ ages?.years }}
                            </label>
                        </div>
                    </div>
                    <div *ngIf="mode !== 'view'" class="d-flex justify-content-end mt-2">
                        <button type="submit" class="btn btn-default mr-2" (click)="childAgeSubmit()"
                            [disabled]="isSelectChildAges.length === 0">Save</button>
                    </div>
                </div>
            </div> -->
            <div class="d-flex justify-content-between" style="padding: 10px">
                <a class="btn yellow btn-outline custom-btn" (click)="goToBack()">Back</a>
                <a class="btn btn-round btn-primary custom-btn" (click)="goToNext()">Next</a>
            </div>
        </form>
    </div>
</div>