<div class="mr-auto">
  <button class="btn btn-outline-primary btn-circle m-b-10 mr-2" (click)="goToBackButton()">
    Go Back
  </button>
</div>
<div class="mat-elevation-z8 background-transparent" *ngIf="isResponse">
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <div (click)="viewOption = 'propertyInformation'" class="div-adjust">
          <span id="propertyInformation">Property Information</span>
        </div>
      </ng-template>
      <div *ngIf="viewOption === 'propertyInformation'">
        <app-add-property-step1 [propertyDetails]="propertyDetails" (changeNextTab)="changeTab($event)">
        </app-add-property-step1>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <div (click)="viewOption = 'hotel-overview'" class="div-adjust">
          <span id="hotel-overview">Hotel Overview</span>
        </div>
      </ng-template>
      <div *ngIf="viewOption === 'hotel-overview'">
        <app-hotel-overview [propertyDetails]="propertyDetails" (changeNextTab)="changeTab($event)">
        </app-hotel-overview>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <div (click)="viewOption = 'interestSettings'" class="div-adjust">
          <span id="interestSettings">General Settings</span>
        </div>
      </ng-template>
      <div *ngIf="viewOption === 'interestSettings'">
        <app-interest-settings [propertyDetails]="propertyDetails" (changeNextTab)="changeTab($event)">
        </app-interest-settings>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <div (click)="viewOption = 'roomType'" class="div-adjust">
          <span id="roomType">Room Types</span>
        </div>
      </ng-template>
      <div *ngIf="viewOption === 'roomType'">
        <app-add-property-step3 [propertyDetails]="propertyDetails" (changeNextTab)="changeTab($event)">
        </app-add-property-step3>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <div (click)="viewOption = 'propertyFacility'" class="div-adjust">
          <span id="propertyFacility">Property Facilities</span>
        </div>
      </ng-template>
      <div *ngIf="viewOption === 'propertyFacility'">
        <app-add-property-step2 [propertyDetails]="propertyDetails" (changeNextTab)="changeTab($event)">
        </app-add-property-step2>
      </div>
    </mat-tab>

    <!-- <mat-tab>
      <ng-template mat-tab-label>
        <div (click)="viewOption = 'roomFacility&Inclusions'" class="div-adjust">
          <span id="roomFacility&Inclusions">Room Facilities & Inclusions</span>
        </div>
      </ng-template>
      <div *ngIf="viewOption === 'roomFacility&Inclusions'">
        <app-room-facility-inclusions [propertyDetails]="propertyDetails" (changeNextTab)="changeTab($event)">
        </app-room-facility-inclusions>
      </div>
    </mat-tab> -->

    <mat-tab>
      <ng-template mat-tab-label>
        <div (click)="viewOption = 'roomRate'" class="div-adjust">
          <span id="roomRate">Room Rates</span>
        </div>
      </ng-template>
      <div *ngIf="viewOption === 'roomRate'">
        <app-add-property-step4 (changeNextTab)="changeTab($event)"> </app-add-property-step4>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div (click)="viewOption = 'stopSales'" class="div-adjust">
          <span id="stopSales">Stop Sells</span>
        </div>
      </ng-template>
      <div *ngIf="viewOption === 'stopSales'">
        <app-add-property-step5 [propertyDetails]="propertyDetails" (changeNextTab)="changeTab($event)">
        </app-add-property-step5>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>