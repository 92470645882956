<div class="page-bar" *ngIf="!mode">
  <div class="page-title-breadcrumb">
    <div class="pull-left">
      <div class="page-title">Step 4 : Room Type</div>
    </div>
    <ol class="breadcrumb page-breadcrumb pull-right">
      <li>
        <a class="parent-item" href="/"><i class="fa fa-home"></i>&nbsp;Home</a>&nbsp;<i class="fa fa-angle-right"></i>
      </li>
      <li class="active">Room Type</li>
    </ol>
  </div>
</div>
<div class="row" [ngClass]="{ 'mt-3': mode }">
  <div class="col-sm-12">
    <div class="card-box" *ngIf="!mode">
      <div class="card-head">
        <header>Progress Bar</header>
      </div>
      <div class="card-body">
        <div class="progressbar-xs progress-rounded progress-striped progress ng-isolate-scope active">
          <div class="progress-bar progress-bar-success width-75" role="progressbar" aria-valuenow="65"
            aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    </div>

    <mat-accordion *ngIf="roomTypeList.length > 0">
      <mat-expansion-panel *ngFor="let item of roomTypeList | paginate: paginateConfig; let i = index" class="mb-1">
        <mat-expansion-panel-header (click)="collapseRoom(item.id)">
          <mat-panel-title>
            <mat-checkbox [disabled]="true" [checked]="isCheckedRoom(item.id)">{{item.name}}
            </mat-checkbox>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="panel-body">
          <form [formGroup]="propertyFormGroup" (ngSubmit)="onSubmit()">
            <fieldset [disabled]="mode && mode === 'view'">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form_label text-red">Room Name</label>
                    <input name="name" class="form-control" formControlName="name" />
                    <span
                      *ngIf="(propertyFormGroup.controls['name'].dirty || propertyFormGroup.controls['name'].touched) && propertyFormGroup.controls['name'].hasError('required')"
                      class="text-red">Please enter Name</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form_label text-red">Bedding Configuration</label>
                    <label class="form_label text-red no-of-beds">No. of beds</label>
                    <div formArrayName="bedding">
                      <div *ngFor="let beddingType of propertyFormGroup.controls.bedding['controls']; let indx = index">
                        <div [formGroupName]="indx" class="confi-row d-flex mb-1">
                          <select class="form-control step3input" name="bedding_type" formControlName="bedding_type"
                            (change)="onBeddingTypeChange()">
                            <option value="">Select Bedding Types</option>
                            <option *ngFor="let typesOfBedding of beddingTypes" [value]="typesOfBedding.id"
                              [disabled]="typesOfBedding.disabled">
                              {{ typesOfBedding.name }}
                            </option>
                          </select>
                          <input class="form-control step3input" min="0" type="number" [pattern]="onlynumberpattern"
                            name="bedding_config" formControlName="bedding_config" />
                          <button [disabled]="propertyFormGroup.value.bedding.length == 1" type="button"
                            class="text-red btn trash-btn" (click)="removeBeddingConfiguration(indx)">
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>
                        <!-- <span
                        *ngIf="(propertyFormGroup.controls['bedding_type'].dirty || propertyFormGroup.controls['bedding_type'].touched) && propertyFormGroup.controls['bedding_type'].hasError('required')"
                        class="text-red">Please enter Bedding Type</span> -->
                      </div>
                    </div>
                  </div>
                  <div class="row" style="justify-content: center">
                    <button type="button" class="btn btn-round btn-default" (click)="initItemRows()">ADD</button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group" *ngIf="!mode || mode === 'edit'">
                    <label class="form_label">Default Image</label>
                    <input #imageVal class="form-control" type="file"
                      (change)="selectDefaultImage($event.target.files); imageVal.value = ''" />
                    <span class="file-cta"> </span>
                    <div *ngIf="setDefaultImage" class="row p-3 border-bottom">
                      <div class="col-md-5">
                        <span style="overflow-wrap: break-word">
                          {{storage.storage.refFromURL(setDefaultImage).name}}
                        </span>
                      </div>
                      <div class="col-md-4">
                        <img [src]="setDefaultImage" style="width: 70px" />
                      </div>
                      <div class="col-md-2">
                        <button type="button" (click)="selectDefaultImage('', 'delete')">Delete</button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" *ngIf="mode && mode === 'view'">
                    <label class="form_label">Default Image </label>
                    <div class="row">
                      <div class="col-lg-2 p-t-20 mr-3" *ngIf="setDefaultImage" style="
                            border-radius: 5px;
                            cursor: pointer;
                            transition: 0.3s;
                          ">
                        <img [src]="setDefaultImage" id="defualtImg" style="width: 200px"
                          (click)="openImageAsModal(i, setDefaultImage)" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="form_label">Description</label>
                    <textarea formControlName="description" name="description" placeholder="Write description here...."
                      cols="50" rows="10" style="border: 1px solid #888 !important"></textarea>
                  </div>
                </div>
              </div>
              <div *ngIf="inventoryAllocation == 'yes'" class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="form_label text-red">Total Rooms</label>
                    <input class="form-control" formControlName="total_room" [pattern]="onlynumberpattern" type="Number"
                      name="total_room" min="0" />
                    <span
                      *ngIf="(propertyFormGroup.controls['total_room'].dirty || propertyFormGroup.controls['total_room'].touched) && propertyFormGroup.controls['total_room'].hasError('required')"
                      class="text-red">Please enter Total Rooms</span>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="form_label text-red">Maximum Capacity</label>
                    <input class="form-control" formControlName="max_capacity" [pattern]="onlynumberpattern"
                      type="Number" name="max_capacity" min="0" />
                    <span
                      *ngIf="(propertyFormGroup.controls['max_capacity'].dirty || propertyFormGroup.controls['max_capacity'].touched) && propertyFormGroup.controls['max_capacity'].hasError('required')"
                      class="text-red">Please enter Maximum Capacity</span>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="form_label text-red">Maximum Children</label>
                    <input class="form-control" formControlName="max_child" type="Number" [pattern]="onlynumberpattern"
                      name="max_child" min="0" />
                    <span
                      *ngIf="(propertyFormGroup.controls['max_child'].dirty || propertyFormGroup.controls['max_child'].touched) && propertyFormGroup.controls['max_child'].hasError('required')"
                      class="text-red">Please enter Maximum Children</span>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="form_label text-red">Buffer Rooms</label>
                    <input class="form-control" formControlName="buffer_room" [pattern]="onlynumberpattern"
                      type="Number" name="buffer_room" min="0" />
                    <span
                      *ngIf="(propertyFormGroup.controls['buffer_room'].dirty || propertyFormGroup.controls['buffer_room'].touched) && propertyFormGroup.controls['buffer_room'].hasError('required')"
                      class="text-red">Please enter Buffer Rooms</span>
                  </div>
                </div>
              </div>
              <div *ngIf="inventoryAllocation == 'yes'" class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="form_label text-red">Total Bedrooms in one unit</label>
                    <input class="form-control" formControlName="total_bedroom_per_unit"
                      (keyup)="updateValuetotalBedroomPerUnit()" name="total_bedroom_per_unit" type="Number"
                      [pattern]="onlynumberpattern" min="1" />
                    <span
                      *ngIf="(propertyFormGroup.controls['total_bedroom_per_unit'].dirty || propertyFormGroup.controls['total_bedroom_per_unit'].touched) && propertyFormGroup.controls['total_bedroom_per_unit'].hasError('required')"
                      class="text-red">Please enter Total Bedrooms in one unit</span>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="form_label text-red">Maximum Adults</label>
                    <input class="form-control" formControlName="max_adult" type="number" [pattern]="onlynumberpattern"
                      name="max_adult" min="0" />
                    <span
                      *ngIf="(propertyFormGroup.controls['max_adult'].dirty || propertyFormGroup.controls['max_adult'].touched) && propertyFormGroup.controls['max_adult'].hasError('required')"
                      class="text-red">Please enter Maximum Adults</span>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="form_label text-red">Maximum Infant</label>
                    <input class="form-control" formControlName="max_infant" [pattern]="onlynumberpattern" type="number"
                      name="max_infant" min="0" />
                    <span
                      *ngIf="(propertyFormGroup.controls['max_infant'].dirty || propertyFormGroup.controls['max_infant'].touched) && propertyFormGroup.controls['max_infant'].hasError('required')"
                      class="text-red">Please enter Maximum Infant</span>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="form_label text-red">Show Room availability counts once reach to</label>
                    <input class="form-control" formControlName="available_room" [pattern]="onlynumberpattern"
                      type="number" name="available_room" min="0" />
                    <span
                      *ngIf="(propertyFormGroup.controls['available_room'].dirty || propertyFormGroup.controls['available_room'].touched) && propertyFormGroup.controls['available_room'].hasError('required')"
                      class="text-red">Please enter Show Room availability counts once reach to</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <label class="form_label weight-bold d-flex">Facilites available for this room</label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8 check-step3">
                  <div class="checkbox checkbox-black d-inline-block" *ngFor="let faci of facility">
                    <input id="faci{{faci.id}} {{item.id}}" [value]="faci.id" type="checkbox"
                      (change)="onFacilityChecked($event, faci.id)" [checked]="faci.isChecked" />
                    <label for="faci{{faci.id}} {{item.id}}"> {{ faci?.name }} </label>
                  </div>
                </div>
                <div class="col-md-4">
                  <button type="button" [disabled]="mode && mode == 'view'" (click)="checkedUnCheckedFacilities(true)"
                    class="btn btn-round btn-default newCustomBtn">
                    Select All
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <button type="button" [disabled]="mode && mode == 'view'" (click)="checkedUnCheckedFacilities(false)"
                    class="btn btn-round btn-default newCustomBtn">
                    Unselect All
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <label class="form_label weight-bold d-flex">Inclusions available for this room</label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8 check-step3">
                  <div class="checkbox checkbox-black d-inline-block" *ngFor="let inc of inclusion">
                    <input id="inc{{inc.id}}{{item.id}}" class="inclusioncheckbox" [value]="inc.id" type="checkbox"
                      [checked]="inc.isChecked" (change)="onInclusionChecked($event, inc.id)" />
                    <label for="inc{{inc.id}}{{item.id}}"> {{ inc?.name }} </label>
                  </div>
                </div>
                <div class="col-md-4">
                  <button type="button" [disabled]="mode && mode == 'view'" (click)="checkedUnCheckedInclusion(true)"
                    class="btn btn-round btn-default newCustomBtn">
                    Select All
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <button type="button" [disabled]="mode && mode == 'view'" (click)="checkedUnCheckedInclusion(false)"
                    class="btn btn-round btn-default newCustomBtn">
                    UnSelect All
                  </button>
                </div>
              </div>
              <div class="form-group mt-4">
                <label class="form_label">Media Gallery</label>
              </div>
              <div *ngIf="!mode || mode !== 'view'" class="col-lg-12">
                <div appDropzone class="dropzone" [id]="'drop' + item.id" dropzone (hovered)="isHovering = $event"
                  (dropped)="onDrop($event, item.id)" [class.hovering]="isHovering">
                  <p>Drag and Drop Image / Video inside the Box</p>
                  <div class="file">
                    <label class="file-label">
                      <input #gallaryImge name="images" class="file-input" type="file" multiple="multiple"
                        (change)="onDrop($event.target.files, item.id); gallaryImge.value = ''" />
                      <span class="file-cta"> </span>
                    </label>
                  </div>
                </div>
                <div id="testParent{{ item.id }}"></div>
                <div>
                  <div class="row p-3 border-bottom" *ngFor="let item of urlSaved; let i = index">
                    <div class="col-md-5">
                      <span style="overflow-wrap: break-word">
                        {{storage.storage.refFromURL(item).name}}
                      </span>
                    </div>

                    <ng-container [ngTemplateOutlet]="showImgVideo"
                      [ngTemplateOutletContext]="{url: storage.storage.refFromURL(item).name, index: i, urlSaved: urlSaved, item: item}">
                    </ng-container>

                    <div class="col-md-2">
                      <button type="button" (click)="removeImg(i)">Delete</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-box" *ngIf="mode && mode === 'view'">
                <div class="card-body row">
                  <div class="mr-3" *ngFor="let item of urlSaved; let i = index">

                    <ng-container [ngTemplateOutlet]="showImgVideo"
                      [ngTemplateOutletContext]="{url: item, index: i, urlSaved: urlSaved, item: item}">
                    </ng-container>

                  </div>
                </div>
              </div>
              <span class="text-red d-flex justify-content-end mt-4" *ngIf="!propertyFormGroup.valid">
                {{validationmsg}}
              </span>
              <div class="d-flex justify-content-end mt-4" *ngIf="!isDataFound">
                <button [disabled]="!propertyFormGroup.valid" type="submit"
                  class="btn btn-round btn-default custom-btn">
                  Save
                </button>
              </div>
              <div class="d-flex justify-content-end row mt-4" *ngIf="isDataFound && (!mode || mode == 'edit')">
                <button [disabled]="!propertyFormGroup.valid" type="submit"
                  class="btn btn-round btn-default custom-btn">
                  Update
                </button>
                &nbsp;&nbsp;
                <button type="button" (click)="confirmationDialog()" class="btn btn-round btn-default custom-btn">
                  Delete
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <section *ngIf="paginateConfig.totalItems" class="page-box">
      <div class="d-flex align-items-center justify-content-end">
        <pagination-controls (pageChange)="onPageChanged($event)" previousLabel="" nextLabel="" [responsive]="true">
        </pagination-controls>
      </div>
    </section>

    <div class="d-flex justify-content-between" style="padding: 10px" *ngIf="!mode || mode !== 'view'">
      <a class="btn yellow btn-outline custom-btn" (click)="goToBack()">Back</a>
      <a class="btn btn-round btn-primary custom-btn" *ngIf="isgonext" (click)="goToNext()">Next</a>
    </div>
  </div>
</div>

<button type="button" id="openModalButton" [hidden]="true" data-toggle="modal" data-backdrop="static"
  data-keyboard="false" data-target="#facilities-modal">
  Open Modal
</button>
<div class="modal fade" id="facilities-modal">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content" style="width: auto">
      <div class="modal-header">
        <h2>
          Do you want to uncheck {{ checkRoomName }}, all data will be lost?
        </h2>
        <button hidden type="button" id="closeModal" class="close" #closeBtnAdd data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <div class="row justify-content-end">
          <div class="col-md-6">
            <button type="button" data-dismiss="modal" class="float-right btn btn-round btn-default custom-btn"
              (click)="noUncheck()">
              No
            </button>
          </div>
          <div class="col-md-6">
            <button type="button" (click)="yesUncheck()" class="float-left btn btn-round btn-default custom-btn">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<button type="button" id="confirmModalButton" [hidden]="true" data-toggle="modal" data-target="#confirm-modal">
  Open Modal
</button>
<div class="modal fade" id="confirm-modal">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content" style="width: auto">
      <div class="modal-header">
        <h2>Following File already selected , do you want to upload?</h2>
        <button type="button" id="closeModal" hidden class="close" #closeBtnConfirm data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngFor="let item of sameNameFiles">
          <div class="row justify-content-center" style="font-size: 18px">
            {{ item.name }}
          </div>
        </ng-container>
      </div>
      <div class="modal-footer">
        <div class="row justify-content-end">
          <div class="col-md-6">
            <button type="button" data-dismiss="modal" (click)="noUncheck1()"
              class="float-right btn btn-round btn-default custom-btn">
              No
            </button>
          </div>
          <div class="col-md-6">
            <button type="button" (click)="yesUncheck1()" class="float-left btn btn-round btn-default custom-btn">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="files">
  <div class="" *ngFor="let file of files" [hidden]="true">
    <app-upload-task [file]="file" (imgUrl)="onImgUpload($event)"></app-upload-task>
  </div>
</ng-container>
<ng-container *ngIf="defaultImage">
  <div [hidden]="true">
    <app-upload-task [file]="defaultImage" (imgUrl)="onUploadDefaultImage($event)"></app-upload-task>
  </div>
</ng-container>

<!-- image modal-->
<div *ngIf="openImageModal">
  <app-image-modal [currentImageIndex]="currentImageIndex" [images]="imagesArr"
    (closeImageModal)="openImageModal = false"></app-image-modal>
</div>

<ng-template #showImgVideo let-url="url" let-index="index" let-urlSaved="urlSaved" let-item="item">
  <div *ngIf="url?.includes('.mp4') || url?.includes('.m4v'); else showImg">
    <video width="150" autoplay onloadedmetadata="this.muted = true" (click)="openImageAsModal(index, urlSaved)">
      <source [src]="item">
    </video>
  </div>
  <ng-template #showImg>
    <img [src]="item" width="150" (click)="openImageAsModal(index, urlSaved)" />
  </ng-template>
</ng-template>