import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.css']
})
export class ImageModalComponent implements OnInit {
  public displayImgNextButton: boolean;
  public displayImgPrevButton: boolean;
  @Input() currentImageIndex: number;
  @Output() closeImageModal: EventEmitter<any> = new EventEmitter<any>();
  @Input() images: any = [];
  constructor() { }

  ngOnInit(): void {
    this.openImageAsModal();
  }

  openImageAsModal(): void {
    const modal = document.getElementById("imageModel");
    modal.style.display = "block";
    const span: any = document.getElementsByClassName("image-modal-close")[0];

    span.onclick = () => {
      modal.style.display = "none";
      this.closeImageModal.emit('close');
    };
    this.loadImageBaseOnIndex();
  }

  /**
   * 
   * @param mode 
   *        next/prev.
   * @description
   *     set porperty details in form when open screen as view or edit
   */
  public loadImageBaseOnIndex(mode?: string): void {
    if (mode === 'next') {
      this.currentImageIndex = this.currentImageIndex + 1;
    } else if (mode === 'prev') {
      this.currentImageIndex = this.currentImageIndex - 1;
    }
    if (this.images.length > 1) {
      this.displayImgNextButton = true;
      this.displayImgPrevButton = true;
      if (this.currentImageIndex === this.images.length - 1) {
        this.displayImgNextButton = false;
        this.displayImgPrevButton = true;
      } else if (this.currentImageIndex === 0) {
        this.displayImgNextButton = true;
        this.displayImgPrevButton = false;
      }

    } else {
      this.displayImgPrevButton = false;
      this.displayImgNextButton = false;
    }
  }
}
