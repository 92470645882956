import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AngularFireStorage } from "@angular/fire/storage";
import { FormArray, FormBuilder, FormGroup, Validators, } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import * as $ from "jquery";
import { message } from "../../../_constants/constants";
import { MastersService } from "../../../masters/masters.service";
import { PropertyService } from "../../property-service.service";
import { step3 } from "../add-property-step3/step3";
import { ConfirmDialogComponent } from "src/app/shared";
import { BeddingTypeService, InclusionsService, RoomFacilitiesService, RoomTypeService } from "src/app/_services";

@Component({
  selector: "app-add-property-step3",
  templateUrl: "./add-property-step3.component.html",
  styleUrls: ["./add-property-step3.component.scss"],
})
export class AddPropertyStep3Component implements OnInit {
  viewmode = false;
  isgonext: boolean = false;
  step3modal: step3 = new step3();
  BeddingDetail: any = [];
  public checkedId: any;
  public unCheckedId: any;
  public nextCheckedId: any;
  public checkRoomName: any;
  public beddingTypes: any = [];
  public allRoom: any = [];
  public facility: any = [];
  public inclusion: any = [];
  public facilityStrArr: any = [];
  public inclusionStrArr: any = [];
  public savedRoomId: any = [];
  public allFacility: any = [];
  public count: any = 0;
  isHovering: boolean;
  files: File[] = [];
  files1: File[] = [];
  public defaultImgUrl: any = [];
  public urls: any = [];
  public editMode: Boolean = false;
  public status: boolean = false;
  public mode: string;
  @Input() propertyDetails: any = {};
  public deleteUrlId: any = [];
  public urlSaved: any = [];
  @ViewChild("closeBtnAdd") closeBtnAdd: ElementRef;
  @ViewChild("add") add: ElementRef;
  private viewedRoom: any = [];
  @Output() changeNextTab: EventEmitter<any> = new EventEmitter<any>();
  public currentImageIndex: number;
  public imagesArr: any = [];
  public openImageModal = false;
  onlynumberpattern: RegExp = new RegExp(/^[0-9]+$/);
  validationmsg = message.requiredmsg;

  hotelId: string | number;
  roomTypeList: any = [];
  propertyFormGroup: FormGroup;
  defaultImage: any;
  defaultImageName: string;
  setDefaultImage: any;
  roomTypeDetails: any = [];
  checkedFacilities: any = [];
  roomType: any;
  isDataFound = false;
  inventoryAllocation = 'yes';
  queryParameter: any;
  paginateConfig = { itemsPerPage: 10, currentPage: 1, totalItems: 0 };

  constructor(
    public storage: AngularFireStorage,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private roomTypeService: RoomTypeService,
    public propertyService: PropertyService,
    public toasterservice: ToastrService,
    private spinner: NgxSpinnerService,
    public matDialog: MatDialog,
    public formBuilder: FormBuilder,
    public changeDetectorRef: ChangeDetectorRef,
    private roomFacilitiesService: RoomFacilitiesService,
    private inclusionsService: InclusionsService,
    private beddingTypeService: BeddingTypeService
  ) { }

  ngOnInit(): void {
    this.setVariable();
    this.initialForm();
    this.getRoomType();
    this.getBeddingType();
    this.getRoomFacilities();
    this.getInclusions();
    this.getPropertyDetails();
  }

  setVariable() {
    this.mode = this.propertyDetails.mode || this.activatedRoute.snapshot.paramMap.get('mode');
    this.inventoryAllocation = this.activatedRoute.snapshot.queryParams.inventory_allocation || 'yes';
    this.hotelId = this.activatedRoute.snapshot.paramMap.get('propertyId') || this.activatedRoute.snapshot.params.hotelId;
    this.roomTypeList = [];
    this.urlSaved = [];
    this.urls = [];
    this.roomTypeDetails = [];
    this.checkedFacilities = [];
    this.inclusionStrArr = [];
    this.defaultImage = null;
    this.defaultImageName = '';
    this.setDefaultImage = null;
    this.roomType = null;
    this.propertyFormGroup = null;
    if (this.activatedRoute.snapshot.queryParams.inventory_allocation) {
      this.queryParameter = {
        queryParams: {
          inventory_allocation: this.inventoryAllocation
        }
      }
    }
  }

  /**
   * initial form
   * @param data details data
   */
  initialForm(data?: any) {
    this.propertyFormGroup = this.formBuilder.group({
      id: [data?.id],
      name: [data?.name, [Validators.required]],
      bedding: this.formBuilder.array([]),
      default_images: [data?.default_images],
      description: [data?.description],
      total_room: [data?.total_room, [Validators.required]],
      max_capacity: [data?.max_capacity, [Validators.required]],
      max_child: [data?.max_child, [Validators.required]],
      buffer_room: [data?.buffer_room, [Validators.required]],
      total_bedroom_per_unit: [data?.total_bedroom_per_unit, [Validators.required]],
      max_adult: [data?.max_adult, [Validators.required]],
      max_infant: [data?.max_infant, [Validators.required]],
      available_room: [data?.available_room, [Validators.required]],
      facilities: [data?.facilities],
      inclusions: [data?.inclusions],
      images: [data?.images],
      room_type: [data?.room_type]
    });

    this.clearValidations();
    this.addBeddingConfiguration(data);
  }

  clearValidations() {
    const removeValidation = [
      'total_room', 'max_capacity', 'max_child', 'buffer_room',
      'total_bedroom_per_unit', 'max_adult', 'max_infant', 'available_room'
    ];
    if (this.inventoryAllocation === 'no') {
      removeValidation.forEach(val => {
        this.propertyFormGroup.controls[val].clearValidators();
      });
    }
  }

  /**
   * add bedding configuration
   * @param data bedding data
   */
  addBeddingConfiguration(data?: any) {
    const biddingData = (this.propertyFormGroup.get('bedding') as FormArray);
    if (data?.bedding) {
      data?.bedding.forEach((element, key) => {
        biddingData.push(
          this.formBuilder.group({
            id: [element?.id],
            bedding_type: [element?.bedding_type, [Validators.required]],
            bedding_config: [element?.bedding_config, [Validators.required]]
          })
        );
        this.onBeddingTypeChange();
      });
    } else {
      biddingData.push(
        this.formBuilder.group({
          id: [''],
          bedding_type: ['', [Validators.required]],
          bedding_config: ['', [Validators.required]]
        })
      );
    }
  }

  initItemRows() {
    const biddingData = (this.propertyFormGroup.get('bedding') as FormArray);
    biddingData.push(this.formBuilder.group({
      id: [''],
      bedding_type: ['', [Validators.required]],
      bedding_config: ['', [Validators.required]]
    }));
  }

  /**
   * remove bedding configuration
   * @param index index
   */
  removeBeddingConfiguration(index: number) {
    const biddingData = (this.propertyFormGroup.get('bedding') as FormArray);
    biddingData.removeAt(index);
    this.onBeddingTypeChange();
  }

  /**
   * get room type
   */
  getRoomType() {
    this.spinner.show();
    this.roomTypeService.getRoomType(null, this.paginateConfig).subscribe((response: any) => {
      if (response.success) {
        this.roomTypeList = response.data;
        this.paginateConfig.totalItems = response?.pageInfo?.count;
        this.spinner.hide();
      }
    });
  }

  /**
   * get property details
   */
  getPropertyDetails() {
    this.roomTypeDetails = [];
    this.spinner.show();
    this.propertyService
      .getHotelPropertyDetailsStep1(this.hotelId)
      .subscribe((res: any) => {
        if (res.success) {
          if (res.data && res.data.room.length > 0) {
            this.roomTypeDetails = res.data.room;
            this.isgonext = true;
          }
        }
        this.spinner.hide();
      });
  }

  isCheckedRoom(id: any) {
    let val = false;
    this.roomTypeDetails.forEach((data: any) => {
      if (id == data.room_type) {
        val = true;
      }
    });
    return val;
  }

  getBeddingType() {
    this.spinner.show();
    this.beddingTypeService.getBeddingType().subscribe((res: any) => {
      this.spinner.hide();
      if (res.success) {
        this.beddingTypes = res.data;
        this.beddingTypes.forEach((element) => {
          element.disabled = false;
        });
      }
    });
  }

  getRoomFacilities(val?: string) {
    if (val) {
      this.facility.forEach(element => {
        element.isChecked = false;
        this.checkedFacilities.forEach(val => {
          if (val == element.id) {
            element.isChecked = true;
          }
        });
      });
    } else {
      this.spinner.show();
      this.roomFacilitiesService.getRoomFacilities().subscribe((res: any) => {
        if (res.success) {
          this.facility = res.data;
        }
        this.spinner.hide();
      });
    }
  }

  getInclusions(val?: string) {
    if (val) {
      this.inclusion.forEach(element => {
        element.isChecked = false;
        this.inclusionStrArr.forEach(val => {
          if (val == element.id) {
            element.isChecked = true;
          }
        });
      });
    } else {
      this.spinner.show();
      this.inclusionsService.getInclusions().subscribe((res: any) => {
        if (res.success) {
          this.inclusion = res.data;
        }
        this.spinner.hide();
      });
    }
  }

  /**
   * select default image
   * @param files image object
   */
  selectDefaultImage(files: any, item?) {
    this.defaultImage = null;
    if (item) {
      this.propertyFormGroup.value.default_images = '';
      this.defaultImageName = '';
      this.setDefaultImage = '';
    } else {
      this.spinner.show();
      this.defaultImage = files[0];
    }
  }

  /**
   * collapse room
   * @param id id
   */
  collapseRoom(id: string | number) {
    this.urlSaved = [];
    this.urls = [];
    this.checkedFacilities = [];
    this.inclusionStrArr = [];
    this.defaultImage = null;
    this.defaultImageName = '';
    this.setDefaultImage = null;
    this.roomType = id;
    const roomDetails = this.roomTypeDetails.filter(item => item.room_type == id)[0];
    this.isDataFound = false;
    this.initialForm(roomDetails);
    if (roomDetails) {
      this.isDataFound = true;
      this.setDefaultImage = roomDetails.default_images;
      this.checkedFacilities = roomDetails.facilities;
      this.inclusionStrArr = roomDetails.inclusions;
      this.urlSaved = roomDetails.images;
      this.urls = roomDetails.images;
    }
    this.getRoomFacilities('collapse');
    this.getInclusions('collapse');
    this.onBeddingTypeChange();
  }

  onBeddingTypeChange() {
    this.beddingTypes.forEach(val => {
      if (this.propertyFormGroup.value.bedding.filter(items => items.bedding_type === val.id).length > 0) {
        val.disabled = true;
      } else {
        val.disabled = false;
      }
    });
  }

  /**
   * upload image on firebase
   * @param url image url
   */
  onUploadDefaultImage(url: any) {
    this.spinner.hide();
    this.setDefaultImage = url;
    this.defaultImageName = `${Date.now()}_${this.defaultImage.name}`;
  }

  checkedUnCheckedFacilities(val) {
    this.checkedFacilities = [];
    this.facility.forEach((element: any) => {
      element.isChecked = val;
      if (element.isChecked) {
        this.checkedFacilities.push(element.id)
      }
    });
  }

  onFacilityChecked(event, id) {
    this.checkedFacilities = [];
    this.facility.forEach((element: any) => {
      if (element.id == id) {
        element.isChecked = event.target.checked;
      }
      if (element.isChecked) {
        this.checkedFacilities.push(element.id);
      }
    });
  }

  checkedUnCheckedInclusion(val) {
    this.inclusionStrArr = [];
    this.inclusion.forEach((element: any) => {
      element.isChecked = val;
      if (element.isChecked) {
        this.inclusionStrArr.push(element.id)
      }
    });
  }

  onInclusionChecked(event, id) {
    this.inclusionStrArr = [];
    this.inclusion.forEach((element: any) => {
      if (element.id == id) {
        element.isChecked = event.target.checked;
      }
      if (element.isChecked) {
        this.inclusionStrArr.push(element.id);
      }
    });
  }

  /**
   * submit form
   */
  async onSubmit() {
    this.propertyFormGroup.value.inclusions = [];
    this.propertyFormGroup.value.facilities = [];
    this.propertyFormGroup.value.inventory_allocation = this.inventoryAllocation === 'no' ? false : true
    this.inclusionStrArr.forEach(element => {
      this.propertyFormGroup.value.inclusions.push(String(element));
    });
    this.checkedFacilities.forEach(element => {
      this.propertyFormGroup.value.facilities.push(String(element));
    });
    this.propertyFormGroup.value.default_images = this.setDefaultImage;
    this.propertyFormGroup.value.images = this.urls;
    this.propertyFormGroup.value.room_type = this.roomType;
    this.spinner.show();

    if (this.propertyFormGroup.value.id) {
      if (this.deleteUrlId.length > 0) {
        for (let i = 0; i < this.deleteUrlId.length; i++) {
          await this.storage.storage.refFromURL(this.deleteUrlId[i]).delete();
        }
      }
      this.propertyService
        .updatePropertyStep3(
          this.hotelId,
          this.propertyFormGroup.value.id,
          this.propertyFormGroup.value
        )
        .subscribe((data) => {
          if (data["success"]) {
            this.ngOnInit();
            this.toasterservice.success(data["message"], "Success", {
              timeOut: 1000,
            });
          } else {
            this.toasterservice.success(data["message"], "Error", {
              timeOut: 3000,
            });
          }
          this.spinner.hide();
        });
    } else {
      this.propertyService
        .addPropertyStep3(this.hotelId, this.propertyFormGroup.value)
        .subscribe((data: any) => {
          if (data.success) {
            this.ngOnInit();
            this.toasterservice.success(data["message"], "Success", {
              timeOut: 3000,
            });
          } else {
            this.toasterservice.success(data["message"], "Error", {
              timeOut: 3000,
            });
          }
          this.spinner.hide();
        });
    }
  }

  /**
   * confirmation dialog for delete property
   * @param element property id
   */
  confirmationDialog() {
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      width: '350px',
      height: '370px',
      data: {
        title: 'Are you sure?',
        message: 'It will permanently deleted !',
        okBtnLabel: 'Yes, delete it!',
        cancelBtnLabel: 'Cancel'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteRoom();
      }
    });
  }

  openImageAsModal(index: number, images: any[]): void {
    this.currentImageIndex = index;
    this.imagesArr = images;
    this.openImageModal = true;
  }

  goToNext(): void {
    if (this.mode && this.mode === 'edit') {
      this.changeNextTab.emit('propertyFacility');
    } else {
      this.router.navigate(['property', 'step-2-add-property', this.hotelId], this.queryParameter);
    }
  }

  goToBack(): void {
    if (this.mode && this.mode === 'edit') {
      this.changeNextTab.emit('interestSettings');
    } else {
      this.router.navigate(['property', 'interests-settings', this.hotelId], this.queryParameter);
    }
  }

  onPageChanged(event) {
    this.paginateConfig.currentPage = event;
    this.getRoomType();
  }

  // -----------------------------------------------------------------------------

  async deleteRoom() {
    if (this.deleteUrlId.length > 0) {
      for (let i = 0; i < this.deleteUrlId.length; i++) {
        await this.storage.storage.refFromURL(this.deleteUrlId[i]).delete();
      }
    }
    this.spinner.show();
    this.propertyService
      .deletePropertyStep3(this.hotelId, this.propertyFormGroup.value.id)
      .subscribe((data) => {
        if (data["success"] === true) {
          this.ngOnInit();
          this.toasterservice.success(data["message"], "Success", {
            timeOut: 3000,
          });
        } else {
          this.toasterservice.success(data["message"], "Error", {
            timeOut: 3000,
          });
        }
        this.spinner.hide();
      });
  }

  yesUncheck() {
    this.step3modal = new step3();
    this.closeBtnAdd.nativeElement.click();

    document.getElementById("testParent" + this.unCheckedId).innerHTML = "";
    this.allFacility.push(this.step3modal);
    this.step3modal = new step3();
    $("#fileControl").val("");
    this.urls = [];
    this.defaultImgUrl = "";
    this.beddingTypes.forEach((element) => {
      element.disabled = false;
    });
    this.files = [];
    this.BeddingDetail = [];
    this.deleteUrlId = [];
    this.urlSaved = [];

    if (
      this.unCheckedId !== "empty" &&
      this.savedRoomId.length > 0 &&
      this.nextCheckedId === "empty"
    ) {
      let indexRoomId = this.savedRoomId.findIndex(
        (item) => item.type === parseInt(this.unCheckedId)
      );
      if (indexRoomId !== -1) {
      }
    }

    if (this.nextCheckedId !== "empty" && this.unCheckedId === "empty") {
      this.collapse(this.checkedId, false);
      this.dosame(true);
      $("#chk" + this.checkedId).prop("checked", false);
      this.checkedId = this.nextCheckedId;
      this.nextCheckedId = "empty";
      let ind = this.roomTypeList.findIndex((item) => item["id"] === this.checkedId);
      this.checkRoomName = this.roomTypeList[ind]["name"];
      this.collapse(this.checkedId, true);
    } else {
      if (this.mode && this.mode === "edit") {
        this.checkedId = this.unCheckedId;
        if (this.urlSaved && this.urlSaved.length > 0) {
          for (const [i, v] of this.urlSaved.entries()) {
            this.removeImg(i);
          }
        } else this.removeImg(0);
      }
      this.collapse(this.checkedId, false);
      this.dosame(true);
      this.checkedId = "done";
    }
  }

  collapse(id, val) {
    if (!val) {
      this.editMode = false;
    }
    const el = document.querySelectorAll('[id^=collapse_]');
    el.forEach(element => {
      if (element.className.search('show') !== -1) {
        $(`#${element.id}`).removeClass("show");
      }
    });
    $("#collapse_" + id).addClass("show");
  }

  noUncheck() {
    if (this.unCheckedId !== "empty") {
      $("#chk" + this.unCheckedId).prop("checked", "checked");
      this.unCheckedId = "empty";
    } else {
      $("#chk" + this.nextCheckedId).prop("checked", false);
      this.nextCheckedId = "empty";
    }
  }

  onImgUpload(url) {
    this.spinner.hide();
    var source = document.getElementById("testChild");
    this.count += 1;
    source.id = "child" + this.count;
    this.urls.push(url);
    this.urlSaved.push(url);
  }


  customTrackBy(index: number, obj: any): any {
    return index;
  }

  @ViewChild("closeBtnConfirm") closeBtnConfirm: ElementRef;
  sameNameFiles: File[] = [];
  onDrop(files: FileList, id) {
    this.checkedId = id;
    let status = false;
    for (let i = 0; i < files.length; i++) {
      const extention = files[i].type.split('/')[1];
      if (extention === 'jpg' || extention === 'jpeg' || extention === 'png' || extention === 'mp4' || extention === 'm4v') {
        this.spinner.show();
        if (this.files.length > 0) {
          let index = this.files.findIndex(
            (item) => item.name === files.item(i).name
          );
          let indexUrl = this.urls.findIndex(
            (item) =>
              this.storage.storage.refFromURL(item).name.substring(14) ===
              files.item(i).name
          );
          var imagelistindex = this.urlSaved.findIndex(
            (item) =>
              this.storage.storage.refFromURL(item).name.substring(14) ===
              files.item(i).name
          );
          if (index > -1) {
            if (indexUrl == -1) {
              this.files.splice(index, 1);
              index = this.files.findIndex(
                (item) => item.name === files.item(i).name
              );
            }
          }
          if (imagelistindex > -1) {
            this.sameNameFiles.push(files.item(i));
          }
          if (index !== -1) {
            this.sameNameFiles.push(files.item(i));
          } else {
            if (this.sameNameFiles.length == 0) this.files.push(files.item(i));
          }
        } else {
          var imagelistindex = this.urlSaved.findIndex(
            (item) =>
              this.storage.storage.refFromURL(item).name.substring(14) ===
              files.item(i).name
          );
          if (imagelistindex > -1) {
            this.sameNameFiles.push(files.item(i));
          } else this.files.push(files.item(i));
        }
        if (i === files.length - 1 && this.sameNameFiles.length > 0) {
          status = true;
        }
      } else {
        this.toasterservice.error('Only jpg, jpeg, png, mp4, m4v formats allowed', 'Error', {
          timeOut: 3000,
        });
      }
    }
    if (this.sameNameFiles.length > 0 && status === true) {
      $("#confirmModalButton").click();
      status = false;
      setTimeout(() => {
        if (this.mode && this.mode === "edit") {
          $(".modal-backdrop").remove();
        }
      }, 500);
    }
  }

  yesUncheck1() {
    for (let i = 0; i < this.sameNameFiles.length; i++) {
      this.files.push(this.sameNameFiles[i]);
    }
    this.dosame(false);
  }

  noUncheck1() {
    this.dosame(false);
  }

  dosame(onlymodel) {
    if (!onlymodel) {
      this.sameNameFiles = [];
      this.closeBtnConfirm.nativeElement.click();
    }
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
  }

  justClose() {
    this.collapse(this.checkedId, false);
    $("#chk" + this.checkedId).prop("checked", true);
    this.refreshmodal(this.checkedId);
  }

  removeImg(p) {
    this.deleteUrlId.push(this.urlSaved[p]);
    this.urlSaved.splice(p, 1);
  }

  refreshmodal(id) {
    document.getElementById("testParent" + this.checkedId).innerHTML = "";
    this.allFacility.push(this.step3modal);
    this.step3modal = new step3();
    $("#fileControl").val("");
    this.urls = [];
    this.defaultImgUrl = "";
    this.beddingTypes.forEach((element) => {
      element.disabled = false;
    });
    this.files = [];
    this.BeddingDetail = [];
    this.deleteUrlId = [];
    this.urlSaved = [];
    this.collapse(id, false);
    this.checkedId = "done";
  }
  updateValuetotalBedroomPerUnit() {
    if (this.step3modal.total_bedroom_per_unit < 1) {
      this.step3modal.total_bedroom_per_unit = 1;
    }
  }
}
