import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MastersService {
  apiURL = environment.origin;
  constructor(private http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  //property facilities
  addPropertyFacilities(data) {
    return this.http
      .post(
        this.apiURL + "property-facilities/save",
        JSON.stringify(data),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  getPropertyFacilities() {
    return this.http
      .get(this.apiURL + "property-facilities/get", this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  editPropertyFacilities(id, data) {
    return this.http
      .put(
        this.apiURL + "property-facilities/save/" + id,
        JSON.stringify(data),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }
  deletePropertyFacilities(id) {
    return this.http
      .delete(
        this.apiURL + "property-facilities/delete/" + id,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  //country
  addCountry(data, video) {
    const formData: FormData = new FormData();
    formData.append('name', data.name);
    formData.append('video', video);
    return this.http.post(this.apiURL + "country/save", formData)
      .pipe(retry(1), catchError(this.handleError));
  }

  getCountry() {
    return this.http
      .get(this.apiURL + "country/get", this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  editCountry(id, data, video) {
    const formData: FormData = new FormData();
    formData.append('name', data.name);
    if (typeof video === 'object') {
      formData.append('video', video);
    } else {
      formData.append('video', data.video);
    }
    return this.http.put(this.apiURL + "country/save/" + id, formData)
      .pipe(retry(1), catchError(this.handleError));
  }
  deleteCountry(id) {
    return this.http
      .delete(this.apiURL + "country/delete/" + id, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  //province
  addProvince(countryId, data) {
    return this.http
      .post(
        this.apiURL + "province/save/" + countryId,
        JSON.stringify(data),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  getProvince() {
    return this.http
      .get(this.apiURL + "province/get", this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  getProvinceById(id) {
    return this.http
      .get(this.apiURL + "province/get/" + id, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  editProvince(countryId, id, data) {
    return this.http
      .put(
        this.apiURL + "province/save/" + countryId + "/" + id,
        JSON.stringify(data),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  deleteProvince(countryId, id) {
    return this.http
      .delete(
        this.apiURL + "province/delete/" + countryId + "/" + id,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }
  //timezone
  addTimezone(data) {
    return this.http
      .post(
        this.apiURL + "timezone/save",
        JSON.stringify(data),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  getTimezone(countryId?: string) {
    let url = this.apiURL + "timezone/get";

    if (countryId) url = url + "/" + countryId;
    return this.http
      .get(url, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  editTimezone(id, data) {
    return this.http
      .put(
        this.apiURL + "timezone/save/" + id,
        JSON.stringify(data),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  deleteTimezone(id) {
    return this.http
      .delete(this.apiURL + "timezone/delete/" + id, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  //city

  addCity(provinceId, data) {
    return this.http
      .post(
        this.apiURL + "city/save/" + provinceId,
        JSON.stringify(data),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  getCity() {
    return this.http
      .get(this.apiURL + "city/get", this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  getCityById(id) {
    return this.http
      .get(this.apiURL + "city/getc/" + id, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  editCity(provinceId, id, data) {
    return this.http
      .put(
        this.apiURL + "city/save/" + provinceId + "/" + id,
        JSON.stringify(data),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  deleteCity(provinceId, id) {
    return this.http
      .delete(
        this.apiURL + "city/delete/" + provinceId + "/" + id,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
