import { Injectable } from '@angular/core';
import { mastersApi } from '../../_constants/api-list';
import { environment } from '../../../environments/environment';
import { HttpClientService } from '../index';


@Injectable({
    providedIn: 'root',
})
export class StyleService {

    constructor(
        private httpClientService: HttpClientService
    ) { }

    /**
     * Add or Edit Style
     * @param requestData request data
     * @returns Return response from api
     */
    saveStyle(requestData: any) {
        let url = environment.origin + mastersApi.SAVE_STYLE;
        return this.httpClientService.post(url, requestData);
    }

    /**
     * Get style list
     * @param styleId style id 
     * @returns Return response from api
     */
    getStyle(styleId?: any) {
        let url = environment.origin + mastersApi.GET_STYLE;
        if (styleId) {
            url += `/${styleId}`;
        }
        return this.httpClientService.get(url);
    }

    /**
     * Delete style
     * @param styleId style id
     * @returns Return response from api
     */
    deleteStyle(styleId?: any) {
        let url = environment.origin + mastersApi.DELETE_STYLE + styleId;
        return this.httpClientService.delete(url);
    }
}
