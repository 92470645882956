import { Injectable } from '@angular/core';
import { mastersApi } from '../../_constants/api-list';
import { environment } from '../../../environments/environment';
import { HttpClientService } from '../index';

@Injectable({
    providedIn: 'root',
})
export class InterestsService {

    constructor(
        private httpClientService: HttpClientService
    ) { }

    /**
     * Add or Edit interests
     * @param requestData request data
     * @returns Return response from api
     */
    saveInterests(requestData: any) {
        let url = environment.origin + mastersApi.SAVE_INTERESTS;
        return this.httpClientService.post(url, requestData);
    }

    /**
     * Get interests list
     * @param interestsId interests id 
     * @returns Return response from api
     */
    getInterests(interestsId?: any) {
        let url = environment.origin + mastersApi.GET_INTERESTS;
        if (interestsId) {
            url += `/${interestsId}`;
        }
        return this.httpClientService.get(url);
    }

    /**
     * Delete Interests
     * @param interestsId interest id
     * @returns Return response from api
     */
    deleteInterests(interestsId?: any) {
        let url = environment.origin + mastersApi.DELETE_INTERESTS + interestsId;
        return this.httpClientService.delete(url);
    }
}
