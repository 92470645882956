import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { PropertyService } from '../../property-service.service';
import { InterestModalComponent } from '../add-property-step7/interest-modal/interest-modal.component';
import { InclusionsService, RoomFacilitiesService } from '../../../_services';

@Component({
    selector: 'app-room-facility-inclusions',
    templateUrl: './room-facility-inclusions.component.html',
    styleUrls: ['./room-facility-inclusions.component.scss']
})

export class RoomFacilityAndInclusionsComponent implements OnInit {

    @Input() propertyDetails: any = {};
    @Output() changeNextTab: EventEmitter<any> = new EventEmitter<any>();
    mode: string;
    hotelId: any;
    listOfRoomFacilities: any = [];
    listOfInclusions: any = [];
    queryParameter: any;
    roomFacility: any = [];
    inclusionsData: any = [];


    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public matDialog: MatDialog,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        private propertyService: PropertyService,
        private roomFacilitiesService: RoomFacilitiesService,
        private inclusionsService: InclusionsService
    ) { }

    ngOnInit() {
        this.initilization();
    }

    /**
     * Initially call
     */
    initilization() {
        window.scrollTo(0, 0);
        this.mode = this.activatedRoute.snapshot.params.mode || this.propertyDetails.mode;
        this.hotelId = this.activatedRoute.snapshot.paramMap.get('hotelId') || this.activatedRoute.snapshot.params.propertyId;
        if (this.activatedRoute.snapshot.queryParams.inventory_allocation) {
            this.queryParameter = {
                queryParams: {
                    inventory_allocation: this.activatedRoute.snapshot.queryParams.inventory_allocation
                }
            }
        }

        this.spinner.show();
        forkJoin([
            this.roomFacilitiesService.getRoomFacilities(),
            this.inclusionsService.getInclusions(),
            this.propertyService.getHotelPropertyDetailsStep1(this.hotelId),
        ]).subscribe(([res1, res2, res3]: any) => {
            this.spinner.hide();
            if (res1.success) {
                this.listOfRoomFacilities = res1.data;
            } else {
                this.toastr.error(res1.message, 'Error');
            }

            if (res2.success) {
                this.listOfInclusions = res2.data;
            } else {
                this.toastr.error(res2.message, 'Error');
            }

            if (res3.success) {
                this.propertyDetails = res3.data;
                if (this.propertyDetails.room_facilities.length > 0) {
                    this.roomFacility = this.propertyDetails.room_facilities[0];
                }
                if (this.propertyDetails.inclusions.length > 0) {
                    this.inclusionsData = this.propertyDetails.inclusions[0];
                }
            } else {
                this.toastr.error(res3.message, 'Error');
            }
        });
    }

    /**
     * Open modal
     * @param item items
     */
    editorModal(item: any, type: string) {
        let detailsData: any = null;
        if (type === 'roomfacility') {
            detailsData = this.propertyDetails.room_facilities.filter(data => data.room_facilities_id == item.id)[0];
        } else {
            detailsData = this.propertyDetails.inclusions.filter(data => data.inclusions_id == item.id)[0];
        }
        const dialogRef = this.matDialog.open(InterestModalComponent, {
            width: '800px',
            height: '650px',
            disableClose: true,
            data: {
                item,
                hotelId: this.hotelId,
                details: detailsData,
                mode: this.mode,
                type
            }
        });

        dialogRef.afterClosed().subscribe(() => {
            this.initilization();
            this.spinner.hide();
        });
    }

    /**
     * Go to next page
     */
    goToNext() {
        if (this.mode && this.mode === 'edit') {
            this.changeNextTab.emit('roomRate');
        } else {
            this.router.navigate(['property', 'step-4-add-property', this.hotelId], this.queryParameter);
        }
    }

    /**
     * Go to previous page
     */
    goToBack() {
        if (this.mode && this.mode === 'edit') {
            this.changeNextTab.emit('propertyFacility');
        } else {
            this.router.navigate(['property', 'step-2-add-property', this.hotelId], this.queryParameter);
        }
    }
}
