<div id="testChild">
  <div class="row p-3 border-bottom" *ngIf="downloadURL !== 'Delete'">

    <div class="col-md-5">
      <span class=" mt-3" style="overflow-wrap: break-word">{{fileName}}</span>
      <div *ngIf="snapshot | async as snap"></div>
    </div>
    <div class="col-md-4">
      <div *ngIf="downloadURL as url">
        <span style="color: red" *ngIf="url && url === 'Delete'"> Image Deleted Successfully </span>
        <img *ngIf="url && url !== 'Delete' && url.indexOf('.mp4') === -1" [src]="url" width="150" (click)="openLightBox(url)">
        <br>
        <video *ngIf="url && url !== 'Delete' && url.indexOf('.mp4') !== -1" [src]="url" width="150" (click)="openLightBox(url)" controls></video>
      </div>
      <div *ngIf="!downloadURL">
        <img src="assets/img/load.gif" width="30px" /><br>
        <span *ngIf=" !downloadURL && percentage | async as pct ">
          Uploading {{ pct | number }}%
        </span>
      </div>
    </div>
    <div class="align-middle">
      <div *ngFor="let p of pathL;let i = index;" class="mt-3">
        <button *ngIf="downloadURL" (click)="removeFile(p)">Delete</button>
      </div>
    </div>
  </div>
</div>
