<div class="mr-auto" *ngIf="mode != undefined">
  <button
    class="btn btn-outline-primary btn-circle m-b-10 mr-2"
    (click)="goToBackButton()"
  >
    Go Back
  </button>
  <button
    *ngIf="isViewMode"
    class="btn btn-outline-primary btn-circle m-b-10 mr-2"
    (click)="editPromotion()"
  >
    Edit Promotion
  </button>
</div>
<div class="page-bar">
  <div class="page-title-breadcrumb">
    <div class="pull-left">
      <div class="page-title">
        {{ mode ? (mode === "edit" ? "Edit" : "View") : "Add" }} Promotion
      </div>
    </div>
    <ol class="breadcrumb page-breadcrumb pull-right">
      <li>
        <a class="parent-item" href="/"><i class="fa fa-home"></i>&nbsp;Home</a
        >&nbsp;<i class="fa fa-angle-right"></i>
      </li>
      <li class="active">
        {{ mode ? (mode === "edit" ? "Edit" : "View") : "Add" }} Promotion
      </li>
    </ol>
  </div>
</div>
<form [formGroup]="promotionForm">
  <fieldset [disabled]="isViewMode">
    <div class="card-box">
      <div class="card-head">
        <header>Promotion Information</header>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form_label text-red">Promotion Code</label>
              <input
                [disabled]="isViewMode"
                type="text"
                name="promotion_code"
                formControlName="promotion_code"
                class="form-control"
              />
              <span
                *ngIf="
                  (promotionForm.controls['promotion_code'].dirty ||
                    promotionForm.controls['promotion_code'].touched) &&
                  promotionForm.controls['promotion_code'].hasError(
                    'required'
                  ) &&
                  mode != 'view'
                "
                class="text-red"
                >Promotion code is required.
              </span>
              <span
                *ngIf="
                  (promotionForm.controls['promotion_code'].dirty ||
                    promotionForm.controls['promotion_code'].touched) &&
                  promotionForm.controls['promotion_code'].hasError(
                    'pattern'
                  ) &&
                  mode != 'view'
                "
                class="text-red"
                >Enter valid promotion code.
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form_label text-red">Promotion Name</label>
              <input
                required
                [disabled]="isViewMode"
                type="text"
                name="promotion_name"
                formControlName="promotion_name"
                class="form-control"
              />
              <span
                *ngIf="
                  (promotionForm.controls['promotion_name'].dirty ||
                    promotionForm.controls['promotion_name'].touched) &&
                  promotionForm.controls['promotion_name'].hasError(
                    'required'
                  ) &&
                  mode != 'view'
                "
                class="text-red"
                >Promotion name is required.
              </span>

              <span
                *ngIf="
                  (promotionForm.controls['promotion_name'].dirty ||
                    promotionForm.controls['promotion_name'].touched) &&
                  promotionForm.controls['promotion_name'].hasError(
                    'pattern'
                  ) &&
                  mode != 'view'
                "
                class="text-red"
                >Enter valid promotion name.
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form_label text-red">Promotion Description</label>
              <span class="note_label"
                >(Max 4000 characters) characters left:
                <b>{{
                  4000 -
                    (this.promotionForm.value.promotion_description
                      ? this.promotionForm.value.promotion_description.length
                      : 0)
                }}</b></span
              >
              <textarea
                required
                maxlength="4000"
                class="form-control"
                name="promotion_description"
                formControlName="promotion_description"
              ></textarea>
              <span
                *ngIf="
                  (promotionForm.controls['promotion_description'].dirty ||
                    promotionForm.controls['promotion_description'].touched) &&
                  promotionForm.controls['promotion_description'].hasError(
                    'required'
                  ) &&
                  mode != 'view'
                "
                class="text-red"
                >Promotion description is required.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-box">
      <div class="card-body">
        <div class="all-table">
          <table class="table display product-overview mb-30 chekbox-table">
            <thead>
              <tr>
                <th>% Off</th>
                <th>Fixed Discount</th>
                <th>Length of Stay</th>
                <th>Value Added</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="checkbox checkbox-black d-inline-block">
                    <input
                      id="checkboxbg1"
                      name="isPercentOff"
                      [(checked)]="isPercentOff"
                      (change)="onDiscountTypeChange('isPercentOff')"
                      type="checkbox"
                    />
                    <label for="checkboxbg1"></label>
                  </div>
                </td>
                <td>
                  <div class="checkbox checkbox-black d-inline-block">
                    <input
                      id="checkboxbg2"
                      name="isFixedDiscount"
                      [(checked)]="isFixedDiscount"
                      (change)="onDiscountTypeChange('isFixedDiscount')"
                      type="checkbox"
                    />
                    <label for="checkboxbg2"></label>
                  </div>
                </td>
                <td>
                  <div class="checkbox checkbox-black d-inline-block">
                    <input
                      id="checkboxbg3"
                      name="isFreeNights"
                      [(checked)]="isFreeNights"
                      (change)="onDiscountTypeChange('isFreeNights')"
                      type="checkbox"
                    />
                    <label for="checkboxbg3"></label>
                  </div>
                </td>
                <td>
                  <div class="checkbox checkbox-black d-inline-block">
                    <input
                      id="checkboxbg4"
                      name="isValueAdded"
                      [(checked)]="isValueAdded"
                      (change)="onDiscountTypeChange('isValueAdded')"
                      type="checkbox"
                    />
                    <label for="checkboxbg4"></label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="row"
          *ngIf="
            isPercentOff || isFreeNights || isFixedDiscount || isValueAdded
          "
        >
          <div class="col-md-6" *ngIf="isPercentOff">
            <div class="form-group">
              <label class="form_label text-red">% Off</label>
              <input
                type="tel"
                name="percent_off"
                formControlName="percent_off"
                class="form-control"
              />
              <span
                *ngIf="
                  (promotionForm.controls['percent_off'].dirty ||
                    promotionForm.controls['percent_off'].touched) &&
                  promotionForm.controls['percent_off'].hasError('required') &&
                  mode != 'view'
                "
                class="text-red"
                >% off is required.
              </span>
              <span
                *ngIf="
                  (promotionForm.controls['percent_off'].dirty ||
                    promotionForm.controls['percent_off'].touched) &&
                  promotionForm.controls['percent_off'].hasError('max') &&
                  mode != 'view'
                "
                class="text-red"
                >You can't set value greater than 100.
              </span>
              <span
                *ngIf="
                  (promotionForm.controls['percent_off'].dirty ||
                    promotionForm.controls['percent_off'].touched) &&
                  promotionForm.controls['percent_off'].hasError('min') &&
                  mode != 'view'
                "
                class="text-red"
                >Enter value greater than 0.
              </span>
              <span
              *ngIf="
                (promotionForm.controls['percent_off'].dirty ||
                  promotionForm.controls['percent_off'].touched) &&
                promotionForm.controls['percent_off'].hasError('pattern') &&
                mode != 'view'"
              class="text-red"
              >
              Decimal value is not allowed.
            </span>

            </div>
          </div>
          <div class="col-md-6" *ngIf="isFixedDiscount">
            <div class="form-group">
              <label class="form_label text-red">Fixed Discount</label>
              <input
                type="tel"
                name="fix_discount"
                formControlName="fix_discount"
                class="form-control custom_dollar"
              />
              <span
                *ngIf="
                  (promotionForm.controls['fix_discount'].dirty ||
                    promotionForm.controls['fix_discount'].touched) &&
                  promotionForm.controls['fix_discount'].hasError('required') &&
                  mode != 'view'
                "
                class="text-red"
                >Fixed value is required.
              </span>
              <span
                *ngIf="
                  (promotionForm.controls['fix_discount'].dirty ||
                    promotionForm.controls['fix_discount'].touched) &&
                  promotionForm.controls['fix_discount'].hasError('min') &&
                  mode != 'view'
                "
                class="text-red"
                >Enter value greater than 0.
              </span>
              <span
              *ngIf="
                (promotionForm.controls['fix_discount'].dirty ||
                  promotionForm.controls['fix_discount'].touched) &&
                promotionForm.controls['fix_discount'].hasError('pattern') &&
                mode != 'view'"
              class="text-red"
              >
              Decimal value is not allowed.
            </span>

            </div>
          </div>
          <div class="col-md-6" *ngIf="isFreeNights">
            <div class="form-group">
              <label class="form_label text-red">Length of Stay</label>
              <input
                type="tel"
                name="free_nights"
                formControlName="free_nights"
                class="form-control"
              />
              <span
                *ngIf="
                  (promotionForm.controls['free_nights'].dirty ||
                    promotionForm.controls['free_nights'].touched) &&
                  promotionForm.controls['free_nights'].hasError('required') &&
                  mode != 'view'
                "
                class="text-red"
                >Length of Stay is required.
              </span>
              <span
                *ngIf="
                  (promotionForm.controls['free_nights'].dirty ||
                    promotionForm.controls['free_nights'].touched) &&
                  promotionForm.controls['free_nights'].hasError('min') &&
                  mode != 'view' &&
                  this.isFormSubmitted
                "
                class="text-red"
                >Enter value greater than 0.
              </span>
              <span
              *ngIf="
                (promotionForm.controls['free_nights'].dirty ||
                  promotionForm.controls['free_nights'].touched) &&
                promotionForm.controls['free_nights'].hasError('pattern') &&
                mode != 'view'"
              class="text-red"
              >
              Decimal value is not allowed.
            </span>
            </div>
          </div>
          <div class="col-md-6" *ngIf="isValueAdded">
            <div class="form-group">
              <label class="form_label text-red">Value Added</label>
              <input
                type="text"
                name="value_added"
                formControlName="value_added"
                class="form-control"
              />
              <span
                *ngIf="
                  (promotionForm.controls['value_added'].dirty ||
                    promotionForm.controls['value_added'].touched) &&
                  promotionForm.controls['value_added'].hasError('required') &&
                  mode != 'view'
                "
                class="text-red"
                >Value added is required.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-box mp-box">
      <div class="card-head d-flex">
        <header>Apply the Promotion to the following Room Types</header>
      </div>
      <div class="card-body">
        <div
          *ngIf="!isViewMode"
          class="checkbox checkbox-black d-inline-block d-flex"
          style="justify-content: flex-end"
        >
          <input
            id="selectAll"
            name="isFreeNights"
            [(checked)]="selectAllRooms"
            (change)="onSelectAllRoomsChanged()"
            type="checkbox"
          />
          <label style="font-weight: bold; font-size: large" for="selectAll"
            >Select All Room Types</label
          >
        </div>
        <br />
        <div
          class="col-md-4 checkbox checkbox-black d-inline-block"
          *ngFor="let room of rooms"
        >
          <input
            [id]="room.id"
            type="checkbox"
            name="room"
            (change)="onRoomCheckChange(room)"
            [(checked)]="room.checked"
          />
          <label [for]="room.id">{{ room.name }}</label>
        </div>
      </div>
    </div>
  </fieldset>

  <div *ngIf="!isViewMode" class="d-flex" style="justify-content: flex-end">
    <button
      data-toggle="modal"
      data-target="#confirmation"
      data-backdrop="static"
      data-keyboard="false"
      class="btn btn-round btn-default custom-btn"
    >
      Submit
    </button>
  </div>
</form>

<div *ngIf="showLoader" class="d-flex justify-content-center position-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div class="modal fade" id="confirmation">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h2>Are you sure?</h2>
        <button
          type="button"
          id="closeModal"
          #closeBtnDelete
          class="close"
          data-dismiss="modal"
        >
          &times;
        </button>
      </div>
      <div class="modal-body delate-popup text-center">
        <form>
          <div class="form-group">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <h2>Submit this form !</h2>
          </div>
          <div class="form-group">
            <button
              (click)="onSubmit()"
              type="submit"
              data-dismiss="modal"
              class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn btn-warning mr-2"
            >
              Submit
            </button>
            <button
              type="button"
              class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn btn-danger"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
