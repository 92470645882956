import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { PropertyService } from '../property/property-service.service';

@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.css']
})
export class UploadTaskComponent implements OnInit {

  @Input() file: File;
  @Output() imgUrl = new EventEmitter<any>();
  @Output() removeImg = new EventEmitter<any>();
  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string;
  public pathL: any = [];
  fileName: any;

  constructor(
    private storage: AngularFireStorage,
    private db: AngularFirestore,
    public propertyService: PropertyService,
  ) { }

  ngOnInit(): void {
    this.startUpload();
  }

  startUpload() {
    this.fileName = this.file.name;
    const path = `test/${Date.now()}_${this.file.name}`;
    this.pathL.push(path);
    const ref = this.storage.ref(path);
    this.task = this.storage.upload(path, this.file);
    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges().pipe(tap(console.log),
      finalize(async () => {
        this.downloadURL = await ref.getDownloadURL().toPromise();
        this.imgUrl.emit(this.downloadURL);
        this.db.collection('files').add({ downloadURL: this.downloadURL, path });
      }),
    );
  }

  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

  removeFile(p) {
    this.removeImg.emit(this.downloadURL);
    var desertRef = this.storage.ref(p);
    let statusFile = desertRef.delete().toPromise();
    this.downloadURL = 'Delete';
  }

  openLightBox(url) {
    this.propertyService.openLightBox.next(url)
  }
}
