<div class="page-bar">
  <div class="page-title-breadcrumb">
    <div class="pull-left">
      <div class="page-title">List Promotions</div>
    </div>
    <ol class="breadcrumb page-breadcrumb pull-right">
      <li>
        <i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="index.php"
          >Home</a
        >&nbsp;<i class="fa fa-angle-right"></i>
      </li>
      <li class="active">List Promotions</li>
    </ol>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="mat-elevation-z8">
      <table
        matSortActive="promotionName"
        matSortDirection="asc"
        mat-table
        [dataSource]="displayPromotionList"
        matSort
        (matSortChange)="sortData($event)"
      >
        <ng-container matColumnDef="promotionName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            title="Sort By Promotion Name"
          >
            Promotion Name
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.promotion_name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="promotionCode">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            title="Sort By Promotion Code"
          >
            Promotion Code
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.promotion_code }}
          </td>
        </ng-container>
        <ng-container matColumnDef="percentOff">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            title="Sort By % Off"
          >
            % Off
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.percent_off }}
          </td>
        </ng-container>
        <ng-container matColumnDef="fixDiscount">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            title="Sort By Fix Discount"
          >
            Fix Discount
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.fix_discount ? "$ " : "" }} {{ element.fix_discount }}
          </td>
        </ng-container>
        <ng-container matColumnDef="freeNights">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            title="Sort By Length of Stay"
          >
            Length of Stay
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.free_nights }}
          </td>
        </ng-container>
        <ng-container matColumnDef="valueAdded">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            title="Sort By Value Added"
          >
            Value Added
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.value_added }}
          </td>
        </ng-container>
        <ng-container matColumnDef="viewRooms">
          <th mat-header-cell *matHeaderCellDef>Room Types</th>
          <td mat-cell *matCellDef="let element">
            <a
              style="
                color: #0047ab;
                text-decoration-line: underline;
                font-size: small;
              "
              data-toggle="modal"
              (click)="onViewRooms(element)"
              data-target="#view-rooms"
              data-backdrop="static"
              data-keyboard="false"
            >
              Show Types
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
          <td mat-cell *matCellDef="let element" class="text-center pt-2">
            <button
              (click)="editPromotion(element.id, 'view')"
              class="btn btn-outline black-bgcolor btn-circle m-b-10 mr-2"
            >
              <i class="fa fa-eye" aria-hidden="true"></i>
            </button>
            <button
              (click)="editPromotion(element.id, 'edit')"
              type="button"
              class="btn btn-outline blue-bgcolor btn-circle m-b-10 mr-2"
            >
              <i aria-hidden="true" class="fa fa-pencil"></i>
            </button>
            <button
              type="button"
              class="btn red btn-outline btn-circle m-b-10"
              data-toggle="modal"
              data-target="#delete-promotion"
              (click)="setDeletePromotionID(element.id)"
              data-backdrop="static"
              data-keyboard="false"
            >
              <i aria-hidden="true" class="fa fa-trash-o"></i>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 20, 30]"></mat-paginator>
    </div>
    <div class="modal fade" id="delete-promotion">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h2>Are you sure?</h2>
            <button
              type="button"
              id="closeModal"
              #closeBtnDelete
              class="close"
              data-dismiss="modal"
            >
              &times;
            </button>
          </div>
          <div class="modal-body delate-popup text-center">
            <form>
              <div class="form-group">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <h2>It will permanently deleted !</h2>
              </div>
              <div class="form-group">
                <button
                  (click)="deletePromotion()"
                  type="submit"
                  class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn btn-warning mr-2"
                >
                  Yes, delete it!
                </button>
                <button
                  type="button"
                  class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn btn-danger"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="view-rooms">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h2>Room Types Of {{ viewRooms?.promotion_name }}</h2>
        <button
          type="button"
          id="closeModal"
          #closeBtnAdd
          class="close"
          data-dismiss="modal"
        >
          &times;
        </button>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <table class="table text-left">
          <thead>
            <tr>
              <th class="sr-no" style="min-width: 70px">Sr No</th>
              <th style="min-width: 200px">Room Type Name</th>
              <th>Room Type Description</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of viewRooms?.roomTypes; index as i">
              <td class="sr-no">{{ i + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showLoader" class="d-flex justify-content-center position-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
