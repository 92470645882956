import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AddPolicyComponent } from "./add-policy/add-policy.component";
import { ListPoliciesComponent } from "./list-policies/list-policies.component";
const routes: Routes = [
  { path: "add-policy", component: AddPolicyComponent },
  { path: "list-policies", component: ListPoliciesComponent },
  {
    path: "view-policy/:policyId/:mode",
    component: AddPolicyComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PoliciesRoutingModule {}
