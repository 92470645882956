import {
  Component,
  OnInit,
  Injector,
  ViewChild,
  ElementRef,
  Input,
  EventEmitter,
  Output,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { PropertyService } from "../../property-service.service";
import { ToastrService } from "ngx-toastr";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import * as moment from "moment";
import * as $ from "jquery";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-add-property-step5",
  templateUrl: "./add-property-step5.component.html",
  styleUrls: ["./add-property-step5.component.css"],
})
export class AddPropertyStep5Component implements OnInit {
  @ViewChild("closeBtnAdd") closeBtnAdd: ElementRef;
  @Output() changeNextTab: EventEmitter<any> = new EventEmitter<any>();
  minformDate = new Date();
  mintoDate = new Date();
  public calendarData: any = [];
  public weekDay = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  public months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  public numbers: any;
  public years: any = [];
  public mode: string;
  @Input() propertyDetails: any = {};
  public currentYear: any;
  public currentMonth: any;
  public selectedDate: any = [];
  public step5RoomRange: FormGroup;
  public hotelId: any;
  public roomId: any;
  public roomData: any = [];
  public totalDays: any;
  public isFormSubmit: Boolean = false;
  public isFormSubmitRange: Boolean = false;
  public fixYear: any;
  public fixMonth: any;
  public stopSales: any = {};
  public stopSalesdata: any = {};
  public prevMonthStatus: Boolean = false;
  public stopSalesDetails: any = { month: "", room: "", year: "" };
  public roomAlreadySelected: any = [];
  public roomSelectedStatus: Boolean = false;
  public roomIdList: any = [];
  public yearList: any = [];
  public monthList: any = [];
  deletedDate = [];
  alertMessage: string = "";
  allSelectedDate = [];
  queryParameter: any;

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private toasterservice: ToastrService,
    private activatedRoute: ActivatedRoute,
    public propertyService: PropertyService,
    private spinner: NgxSpinnerService
  ) {
    this.roomSelectedStatus = false;
    let cyear = parseInt(moment().format("YYYY"));
    this.currentYear = cyear;
    this.fixYear = cyear;

    for (let i = 0; i <= 5; i++) {
      this.years[i] = cyear;
      cyear += 1;
      console.log(cyear);
    }
    this.currentMonth = moment().format("MM");
    this.fixMonth = moment().format("MM");
    this.step5RoomRange = this.fb.group(
      {
        date_from: ["", [Validators.required]],
        date_to: ["", [Validators.required]],
      },
      {
        validators: [this.compareDateValidator.bind(this)],
      }
    );
  }

  compareDateValidator(control: FormControl) {
    if (this.step5RoomRange) {
      let fromDate = this.step5RoomRange.get("date_from").value;
      let toDate = this.step5RoomRange.get("date_to").value;
      if (fromDate && toDate && fromDate > toDate) {
        return { invalidDate: true };
      }
    }
    return null;
  }

  createCalendar(mon, yr) {
    var current = new Date();
    this.totalDays = moment(
      yr + "-" + mon + "-" + "01",
      "YYYY-MM-DD"
    ).daysInMonth();
    let day = moment(yr + "-" + mon + "-" + "01").day();
    if (current.getFullYear() == yr) {
      if (current.getMonth() + 1 == mon) {
        var a = moment().endOf("month");
        this.totalDays = a.diff(moment(), "days") + 1;
        day = moment(yr + "-" + mon + "-" + current.getDate()).day();
        this.numbers = [];
        this.numbers = Array(this.totalDays)
          .fill(0)
          .map((x, i) => i);
        this.calendarData = [];
        for (let i = 0; i < this.totalDays; i++) {
          console.log("day0", current.getDate() + i);
          // this.numbers.push(current.getDate() + i);
          this.calendarData.push({
            date: current.getDate() + i,
            day: this.weekDay[day],
          });
          if (day < 6) day = day + 1;
          else day = 0;
        }
      } else {
        if (current.getMonth() + 1 > mon) this.prevMonthStatus = true;
        this.numbers = [];
        this.numbers = Array(this.totalDays)
          .fill(0)
          .map((x, i) => i);
        this.calendarData = [];
        for (let i = 1; i <= this.totalDays; i++) {
          console.log("day1", i);
          // this.numbers.push(i);
          this.calendarData.push({ date: i, day: this.weekDay[day] });
          if (day < 6) day = day + 1;
          else day = 0;
        }
      }
    } else {
      this.numbers = [];
      this.numbers = Array(this.totalDays)
        .fill(0)
        .map((x, i) => i);
      this.calendarData = [];
      for (let i = 1; i <= this.totalDays; i++) {
        // this.numbers.push(i);
        this.calendarData.push({ date: i, day: this.weekDay[day] });
        if (day < 6) day = day + 1;
        else day = 0;
      }
    }

    console.log("[this.numbers]", this.numbers);
  }

  selectedMonth(evalue) {
    if (
      parseInt(evalue) < parseInt(this.fixMonth) &&
      parseInt(this.currentYear) === this.fixYear
    ) {
      this.prevMonthStatus = true;
    } else {
      this.currentMonth = evalue;
      this.prevMonthStatus = false;
      this.createCalendar(this.currentMonth, this.currentYear);
    }
    // if (this.mode)
    this.setCalenderProperty();
  }

  selectedRoom(evalue) {
    this.roomId = evalue;
    if (this.roomAlreadySelected.includes(this.roomId)) {
      this.roomSelectedStatus = true;
    } else {
      this.roomSelectedStatus = false;
    }
    // if (this.mode)
    this.setCalenderProperty();
  }

  selectedYear(evalue) {
    this.currentYear = evalue;
    this.createCalendar(this.currentMonth, this.currentYear);
    // if (this.mode)
    this.setCalenderProperty();
  }

  selectAll() {
    for (let i = 1; i <= this.totalDays; i++) {
      $("#chk" + (i - 1)).prop("checked", true);
      let date = "";
      if (i < 10) {
        date = "0" + i;
      } else {
        date = i.toString();
      }
      if (this.currentMonth.length === 1)
        this.currentMonth = "0" + this.currentMonth;
      let dt = this.currentYear + "-" + this.currentMonth + "-" + date;
      this.selectedDate.push({ date: dt, stopSell: true });
    }
  }

  unselectAll() {
    this.deletedDate.push(...this.selectedDate);
    this.deletedDate.map((date) => (date.stopSell = false));
    this.selectedDate = [];
    for (let i = 1; i <= this.totalDays; i++) {
      $("#chk" + (i - 1)).prop("checked", false);
    }
  }

  onChangeEvent(e, date, day) {
    if (e["target"]["checked"] === true) {
      if (date < 10) {
        date = "0" + date;
      }
      if (this.currentMonth.length === 1)
        this.currentMonth = "0" + this.currentMonth;
      let dt = this.currentYear + "-" + this.currentMonth + "-" + date;
      this.selectedDate.push({ date: dt, stopSell: true });
      let indexDate = this.deletedDate.findIndex((item) => item.date === dt);
      this.deletedDate.splice(indexDate, 1);
    } else {
      if (date < 10) {
        date = "0" + date;
      }
      if (this.currentMonth.length === 1)
        this.currentMonth = "0" + this.currentMonth;
      let dt = this.currentYear + "-" + this.currentMonth + "-" + date;
      let indexDate = this.selectedDate.findIndex((item) => item.date === dt);
      if (indexDate !== -1) {
        this.deletedDate.push(...this.selectedDate.splice(indexDate, 1));
        this.deletedDate.map((date) => (date.stopSell = false));
        console.log("[this.deletedDate]", this.deletedDate);
      }
    }
  }

  private setCalenderProperty(): void {
    console.log(this.mode);
    this.selectedDate = [];
    setTimeout(() => {
      this.updateSelectedDateOfRoom();
      let currentMonth: any;
      if (this.currentMonth) {
        currentMonth = parseInt(this.currentMonth, 0);
        if (currentMonth < 10 && !this.currentMonth.includes("0"))
          currentMonth = "0" + this.currentMonth;
        else currentMonth = this.currentMonth.toString();
      }
      if (
        this.stopSalesdata[this.stopSalesDetails.room] &&
        this.stopSalesdata[this.stopSalesDetails.room][
        this.stopSalesDetails.year
        ] &&
        this.stopSalesdata[this.stopSalesDetails.room][
        this.stopSalesDetails.year
        ][currentMonth] &&
        this.stopSalesdata[this.stopSalesDetails.room][
          this.stopSalesDetails.year
        ][currentMonth].length > 0
      ) {
        for (const iterator of this.numbers) {
          $("#chk" + iterator).prop("checked", false);
          $("#chk" + iterator).prop("disabled", false);
        }
        for (const data of this.stopSalesdata[this.stopSalesDetails.room][
          this.stopSalesDetails.year
        ][currentMonth]) {
          if (data.date) {
            this.selectedDate.push({ date: data.date, stopSell: true });
            const day = parseInt(data.date.split("-")[2], 0) - 1;
            $("#chk" + day).prop("checked", "checked");
            if (this.mode == undefined) $("#chk" + day).prop("disabled", true);
          }
        }
      } else {
        for (const iterator of this.numbers) {
          $("#chk" + iterator).prop("checked", false);
        }
      }
    }, 0);
  }

  private getUnique(array: any[]) {
    var uniqueArray = [];
    for (let i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) uniqueArray.push(array[i]);
    }
    return uniqueArray;
  }

  private setPropertyDetails(): void {
    for (let key in this.stopSales) {
      if (this.stopSales[key]) {
        this.roomIdList.push(key);
        if (Object.keys(this.stopSales[key]).length > 0) {
          for (const key1 in this.stopSales[key]) {
            if (this.stopSales[key][key1]) {
              this.yearList.push(key1);
              if (Object.keys(this.stopSales[key][key1]).length > 0) {
                for (const key2 in this.stopSales[key][key1]) {
                  if (this.stopSales[key][key1][key2]) {
                    this.monthList.push(key2);
                  }
                }
              }
            }
          }
        }
      }
    }

    console.log("[ALL DATES]", this.allSelectedDate);

    this.roomIdList = this.getUnique(this.roomIdList);
    this.yearList = this.getUnique(this.yearList);
    this.monthList = this.getUnique(this.monthList);

    // this.years =  this.years;

    // this.stopSalesDetails.room = this.roomIdList[0];
    // this.roomId = this.roomIdList[0];
    // this.stopSalesDetails.year = this.yearList[0];
    // this.currentMonth = this.monthList[0];
    // this.stopSalesDetails.month = parseInt(this.monthList[0], 0);
    if (this.roomAlreadySelected.includes(this.stopSalesDetails.room))
      this.roomSelectedStatus = true;
    else this.roomSelectedStatus = false;
    // this.createCalendar(
    //   this.stopSalesDetails.month,
    //   this.stopSalesDetails.year
    // );
    // this.setCalenderProperty();
    if (this.mode && this.mode === "view") this.step5RoomRange.disable();
  }

  private updateSelectedDateOfRoom() {
    this.allSelectedDate = [];

    console.log("[updateSelectedDateOfRoom]");

    for (let key in this.stopSales) {
      if (this.stopSales[key] && this.roomId == key) {
        if (Object.keys(this.stopSales[key]).length > 0) {
          for (const key1 in this.stopSales[key]) {
            if (this.stopSales[key][key1]) {
              if (Object.keys(this.stopSales[key][key1]).length > 0) {
                for (const key2 in this.stopSales[key][key1]) {
                  if (this.stopSales[key][key1][key2]) {
                    this.monthList.push(key2);

                    if (this.stopSales[key][key1][key2].length > 0) {
                      for (
                        let i = 0;
                        i < this.stopSales[key][key1][key2].length;
                        i++
                      ) {
                        const dateObject = this.stopSales[key][key1][key2][i];
                        this.allSelectedDate.push(dateObject.date);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    console.log("[updateSelectedDateOfRoom]", this.allSelectedDate);
  }

  ngOnInit(): void {
    if (
      Object.keys(this.propertyDetails).length > 0 &&
      this.propertyDetails.propertyId &&
      this.propertyDetails.mode
    ) {
      const noMOn = parseInt(this.fixMonth);
      this.mode = this.propertyDetails.mode;
      this.roomData = this.propertyDetails["room"];
      this.stopSales = this.propertyDetails.stopSales;
      this.stopSalesdata = this.propertyDetails.stopSales;
      this.hotelId = this.propertyDetails.propertyId;
      this.getStep1PropertyDetails(this.hotelId);
      this.setPropertyDetails();
      $("#year").val(this.fixYear.toString()).prop("selected", true);
      $("#month > select > option[value=" + noMOn + "]").prop("selected", true);
    } else {
      this.hotelId = this.activatedRoute.snapshot.paramMap.get("hotelId");
      this.getStep1PropertyDetails(this.hotelId);
      let noMOn = parseInt(this.fixMonth);
      $("#year").val(this.fixYear.toString()).prop("selected", true);
      $("#month > select > option[value=" + noMOn + "]").prop("selected", true);
    }
  }

  getStep1PropertyDetails(id) {
    this.spinner.show();
    this.propertyService.getHotelPropertyDetailsStep1(id).subscribe((data) => {
      if (data["success"] === true) this.roomData = data["data"]["room"];
      this.stopSalesdata = data["data"]["stopSales"];
      this.stopSales = data["data"]["stopSales"];
      this.roomData = data["data"]["room"];
      this.createCalendar(this.currentMonth, this.currentYear);
      this.spinner.hide();
    });
  }

  saveStep5Range() {
    console.log("[DATEA]", this.step5RoomRange.value);

    this.updateSelectedDateOfRoom();
    let selectedDateArray = [];
    for (var dateIndex in this.calendarData) {
      selectedDateArray.push(
        this.stopSalesDetails.year +
        "-" +
        this.stopSalesDetails.month +
        "-" +
        this.calendarData[dateIndex].date
      );
    }
    this.isFormSubmitRange = true;
    if (this.roomId && this.step5RoomRange.valid) {
      let obj = this.step5RoomRange.value;

      if (
        this.checkIfDatesAlreadySelected(
          this.allSelectedDate,
          obj["date_from"],
          obj["date_to"]
        )
      ) {
        this.alertMessage = "Dates are already selected";
        document.getElementById("showAlertModal").click();
        $(".modal-backdrop").remove();
        return;
      }
      obj["stopSell"] = true;
      obj["date_from"] = moment(obj["date_from"]).format("YYYY-MM-DD");
      obj["date_to"] = moment(obj["date_to"]).format("YYYY-MM-DD");

      this.spinner.show();
      this.propertyService
        .addPropertyStep5Range(this.roomId, this.hotelId, obj)
        .subscribe((data) => {
          if (data["success"] === true) {
            this.roomAlreadySelected.push(this.roomId);
            this.roomSelectedStatus = false;
            this.step5RoomRange.reset();
            this.isFormSubmitRange = false;
            this.toasterservice.success(data["message"], "Success", {
              timeOut: 3000,
            });
            document.getElementById("openModalButton").click();
            $(".modal-backdrop").remove();

            this.getStep1PropertyDetails(this.hotelId);
            this.setPropertyDetails();
          } else {
            this.toasterservice.success(data["message"], "Error", {
              timeOut: 3000,
            });
          }

          this.spinner.hide();
        });
    }
  }

  checkIfDatesAlreadySelected(selectedArray, fromDate, toDate) {
    let alreadyAvailable = false;
    let f = new Date(fromDate);
    let t = new Date(toDate);
    console.log("[checkIfDatesAlreadySelected]", f, t);

    while (f <= t) {
      if (selectedArray.indexOf(moment(f).format("YYYY-MM-DD")) != -1) {
        alreadyAvailable = true;
        break;
      } else if (selectedArray.indexOf(moment(t).format("YYYY-MM-DD")) != -1) {
        alreadyAvailable = true;
        break;
      }
      f.setDate(f.getDate() + 1);
    }

    return alreadyAvailable;
  }

  saveStep5Single() {
    this.isFormSubmit = true;
    if (this.selectedDate.length > 0 && this.roomId) {
      let obj = {
        stopSales: [...this.deletedDate, ...this.selectedDate],
      };
      console.log("[obj]", obj);
      this.spinner.show();
      this.propertyService
        .addPropertyStep5Single(this.roomId, this.hotelId, obj)
        .subscribe((data) => {
          if (data["success"] === true) {
            console.log("[saveStep5Single data]", data);

            this.roomAlreadySelected.push(this.roomId);
            this.roomSelectedStatus = false;
            this.isFormSubmit = false;
            this.toasterservice.success(data["message"], "Success", {
              timeOut: 3000,
            });
            document.getElementById("openModalButton").click();
            $(".modal-backdrop").remove();

            this.getStep1PropertyDetails(this.hotelId);
            this.setPropertyDetails();
          } else {
            this.toasterservice.success(data["message"], "Error", {
              timeOut: 3000,
            });
          }
          this.spinner.hide();
        });
    }
  }

  noFun() {
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
    this.router.navigate(["/"]);
  }

  yesFun() {
    this.stopSalesDetails = { month: "", room: "", year: "" };
    this.selectedRoom("");
    document.getElementById("closeModal").click();
    $("#facilities-modal").hide();
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
    this.ngOnInit();
  }

  hideAlreadyDateModal() {
    console.log("[hideAlreadyDateModal]");
    document.getElementById("closeModal").click();
    $("#alert-modal").hide();
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
  }

  gotoBack() {
    if (this.activatedRoute.snapshot.queryParams.inventory_allocation) {
      this.queryParameter = {
        queryParams: {
          inventory_allocation: this.activatedRoute.snapshot.queryParams.inventory_allocation
        }
      }
    }
    if (this.mode && this.mode === "edit") {
      this.changeNextTab.emit("roomRate");
    } else {
      this.router.navigate(["/property/step-4-add-property", this.hotelId], this.queryParameter);
    }
  }
}
