import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BookingRoutingModule } from './booking-routing.module';
import { SearchBookingComponent } from './search-booking/search-booking.component';


@NgModule({
  declarations: [SearchBookingComponent],
  imports: [
    CommonModule,
    BookingRoutingModule
  ],
  exports: [SearchBookingComponent]
})
export class BookingModule { }
