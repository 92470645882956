import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportRoutingModule } from './report-routing.module';
import { ManageReportsComponent } from './manage-reports/manage-reports.component';


@NgModule({
  declarations: [ManageReportsComponent],
  imports: [
    CommonModule,
    ReportRoutingModule
  ],
  exports: [ManageReportsComponent]
})
export class ReportModule { }
