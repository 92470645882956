<div class="page-bar">
  <div class="page-title-breadcrumb">
    <div class=" pull-left">
      <div class="page-title">Search Booking</div>
    </div>
    <ol class="breadcrumb page-breadcrumb pull-right">
      <li>
        <i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="index.php"
          >Home</a
        >&nbsp;<i class="fa fa-angle-right"></i>
      </li>
      <li class="active">Search Booking</li>
    </ol>
  </div>
</div>
<form>
  <div class="card-box">
    <div class="card-head">
      <header>Search your Bookings</header>
    </div>
    <div class="card-body ">
      <ul class="list-ul">
        <li><b>*Booked :</b>Bookings those are confirmed.</li>
        <li><b>*Cancelled :</b>Bookings those are cancelled.</li>
        <li><b>*Modified :</b>Bookings those are modified.</li>
      </ul>
      <div class="date-calender mt-3">
        <div class="form-group">
          <label class="form_label">Search Data Range</label>
          <div class="date-input d-flex align-items-center">
            <div class="pr-4">
              <span>Date From</span>
              <input
                type="text"
                name=""
                class="form-control custom_datepicker"
              />
            </div>
            <div>
              <span>Date To</span>
              <input
                type="text"
                name=""
                class="form-control custom_datepicker"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form_label">Stuts</label>
        <div class="d-flex room-checkbox">
          <div class="checkbox checkbox-black d-inline-block">
            <input id="room15" type="checkbox" />
            <label for="room15">
              Booked
            </label>
          </div>
          <div class="checkbox checkbox-black d-inline-block">
            <input id="room1" type="checkbox" />
            <label for="room1">
              Cancelled
            </label>
          </div>
          <div class="checkbox checkbox-black d-inline-block">
            <input id="room2" type="checkbox" />
            <label for="room2">
              Modified
            </label>
          </div>
          <div class="checkbox checkbox-black d-inline-block">
            <input id="room3" type="checkbox" />
            <label for="room3">
              OnRequest
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label class="form_label">Room Name</label>
            <select class="form-control">
              <option>All</option>
              <option>Member Name</option>
              <option>Member Name</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label class="form_label">Reference Code</label>
            <input type="text" name="" class="form-control" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label class="form_label">Customer Name</label>
            <input type="text" name="" class="form-control" />
          </div>
        </div>
      </div>
      <div>
        <a
          href="#"
          class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn btn-default"
        >
          Search
        </a>
        <a
          href="#"
          class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn btn-primary"
        >
          Reset
        </a>
      </div>
    </div>
  </div>
</form>
