<div class="page-bar" *ngIf="!mode">
  <div class="page-title-breadcrumb">
    <div class="pull-left">
      <div class="page-title">Step 5 : Assign Facilities</div>
    </div>
    <ol class="breadcrumb page-breadcrumb pull-right">
      <li>
        <a class="parent-item" href="/"><i class="fa fa-home"></i>&nbsp;Home</a>&nbsp;<i class="fa fa-angle-right"></i>
      </li>
      <li class="active">Assign Facilities</li>
    </ol>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <form id="myform" (ngSubmit)="step2AddProperty()">
      <div class="card-box" *ngIf="!mode">
        <div class="card-head">
          <header>Progress Bar</header>
        </div>
        <div class="card-body">
          <div class="progressbar-xs progress-rounded progress-striped progress ng-isolate-scope active">
            <div class="progress-bar progress-bar-success width-50" role="progressbar" aria-valuenow="65"
              aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      </div>
      <div *ngIf="mode" class="card-box">
        <div class="card-head">
          <header>{{ hotelName }}</header>
        </div>
      </div>
      <div *ngFor="let facilityCategory of propertyFacilityCategory">
        <div class="card-box">
          <div class="card-body grid-div">
            <label class="category-label"><b>{{facilityCategory.name}}</b></label><br>
            <div class="checkbox checkbox-black d-inline-block" [ngClass]="mode == 'view' ? 'disabled' : ''"
              *ngFor="let item of allFacility">
              <div *ngIf="item.property_facility_category_id == facilityCategory.id">
                <div>
                  <input id="chk{{ item.id }}" [value]="item.id" type="checkbox"
                    (change)="facilityEditorModal($event.target.checked, item)" [disabled]="mode == 'view'" />
                  <label id="lbl{{ item.id }}" for="chk{{ item.id }}">
                    {{ item?.name }}
                  </label>
                  <a class="pl-2" [hidden]="mode !== 'view'" id="viewFacilityIcon{{ item.id }}"
                    (click)="facilityEditorModal($event.target.checked, item, 'viewIcon')">
                    <i class="fa fa-eye" aria-hidden="true"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between" style="padding: 10px" *ngIf="!mode || mode !== 'view'">
        <a class="btn yellow btn-outline custom-btn" (click)="gotoBack()">Back</a>
        <a class="btn btn-round btn-primary custom-btn" *ngIf="backhotelid || mode == 'edit'"
          (click)="goNext()">Next</a>
      </div>
    </form>
  </div>
</div>


<button id="openModalButton" [hidden]="true" data-toggle="modal" data-backdrop="static" data-keyboard="false"
  data-target="#facilities-modal">
  Open Modal
</button>

<div class="modal fade" id="facilities-modal">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h2>{{ facName }}</h2>
        <button type="button" id="closeModal" (click)="onClose()" #closeBtnAdd class="close" data-dismiss="modal">
          &times;
        </button>
      </div>
      <!-- Modal body -->
      <!-- <form> -->

      <div class="modal-body">
        <form #f="ngForm">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-6">
              <div class="form-group">
                <label class="form-label text-red">Description of service</label>
                <textarea class="" [(ngModel)]="modalDesc" name="Desc" #Desc="ngModel" class="form-control" required
                  [disabled]="mode && mode === 'view'"></textarea>
                <span *ngIf="Desc.errors?.required && (Desc.dirty || Desc.touched)" class="text-red">Please enter
                  Description</span>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-6">
              <div class="form-group mb-0">
                <label class="form_label">Photo Gallery</label>
              </div>
              <div class="p-t-20" *ngIf="!mode || mode !== 'view'">
                <div appDropzone class="dropzone" dropzone (hovered)="isHovering = $event" (dropped)="onDrop($event)"
                  [class.hovering]="isHovering">
                  <!-- <h3>AngularFire Drop Zone</h3> -->
                  <p>Drag and Drop Image inside the Box</p>
                  <div class="file">
                    <label class="file-label">
                      <input #myInput class="file-input" type="file" id="fileControl" multiple="multiple"
                        (change)="onDrop($event.target.files)" />
                      <span class="file-cta"> </span>
                    </label>
                  </div>
                </div>
                <div class="" *ngFor="let file of files">
                  <app-upload-task [file]="file" (imgUrl)="onImgUpload($event)" (removeImg)="onImgDelete($event)">1
                  </app-upload-task>
                </div>
                <div *ngIf="editMode && urlSaved.length > 0">
                  <div class="row p-3 border-bottom" *ngFor="let item of urlSaved; let i = index">
                    <div class="col-md-5">
                      <span style="overflow-wrap: break-word">{{
                        this.storage.storage.refFromURL(item).name
                        }}</span>
                    </div>
                    <div class="col-md-4">
                      <img src="{{ item }}" style="width: 70px" />
                    </div>
                    <div class="col-md-2">
                      <button (click)="removeImg(i)">Delete</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-box" *ngIf="mode && mode === 'view'">
                <div class="card-body row">
                  <div class="col-md-4 p-t-20 mr-1" *ngFor="let item of urlSaved; let i = index" style="
                      border-radius: 5px;
                      cursor: pointer;
                      transition: 0.3s;
                    ">
                    <img src="{{ item }}" id="myImg_{{ i }}" style="width: 100px" (click)="openImageAsModal(i)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-end" *ngIf="!editMode && (!mode || mode !== 'view')">
            <div class="d-flex mb-3 mt-4 pr-3">
              <span [hidden]="errorStatus" class="text-red p-2">
                All Required Fields are compulsory to fill
              </span>

              <button type="submit" [disabled]="!f.form.valid" (click)="saveData()"
                class="btn btn-round btn-default custom-btn">
                Save
              </button>
            </div>
          </div>
          <div class="row justify-content-end" *ngIf="editMode && (!mode || mode !== 'view')">
            <div class="col-md-6">
              <button type="button" data-dismiss="modal" (click)="deleteFac()"
                class="float-right btn btn-round btn-default custom-btn">
                Delete
              </button>
            </div>
            <!-- <div class="col-md-6">
              <button type="button" (click)="updateFac()" class="float-left btn btn-round btn-default custom-btn">
                Update
              </button>
            </div> -->
          </div>
        </form>
        <!-- </form> -->
      </div>

      <!-- Modal footer -->
    </div>
  </div>
</div>

<button id="uncheckModalButton" [hidden]="true" data-toggle="modal" data-backdrop="static" data-keyboard="false"
  data-target="#uncheck-Modal">
  uncheck Modal
</button>

<div class="modal fade" id="uncheck-Modal">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content" style="width: auto">
      <!-- Modal Header -->
      <div class="modal-header">
        <h2>Do you want to uncheck this facility, all data will be lost?</h2>
        <button type="button" id="closeModal" hidden class="close" #closeBtnAdd1 data-dismiss="modal">
          &times;
        </button>
      </div>
      <!-- Modal body -->
      <!-- <form> -->

      <div class="modal-body">
        <div class="row justify-content-end">
          <div class="col-md-6">
            <button type="button" data-dismiss="modal" class="float-right btn btn-round btn-default custom-btn"
              (click)="noUncheck()">
              No
            </button>
          </div>
          <div class="col-md-6">
            <button type="button" (click)="yesUncheck()" class="float-left btn btn-round btn-default custom-btn">
              Yes
            </button>
          </div>
        </div>
        <!-- </form> -->
      </div>

      <!-- Modal footer -->
    </div>
  </div>
</div>

<button id="confirmModalButton" [hidden]="true" data-toggle="modal" data-target="#confirm-modal">
  Open Modal
</button>

<div class="modal fade" id="confirm-modal">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content" style="width: auto">
      <!-- Modal Header -->
      <div class="modal-header">
        <h2>Following File already selected , do you want to upload?</h2>

        <button type="button" id="closeModal" hidden class="close" #closeBtnConfirm data-dismiss="modal">
          &times;
        </button>
      </div>
      <!-- Modal body -->
      <!-- <form> -->

      <div class="modal-body">
        <!-- <div class="row justify-content-center"> -->
        <ng-container *ngFor="let item of sameNameFiles">
          <div class="row justify-content-center" style="font-size: 18px">
            {{ item.name }}
          </div>
        </ng-container>

        <!-- </div> -->
        <!-- </form> -->
      </div>
      <div class="modal-footer">
        <div class="row justify-content-end">
          <div class="col-md-6">
            <button type="button" data-dismiss="modal" (click)="noUncheck1()"
              class="float-right btn btn-round btn-default custom-btn">
              No
            </button>
          </div>
          <div class="col-md-6">
            <button type="button" (click)="yesUncheck1()" class="float-left btn btn-round btn-default custom-btn">
              Yes
            </button>
          </div>
        </div>
      </div>
      <!-- Modal footer -->
    </div>
  </div>
</div>

<div *ngIf="openImageModal">
  <app-image-modal [currentImageIndex]="currentImageIndex" [images]="imagesArr"
    (closeImageModal)="closeImageModal($event)"></app-image-modal>
</div>