import { Component, EventEmitter, Inject, Injector, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireStorage } from "@angular/fire/storage";
import { PropertyService } from 'src/app/property/property-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/shared';
import { message } from '../../../../_constants/constants';

@Component({
    selector: 'app-facility-modal',
    templateUrl: './facility-modal.component.html',
    styleUrls: ['./facility-modal.component.scss']
})
export class FacilityModalComponent implements OnInit {

    @Output() changeNextTab: EventEmitter<any> = new EventEmitter<any>();
    facilityForm: FormGroup;
    isHovering: boolean;
    saveImages: any = [];
    selectedImageObj: any = [];
    saveImageUrl: any = [];
    filterUrl: any = []
    openImageModal = false;
    currentImageIndex: number;
    imagesArr: any = [];
    deleteImage: any = [];
    toaster: any;
    isShowDeleteBtn = false;
    isMediaRequired: any = [];
    validationmsg = message.requiredmsg;

    constructor(
        public matDialogRef: MatDialogRef<FacilityModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        public angularFireStorage: AngularFireStorage,
        public propertyService: PropertyService,
        private spinner: NgxSpinnerService,
        private injector: Injector,
        private matDialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.toaster = this.injector.get(ToastrService);
        this.initialFormLoad();
    }

    initialFormLoad() {
        console.log(this.data)
        this.facilityForm = this.formBuilder.group({
            facilityId: [this.data.item.id],
            request_data: this.formBuilder.array([]),
        });
        this.multipleFacility();
    }

    multipleFacility() {
        const ctrl = (this.facilityForm.get('request_data') as FormArray);
        if (this.data?.details.length > 0) {
            this.data?.details.forEach((element: any, index) => {
                ctrl.push(
                    this.formBuilder.group({
                        id: [element?.id],
                        name: [element?.name, Validators.required],
                        description: [element?.description, Validators.required],
                        images: [element?.images]
                    })
                );
                if (element.id) {
                    this.isShowDeleteBtn = true;
                }
                element?.images.forEach(item => {
                    this.saveImageUrl.push({ [index]: item });
                });
            });
        } else {
            this.addFacility();
        }
    }

    addFacility() {
        const ctrl = (this.facilityForm.get('request_data') as FormArray);
        ctrl.push(
            this.formBuilder.group({
                name: ['', Validators.required],
                description: ['', Validators.required],
                images: [],
                selected_image: []
            })
        );
    }

    /**
   * confirmation dialog for delete property
   * @param element property id
   */
    confirmationDialog(index: any, item, key) {
        const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
            width: '350px',
            height: '380px',
            data: {
                title: 'Are you sure?',
                message: 'It will permanently deleted !',
                okBtnLabel: 'Yes, delete it!',
                cancelBtnLabel: 'Cancel'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (key === 'remove') {
                    this.removeFields(index, item);
                } else {
                    this.deleteFacility(item);
                }
            }
        });
    }

    removeFields(index: any, item) {
        const newFields = (this.facilityForm.get('request_data') as FormArray);
        newFields.removeAt(index);

        if (item.id) {
            this.spinner.show();
            this.propertyService.deleteFacilityById(item.id).subscribe((data) => {
                if (data['success']) {
                    if (item.images && item.images.length > 0) {
                        item.images.forEach(val => {
                            this.deleteImg(val);
                        });
                    }
                    this.toaster.success(data['message'], 'Success', { timeOut: 3000 });
                } else {
                    this.toaster.success(data['message'], 'Error', { timeOut: 3000 });
                }
                this.spinner.hide();
            }, error => console.log(error));
        }
    }

    deleteFacility(id) {
        this.spinner.show();
        this.propertyService.deleteFacilities(id, this.data.item.id).subscribe((data) => {
            if (data['success']) {
                this.toaster.success(data['message'], 'Success', { timeOut: 3000 });
                this.matDialogRef.close('close');
            } else {
                this.toaster.success(data['message'], 'Error', { timeOut: 3000 });
            }
            this.spinner.hide();
        });
    }

    onDrop(files: FileList, index: any) {
        for (let i = 0; i < files.length; i++) {
            const extentions = files[i].type.split('/')[1];
            if (extentions === 'jpg' || extentions === 'jpeg' || extentions === 'png' || extentions === 'mp4' || extentions === 'm4v') {
                this.spinner.show();
                this.selectedImageObj.push({ [index]: files[i] });
            } else {
                this.toaster.error('Only jpg, jpeg, png, mp4, m4v formats allowed', 'Error', {
                    timeOut: 3000,
                });
            }
        }
        this.saveImages = []
        this.selectedImageObj.forEach((val) => {
            if (Object.keys(val) == index) {
                this.saveImages.push(val[index]);
                this.facilityForm.controls.request_data.value[index].selected_image = this.saveImages;
            }
        });
    }

    onImgUpload(url, index) {
        this.spinner.hide();
        this.saveImageUrl.push({ [index]: url });
        this.filterUrl = [];
        this.saveImageUrl.forEach((val) => {
            if (Object.keys(val) == index) {
                this.filterUrl.push(val[index]);
                this.facilityForm.controls.request_data.value[index].images = this.filterUrl;
            }
        });
        this.mediaGalleryValidation();
    }

    onImgDelete(str, i) {
        this.filterUrl = [];
        if (this.filterUrl.length == 0) {
            this.filterUrl = this.facilityForm.controls.request_data.value[i].images;
        }
        this.filterUrl = this.filterUrl?.filter(item => item != str);
        this.saveImageUrl = this.saveImageUrl.filter(item => item[i] != str);
        this.facilityForm.controls.request_data.value[i].images = this.filterUrl;
        this.deleteImage.push(str);
    }

    openImageAsModal(i: number, index): void {
        this.imagesArr = [];
        this.currentImageIndex = index;
        this.imagesArr = this.facilityForm.controls.request_data.value[i].images;
        this.openImageModal = true;
    }

    deleteImg(imagePath) {
        this.angularFireStorage.storage.refFromURL(imagePath).delete();
    }

    closeImageModal() {
        this.openImageModal = false;
    }

    mediaGalleryValidation() {
        if (this.facilityForm.value.request_data.length > 0) {
            this.facilityForm.value.request_data.forEach((value, key) => {
                this.isMediaRequired[key] = (!value.images || value.images?.length === 0);
            });
        }
    }

    onSubmitClick() {
        let requestObj = { facilities: [this.facilityForm.value] };

        this.mediaGalleryValidation();

        const returnVal = this.isMediaRequired.filter(res => res == true)[0];
        if (returnVal) {
            return false;
        }

        if (this.deleteImage.length > 0) {
            this.deleteImage.forEach(element => {
                this.deleteImg(element);
            });
        }

        this.spinner.show();
        this.propertyService.addPropertyStep2(this.data.hotel_id, requestObj)
            .subscribe((data) => {
                if (data['success']) {
                    this.toaster.success(data['message'], 'Success', { timeOut: 3000 });
                    this.matDialogRef.close('success');
                } else {
                    this.toaster.success(data['message'], 'Error', { timeOut: 3000 });
                }
                this.spinner.hide();
            });
    }
}
