import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

export class PropertyService {
  reloadPrevTab: EventEmitter<boolean> = new EventEmitter(false);
  openLightBox: EventEmitter<any> = new EventEmitter(null);
  apiURL = environment.origin;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) { }

  addPropertyStep1(data, mainImage, thumbnailImage): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('addressLine1', data.addressLine1);
    formData.append('addressLine2', data.addressLine2);
    formData.append('city', data.city);
    formData.append('communication_address', data.communication_address);
    formData.append('contact_num', data.contact_num);
    formData.append('contact_person_firstname', data.contact_person_firstname);
    formData.append('contact_person_lastname', data.contact_person_lastname);
    formData.append('country', data.country);
    formData.append('dial_code', data.dial_code);
    formData.append('email', data.email);
    formData.append('name', data.name);
    formData.append('pincode', data.pincode);
    formData.append('province', data.province);
    formData.append('star_ratings', data.star_ratings);
    formData.append('telephone_num', data.telephone_num);
    formData.append('timezone', data.timezone);
    formData.append('title', data.title);
    formData.append('website_link', data.website_link);
    formData.append('is_req', 'true');
    formData.append('isContactDetail', data.isContactDetail);
    formData.append('isCorrespondanceDetail', data.isCorrespondanceDetail);
    formData.append('latitude', data.latitude);
    formData.append('longitude', data.longitude);

    if (!data.correspondanceDetail) {
      formData.append('correspondanceDetail', JSON.stringify([]));
    } else {
      formData.append('correspondanceDetail', JSON.stringify(data.correspondanceDetail));
    }

    if (!data.contactDetail) {
      formData.append('contactDetail', JSON.stringify([]));
    } else {
      formData.append('contactDetail', JSON.stringify(data.contactDetail));
    }

    if ((typeof data.images) === 'string') {
      formData.append('images', JSON.stringify([data.images]));
    } else {
      formData.append('images', JSON.stringify(data.images));
    }

    if (mainImage) {
      formData.append('main_image', mainImage);
    }
    if (thumbnailImage) {
      formData.append('thumbnail_image', thumbnailImage);
    }
    return this.http.post<any>(this.apiURL + 'admin-hotel/property/save', formData)
      .pipe(retry(1), catchError(this.handleError));
  }

  updatePropertyStep1(data, hotelId, mainImage, thumbnailImage): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('id', data.id);
    formData.append('addressLine1', data.addressLine1);
    formData.append('addressLine2', data.addressLine2);
    formData.append('city', data.city);
    formData.append('communication_address', data.communication_address);
    formData.append('contact_num', data.contact_num);
    formData.append('contact_person_firstname', data.contact_person_firstname);
    formData.append('contact_person_lastname', data.contact_person_lastname);
    formData.append('isContactDetail', data.isContactDetail);
    formData.append('isCorrespondanceDetail', data.isCorrespondanceDetail);
    formData.append('country', data.country);
    formData.append('dial_code', data.dial_code);
    formData.append('email', data.email);
    formData.append('name', data.name);
    formData.append('pincode', data.pincode);
    formData.append('propertyId', data.propertyId);
    formData.append('province', data.province);
    formData.append('star_ratings', data.star_ratings);
    formData.append('telephone_num', data.telephone_num);
    formData.append('timezone', data.timezone);
    formData.append('title', data.title);
    formData.append('website_link', data.website_link);
    formData.append('latitude', data.latitude);
    formData.append('longitude', data.longitude);
    formData.append('is_req', 'true');

    if (!data.correspondanceDetail) {
      formData.append('correspondanceDetail', JSON.stringify([]));
    } else {
      formData.append('correspondanceDetail', JSON.stringify(data.correspondanceDetail));
    }

    if (!data.contactDetail) {
      formData.append('contactDetail', JSON.stringify([]));
    } else {
      formData.append('contactDetail', JSON.stringify(data.contactDetail));
    }

    if ((typeof data.images) === 'string') {
      formData.append('images', JSON.stringify([data.images]));
    } else {
      formData.append('images', JSON.stringify(data.images));
    }

    if (mainImage) {
      formData.append('main_image', mainImage);
    } else {
      formData.append('main_image', data.main_image);
    }

    if (thumbnailImage) {
      formData.append('thumbnail_image', thumbnailImage);
    } else {
      formData.append('thumbnail_image', data.thumbnail_image);
    }

    return this.http.put<any>(this.apiURL + 'admin-hotel/property/save/' + parseInt(hotelId),
      formData
    ).pipe(retry(1), catchError(this.handleError));
  }

  addPropertyStep2(hotelId, data) {
    return this.http
      .post(
        this.apiURL + 'admin-hotel/facility/assign/' + parseInt(hotelId),
        data,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  deleteFacilities(id, facilityId) {
    return this.http
      .delete(
        this.apiURL + `admin-hotel/facilities/delete-all/${id}/${facilityId}`,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  deleteFacilityById(id) {
    return this.http
      .delete(
        this.apiURL + `admin-hotel/facilities/delete/${id}`,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  getAllFacilities() {
    return this.http
      .get(this.apiURL + 'property-facilities/get', this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  addPropertyStep3(hotelId, data) {
    return this.http
      .post(
        this.apiURL + 'admin-hotel/room/save/' + parseInt(hotelId),
        data,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  updatePropertyStep3(hotelId, roomId, data) {
    return this.http
      .put(
        this.apiURL +
        'admin-hotel/room/save/' +
        parseInt(hotelId) +
        '/' +
        parseInt(roomId),
        data,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  deletePropertyStep3(hotelId, roomId) {
    return this.http
      .delete(
        this.apiURL +
        'admin-hotel/room/delete/' +
        hotelId +
        '/' +
        roomId,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  addPropertyStep4(roomId, hotelId, data) {
    return this.http
      .post(
        this.apiURL +
        'admin-hotel/room-rate/save/' +
        parseInt(hotelId) +
        '/' +
        parseInt(roomId),
        data,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  updatePropertyStep4(hotelId, roomId, rateId, data) {
    return this.http
      .put(
        this.apiURL +
        'admin-hotel/room-rate/save/' +
        parseInt(hotelId) +
        '/' +
        parseInt(roomId) +
        '/' +
        parseInt(rateId),
        data,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  deletePropertyStep4(hotelId, roomId, id) {
    return this.http
      .delete(
        this.apiURL +
        'admin-hotel/room-rate/delete/' +
        hotelId.toString() +
        '/' +
        roomId.toString() +
        '/' +
        id.toString(),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  addPropertyStep5Range(roomId, hotelId, data) {
    return this.http
      .post(
        this.apiURL +
        'admin-hotel/stop-sell/range/' +
        parseInt(hotelId) +
        '/' +
        parseInt(roomId),
        data,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  addPropertyStep5Single(roomId, hotelId, data) {
    return this.http
      .post(
        this.apiURL +
        'admin-hotel/stop-sell/single/' +
        parseInt(hotelId) +
        '/' +
        parseInt(roomId),
        data,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  getHotelPropertyDetailsStep1(hotelId) {
    return this.http
      .get(
        this.apiURL + 'admin-hotel/property/get/'.trim() + hotelId,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }
  /**
   * @description
   *  Get Property List from Api.
   */
  getPropertyList() {
    return this.http
      .get(this.apiURL + 'admin-hotel/property/get'.trim(), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * @description
   *  delete Property on base in id.
   */
  deletePropertyById(propertyId: string) {
    return this.http
      .delete(
        this.apiURL + 'admin-hotel/property/delete/'.trim() + propertyId,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  getLatLong(address) {
    return this.http
      .get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyCrzzuTPy7VUtlUtIZuK6JsB8l9hGvN-N0`)
      .pipe(retry(1), catchError(this.handleError));
  }

  hotelOverviewSave(requestData) {
    return this.http.post(this.apiURL + 'hotel-overview/save', requestData, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  styleSave(requestData) {
    return this.http.post(this.apiURL + 'admin-hotel/style/save', requestData, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  interestSave(requestData, fileObj: any) {
    let formData: FormData = new FormData();
    formData.append('name', requestData.name);
    formData.append('description', requestData.description);
    formData.append('hotelId', requestData.hotelId);
    formData.append('interestId', requestData.interestId);
    if (requestData.media && requestData.media.length > 0) {
      requestData.media.forEach(val => {
        formData.append('media', val);
      })
    }
    if (requestData.id) {
      formData.append('id', requestData.id);
    }
    if (fileObj.length > 0) {
      fileObj.forEach(val => {
        formData.append('media_file', val);
      })
    }
    return this.http.post(this.apiURL + 'admin-hotel/interests/save', formData)
      .pipe(retry(1), catchError(this.handleError));
  }

  deleteInterests(id, hotelId) {
    return this.http
      .delete(
        this.apiURL + `admin-hotel/interests/delete/${id}/${hotelId}`,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  hotelCategorySave(requestData: any) {
    return this.http.post(this.apiURL + 'admin-hotel/hotel-category/save', requestData, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  childAgeSave(requestData: any) {
    return this.http.post(this.apiURL + 'admin-hotel/child-age/save', requestData, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  chargeableFacilitySave(requestData: any) {
    return this.http.post(this.apiURL + 'admin-hotel/chargeable-facility/save', requestData, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  saveRoomFacility(requestData: any, fileObj: any) {
    let formData: FormData = new FormData();
    formData.append('name', requestData.name);
    formData.append('description', requestData.description);
    formData.append('hotelId', requestData.hotelId);
    formData.append('room_facilities_id', requestData.roomFacilityId);
    if (requestData.media && requestData.media.length > 0) {
      requestData.media.forEach(val => {
        formData.append('media', val);
      })
    }
    if (requestData.id) {
      formData.append('id', requestData.id);
    }
    if (fileObj.length > 0) {
      fileObj.forEach(val => {
        formData.append('media_file', val);
      })
    }
    return this.http.post(this.apiURL + 'admin-hotel/room-facilities/save', formData)
      .pipe(retry(1), catchError(this.handleError));
  }

  deleteRoomFacilities(id, hotelId) {
    const url = this.apiURL + `admin-hotel/room-rate-facilities/delete/${id}/${hotelId}`;
    return this.http.delete(url, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  saveInclusions(requestData: any, fileObj: any) {
    let formData: FormData = new FormData();
    formData.append('name', requestData.name);
    formData.append('description', requestData.description);
    formData.append('hotelId', requestData.hotelId);
    formData.append('inclusions_id', requestData.inclusionsId);
    if (requestData.media && requestData.media.length > 0) {
      requestData.media.forEach(val => {
        formData.append('media', val);
      })
    }
    if (requestData.id) {
      formData.append('id', requestData.id);
    }
    if (fileObj.length > 0) {
      fileObj.forEach(val => {
        formData.append('media_file', val);
      })
    }
    return this.http.post(this.apiURL + 'admin-hotel/inclusions/save', formData)
      .pipe(retry(1), catchError(this.handleError));
  }

  deleteInclusion(id, hotelId) {
    const url = this.apiURL + `admin-hotel/inclusions/delete/${id}/${hotelId}`;
    return this.http.delete(url, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
