import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { PromotionDataTable } from "./list-promotion";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { PromotionsService } from "../promotions.service";
import { MastersService } from "src/app/masters/masters.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

@Component({
  selector: "app-list-promotions",
  templateUrl: "./list-promotions.component.html",
  styleUrls: ["./list-promotions.component.css"],
})
export class ListPromotionsComponent implements OnInit {
  displayedColumns = [
    "promotionCode",
    "promotionName",
    "percentOff",
    "fixDiscount",
    "freeNights",
    "valueAdded",
    "viewRooms",
    "action",
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("closeBtnDelete") closeBtnConfirm: ElementRef;
  public displayPromotionList = new MatTableDataSource<PromotionDataTable>();
  public promotionList: PromotionDataTable[];
  public sortedData: PromotionDataTable[];
  public showLoader = false;
  deletedPromotionId: string;
  viewRooms: PromotionDataTable = null;

  currentSort: Sort = {
    active:'promotionName',
    direction:'asc'
  };

  constructor(
    private router: Router,
    private promotionsService: PromotionsService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.showLoader = true;
    this.getPromotions();
  }

  private getPromotions(): void {
    this.promotionsService.getPromotion().subscribe(
      (promotionResponse: any) => {
        console.log(promotionResponse);

        if (promotionResponse["success"] === true) {
          // promotionResponse.data.map(
          //   (x: any) =>
          //     (x["countryCityName"] = x.countryName + " , " + x.cityName)
          // );
          // promotionResponse.data.map((x: any) => {
          //   if (x.contacts && x.contacts.length > 0) {
          //     x["operationalContact"] =
          //       x.contacts[0].firstname + " " + x.contacts[0].lastname;
          //     if (x.contacts[0].mobile_num) {
          //       x["operationalContact"] += " - " + x.contacts[0].mobile_num;
          //     }
          //   }
          // });
          // append mat table source data to api reponse
          this.displayPromotionList.data = promotionResponse.data;
          // to hold propety list
          this.promotionList = promotionResponse.data;
          // for pagination
          this.displayPromotionList.paginator = this.paginator;
          // for sorting
          this.displayPromotionList.sort = this.sort;
          this.sortedData = this.promotionList.slice();

          this.sort.sortChange.emit();

          this.toastr.success(
            "Promotion List Fetched Successfully.",
            "Success"
          );
          this.showLoader = false;
        }
      },
      (error) => {
        console.log(error);
        this.showLoader = false;
        this.toastr.error(
          "Getting Error While Fetching Promotion List.",
          "Error"
        );
      }
    );
  }

  public sortData(sort: Sort = this.currentSort): void {
   console.log('[sortData]', this.currentSort);

    this.currentSort = sort;
    const data = this.promotionList;
    if (!sort.active || sort.direction === "") {
      this.sortedData = data;
      return;
    }

    console.log("Sort", sort, data);
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === "asc";
      console.log("data", a, b);
      switch (sort.active) {
        case "promotionName":
          return this.compare(a.promotion_name, b.promotion_name, isAsc);
        case "promotionCode":
          return this.compare(a.promotion_code, b.promotion_code, isAsc);
        case "percentOff":
          return this.compare(a.percent_off, b.percent_off, isAsc);
        case "fixDiscount":
          return this.compare(a.fix_discount, b.fix_discount, isAsc);
        case "freeNights":
          return this.compare(a.free_nigts, b.free_nigts, isAsc);
        case "valueAdded":
          return this.compare(a.value_added, b.value_added, isAsc);

        default:
          return 0;
      }
    });
  }

  public setDeletePromotionID(promotionId: string): void {
    console.log("delete clicked", promotionId);
    this.deletedPromotionId = promotionId;
  }

  public deletePromotion(): void {
    this.promotionsService.deletePromotion(this.deletedPromotionId).subscribe(
      (data: any) => {
        if (data["success"] === true) {
          this.closeBtnConfirm.nativeElement.click();
          $("body").removeClass("modal-open");
          $(".modal-backdrop").remove();
          const index = this.promotionList.findIndex(
            (x) => x.id === this.deletedPromotionId
          );
          if (index > -1) {
            this.promotionList.splice(index, 1);
            this.displayPromotionList.data = this.promotionList;
            this.toastr.success("Promotion Deleted Successfully.", "Success");
          }
        }
      },
      (error) => {
        console.log(error);
        this.toastr.error("Getting Error While Delete Promotion.", "Error");
      }
    );
  }

  editPromotion(promotionId: String, mode: string) {
    this.router.navigate(["promotions", "view-promotion", promotionId, mode]);
  }

  private compare(a: number | string, b: number | string, isAsc: boolean) {
    console.log(a, b);

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onViewRooms(element) {
    this.viewRooms = element;
    console.log("element", element);
  }
}
