import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AddPromotionComponent } from "./add-promotion/add-promotion.component";
import { ListPromotionsComponent } from "./list-promotions/list-promotions.component";

const routes: Routes = [
  { path: "add-promotion", component: AddPromotionComponent },
  { path: "list-promotions", component: ListPromotionsComponent },
  {
    path: "view-promotion/:promotionId/:mode",
    component: AddPromotionComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PromotionsRoutingModule {}
