export class HotelInfo {
      id?: any;
      name?: string;
      member_name?: string;
      addressLine1?: string;
      addressLine2?: string;
      country?: string;
      city?: string;
      province?: string;
      pincode?: number | string;
      timezone?: string;
      telephone_num?: string;
      contact_num?: string;
      email?: string;
      title?: string;
      star_ratings?: any;
      contact_person_firstname?: string;
      contact_person_lastname?: string;
      website_link?: string;
      communication_address?: true;
      main_image: any;
      thumbnail_image: any;
      images?: string;
      isContactDetail?: boolean;
      isCorrespondanceDetail?: boolean;
      dial_code?: string;
      contactDetail?: [ContactDetail];
      correspondanceDetail?: [CorrespondenceDetail];
      isShowRates?: any;
      propertyId: any;
      latitude?: number;
      longitude?: number;
}


export class ContactDetail {
      id?: string;
      firstname?: string;
      lastname?: string;
      email?: string;
      mobile_num?: string;
      telephone_num?: string;
      fax_num?: string;
      website_link?: string;
}

export class CorrespondenceDetail {
      id?: string;
      addressLine1?: string;
      addressLine2?: string;
      country?: string;
      city?: string;
      province?: string;
      pincode?: string;
      contact_num?: string;
      email?: string;
      firstname?: string;
      lastname?: string;
      dial_code?: string;
}
