export const regexValid = {
    spaceNotAllow: /[^-s]/,
    onlyNumeric: /[^0-9]/,

}

export const message = {
    requiredmsg: 'Button will be enabled after all required fields are filled in. Required fields are highlighted in Red'
}

export const sideBar = [
    {
        name: 'Masters',
        icon: 'fa fa-tachometer',
        submenu: [
            {
                name: 'Room Type',
                route: '/masters/room-type',
                icon: '',
            }, {
                name: 'Property Facilities Category',
                route: '/masters/property-facilities-category',
                icon: ''
            }, {
                name: 'Property Facilities',
                route: '/masters/property-facilities',
                icon: ''
            }, {
                name: 'Bedding Type',
                route: '/masters/bedding-type',
                icon: ''
            }, {
                name: 'Room Facilities',
                route: '/masters/room-facilities',
                icon: ''
            }, {
                name: 'Inclusions',
                route: '/masters/inclusions',
                icon: ''
            }, {
                name: 'SPA Services',
                route: '/masters/spa-services/list',
                icon: ''
            },
        ]
    }, {
        name: 'Reports',
        icon: 'fa fa-file-text-o',
        submenu: [
            {
                name: 'Room Type',
                route: '/masters/room-type',
                icon: '',
            }, {
                name: 'Property Facilities Category',
                route: '/masters/property-facilities-category',
                icon: ''
            }, {
                name: 'Property Facilities',
                route: '/masters/property-facilities',
                icon: ''
            }, {
                name: 'Bedding Type',
                route: '/masters/bedding-type',
                icon: ''
            }, {
                name: 'Room Facilities',
                route: '/masters/room-facilities',
                icon: ''
            }, {
                name: 'Inclusions',
                route: '/masters/inclusions',
                icon: ''
            },
        ]
    },

]

export const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtSUNILkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_svm123';