export class HotelItem {
  checked: boolean;
  id: string;
  name: string;
  country: number;
  city: number;
  province: number;
  countryName: string;
  cityName: string;
  provinceName: string;
  contacts: Array<any>;
}

export const alphabets = [
  { id: "a", name: "A" },
  { id: "b", name: "B" },
  { id: "c", name: "C" },
  { id: "d", name: "D" },
  { id: "e", name: "E" },
  { id: "f", name: "F" },
  { id: "g", name: "G" },
  { id: "h", name: "H" },
  { id: "i", name: "I" },
  { id: "j", name: "J" },
  { id: "k", name: "K" },
  { id: "l", name: "L" },
  { id: "m", name: "M" },
  { id: "n", name: "N" },
  { id: "o", name: "O" },
  { id: "p", name: "P" },
  { id: "q", name: "Q" },
  { id: "r", name: "R" },
  { id: "s", name: "S" },
  { id: "t", name: "T" },
  { id: "u", name: "U" },
  { id: "v", name: "V" },
  { id: "w", name: "W" },
  { id: "x", name: "X" },
  { id: "y", name: "Y" },
  { id: "z", name: "Z" },
];

