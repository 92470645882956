<div class="page-footer">
  <div class="page-footer-inner"> 2018 &copy; Spice Hotel Template By
    <a href="#/dashboard" target="_top" class="makerCss">PRAIVIT</a>
  </div>
  <div class="scroll-to-top">
    <i class="fa fa-angle-double-up" aria-hidden="true"></i>

  </div>
</div>
<script src="../../../assets/js/jquery.min.js"></script>
<script src="../../../assets/js/popper.min.js"></script>
<script src="../../../assets/js/bootstrap.min.js"></script>
<script src="../../../assets/js/material.min.js"></script>
<script src="../../../assets/js/app.js"></script>
<script src="../../../assets/js/layout.js"></script>
<script src="../../../assets/js/theme-color.js"></script>
<script src="../../../assets/js/dropzone.js"></script>
<script src="../../../assets/js/dropzone-call.js"></script>
<script src="../../../assets/js/summernote.min.js"></script>
<script src="../../../assets/js/summernote-data.js"></script>
<script src="../../../assets/js/jquery.rtResponsiveTables.min.js"></script>
<script src="../../../assets/js/jquery-ui.js"></script>
<script src="../../../assets/js/datatables.min.js"></script>
<script>
  /********************      CALENDER            ****************/
  $(".custom_datepicker").datepicker({
    minDate: 0,
    showOtherMonths: true,
    selectOtherMonths: true,
    firstDay: 1
  });
  $(document).ready(function () {
    $(".datable").DataTable();
  });
  /********************     responsive table           ****************/
  $(document).ready(function () {
    $(".all-table table").rtResponsiveTables({
      containerBreakPoint: 733
    });
  });

  //calendar
  $(".calnder_btn").click(function () {
    $("body").toggleClass("calender_open");
  });
  $(".calender_table td").click(function () {
    $(this).toggleClass("select-date");
  });
</script>
<div class="modal fade" id="myModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">

      <!-- Modal Header -->
      <button type="button"  
id="closeModal"  class="close" data-dismiss="modal">&times;</button>

      <!-- Modal body -->
      <div class="modal-body mt-5">
        <h3>Do you want to manage stop sell for this hotel now?</h3>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <a href="#" class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn btn-primary">
          No
        </a>
        <a href="#" class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn btn-success">
          Yes
        </a>
      </div>

    </div>
  </div>
</div>
<div class="modal fade" id="rooms-available">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h2>Rooms Available</h2>
        <button type="button"  
id="closeModal"  class="close" data-dismiss="modal">&times;</button>
      </div>
      <!-- Modal body -->
      <div class="modal-body ">
        <ul class="list-ul">
          <li><b>Deluxe Room</b>1</li>
          <li><b>Semi Special</b>1</li>
          <li><b>Superior Room</b>1</li>
          <li><b>Suite Special</b>1</li>
          <li><b>Deluxe Room</b>1</li>
        </ul>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">

        <button type="button" class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn btn-warning"
          data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>
<div class="modal fade" id="add-master">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h2>Add Master</h2>
        <button type="button"  
id="closeModal"  class="close" data-dismiss="modal">&times;</button>
      </div>
      <!-- Modal body -->
      <div class="modal-body ">
        <form>
          <div class="form-group">
            <label class="form-label">Activity Name</label>
            <input class="form-control">
          </div>
          <div class="form-group">
            <label class="form-label">Activity Description</label>
            <textarea class="form-control"></textarea>
          </div>
        </form>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">

        <button type="button"
          class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn btn-warning">Add</button>
      </div>

    </div>
  </div>
</div>
<div class="modal fade" id="edit-master">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h2>Edit Master</h2>
        <button type="button"  
id="closeModal"  class="close" data-dismiss="modal">&times;</button>
      </div>
      <!-- Modal body -->
      <div class="modal-body ">
        <form>
          <div class="form-group">
            <label class="form-label">Activity Name</label>
            <input class="form-control" value="Activity One">
          </div>
          <div class="form-group">
            <label class="form-label">Activity Description</label>
            <textarea
              class="form-control">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been</textarea>
          </div>
        </form>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">

        <button type="button"
          class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn btn-warning">Update</button>
      </div>

    </div>
  </div>
</div>
<div class="modal fade" id="delate-master">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h2>Are you sure?</h2>
        <button type="button"  
id="closeModal"  class="close" data-dismiss="modal">&times;</button>
      </div>
      <!-- Modal body -->
      <div class="modal-body delate-popup text-center">
        <form>
          <div class="form-group">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <h2>It will permanently deleted !</h2>
          </div>
          <div class="form-group">
            <button type="button"
              class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn btn-warning">Yes, delete
              it!</button>
            <button type="button"
              class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn btn-danger"
              data-dismiss="modal">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
