// External Imports
import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ElementRef,
} from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Sort, MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

// Internal imports
import { PropertyDataTable } from "./listing-property";
import { PropertyService } from "../property-service.service";
import { MastersService } from "../../masters/masters.service";
import { NgxSpinnerService } from "ngx-spinner";
import { BeddingTypeService, HotelSyncToolService, RoomFacilitiesService } from "../../_services";

@Component({
  selector: "app-listing-property",
  templateUrl: "./listing-property.component.html",
  styleUrls: ["./listing-property.component.css"],
})
export class ListingPropertyComponent implements OnInit, OnDestroy {
  hotelsForDeletion: Array<any> = [];
  selectCurrentPageHotels: boolean = false;
  public displayedColumns: string[] = [
    "checked",
    "name",
    "country",
    "operationalContact",
    "action",
  ];
  public propertyDetails: PropertyDataTable[];
  public showLoader = false;
  public displayPropertyList = new MatTableDataSource<PropertyDataTable>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public ratings = 0; // store ratings
  @ViewChild("closeBtnDelete") closeBtnConfirm: ElementRef;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public defaultMinPrice = 0;
  public defaultMaxPrice = 10000;
  public filterCriteria = {
    country: "",
    province: "",
    city: "",
    minPrice: this.defaultMinPrice,
    maxPrice: this.defaultMaxPrice,
    distance: 0,
    availableFrom: "",
    availableTo: "",
    propertyFacility: [],
    bedType: [],
    roomType: [],
    starCategory: 0,
    area: "",
  }; // hold filtered criteria
  public sortedData: PropertyDataTable[];
  private deletedPropertyId: string;
  public showHideFilters = {
    displayCountryFilter: false,
    displayPriceFilter: false,
    displayDistanceFilter: false,
    displayAvailabilityFilter: false,
    displayPropertyFacilityFilter: false,
    displayBedTypeFilter: false,
    displayRoomTypeFilter: false,
    displayStarCategoryFilter: false,
    displayAreaFilter: false,
  };
  public displayFilters = false;
  public propertyList: any = {
    countryList: [],
    provinceList: [],
    cityList: [],
    propertFacilityList: [],
    beddingList: [],
    roomFacilityList: [],
  }; // holds list to be filted.

  currentPage: PageEvent = {
    pageIndex: 0,
    pageSize: 10,
    length,
  };

  constructor(
    private propertyService: PropertyService,
    private router: Router,
    private mastersService: MastersService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private roomFacilitiesService: RoomFacilitiesService,
    private hotelSyncToolService: HotelSyncToolService,
    private beddingTypeService: BeddingTypeService
  ) { }
  /**
   * @description
   * apply filter in list when user started typing.
   */
  public applyFilter(): void {
    if (this.propertyDetails && this.propertyDetails.length > 0) {
      const list = JSON.parse(JSON.stringify(this.propertyDetails));
      let filteredArr = [];
      for (const data of list) {
        if (
          this.filterCriteria.country &&
          this.filterCriteria.city &&
          this.filterCriteria.province
        ) {
          if (this.filterCriteria.country === data.country.toString()) {
            if (this.filterCriteria.province === data.province.toString()) {
              if (this.filterCriteria.city === data.city.toString()) {
                filteredArr.push(data);
              }
            }
          }
        } else if (
          this.filterCriteria.country &&
          this.filterCriteria.province
        ) {
          if (this.filterCriteria.country === data.country.toString()) {
            if (this.filterCriteria.province === data.province.toString()) {
              filteredArr.push(data);
            }
          }
        } else if (this.filterCriteria.country) {
          if (this.filterCriteria.country === data.country.toString()) {
            filteredArr.push(data);
          }
        } else {
          filteredArr.push(data);
        }

        if (this.filterCriteria.propertyFacility.length > 0) {
          filteredArr = filteredArr.filter((hotel) => {
            return hotel.facilities.some((facility) =>
              this.filterCriteria.propertyFacility.some(
                (t) => t == facility.facilityId
              )
            );
          });
        }

        if (this.filterCriteria.bedType.length > 0) {
          filteredArr = filteredArr.filter((hotel) => {
            return hotel.beddingTypes.some((b) =>
              this.filterCriteria.bedType.some((t) => t == b.bedding_type)
            );
          });
        }
        if (this.filterCriteria.roomType.length > 0) {
          filteredArr = filteredArr.filter((hotel) => {
            return hotel.roomFacilities.some((r) =>
              this.filterCriteria.roomType.some((t) => t == r.room_facility)
            );
          });
        }

        if (
          this.filterCriteria.minPrice !== this.defaultMinPrice &&
          this.filterCriteria.maxPrice !== this.defaultMaxPrice
        ) {
          if (this.filterCriteria.minPrice >= 0) {
            filteredArr = filteredArr.filter((hotel) => {
              return hotel.roomRates.some(
                (r) =>
                  r.per_adult_cost_rate_weekday >=
                  this.filterCriteria.minPrice ||
                  r.per_adult_cost_rate_weekend >= this.filterCriteria.minPrice
              );
            });
          }

          if (this.filterCriteria.maxPrice >= 0) {
            filteredArr = filteredArr.filter((hotel) => {
              return hotel.roomRates.some(
                (r) =>
                  r.per_adult_cost_rate_weekday <=
                  this.filterCriteria.maxPrice ||
                  r.per_adult_cost_rate_weekend <= this.filterCriteria.maxPrice
              );
            });
          }
        }
      }
      this.displayPropertyList.data = filteredArr;
      // this.filterCriteria = {
      //   country: "",
      //   province: "",
      //   city: "",
      //   price: 0,
      //   distance: 0,
      //   availableFrom: "",
      //   availableTo: "",
      //   propertyFacility: [],
      //   bedType: [],
      //   roomType: [],
      //   starCategory: 0,
      //   area: "",
      // };
    }
  }

  onPageChange(page: PageEvent) {
    console.log("[CurrentPage]", page);
    this.currentPage = page;
    this.selectCurrentPageHotels = false;
    let recordCount = 0;

    for (
      let index = page.pageIndex * page.pageSize;
      index < page.pageIndex * page.pageSize + page.pageSize;
      index++
    ) {
      const hotel = this.displayPropertyList.data[index];
      if (hotel && hotel["checked"]) recordCount++;
    }

    if (page.pageSize === recordCount) {
      this.selectCurrentPageHotels = true;
    }

    console.log("[recordCount]", recordCount);
  }

  onSelectCurrentPageHotel(isUpdateFlag = true) {
    console.log("[onSelectCurrentPageHotel]", this.selectCurrentPageHotels);
    if (isUpdateFlag)
      this.selectCurrentPageHotels = !this.selectCurrentPageHotels;
    for (
      let index = this.currentPage.pageIndex * this.currentPage.pageSize;
      index <
      this.currentPage.pageIndex * this.currentPage.pageSize +
      this.currentPage.pageSize;
      index++
    ) {
      const hotel = this.displayPropertyList.data[index];
      console.log("[HOTEL]", hotel);
      if (hotel) hotel["checked"] = this.selectCurrentPageHotels;
      // if (hotel) hotel.checked = true;
    }
  }

  onHotelCheckChange(hotel) {
    hotel.checked = !hotel.checked;
  }

  public bindPropertyFacility(item): void {
    this.spinner.show();
    if (!this.filterCriteria.propertyFacility.includes(item.id)) {
      this.filterCriteria.propertyFacility.push(item.id);
    } else {
      const index = this.filterCriteria.propertyFacility.findIndex(
        (x) => x.id === item.id
      );
      this.filterCriteria.propertyFacility.splice(index, 1);
    }
    this.spinner.hide();
  }
  bindBedType(item: any): void {
    this.spinner.show();
    if (!this.filterCriteria.bedType.includes(item.id)) {
      this.filterCriteria.bedType.push(item.id);
    } else {
      const index = this.filterCriteria.bedType.findIndex(
        (x) => x.id === item.id
      );
      this.filterCriteria.bedType.splice(index, 1);
    }
    this.spinner.hide();
  }
  bindRoomType(item: any): void {
    this.spinner.show();
    if (!this.filterCriteria.roomType.includes(item.id)) {
      this.filterCriteria.roomType.push(item.id);
    } else {
      const index = this.filterCriteria.roomType.findIndex(
        (x) => x.id === item.id
      );
      this.filterCriteria.roomType.splice(index, 1);
    }

    this.spinner.hide();
  }
  /**
   *
   * @param filterName
   *        holds filter name .
   * @description
   *     manage filters.
   */
  manageFilters(filterName: string) {
    switch (filterName) {
      case "country":
        this.showHideFilters.displayCountryFilter = !this.showHideFilters
          .displayCountryFilter;
        break;
      case "price":
        this.showHideFilters.displayPriceFilter = !this.showHideFilters
          .displayPriceFilter;
        this.filterCriteria.minPrice = this.defaultMinPrice;
        this.filterCriteria.maxPrice = this.defaultMaxPrice;

        break;
      case "distance":
        this.showHideFilters.displayDistanceFilter = !this.showHideFilters
          .displayDistanceFilter;
        break;
      case "available":
        this.showHideFilters.displayAvailabilityFilter = !this.showHideFilters
          .displayAvailabilityFilter;
        break;
      case "propertyFacility":
        this.showHideFilters.displayPropertyFacilityFilter = !this
          .showHideFilters.displayPropertyFacilityFilter;
        break;
      case "bedType":
        this.showHideFilters.displayBedTypeFilter = !this.showHideFilters
          .displayBedTypeFilter;
        break;
      case "roomType":
        this.showHideFilters.displayRoomTypeFilter = !this.showHideFilters
          .displayRoomTypeFilter;
        break;
      case "starCategory":
        this.showHideFilters.displayStarCategoryFilter = !this.showHideFilters
          .displayStarCategoryFilter;
        break;
      case "area":
        this.showHideFilters.displayAreaFilter = !this.showHideFilters
          .displayAreaFilter;
        break;
      case "intial":
        this.hideAllFilters();
        this.filterCriteria.minPrice = this.defaultMinPrice;
        this.filterCriteria.maxPrice = this.defaultMaxPrice;
        break;
      case "close":
        this.displayPropertyList.data = JSON.parse(
          JSON.stringify(this.propertyDetails)
        );
        this.ratings = 0;
        this.filterCriteria = {
          country: "",
          province: "",
          city: "",
          minPrice: this.defaultMinPrice,
          maxPrice: this.defaultMaxPrice,
          distance: 0,
          availableFrom: "",
          availableTo: "",
          propertyFacility: [],
          bedType: [],
          roomType: [],
          starCategory: 0,
          area: "",
        };
        this.hideAllFilters();
        break;
      default:
      // code block
    }
    // display common level filter
    const filteredArr = [];
    for (const key in this.showHideFilters) {
      if (this.showHideFilters.hasOwnProperty(key)) {
        filteredArr.push(this.showHideFilters[key]);
      }
    }
    if (filteredArr.includes(true)) {
      this.displayFilters = true;
    } else {
      this.displayFilters = false;
    }
  }
  public onlyNumeric(e): boolean {
    const keyCode = e.keyCode;
    if (keyCode === 8 || keyCode === 46 || keyCode === 37 || keyCode === 39) {
      return true;
    }
    if (!(keyCode >= 48 && keyCode <= 57)) {
      return false;
    }
  }
  /**
   * @description
   *     hide all filters.
   */
  hideAllFilters() {
    this.displayFilters = false;
    this.showHideFilters.displayPriceFilter = false;
    this.showHideFilters.displayCountryFilter = false;
    this.showHideFilters.displayDistanceFilter = false;
    this.showHideFilters.displayAvailabilityFilter = false;
    this.showHideFilters.displayPropertyFacilityFilter = false;
    this.showHideFilters.displayBedTypeFilter = false;
    this.showHideFilters.displayRoomTypeFilter = false;
    this.showHideFilters.displayStarCategoryFilter = false;
    this.showHideFilters.displayAreaFilter = false;
  }
  /**
   * @description
   * get Property List from api call on intialization.
   */
  private getPropertyList(): void {
    this.spinner.show();
    this.propertyService.getPropertyList().subscribe(
      (propertyList: any) => {
        if (propertyList["success"] === true) {
          propertyList.data.map(
            (x: any) =>
              (x["countryCityName"] = x.countryName + " , " + x.cityName)
          );
          propertyList.data.map((x: any) => {
            if (x.contacts && x.contacts.length > 0) {
              x["operationalContact"] =
                x.contacts[0].firstname + " " + x.contacts[0].lastname;
              if (x.contacts[0].mobile_num) {
                x["operationalContact"] += " - " + x.contacts[0].mobile_num;
              }
            }
          });
          // append mat table source data to api reponse
          this.displayPropertyList.data = propertyList.data;
          // to hold propety list
          this.propertyDetails = propertyList.data;
          // for pagination
          this.displayPropertyList.paginator = this.paginator;
          // for sorting
          this.displayPropertyList.sort = this.sort;
          this.sortedData = this.propertyDetails.slice();
          this.toastr.success("Property List Fetched Successfully.", "Success");
          this.showLoader = false;
          this.selectCurrentPageHotels = false;
          this.spinner.hide();
        }
      },
      (error) => {
        console.log(error);
        this.showLoader = false;
        this.toastr.error(
          "Getting Error While Fetching Property List.",
          "Error"
        );

        this.spinner.hide();
      }
    );
  }

  deleteSelectedHotels() {
    this.hotelsForDeletion = [];
    this.displayPropertyList.data.forEach((element) => {
      if (element["checked"]) {
        this.hotelsForDeletion.push(element);
      }
    });
    console.log(this.hotelsForDeletion);
    this.closeBtnConfirm.nativeElement.click();
    this.detetePropertyById(this.hotelsForDeletion.pop().id);
  }

  /**
   * @description
   * redirect to view-property page for view or edit purpose.
   */
  public editProperty(propertyId: string, mode: string): void {
    this.router.navigate(["property", "view-property", propertyId, mode]);
  }

  /**
   * @description
   * delete property base on property id from api call.
   */
  public deleteProperty(propertyId?: string): void {
    if (propertyId) {
      this.deletedPropertyId = propertyId;
    } else {
      this.detetePropertyById(this.deletedPropertyId);
    }
  }

  detetePropertyById(propertyId) {
    this.spinner.show();
    this.propertyService.deletePropertyById(propertyId).subscribe(
      (data: any) => {
        if (data["success"] === true) {
          this.closeBtnConfirm.nativeElement.click();
          $("body").removeClass("modal-open");
          $(".modal-backdrop").remove();
          const index = this.propertyDetails.findIndex(
            (x) => x.id === propertyId
          );
          if (index > -1) {
            this.propertyDetails.splice(index, 1);
            this.displayPropertyList.data = this.propertyDetails;
            this.toastr.success("Property Deleted Successfully.", "Success");
          }

          if (this.hotelsForDeletion.length == 0) {
            this.spinner.hide();
            this.selectCurrentPageHotels = false;
          } else {
            this.detetePropertyById(this.hotelsForDeletion.pop().id);
          }
        }
      },
      (error) => {
        console.log(error);
        this.toastr.error("Getting Error While Delete Property.", "Error");
        this.spinner.hide();
      }
    );
  }
  /**
   * @description
   * sorting Data in ascending / descending Order.
   */
  public sortData(sort: Sort): void {
    const data = this.propertyDetails.slice();
    if (!sort.active || sort.direction === "") {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "name":
          return this.compare(a.name, b.name, isAsc);
        case "country":
          return this.compare(a.countryName, b.countryName, isAsc);
        case "operationalContact":
          return this.compare(
            a.operationalContact,
            b.operationalContact,
            isAsc
          );
        default:
          return 0;
      }
    });
  }
  /**
   * @description
   * get country List from Api call.
   */
  private getCountry(): void {
    this.mastersService.getCountry().subscribe(
      (data) => {
        if (data["success"] === true) {
          this.propertyList.countryList = data["data"];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  /**
   * @description
   * Get Province List based on countryId.
   */
  public getProvinceById(countryId: string): void {
    if (parseInt(countryId, 0)) {
      this.mastersService
        .getProvinceById(parseInt(countryId, 0))
        .subscribe((data) => {
          if (data["success"] === true) {
            this.filterCriteria.province = "";
            $("#province").removeAttr("disabled");
            this.propertyList.provinceList = data["data"];
          }
        });
    } else {
      this.propertyList.provinceList = [];
      this.propertyList.cityList = [];
    }
  }

  /**
   * @description
   * Get City List based on province id.
   */
  public getCityById(ProvinceId: string): void {
    if (parseInt(ProvinceId, 0)) {
      this.mastersService
        .getCityById(parseInt(ProvinceId, 0))
        .subscribe((data) => {
          if (data["success"] === true) {
            this.filterCriteria.city = "";
            $("#city").removeAttr("disabled");
            this.propertyList.cityList = data["data"];
          }
        });
    } else {
      this.propertyList.cityList = [];
    }
  }
  /**
   * @description
   * compare Property list data.
   */
  private compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  /**
   * @description
   * to manage toggle button to toggle-in/out left side bar.
   */
  manageHeaderToggle(): void {
    const toggle = document.getElementById("toggle-button-header");
    toggle.click();
  }
  getPropertyFacility() {
    this.mastersService.getPropertyFacilities().subscribe(
      (data: any) => {
        if (data["success"] === true) {
          this.propertyList.propertFacilityList = data.data;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getBeddingType() {
    this.beddingTypeService.getBeddingType().subscribe(
      (data: any) => {
        if (data["success"] === true) {
          this.propertyList.beddingList = data.data;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getRoomType(): void {
    this.roomFacilitiesService.getRoomFacilities().subscribe(
      (data: any) => {
        if (data["success"] === true) {
          this.propertyList.roomFacilityList = data.data;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  // intialization.
  ngOnInit() {
    this.showLoader = true;
    this.getPropertyList();
    this.getCountry();
    this.manageHeaderToggle();
    this.getPropertyFacility();
    this.getBeddingType();
    this.getRoomType();
    this.manageFilters("intial");
  }
  ngOnDestroy(): void {
    this.manageHeaderToggle();
  }

  updateMinPriceRange() {
    if (this.filterCriteria.maxPrice == null) {
      this.filterCriteria.maxPrice = this.defaultMaxPrice;
    }
    if (this.filterCriteria.minPrice > this.filterCriteria.maxPrice) {
      this.filterCriteria.minPrice = this.filterCriteria.maxPrice;
    }
  }

  updateMaxPriceRange() {
    console.log("[updateMaxPriceRange]", this.filterCriteria.minPrice);

    if (this.filterCriteria.minPrice == null) {
      this.filterCriteria.minPrice = 1;
      console.log("[updateMaxPriceRange if]", this.filterCriteria.minPrice);
    }
    if (this.filterCriteria.minPrice > this.filterCriteria.maxPrice) {
      this.filterCriteria.maxPrice = this.filterCriteria.minPrice;
    }
  }

  searchHotel(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.displayPropertyList.filter = filterValue.trim().toLowerCase();
  }

  markAsFeatured(element) {
    const requestData = {
      is_featured: !element.is_featured,
      view_id: element.id
    };

    this.hotelSyncToolService.markAsFeaturedFromHotel(requestData).subscribe((res: any) => {
      if (!res.success) {
        this.toastr.error(res['message'], 'Error');
      }
    });
    this.hotelSyncToolService.markAsFeatured(requestData).subscribe((res: any) => {
      if (res.success) {
        this.displayPropertyList.data.forEach((items: any) => {
          if (element.id == items.id) {
            return items.is_featured = !element.is_featured;
          }
        });
        this.toastr.success(res['message'], 'Success');
      } else {
        this.toastr.error(res['message'], 'Error');
      }
    });
  }
}
