/// <reference types='@types/googlemaps' />
import { Component, OnInit, Injector, ViewChild, ElementRef, Input, Output, EventEmitter, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { PropertyService } from '../../property-service.service';
import { MastersService } from '../../../masters/masters.service';
import { message } from '../../../_constants/constants';
import { Pincode } from '../../../_constants/pincode';
import { HotelInfo, CorrespondenceDetail, ContactDetail } from './step1';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';


@Component({
  selector: 'app-add-property-step1',
  templateUrl: './add-property-step1.component.html',
  styleUrls: ['./add-property-step1.component.scss'],
})
export class AddPropertyStep1Component implements OnInit {
  step1modal: HotelInfo = new HotelInfo();
  correspondenceDetail: CorrespondenceDetail = new CorrespondenceDetail();
  contactDetail: ContactDetail = new ContactDetail();
  validationmsg = message.requiredmsg;
  coorespondenceStatus: boolean = true;
  operationalStatus: boolean = true;
  enableProvince: boolean = true;
  enableCity: boolean = true;
  errorStatus: boolean = true;
  viewmode: boolean = false;
  provinceList: any;
  countryList: any;
  cityList: any;
  timezoneList: any;
  backHotelId: any;
  isHovering: boolean;
  toggleHover(event: boolean) {
    this.isHovering = event;
  }
  hotelId: any;
  hotelnamePattern: string = '.*[^0-9].*';
  onlynumberpattern: string = '^-?(0|[1-9]d*)?$';
  telephonenumbervalidation = '^(\\+)?[0-9]{6,15}$';
  contactnumbervalidation = '^(\\+)?[0-9]{10,15}$';
  mobNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$';
  emailpatternvalidation: string = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  namepatternvalidation: string = '';
  websitepatternvalidation: string =
    '^(((http|https)://)?[0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?';
  primaryPincode: any;
  correspondPincode: any;
  public urlSaved: any = [];
  @Input() propertyDetails: any = {};
  @Output() changeNextTab: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('closeBtnConfirm') closeBtnConfirm: ElementRef;
  correspondenceProvinceList: any;
  correspondenceCityList: any;
  imgeList: any = [];
  toaster = this.injector.get(ToastrService);

  public latitude: number;
  public longitude: number;
  public zoom: number = 10;
  public map: google.maps.Map;
  debounceTime = new Subject<string>();
  primaryPhoneCode: any;
  correspondePhoneCode: any;
  queryParameter: any;
  mainImageObject: any;
  mainPreview: any;
  thumbnailImageObject: any;
  thumbnailPreview: any;


  constructor(
    public mastersService: MastersService,
    private injector: Injector,
    public propertyService: PropertyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public storage: AngularFireStorage,
    private spinner: NgxSpinnerService,
    private ngZone: NgZone,
  ) {
    this.propertyService.reloadPrevTab.subscribe(res => {
      if (res) {
        this.ngOnInit();
      }
    })
  }

  ngOnInit(): void {
    this.step1modal.isCorrespondanceDetail = true;
    this.step1modal.isContactDetail = false;
    this.getCountry();
    this.getTimezone();
    this.intialSetData();
    this.getAddress();
    this.openLightBox();
  }

  intialSetData() {
    if (
      Object.keys(this.propertyDetails).length > 0 &&
      this.propertyDetails &&
      this.propertyDetails.propertyId &&
      this.propertyDetails.mode
    ) {
      this.mode = this.propertyDetails.mode;
      this.backHotelId = this.propertyDetails.propertyId;
      this.setdata(this.propertyDetails);
      if (this.propertyDetails.mode === 'view') {
        this.viewmode = true;
      }
    } else {
      if (history.state.data) {
        let hid = history.state.data;
        if (hid) {
          this.backHotelId = hid;
          this.getStep1PropertyDetails(hid);
        }
      }
    }
    if (this.activatedRoute.snapshot.queryParams.inventory_allocation) {
      this.queryParameter = {
        queryParams: {
          inventory_allocation: this.activatedRoute.snapshot.queryParams.inventory_allocation
        }
      }
    }
  }

  getCountry() {
    this.spinner.show();
    this.mastersService.getCountry().subscribe((data) => {
      if (data['success']) {
        this.countryList = data['data'];
        if (this.step1modal.country) {
          const country = this.filterISDCode(this.step1modal.country);
          this.primaryPincode = this.filterPincode(country);
          this.step1modal.dial_code = country?.isd_code;
        } else {
          const country = this.filterISDCode(this.correspondenceDetail.country);
          this.correspondPincode = this.filterPincode(country);
          this.correspondenceDetail.dial_code = country?.isd_code;
        }
      }
      this.spinner.hide();
    });
  }

  getTimezone(countryId?: string) {
    this.spinner.show();
    this.mastersService.getTimezone(countryId).subscribe((data) => {
      if (data['success']) {
        this.timezoneList = data['data'];
      }
      this.spinner.hide();
    });
  }

  change(val) {
    this.step1modal.email = val && val.toLowerCase();
  }

  filterPincode(country: any) {
    return Pincode.filter(items => items?.country == country?.name)[0];
  }

  filterISDCode(countryId: any) {
    return this.countryList.filter(item => item.id == countryId)[0];
  }

  getProvinceById(id, type?, onchange?) {
    if (onchange && type) {
      const country = this.filterISDCode(id);
      const pincode = this.filterPincode(country);
      this.correspondenceDetail.province = '';
      this.correspondenceDetail.city = '';
      this.correspondenceDetail.dial_code = country.isd_code;
      this.correspondPincode = pincode;
    } else if (onchange) {
      this.step1modal.city = '';
      this.step1modal.province = '';
      const country = this.filterISDCode(id);
      const pincode = this.filterPincode(country);
      this.step1modal.dial_code = country.isd_code;
      this.primaryPincode = pincode;
    }
    if (id) {
      this.spinner.show();
      this.mastersService.getProvinceById(id).subscribe((data) => {
        if (data['success']) {
          if (!type) {
            this.provinceList = data['data'];
          } else {
            this.correspondenceProvinceList = data['data'];
          }
        }
        this.spinner.hide();
      });
    } else {
      if (!type) {
        this.step1modal.city = '';
        this.step1modal.province = '';
        this.provinceList = null;
        this.cityList = null;
      } else {
        this.correspondenceProvinceList = null;
        this.correspondenceCityList = null;
        this.correspondenceDetail.province = '';
        this.correspondenceDetail.city = '';
      }
    }
  }

  getCityById(id, type?, onchange?) {
    if (onchange && type) {
      this.correspondenceDetail.city = '';
    } else if (onchange) {
      this.step1modal.city = '';
    }
    if (id) {
      this.spinner.show();
      this.mastersService.getCityById(id).subscribe((data) => {
        if (data['success']) {
          if (!type) {
            this.cityList = data['data'];
          } else {
            this.correspondenceCityList = data['data'];
          }
        }
        this.spinner.hide();
      });
    } else {
      if (!type) {
        this.step1modal.city = '';
        this.cityList = null;
      } else {
        this.correspondenceCityList = null;
        this.correspondenceDetail.city = '';
      }
    }
  }

  getStep1PropertyDetails(id) {
    this.spinner.show();
    this.propertyService
      .getHotelPropertyDetailsStep1(id.trim())
      .subscribe((res) => {
        this.setdata(res['data']);
        this.spinner.hide();
      });
  }

  setdata(data) {
    this.getLatLongOfLocation(data);
    data.pincode = data.pincode == '0' ? '000000' : data.pincode;
    this.step1modal = {
      id: data.id,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      city: data.city,
      communication_address: data.communication_address,
      contactDetail: data.contactDetail,
      contact_num: data.contact_num,
      contact_person_firstname: data.contact_person_firstname,
      contact_person_lastname: data.contact_person_lastname,
      correspondanceDetail: data.correspondanceDetail,
      country: data.country,
      dial_code: data.dial_code,
      email: data.email,
      isContactDetail: data.isContactDetail,
      isCorrespondanceDetail: data.isCorrespondanceDetail,
      name: data.name,
      pincode: data.pincode,
      propertyId: data.propertyId,
      province: data.province,
      main_image: data.main_image,
      thumbnail_image: data.thumbnail_image,
      star_ratings: data.star_ratings,
      telephone_num: data.telephone_num,
      timezone: data.timezone,
      title: data.title,
      website_link: data.website_link,
      images: data.images
    };
    this.mainPreview = data.main_image;
    this.thumbnailPreview = data.thumbnail_image;
    if (this.step1modal.star_ratings && this.step1modal.star_ratings.includes('*')) {
      this.step1modal.star_ratings = this.step1modal.star_ratings.split(' ')[1].length;
    }
    if (this.step1modal.correspondanceDetail.length > 0) {
      this.correspondenceDetail = this.step1modal.correspondanceDetail[0];
      this.correspondenceDetail.pincode = this.correspondenceDetail.pincode == '0' ? '000000' : this.correspondenceDetail.pincode;
      this.coorespondenceStatus = false;
      setTimeout(() => {
        this.getProvinceById(this.correspondenceDetail.country, 'coorespondenceStatus');
        this.getCityById(this.correspondenceDetail.province, 'coorespondenceStatus');
      }, 500);
    } else {
      this.coorespondenceStatus = true;
    }

    if (this.step1modal.contactDetail.length > 0) {
      this.contactDetail = this.step1modal.contactDetail[0];
      this.operationalStatus = false;
    } else {
      this.operationalStatus = true;
    }
    this.enableProvince = false;
    this.enableCity = false;
    this.urls = this.step1modal.images;
    var list = data.images;

    this.imgeList = [];
    list.forEach((element) => {
      this.imgeList.push(element);
    });
    this.uploadedImg = this.step1modal.images;
    this.getProvinceById(this.step1modal.country);
    this.getCityById(this.step1modal.province);
  }

  save() {
    this.setDataOnSave()
    this.step1modal.images = this.urls;
    this.step1modal.communication_address = true;
    this.step1modal.latitude = this.latitude;
    this.step1modal.longitude = this.longitude;
    if (!this.backHotelId) {
      this.spinner.show();
      this.propertyService
        .addPropertyStep1(this.step1modal, this.mainImageObject, this.thumbnailImageObject)
        .subscribe((data) => {
          if (data['success']) {
            this.hotelId = data['data']['id'];
            this.toaster.success(data['message'], 'Success', {
              timeOut: 3000,
            });
            this.router.navigate([
              '/property/hotel-overview/' + this.hotelId,
            ]);
            this.step1modal.contactDetail = null;
          } else {
            this.toaster.error(data['message'], 'Error', {
              timeOut: 3000,
            });
          }

          this.spinner.hide();
        });
    } else {
      this.step1modal.images = this.urls;

      this.spinner.show();
      this.propertyService
        .updatePropertyStep1(this.step1modal, this.backHotelId, this.mainImageObject, this.thumbnailImageObject)
        .subscribe((data) => {
          if (data['success']) {
            this.hotelId = this.backHotelId;
            this.toaster.success(data['message'], 'Success', {
              timeOut: 3000,
            });
            if (this.mode && this.mode === 'edit') {
              this.changeNextTab.emit('hotel-overview');
            } else {
              this.router.navigate([
                '/property/hotel-overview/' + this.hotelId,
              ]);
            }
          } else {
            this.toaster.success(data['message'], 'Error', {
              timeOut: 3000,
            });
          }
          this.spinner.hide();
        });
    }
  }

  setDataOnSave() {
    this.step1modal.pincode = String(this.step1modal.pincode);
    if (this.step1modal.name) {
      this.step1modal.name = this.step1modal.name.trim();
    }
    if (this.step1modal.contact_person_firstname) {
      this.step1modal.contact_person_firstname = this.step1modal.contact_person_firstname.trim();
    }
    if (this.step1modal.contact_person_lastname) {
      this.step1modal.contact_person_lastname = this.step1modal.contact_person_lastname.trim();
    }
    if (this.step1modal.addressLine1) {
      this.step1modal.addressLine1 = this.step1modal.addressLine1.trim();
    }
    if (this.step1modal.addressLine1) {
      this.step1modal.addressLine1 = this.step1modal.addressLine1.trim();
    }
    if (this.step1modal.addressLine2) {
      this.step1modal.addressLine2 = this.step1modal.addressLine2.trim();
    }
    if (this.correspondenceDetail.firstname) {
      this.correspondenceDetail.firstname = this.correspondenceDetail.firstname.trim();
    }
    if (this.correspondenceDetail.lastname) {
      this.correspondenceDetail.lastname = this.correspondenceDetail.lastname.trim();
    }
    if (this.correspondenceDetail.addressLine1) {
      this.correspondenceDetail.addressLine1 = this.correspondenceDetail.addressLine1.trim();
    }
    if (this.correspondenceDetail.addressLine2) {
      this.correspondenceDetail.addressLine2 = this.correspondenceDetail.addressLine2.trim();
    }
    if (this.contactDetail.firstname) {
      this.contactDetail.firstname = this.contactDetail.firstname.trim();
    }
    if (this.contactDetail.lastname) {
      this.contactDetail.lastname = this.contactDetail.lastname.trim();
    }

    if (this.step1modal.isContactDetail) {
      this.step1modal.contactDetail = [this.contactDetail];
    }
    if (!this.step1modal.isCorrespondanceDetail) {
      this.correspondenceDetail.pincode = String(this.correspondenceDetail.pincode);
      this.step1modal.correspondanceDetail = [this.correspondenceDetail];
    }
  }

  goToNextPage(): void {
    if (this.mode && this.mode === 'edit') {
      this.changeNextTab.emit('hotel-overview');
    } else {
      this.router.navigate(['property', 'hotel-overview', this.backHotelId], this.queryParameter);
    }
  }

  // upload File
  sameNameFiles: File[] = [];
  files: File[] = [];
  public mode: string;
  public urls: any = [];
  public uploadedImg: any = [];
  public openImageModal = false;
  public imagesArr: any = [];
  public currentImageIndex: number;

  @ViewChild('myinput') myInputVariable: ElementRef;
  onDrop(files: FileList) {
    var list = this.imgeList;
    let status = false;
    for (let i = 0; i < files.length; i++) {
      const extention = files[i].type.split('/')[1];
      if (extention === 'jpg' || extention === 'jpeg' || extention === 'png' || extention === 'mp4' || extention === 'm4v') {
        if (this.files.length > 0) {
          let index = this.files.findIndex(
            (item) => item.name === files.item(i).name
          );
          let indexUrl = this.urls.findIndex(
            (item) =>
              this.storage.storage.refFromURL(item).name.substring(14) ===
              files.item(i).name
          );
          var imagelistindex = this.imgeList.findIndex(
            (item) =>
              this.storage.storage.refFromURL(item).name.substring(14) ===
              files.item(i).name
          );
          if (index > -1) {
            if (indexUrl == -1) {
              this.files.splice(index, 1);
              index = this.files.findIndex(
                (item) => item.name === files.item(i).name
              );
            }
          }
          if (imagelistindex > -1) {
            this.sameNameFiles.push(files.item(i));
          }
          if (index !== -1) {
            this.sameNameFiles.push(files.item(i));
          } else {
            if (this.sameNameFiles.length == 0) this.files.push(files.item(i));
          }
        } else {
          var imagelistindex = this.imgeList.findIndex(
            (item) =>
              this.storage.storage.refFromURL(item).name.substring(14) ===
              files.item(i).name
          );
          if (imagelistindex > -1) {
            this.sameNameFiles.push(files.item(i));
          } else this.files.push(files.item(i));
        }
        if (i === files.length - 1 && this.sameNameFiles.length > 0) {
          status = true;
        }
      } else {
        this.toaster.error('Only jpg, jpeg, png, mp4, m4v formats allowed', 'Error', {
          timeOut: 3000,
        });
      }
      if (this.sameNameFiles.length > 0 && status) {
        $('#confirmModalButton').click();
        status = false;
        setTimeout(() => {
          if (this.mode && this.mode === 'edit') {
            $('.modal-backdrop').remove();
          }
        }, 500);
      }
    }
    this.imgeList = list;
  }

  onImgUpload(url) {
    this.urls.push(url);
    this.myInputVariable.nativeElement.value = '';
    if (this.urls.length > 0) {
      this.imagesArr = [...this.urls];
    }
  }

  onImgDelete(str) {
    if (this.urls.indexOf(str) !== -1) {
      let indexUrl = this.urls.indexOf(str);
      this.urls.splice(indexUrl, 1);
      if (this.imagesArr.length > 0) {
        this.imagesArr = this.imagesArr.filter(items => items !== str);
      }
    }
  }

  removeImg(p) {
    this.urls.splice(p, 1);
    this.imgeList.splice(p, 1);
  }

  openImageAsModal(index: number): void {
    this.currentImageIndex = index;
    this.openImageModal = true;
    if (this.uploadedImg.length > 0) {
      this.imagesArr = [...this.uploadedImg];
    }
  }

  openLightBox() {
    this.propertyService.openLightBox.subscribe(res => {
      if (res) {
        for (let i = 0; i < this.imagesArr.length; i++) {
          if (this.imagesArr[i] == res) {
            this.openImageAsModal(i);
          }
        }
      }
    });
  }

  closeImageModal(event) {
    this.openImageModal = false;
  }

  yesUncheck() {
    for (let i = 0; i < this.sameNameFiles.length; i++) {
      this.files.push(this.sameNameFiles[i]);
    }
    this.closeBtnConfirm.nativeElement.click();
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    this.sameNameFiles = [];
  }

  noUncheck() {
    this.closeBtnConfirm.nativeElement.click();
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    this.sameNameFiles = [];
    this.myInputVariable.nativeElement.value = '';
  }

  getLatLongOfLocation(data) {
    if (data.addressLine1) {
      this.propertyService.getLatLong(data.addressLine1).subscribe((res: any) => {
        this.latitude = res?.results[0]?.geometry?.location?.lat;
        this.longitude = res?.results[0]?.geometry?.location?.lng;
      });
    }
  }

  markerDragEnd(event: any) {
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    const geocoder: any = new google.maps.Geocoder();
    const latlng = new google.maps.LatLng(this.latitude, this.longitude);
    const request = {
      latLng: latlng
    };

    geocoder.geocode(request, (results, status) => {
      this.ngZone.run(() => {
        this.step1modal.addressLine1 = results[0]?.formatted_address?.replace(/\b(Unnamed Road, )\b/gi, '');
        const pincode = results[0]?.address_components;
        if (pincode[pincode.length - 1].types[0] === 'postal_code') {
          this.step1modal.pincode = pincode[pincode.length - 1].long_name;
        }
      })
    });
  }

  getAddress() {
    this.debounceTime.pipe(debounceTime(400), distinctUntilChanged())
      .subscribe(value => {
        const data = { addressLine1: value }
        this.getLatLongOfLocation(data);
      });
  }

  selectImages(event: any, type: string) {
    const extentions = event.target.files[0].type.split('/')[1];
    if (extentions === 'jpg' || extentions === 'jpeg' || extentions === 'png' || extentions === 'mp4' || extentions === 'm4v') {
      let reader = new FileReader();
      const img: any = new Image();
      if (type === 'main') {
        this.mainImageObject = event.target.files[0];
        reader.readAsDataURL(this.mainImageObject);
        reader.onload = (data: any) => {
          img.src = data.target.result;
          img.onload = () => {
            // if (img.width !== 1920 && img.height !== 1080) {
            //   this.toaster.error('Image should be 1920 X 1080 dimension', 'Error', {
            //     timeOut: 3000,
            //   });
            //   this.mainImageObject = null;
            //   return false;
            // }
            this.mainPreview = data.target.result;
          }
        };
      } else {
        this.thumbnailImageObject = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(this.thumbnailImageObject);
        reader.onload = (data: any) => {
          img.src = data.target.result;
          img.onload = () => {
            // if (img.width !== 1200 && img.height !== 800) {
            //   this.toaster.error('Image should be 1200 X 800 dimension', 'Error', {
            //     timeOut: 3000,
            //   });
            //   this.thumbnailImageObject = null;
            //   return false;
            // }
            this.thumbnailPreview = data.target.result;
          }
        };
      }
    } else {
      this.toaster.error('Only jpg, jpeg, png, mp4, m4v formats allowed', 'Error', {
        timeOut: 3000,
      });
    }
  }
}
