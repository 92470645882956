<div class="page-bar" *ngIf="!mode">
    <div class="page-title-breadcrumb">
        <div class=" pull-left">
            <div class="page-title">Step 1 : Basic Property Information</div>
        </div>
        <ol class="breadcrumb page-breadcrumb pull-right">
            <li>
                <a class="parent-item" href="/"><i class="fa fa-home"></i>&nbsp;Home</a>
                &nbsp;<i class="fa fa-angle-right"></i>
            </li>
            <li class="active">Basic Property Information</li>
        </ol>
    </div>
</div>
<div class="row">
    <div class="col-sm-12">
        <form #f="ngForm" (ngSubmit)="f.form.valid && save()">
            <fieldset [disabled]="viewmode">
                <div class="card-box" *ngIf="!mode">
                    <!-- || mode !== 'view' || mode !== 'edit' -->
                    <div class="card-head">
                        <header>Progress Bar </header>
                    </div>
                    <div class="card-body ">
                        <div class="progressbar-xs progress-rounded progress-striped progress ng-isolate-scope active">
                            <div class="progress-bar progress-bar-success width-25" role="progressbar"
                                aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
                <div class="card-box">
                    <div class="card-head">
                        <header>Hotel Details</header>
                    </div>
                    <div class="card-body ">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="form_label text-red">Hotel Name </label>
                                    <input [(ngModel)]="step1modal.name" class="form-control" name="Name"
                                        #Name="ngModel" [pattern]="hotelnamePattern" type="text" required>
                                    <span *ngIf="Name.errors?.required && (Name.dirty || Name.touched) "
                                        class="text-red">Please enter hotel name</span>
                                    <span *ngIf="Name.errors?.pattern &&  mode!='view'" class="text-red">Please enter
                                        valid hotel name
                                    </span>
                                </div>

                            </div>
                            <!-- <div class="col-md-6">
              <div class="form-group">
                <label class="form_label">Member Name</label>
                <select class="form-control" [(ngModel)]="member_name" (change)="changeMember($event)">
                  <option value=''>Select member</option>
                  <option *ngFor="let item of City" value=" {{item}}">
                    {{item}}
                  </option>
                </select>
              </div>
            </div> -->
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form_label text-red">Add Line 1</label>
                                    <input (ngModelChange)="debounceTime.next($event)"
                                        [(ngModel)]="step1modal.addressLine1" name="addressLine1"
                                        #addressLine1="ngModel" class="form-control" type="text" required>
                                    <span
                                        *ngIf="addressLine1.errors?.required && (addressLine1.dirty || addressLine1.touched)"
                                        class="text-red">Please enter address line 1</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form_label">Add Line 2</label>
                                    <input [(ngModel)]="step1modal.addressLine2" name="addressLine2"
                                        #addressLine2="ngModel" class="form-control" type="text">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form_label text-red">Time Zone</label>
                                    <select class="form-control" [(ngModel)]="step1modal.timezone" name="timezone"
                                        #timezone="ngModel" required id="timezone">
                                        <option value="">Select Timezone</option>
                                        <option *ngFor="let item of timezoneList" [value]="item.id">
                                            {{item.timezone}}
                                        </option>
                                    </select>
                                    <span *ngIf="timezone.errors?.required && (timezone.dirty || timezone.touched)"
                                        class="text-red">Please select timezone</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form_label text-red">Country</label>
                                    <select class="form-control" [(ngModel)]="step1modal.country" required
                                        name="country" #country="ngModel"
                                        (change)="getProvinceById($event.target.value, '', 'onchange');enableProvince=false">
                                        <option value="">Select Country</option>
                                        <option *ngFor="let item of countryList" value="{{item.id}}">
                                            {{item.name}}
                                        </option>
                                    </select>
                                    <span *ngIf="country.errors?.required && (country.dirty || country.touched)"
                                        class="text-red">Please select country</span>
                                </div>
                            </div>


                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form_label text-red">Province</label>
                                    <select id="province" class="form-control" [disabled]="enableProvince" required
                                        [(ngModel)]="step1modal.province" name="province" #province="ngModel"
                                        (change)="getCityById($event.target.value, '', 'onchange');enableCity=false">
                                        <option value="">Select Province</option>
                                        <option *ngFor="let item of provinceList" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>
                                    <span *ngIf="province.errors?.required && (province.dirty || province.touched)"
                                        class="text-red">Please select province</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form_label text-red">City</label>
                                    <select id="city" class="form-control" [(ngModel)]="step1modal.city" required
                                        [disabled]="enableCity" name="city" #city="ngModel">
                                        <option value="">Select City</option>
                                        <option *ngFor="let item of cityList" value="{{item.id}}">
                                            {{item.name}}
                                        </option>
                                    </select>
                                    <span *ngIf="city.errors?.required && (city.dirty || city.touched)"
                                        class="text-red">Please select
                                        city</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <!-- [pattern]="primaryPincode?.regex"  minlength="4" maxlength="10"-->
                                    <label class="form_label text-red">Pincode/Zipcode/Postcode</label>
                                    <input type="text" [(ngModel)]="step1modal.pincode"
                                       name="pincode" #pincode="ngModel" required
                                        class="form-control">
                                    <span *ngIf="pincode.errors?.required && (pincode.dirty || pincode.touched)"
                                        class="text-red">Please enter pincode
                                    </span>
                                    <!-- <span *ngIf="pincode.errors?.pattern &&  mode!='view'" class="text-red">
                                        Please enter valid pincode (ex: {{primaryPincode?.code}})
                                    </span> -->
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form_label text-red">Telephone Number</label>
                                    <input [(ngModel)]="step1modal.telephone_num" [pattern]="telephonenumbervalidation"
                                        required name="telephone_num" #telephone_num="ngModel" class="form-control"
                                        maxlength="15" min="0" type="text">
                                    <span
                                        *ngIf="telephone_num.errors?.required  && (telephone_num.dirty || telephone_num.touched)"
                                        class="text-red">Please enter telephone number
                                    </span>
                                    <span *ngIf="telephone_num.errors?.pattern  &&  mode!='view'"
                                        class="text-red">Please enter valid telephone number</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form_label">Mobile Number</label>
                                    <label *ngIf="step1modal?.dial_code"
                                        class="form_label phone-code">+{{step1modal.dial_code}}</label>
                                    <input [(ngModel)]="step1modal.contact_num" name="contact_num"
                                        #contact_num="ngModel" [pattern]="contactnumbervalidation"
                                        class="phone-code-border form-control" maxlength="15" min="0" type="text">
                                    <span *ngIf="contact_num.errors?.pattern &&  mode!='view'" class="text-red">Please
                                        enter valid mobile number</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form_label text-red">Email ID</label>
                                    <input [(ngModel)]="step1modal.email" required (keyup)="change(email.value)"
                                        name="email" #email="ngModel" [pattern]="emailpatternvalidation"
                                        class="form-control" type="text">
                                    <span *ngIf="email.errors?.required && (email.dirty || email.touched)"
                                        class="text-red">Please enter email
                                    </span>
                                    <span *ngIf="email.errors?.pattern &&  mode!='view'" class="text-red">Please enter
                                        valid email
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form_label">First Name</label>
                                    <input [(ngModel)]="step1modal.contact_person_firstname"
                                        name="contact_person_firstname" #contact_person_firstname="ngModel"
                                        class="form-control" type="text">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form_label">Last Name</label>
                                    <input [(ngModel)]="step1modal.contact_person_lastname"
                                        name="contact_person_lastname" #contact_person_lastname="ngModel"
                                        class="form-control" type="text">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form_label">Website</label>
                                    <input [(ngModel)]="step1modal.website_link" name="website_link"
                                        #website_link="ngModel" [pattern]="websitepatternvalidation"
                                        class="form-control" type="url">
                                    <span *ngIf="website_link.errors?.pattern &&  mode!='view'" class="text-red">Please
                                        enter valid website
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form_label">Title</label>
                                    <input [(ngModel)]="step1modal.title" name="title" #title="ngModel"
                                        class="form-control" type="text">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form_label">Star Ratings</label>
                                    <ngx-star-rating class="d-flex" [(ngModel)]="step1modal.star_ratings"
                                        name="star_ratings" #star_ratings="ngModel">
                                    </ngx-star-rating>
                                </div>
                            </div>
                        </div>

                        <div class="row m-1 mb-2">
                            <agm-map [scrollwheel]="false" [gestureHandling]="'cooperative'" [latitude]="latitude"
                                [longitude]="longitude" [zoom]="zoom">
                                <agm-marker [latitude]="latitude" [longitude]="longitude"
                                    (dragEnd)="markerDragEnd($event)" [markerDraggable]="mode!='view'">
                                </agm-marker>
                            </agm-map>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <div class="checkbox checkbox-black d-inline-block ml-4">
                                        <input id="checkboxbg6" type="checkbox"
                                            (change)="coorespondenceStatus = !coorespondenceStatus"
                                            [(ngModel)]="step1modal.isCorrespondanceDetail"
                                            name="isCorrespondanceDetail" #isCorrespondanceDetail="ngModel">
                                        <label class="form_label" for="checkboxbg6">
                                            Same for Correspondence
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div [hidden]="step1modal.isCorrespondanceDetail">
                        <div class="card-head">
                            <header>Correspondence Details</header>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form_label  text-red">Add Line 1</label>
                                        <input [(ngModel)]="correspondenceDetail.addressLine1"
                                            name="correspondenceAddressLine1" #correspondenceAddressLine1="ngModel"
                                            class="form-control" type="text"
                                            [required]="!step1modal.isCorrespondanceDetail">
                                        <span
                                            *ngIf="correspondenceAddressLine1.errors?.required && (correspondenceAddressLine1.dirty || correspondenceAddressLine1.touched)"
                                            class="text-red">Please enter address line 1
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form_label">Add Line 2</label>
                                        <input [(ngModel)]="correspondenceDetail.addressLine2"
                                            name="correspondenceAddressLine2" #correspondenceAddressLine2="ngModel"
                                            class="form-control" type="text">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="form_label text-red">Country</label>
                                        <select class="form-control" [(ngModel)]="correspondenceDetail.country"
                                            name="correspondenceCountry" #correspondenceCountry="ngModel"
                                            [required]="!step1modal.isCorrespondanceDetail"
                                            (change)="getProvinceById($event.target.value, 'coorespondenceStatus', 'onchange');enableProvince=false">
                                            <option value=''>Select country</option>
                                            <option *ngFor="let item of countryList" value="{{item.id}}">
                                                {{item.name}}
                                            </option>
                                        </select>
                                        <span
                                            *ngIf="correspondenceCountry.errors?.required && (correspondenceCountry.dirty || correspondenceCountry.touched)"
                                            class="text-red">Please enter country
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="form_label text-red">Province</label>
                                        <select class="form-control" [(ngModel)]="correspondenceDetail.province"
                                            name="correspondenceProvince" #correspondenceProvince="ngModel"
                                            [required]="!step1modal.isCorrespondanceDetail"
                                            (change)="getCityById($event.target.value, 'coorespondenceStatus', 'onchange');enableCity=false">
                                            <option value=''>Select province</option>
                                            <option *ngFor="let item of correspondenceProvinceList" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                        <span
                                            *ngIf="correspondenceProvince.errors?.required && (correspondenceProvince.dirty || correspondenceProvince.touched)"
                                            class="text-red">Please enter province
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="form_label text-red">City</label>
                                        <select class="form-control" [(ngModel)]="correspondenceDetail.city"
                                            name="correspondenceCity" #correspondenceCity="ngModel"
                                            [required]="!step1modal.isCorrespondanceDetail">
                                            <option value=''>Select city</option>
                                            <option *ngFor="let item of correspondenceCityList" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                        <span
                                            *ngIf="correspondenceCity.errors?.required && (correspondenceCity.dirty || correspondenceCity.touched)"
                                            class="text-red">Please enter city
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form_label text-red">Pincode/Zipcode/Postcode</label>
                                        <input type="text" [(ngModel)]="correspondenceDetail.pincode"
                                            name="correspondencePincode" [pattern]="correspondPincode?.regex"
                                            #correspondencePincode="ngModel"
                                            [required]="!step1modal.isCorrespondanceDetail" class="form-control"
                                            minlength="4" maxlength="10">
                                        <span
                                            *ngIf="correspondencePincode.errors?.required && (correspondencePincode.dirty || correspondencePincode.touched)"
                                            class="text-red">Please enter pincode
                                        </span>
                                        <span *ngIf="correspondencePincode.errors?.pattern &&  mode!='view'"
                                            class="text-red">
                                            Please enter valid pincode (ex: {{correspondPincode?.code}})
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form_label">Mobile Number</label>
                                        <label *ngIf="correspondenceDetail?.dial_code"
                                            class="form_label phone-code">+{{correspondenceDetail.dial_code}}</label>
                                        <input [(ngModel)]="correspondenceDetail.contact_num"
                                            name="correspondenceContactnum" #correspondenceContactnum="ngModel"
                                            [pattern]="contactnumbervalidation" maxlength="15" min="0"
                                            class="form-control phone-code-border" type="text">
                                        <span *ngIf="correspondenceContactnum.errors?.pattern &&  mode!='view'"
                                            class="text-red">Please enter valid mobile number</span>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="  ">First Name</label>
                                        <input [(ngModel)]="correspondenceDetail.firstname" name="correspondenceFname"
                                            #correspondenceFname="ngModel"
                                            [required]="!step1modal.isCorrespondanceDetail" class="form-control"
                                            type="text">
                                        <span
                                            *ngIf="correspondenceFname.errors?.required && (correspondenceFname.dirty || correspondenceFname.touched)"
                                            class="text-red">Please enter first name
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="form_label text-red">Last Name</label>
                                        <input [(ngModel)]="correspondenceDetail.lastname" name="correspondenceLname"
                                            #correspondenceLname="ngModel"
                                            [required]="!step1modal.isCorrespondanceDetail" class="form-control"
                                            type="text">
                                        <span
                                            *ngIf="correspondenceLname.errors?.required && (correspondenceLname.dirty || correspondenceLname.touched)"
                                            class="text-red">Please enter last name
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="form_label text-red">Email ID</label>
                                        <input [(ngModel)]="correspondenceDetail.email" name="correspondenceEmail"
                                            #correspondenceEmail="ngModel" [pattern]="emailpatternvalidation"
                                            class="form-control" type="text"
                                            [required]="!step1modal.isCorrespondanceDetail">
                                        <span
                                            *ngIf="correspondenceEmail.errors?.required && (correspondenceEmail.dirty || correspondenceEmail.touched)"
                                            class="text-red">Please enter email
                                        </span>
                                        <span *ngIf="correspondenceEmail.errors?.pattern &&  mode!='view'"
                                            class="text-red">Please enter valid email
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="card-body" id="ch">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <div class="checkbox checkbox-black d-inline-block ml-4">
                                        <input id="checkboxoperational" type="checkbox"
                                            (change)="operationalStatus= !operationalStatus"
                                            [(ngModel)]="step1modal.isContactDetail" name="isContactDetail"
                                            #isContactDetail="ngModel">
                                        <label class="form_label" for="checkboxoperational">
                                            Operational Contact Details [Legal and Admin]
                                        </label>
                                        <span class="font-weight-bold pl-3"
                                            *ngIf="mode && mode === 'view' && operationalStatus">- No Data
                                            Available</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" id="cb" [hidden]="!step1modal.isContactDetail">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form_label text-red">First Name</label>
                                    <input [(ngModel)]="contactDetail.firstname" name="contactFname"
                                        #contactFname="ngModel" [required]="!operationalStatus" class="form-control"
                                        type="text">
                                    <span
                                        *ngIf="contactFname.errors?.required && (contactFname.dirty || contactFname.touched)"
                                        class="text-red">Please enter first name
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form_label text-red">Last Name</label>
                                    <input [(ngModel)]="contactDetail.lastname" name="contactLname"
                                        #contactLname="ngModel" [required]="!operationalStatus" class="form-control"
                                        type="text">
                                    <span
                                        *ngIf="contactLname.errors?.required  && (contactLname.dirty || contactLname.touched)"
                                        class="text-red">Please enter last name
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form_label text-red">E-Mail</label>
                                    <input [(ngModel)]="contactDetail.email" name="contactEmail" #contactEmail="ngModel"
                                        [pattern]="emailpatternvalidation" class="form-control" type="text"
                                        [required]="!operationalStatus">
                                    <span
                                        *ngIf="contactEmail.errors?.required  && (contactEmail.dirty || contactEmail.touched)"
                                        class="text-red">Please enter email
                                    </span>
                                    <span *ngIf="contactEmail.errors?.pattern &&  mode!='view'" class="text-red">Please
                                        enter valid email
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form_label text-red">Mobile Number</label>
                                    <input [(ngModel)]="contactDetail.mobile_num" maxlength="15" min="0"
                                        name="contactMobile" #contactMobile="ngModel"
                                        [pattern]="contactnumbervalidation" class="form-control" type="text"
                                        [required]="!operationalStatus">
                                    <span
                                        *ngIf="contactMobile.errors?.required  && (contactMobile.dirty || contactMobile.touched)"
                                        class="text-red">Please enter mobile number
                                    </span>
                                    <span *ngIf="contactMobile.errors?.pattern &&  mode!='view'" class="text-red">Please
                                        enter valid mobile number</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form_label">Telephone</label>
                                    <input [(ngModel)]="contactDetail.telephone_num" maxlength="15" min="0"
                                        name="contactTelephone" #contactTelephone="ngModel"
                                        [pattern]="contactnumbervalidation" class="form-control" type="text">
                                    <span *ngIf="contactTelephone.errors?.pattern &&  mode!='view'"
                                        class="text-red">Please enter valid telephone</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form_label">Skype ID</label>
                                    <input [(ngModel)]="contactDetail.fax_num" name="contactFax" #contactFax="ngModel"
                                        class="form-control" type="text">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form_label">Website</label>
                                    <input [(ngModel)]="contactDetail.website_link" name="contactWebsite"
                                        #contactWebsite="ngModel" [pattern]="websitepatternvalidation"
                                        class="form-control" type="text">
                                    <span *ngIf="contactWebsite.errors?.pattern &&  mode!='view'"
                                        class="text-red">Please enter website
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ml-3">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label class="form_label text-red pb-1">Main Image
                                    <!-- (Required Dimension is 1920 x 1080) -->
                                </label><br />
                                <input type="file" (change)="selectImages($event, 'main')" /><br />
                                <img *ngIf="mainPreview" [src]="mainPreview" class="image-preview" />
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label class="form_label text-red pb-1">Thumbnail Image
                                    <!-- (Required Dimension is 1200 x 800) -->
                                </label><br />
                                <input type="file" (change)="selectImages($event, 'thumbnail')" /><br />
                                <img *ngIf="thumbnailPreview" [src]="thumbnailPreview" class="image-preview" />
                            </div>
                        </div>
                    </div>
                    <div class="card-head">
                        <header>Media Gallery</header>
                    </div>
                    <div class="card-box" *ngIf=" !mode || mode !== 'view'">
                        <div class="card-body row">
                            <div class="col-lg-12 p-t-20">
                                <div appDropzone class="dropzone" dropzone (hovered)="toggleHover($event)"
                                    (dropped)="onDrop($event)" [class.hovering]="isHovering">
                                    <!-- <h3>AngularFire Drop Zone</h3> -->
                                    <p>Drag and Drop Image / Video inside the Box</p>
                                    <div class="file">
                                        <label class="file-label">
                                            <input #myinput class="file-input" type="file" multiple="multiple"
                                                (change)="onDrop($event.target.files)">
                                            <span class="file-cta"></span>
                                        </label>
                                    </div>
                                </div>
                                <div *ngIf="mode=='edit'"></div>
                                <div class="" *ngFor="let file of files">
                                    <app-upload-task [file]="file" [allImages]="uploadedImg"
                                        (imgUrl)="onImgUpload($event)" (removeImg)="onImgDelete($event)">1
                                    </app-upload-task>

                                </div>
                                <div *ngIf="backHotelId && mode!='edit'">
                                    <div *ngIf="imgeList.length > 0">
                                        <div class="row p-3 border-bottom" *ngFor="let item of imgeList;let i=index;">
                                            <div class="col-md-5">
                                                <span
                                                    style="overflow-wrap: break-word">{{this.storage.storage.refFromURL(item).name}}</span>
                                            </div>

                                            <ng-container [ngTemplateOutlet]="showImgVideo" [ngTemplateOutletContext]="{
                                                    url: storage.storage.refFromURL(item).name, item: item,
                                                    index: i
                                                }">
                                            </ng-container>

                                            <div class="col-md-2 mt-2">
                                                <button type="button" (click)="removeImg(i)">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="mode=='edit'">
                                    <div *ngIf="imgeList.length > 0">
                                        <div class="row p-3 border-bottom" *ngFor="let item of imgeList;let i=index;">
                                            <div class="col-md-5">
                                                <span
                                                    style="overflow-wrap: break-word">{{this.storage.storage.refFromURL(item).name}}</span>
                                            </div>

                                            <ng-container [ngTemplateOutlet]="showImgVideo" [ngTemplateOutletContext]="{
                                                    url: storage.storage.refFromURL(item).name, 
                                                    item: item,
                                                    index: i
                                                }">
                                            </ng-container>

                                            <div class="col-md-2 mt-2">
                                                <button type="button" (click)="removeImg(i)">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-box" *ngIf=" mode && mode === 'view'">
                        <div class="card-body row">
                            <div class="col-lg-2 p-t-20 mr-3" *ngFor="let item of uploadedImg;let i = index"
                                style="border-radius: 5px;cursor: pointer;transition: 0.3s;">
                                <ng-container [ngTemplateOutlet]="showImgVideo"
                                    [ngTemplateOutletContext]="{url: item, index: i, item: item}">
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
            <span class="text-red d-flex justify-content-end mt-4 pr-2" *ngIf="!f.form.valid">
                {{validationmsg}}
            </span>
            <div class="d-flex " style="justify-content: flex-end;" *ngIf="!backHotelId">
                <button type="submit" class="btn btn-round btn-default custom-btn" [disabled]="!f.form.valid">Save &
                    Continue</button>
            </div>
            <div class="d-flex " style="justify-content: space-between;padding: 10px" *ngIf="backHotelId">
                <button type="submit" class="btn btn-round btn-default custom-btn" *ngIf="!mode || mode !== 'view'"
                    [disabled]="!f.form.valid">Update</button>

                <a class="btn btn-round btn-primary  custom-btn" *ngIf="!mode || mode !== 'view'"
                    (click)="goToNextPage()">Next</a>
            </div>
        </form>
    </div>
</div>


<button id="confirmModalButton" [hidden]="true" data-toggle="modal" data-target="#confirm-modal">Open Modal</button>

<div class="modal fade" id="confirm-modal">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content" style="width: auto;">

            <!-- Modal Header -->
            <div class="modal-header">
                <h2>Following File already selected , do you want to upload?</h2>

                <button type="button" id="closeModal" hidden class="close" #closeBtnConfirm
                    data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <!-- <form> -->

            <div class="modal-body ">
                <!-- <div class="row justify-content-center"> -->
                <ng-container *ngFor="let item of sameNameFiles">
                    <div class="row justify-content-center" style="font-size: 18px;">{{item.name}}</div>

                </ng-container>

                <!-- </div> -->
                <!-- </form> -->
            </div>
            <div class="modal-footer">
                <div class="row justify-content-end">
                    <div class="col-md-6">
                        <button type="button" data-dismiss="modal" (click)="noUncheck()"
                            class="float-right btn btn-round btn-default custom-btn">No</button>
                    </div>
                    <div class="col-md-6">
                        <button type="button" (click)="yesUncheck()"
                            class="float-left btn btn-round btn-default custom-btn">Yes</button>
                    </div>
                </div>

            </div>
            <!-- Modal footer -->

        </div>
    </div>
</div>
<div *ngIf="openImageModal">
    <app-image-modal [currentImageIndex]="currentImageIndex" [images]="imagesArr"
        (closeImageModal)="closeImageModal($event)"></app-image-modal>
</div>

<ng-template #showImgVideo let-url="url" let-index="index" let-item="item">
    <div *ngIf="url?.includes('.mp4') || url?.includes('.m4v'); else showImg">
        <video width="150" autoplay onloadedmetadata="this.muted = true" (click)="openImageAsModal(index)">
            <source [src]="item">
        </video>
    </div>
    <ng-template #showImg>
        <img [src]="item" width="150" (click)="openImageAsModal(index)" />
    </ng-template>
</ng-template>