
import { Router, ActivatedRoute } from "@angular/router";
import { PropertyService } from "../../property-service.service";
import { ToastrService } from "ngx-toastr";
import { FieldsList } from "../add-property-step4/step4";
import * as moment from "moment";
import * as $ from "jquery";

// New Code
import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators, } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { message } from "../../../_constants/constants";
import { MastersService } from "../../../masters/masters.service";
import { ChargeableFacilitiesService } from "../../../_services";
import { DatePipe } from "@angular/common";
// 

@Component({
  selector: "app-add-property-step4",
  templateUrl: "./add-property-step4.component.html",
  styleUrls: ["./add-property-step4.component.scss"],
})
export class AddPropertyStep4Component implements OnInit {
  @ViewChild("closeBtnAdd") closeBtnAdd: ElementRef;
  @Output() changeNextTab: EventEmitter<any> = new EventEmitter<any>();
  fieldsList = FieldsList;
  minformDate = new Date();
  mintoDate = new Date();
  checkDate: Boolean = false;
  ratingFormGroup: FormGroup | any;
  roomData: any = [];
  mode: string;
  isgonext: boolean = false;
  hotelId: any;
  ratingId: any;
  max_room = 0;
  validationmsg = message.requiredmsg;
  currencyList: any = [];
  selectedCurrency: any;
  isDataFound = false;
  queryParameter: any;
  generalSettings: any;
  chargeFacility: any = [];
  deletedChargeFacility: any = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toastrService: ToastrService,
    public propertyService: PropertyService,
    private spinner: NgxSpinnerService,
    public formBuilder: FormBuilder,
    public changeDetectorRef: ChangeDetectorRef,
    private masterService: MastersService,
    private chargeableFacilitiesService: ChargeableFacilitiesService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.initialData();
    this.initialForm();
    this.getCurrencyList();
  }

  async initialData() {
    this.hotelId = await this.activatedRoute.snapshot.paramMap.get('propertyId') || this.activatedRoute.snapshot.params.hotelId;
    this.mode = await this.activatedRoute.snapshot.paramMap.get('mode');
    await this.getRatingDetails(this.hotelId);
    if (this.activatedRoute.snapshot.queryParams.inventory_allocation) {
      this.queryParameter = {
        queryParams: {
          inventory_allocation: this.activatedRoute.snapshot.queryParams.inventory_allocation
        }
      }
    }
  }

  /**
   * initial form
   * @param data details data
   */
  initialForm(data?: any) {
    this.ratingFormGroup = this.formBuilder.group({
      id: [data?.id],
      date_from: [data?.date_from, [Validators.required]],
      date_to: [data?.date_to, [Validators.required]],
      commission_type: [data?.commission_type || 'percentage', [Validators.required]],
      commission_weekday: [data?.commission_weekday, [Validators.required]],
      commission_weekend: [data?.commission_weekend, [Validators.required]],
      currency_id: [data?.currency_id ? data?.currency_id : '', [Validators.required]],
      cost_rate_weekday: [data?.cost_rate_weekday, [Validators.required]],
      cost_rate_weekend: [data?.cost_rate_weekend, [Validators.required]],
      net_rate_weekday: [data?.net_rate_weekday, [Validators.required]],
      net_rate_weekend: [data?.net_rate_weekend, [Validators.required]],
      per_adult_net_rate_weekday: [data?.per_adult_net_rate_weekday],
      per_adult_net_rate_weekend: [data?.per_adult_net_rate_weekend],
      per_adult_cost_rate_weekday: [data?.per_adult_cost_rate_weekday],
      per_adult_cost_rate_weekend: [data?.per_adult_cost_rate_weekend],
      per_child_net_rate_weekday: [data?.per_child_net_rate_weekday],
      per_child_net_rate_weekend: [data?.per_child_net_rate_weekend],
      per_child_cost_rate_weekday: [data?.per_child_cost_rate_weekday],
      per_child_cost_rate_weekend: [data?.per_child_cost_rate_weekend],
      per_adult_min_net_rate_weekday: [data?.per_adult_min_net_rate_weekday],
      per_adult_min_net_rate_weekend: [data?.per_adult_min_net_rate_weekend],
      per_adult_min_cost_rate_weekday: [data?.per_adult_min_cost_rate_weekday],
      per_adult_min_cost_rate_weekend: [data?.per_adult_min_cost_rate_weekend],
      per_child_min_net_rate_weekday: [data?.per_child_min_net_rate_weekday],
      per_child_min_net_rate_weekend: [data?.per_child_min_net_rate_weekend],
      per_child_min_cost_rate_weekday: [data?.per_child_min_cost_rate_weekday],
      per_child_min_cost_rate_weekend: [data?.per_child_min_cost_rate_weekend],
      per_infant_net_rate_weekday: [data?.per_infant_net_rate_weekday],
      per_infant_net_rate_weekend: [data?.per_infant_net_rate_weekend],
      per_infant_cost_rate_weekday: [data?.per_infant_cost_rate_weekday],
      per_infant_cost_rate_weekend: [data?.per_infant_cost_rate_weekend],
      extra_adult_net_rate_weekday: [data?.extra_adult_net_rate_weekday],
      extra_adult_net_rate_weekend: [data?.extra_adult_net_rate_weekend],
      extra_adult_cost_rate_weekday: [data?.extra_adult_cost_rate_weekday],
      extra_adult_cost_rate_weekend: [data?.extra_adult_cost_rate_weekend],
      extra_child_net_rate_weekday: [data?.extra_child_net_rate_weekday],
      extra_child_net_rate_weekend: [data?.extra_child_net_rate_weekend],
      extra_child_cost_rate_weekday: [data?.extra_child_cost_rate_weekday],
      extra_child_cost_rate_weekend: [data?.extra_child_cost_rate_weekend],
      extra_infant_net_rate_weekday: [data?.extra_infant_net_rate_weekday],
      extra_infant_net_rate_weekend: [data?.extra_infant_net_rate_weekend],
      extra_infant_cost_rate_weekday: [data?.extra_infant_cost_rate_weekday],
      extra_infant_cost_rate_weekend: [data?.extra_infant_cost_rate_weekend],
      per_adult_breakfast_net_rate_weekday: [data?.per_adult_breakfast_net_rate_weekday],
      per_adult_breakfast_net_rate_weekend: [data?.per_adult_breakfast_net_rate_weekend],
      per_adult_breakfast_cost_rate_weekday: [data?.per_adult_breakfast_cost_rate_weekday],
      per_adult_breakfast_cost_rate_weekend: [data?.per_adult_breakfast_cost_rate_weekend],
      per_child_breakfast_net_rate_weekday: [data?.per_child_breakfast_net_rate_weekday],
      per_child_breakfast_net_rate_weekend: [data?.per_child_breakfast_net_rate_weekend],
      per_child_breakfast_cost_rate_weekday: [data?.per_child_breakfast_cost_rate_weekday],
      per_child_breakfast_cost_rate_weekend: [data?.per_child_breakfast_cost_rate_weekend],
      per_adult_halfboard_net_rate_weekday: [data?.per_adult_halfboard_net_rate_weekday],
      per_adult_halfboard_net_rate_weekend: [data?.per_adult_halfboard_net_rate_weekend],
      per_adult_halfboard_cost_rate_weekday: [data?.per_adult_halfboard_cost_rate_weekday],
      per_adult_halfboard_cost_rate_weekend: [data?.per_adult_halfboard_cost_rate_weekend],
      per_child_halfboard_net_rate_weekday: [data?.per_child_halfboard_net_rate_weekday],
      per_child_halfboard_net_rate_weekend: [data?.per_child_halfboard_net_rate_weekend],
      per_child_halfboard_cost_rate_weekday: [data?.per_child_halfboard_cost_rate_weekday],
      per_child_halfboard_cost_rate_weekend: [data?.per_child_halfboard_cost_rate_weekend],
      per_adult_fullboard_net_rate_weekday: [data?.per_adult_fullboard_net_rate_weekday],
      per_adult_fullboard_net_rate_weekend: [data?.per_adult_fullboard_net_rate_weekend],
      per_adult_fullboard_cost_rate_weekday: [data?.per_adult_fullboard_cost_rate_weekday],
      per_adult_fullboard_cost_rate_weekend: [data?.per_adult_fullboard_cost_rate_weekend],
      per_child_fullboard_net_rate_weekday: [data?.per_child_fullboard_net_rate_weekday],
      per_child_fullboard_net_rate_weekend: [data?.per_child_fullboard_net_rate_weekend],
      per_child_fullboard_cost_rate_weekday: [data?.per_child_fullboard_cost_rate_weekday],
      per_child_fullboard_cost_rate_weekend: [data?.per_child_fullboard_cost_rate_weekend],
      per_adult_allinclusive_net_rate_weekday: [data?.per_adult_allinclusive_net_rate_weekday],
      per_adult_allinclusive_net_rate_weekend: [data?.per_adult_allinclusive_net_rate_weekend],
      per_adult_allinclusive_cost_rate_weekday: [data?.per_adult_allinclusive_cost_rate_weekday],
      per_adult_allinclusive_cost_rate_weekend: [data?.per_adult_allinclusive_cost_rate_weekend],
      per_child_allinclusive_net_rate_weekday: [data?.per_child_allinclusive_net_rate_weekday],
      per_child_allinclusive_net_rate_weekend: [data?.per_child_allinclusive_net_rate_weekend],
      per_child_allinclusive_cost_rate_weekday: [data?.per_child_allinclusive_cost_rate_weekday],
      per_child_allinclusive_cost_rate_weekend: [data?.per_child_allinclusive_cost_rate_weekend],

      allocation_weekday: [data?.allocation_weekday, [Validators.required]],
      allocation_weekend: [data?.allocation_weekend, [Validators.required]],
      min_stay: [data?.min_stay, [Validators.required]],
      max_room_per_booking: [data?.max_room_per_booking, [Validators.required]],
      notification_after_units: [data?.notification_after_units, [Validators.required]],
      cut_of_day: [data?.cut_of_day, [Validators.required]],
      roomId: [data?.roomId],
      chargeable_facility: this.formBuilder.array([])
    });
    setTimeout(() => {
      this.disabledFields();
      this.changeCurrency(data?.currency);
      this.getChargeableFacility();
    }, 200);
  }


  disabledFields() {
    this.fieldsList.forEach(ele => {
      if (this.ratingFormGroup.value.commission_weekday && this.ratingFormGroup.value.commission_weekend) {
        this.ratingFormGroup.controls[ele].enable();
      } else {
        this.ratingFormGroup.controls[ele].disable();
      }
    });

    // let ctrlArray: any = this.ratingFormGroup.get('chargeable_facility') as FormArray;
    // ctrlArray.value.forEach((val, k) => {
    //   ctrlArray.controls[k].disable();
    //   if (this.ratingFormGroup.value.commission_weekday && this.ratingFormGroup.value.commission_weekend) {
    //     ctrlArray.controls[k]['controls'].net_rate_weekday.enable();
    //     ctrlArray.controls[k]['controls'].net_rate_weekend.enable();
    //   }
    // });
  }

  dynamicFacility(facility?: any) {
    const ctrl = (this.ratingFormGroup.get('chargeable_facility') as FormArray);
    if (facility?.length > 0) {
      facility.forEach((element: any, index) => {
        this.generalSettings.chargeableFacilityId.forEach(ele => {
          if (ele == element?.master_charge_facility_id) {
            ctrl.push(
              this.formBuilder.group({
                id: [element?.id],
                master_charge_facility_id: [element?.master_charge_facility_id, Validators.required],
                net_rate_weekday: [element?.net_rate_weekday, Validators.required],
                net_rate_weekend: [element?.net_rate_weekend, Validators.required],
                cost_rate_weekday: [element?.cost_rate_weekday, Validators.required],
                cost_rate_weekend: [element?.cost_rate_weekend, Validators.required],
                labelName: [element?.master_charge_facility_name]
              })
            );
          }
        })
      });
      this.setData(ctrl, facility);
    } else {
      this.addNewFacility(ctrl, this.generalSettings?.chargeableFacilityId);
    }
  }

  addNewFacility(ctrl, newFacilityId) {
    this.chargeFacility.forEach(item => {
      if (newFacilityId?.length > 0) {
        newFacilityId?.forEach(val => {
          if (item.id == val) {
            ctrl.push(
              this.formBuilder.group({
                master_charge_facility_id: [item.id, Validators.required],
                net_rate_weekday: ['', Validators.required],
                net_rate_weekend: ['', Validators.required],
                cost_rate_weekday: ['', Validators.required],
                cost_rate_weekend: ['', Validators.required],
                labelName: [item?.name]
              })
            );
          }
        })
      }
    });
  }

  setData(ctrl, facility) {
    let newFacilityId = [];
    let yFilter = facility.map(itemY => { return itemY.master_charge_facility_id; });
    newFacilityId = this.generalSettings.chargeableFacilityId.filter(items => !yFilter.includes(items));
    this.deletedChargeFacility = yFilter.filter(items => !this.generalSettings.chargeableFacilityId.includes(items));
    this.addNewFacility(ctrl, newFacilityId);
  }

  getCurrencyList() {
    this.masterService.getCountry().subscribe((response: any) => {
      if (response.success) {
        this.currencyList = response.data;
      }
    });
  }

  getChargeableFacility() {
    this.chargeableFacilitiesService.getChargeableFacilities().subscribe((response: any) => {
      if (response.success) {
        this.chargeFacility = response.data;
      }
    });
  }

  async getRatingDetails(id) {
    this.spinner.show();
    await this.propertyService.getHotelPropertyDetailsStep1(id).subscribe(async (res: any) => {
      this.generalSettings = res['data']['interests_setting']['chargeable_facility'][0];
      if (res.success && res['data']['room']) {
        this.roomData = res['data']['room'];
        this.isgonext = true;
      }
      this.spinner.hide();
    });
  }

  /**
   * collapse room
   * @param item
   */
  collapseRoom(item: any) {
    let ratingDetails;
    ratingDetails = item['rates'][0];
    this.initialForm(ratingDetails);
    this.dynamicFacility(item['chargeable_facility_rate']);
    this.max_room = item?.total_room;
    this.isDataFound = false;
    if (ratingDetails) {
      this.isDataFound = true;
      this.ratingId = ratingDetails.id;
    }
  }

  rateCalculation(index?: number) {
    let allFields = this.ratingFormGroup.value;
    this.disabledFields();
    if (allFields.commission_type && allFields.commission_weekday && allFields.commission_weekend) {
      let wday = allFields.commission_weekday;
      let wend = allFields.commission_weekend;
      this.ratingFormGroup.patchValue({
        per_adult_net_rate_weekday: allFields.net_rate_weekday,
        per_adult_net_rate_weekend: allFields.net_rate_weekend,
        cost_rate_weekday: this.setCalculation(wday, allFields.net_rate_weekday),
        cost_rate_weekend: this.setCalculation(wend, allFields.net_rate_weekend),
        per_adult_cost_rate_weekday: this.setCalculation(wday, allFields.net_rate_weekday),
        per_adult_cost_rate_weekend: this.setCalculation(wend, allFields.net_rate_weekend),
        per_child_cost_rate_weekday: this.setCalculation(wday, allFields.per_child_net_rate_weekday),
        per_child_cost_rate_weekend: this.setCalculation(wend, allFields.per_child_net_rate_weekend),
        per_adult_min_cost_rate_weekday: this.setCalculation(wday, allFields.per_adult_min_net_rate_weekday),
        per_adult_min_cost_rate_weekend: this.setCalculation(wend, allFields.per_adult_min_net_rate_weekend),
        per_child_min_cost_rate_weekday: this.setCalculation(wday, allFields.per_child_min_net_rate_weekday),
        per_child_min_cost_rate_weekend: this.setCalculation(wend, allFields.per_child_min_net_rate_weekend),
        per_infant_cost_rate_weekday: this.setCalculation(wday, allFields.per_infant_net_rate_weekday),
        per_infant_cost_rate_weekend: this.setCalculation(wend, allFields.per_infant_net_rate_weekend),
        extra_adult_cost_rate_weekday: this.setCalculation(wday, allFields.extra_adult_net_rate_weekday),
        extra_adult_cost_rate_weekend: this.setCalculation(wend, allFields.extra_adult_net_rate_weekend),
        extra_child_cost_rate_weekday: this.setCalculation(wday, allFields.extra_child_net_rate_weekday),
        extra_child_cost_rate_weekend: this.setCalculation(wend, allFields.extra_child_net_rate_weekend),
        extra_infant_cost_rate_weekday: this.setCalculation(wday, allFields.extra_infant_net_rate_weekday),
        extra_infant_cost_rate_weekend: this.setCalculation(wend, allFields.extra_infant_net_rate_weekend),
        per_adult_breakfast_cost_rate_weekday: this.setCalculation(wday, allFields.per_adult_breakfast_net_rate_weekday),
        per_adult_breakfast_cost_rate_weekend: this.setCalculation(wend, allFields.per_adult_breakfast_net_rate_weekend),
        per_child_breakfast_cost_rate_weekday: this.setCalculation(wday, allFields.per_child_breakfast_net_rate_weekday),
        per_child_breakfast_cost_rate_weekend: this.setCalculation(wend, allFields.per_child_breakfast_net_rate_weekend),
        per_adult_halfboard_cost_rate_weekday: this.setCalculation(wday, allFields.per_adult_halfboard_net_rate_weekday),
        per_adult_halfboard_cost_rate_weekend: this.setCalculation(wend, allFields.per_adult_halfboard_net_rate_weekend),
        per_child_halfboard_cost_rate_weekday: this.setCalculation(wday, allFields.per_child_halfboard_net_rate_weekday),
        per_child_halfboard_cost_rate_weekend: this.setCalculation(wend, allFields.per_child_halfboard_net_rate_weekend),
        per_adult_fullboard_cost_rate_weekday: this.setCalculation(wday, allFields.per_adult_fullboard_net_rate_weekday),
        per_adult_fullboard_cost_rate_weekend: this.setCalculation(wend, allFields.per_adult_fullboard_net_rate_weekend),
        per_child_fullboard_cost_rate_weekday: this.setCalculation(wday, allFields.per_child_fullboard_net_rate_weekday),
        per_child_fullboard_cost_rate_weekend: this.setCalculation(wend, allFields.per_child_fullboard_net_rate_weekend),
        per_adult_allinclusive_cost_rate_weekday: this.setCalculation(wday, allFields.per_adult_allinclusive_net_rate_weekday),
        per_adult_allinclusive_cost_rate_weekend: this.setCalculation(wend, allFields.per_adult_allinclusive_net_rate_weekend),
        per_child_allinclusive_cost_rate_weekday: this.setCalculation(wday, allFields.per_child_allinclusive_net_rate_weekday),
        per_child_allinclusive_cost_rate_weekend: this.setCalculation(wend, allFields.per_child_allinclusive_net_rate_weekend),
      });

      let ctrlArray = this.ratingFormGroup.get('chargeable_facility') as FormArray;
      ctrlArray.value.forEach((val, k) => {
        ctrlArray.controls[k].patchValue({
          cost_rate_weekday: this.setCalculation(wday, ctrlArray.value[k].net_rate_weekday),
          cost_rate_weekend: this.setCalculation(wend, ctrlArray.value[k].net_rate_weekend)
        });
      });
    }
  }

  setCalculation(weekEndDay, value) {
    if (this.ratingFormGroup.value.commission_type === 'percentage') {
      return this.percentageCalculation(weekEndDay, value);
    } else {
      return this.fixPriceCalculation(weekEndDay, value);
    }
  }

  percentageCalculation(com, rate) {
    if (rate === undefined) return 0;
    else {
      let commission = (rate * com) / 100;
      if (rate - commission > 0) {
        return rate + commission;
      } else {
        return 0;
      }
    }
  }

  fixPriceCalculation(price, rate) {
    if (rate - price > 0) {
      return rate + price;
    } else {
      return 0;
    }
  }

  validateMaxRoom(event) {
    if (Number(event.target.value) > Number(this.max_room)) {
      event.target.value = this.max_room;
    }
  }

  onSubmit(roomId) {
    this.ratingFormGroup.value.date_from = moment(this.ratingFormGroup.value.date_from).format("YYYY-MM-DD");
    this.ratingFormGroup.value.date_to = moment(this.ratingFormGroup.value.date_to).format("YYYY-MM-DD");
    this.spinner.show();
    if (this.ratingFormGroup.value.id) {
      this.ratingFormGroup.value.deleted_charge_facility = this.deletedChargeFacility;
      this.propertyService
        .updatePropertyStep4(this.hotelId, roomId, this.ratingFormGroup.value.id, this.ratingFormGroup.value)
        .subscribe((data) => {
          if (data["success"] === true) {
            // this.goToNext();
            this.initialData();
            this.initialForm();
            this.toastrService.success(data["message"], "Success", {
              timeOut: 3000,
            });
          } else {
            this.toastrService.success(data["message"], "Error", {
              timeOut: 3000,
            });
          }
          this.spinner.hide();
        });
    } else {
      delete this.ratingFormGroup.value.id;
      this.propertyService.addPropertyStep4(roomId, this.hotelId, this.ratingFormGroup.value).subscribe((data) => {
        if (data["success"]) {
          this.isgonext = true;
          // this.goToNext();
          this.initialData();
          this.initialForm();
          this.toastrService.success(data["message"], "Success", {
            timeOut: 3000,
          });
        } else {
          this.toastrService.success(data["message"], "Error", {
            timeOut: 3000,
          });
        }
        this.spinner.hide();
      });
    }
  }

  deleteRoom(roomId?: any) {
    this.spinner.show();
    this.propertyService
      .deletePropertyStep4(this.hotelId, roomId, this.ratingId)
      .subscribe((data) => {
        if (data["success"]) {
          this.toastrService.success(data["message"], "Success", {
            timeOut: 3000,
          });
          this.ngOnInit();
        } else {
          this.toastrService.success(data["message"], "Error", {
            timeOut: 3000,
          });
        }
        this.spinner.hide();
      });
  }

  goToNext(): void {
    if (this.mode && this.mode === "edit") {
      this.changeNextTab.emit("stopSales");
    } else {
      this.router.navigate(["/property/step-5-add-property", this.hotelId], this.queryParameter);
    }
  }

  goToBack(): void {
    // if (this.mode && this.mode === 'edit') {
    //   this.changeNextTab.emit('roomFacility&Inclusions');
    // } else {
    //   this.router.navigate(['property', 'room-facilities-and-inclusions', this.hotelId], this.queryParameter);
    // }

    if (this.mode && this.mode === 'edit') {
      this.changeNextTab.emit('propertyFacility');
    } else {
      this.router.navigate(['property', 'step-2-add-property', this.hotelId], this.queryParameter);
    }
  }

  // ----------------------------------------------------------
  public removeBackdropClass(): void {
    if (this.mode && this.mode === "edit") {
      setTimeout(() => {
        $(".modal-backdrop").remove();
      }, 500);
    }
  }

  noFun() {
    this.closeBtnAdd.nativeElement.click();
    this.dosame();
    this.router.navigate(["/"]);
  }

  dosame() {
    $("body").removeClass("modal-open");
    setTimeout(() => {
      $(".modal-backdrop").remove();
    }, 500);
  }

  yesFun() {
    this.closeBtnAdd.nativeElement.click();
    this.dosame();
    this.goToNext();
  }

  changeCurrency(event) {
    this.selectedCurrency = this.currencyList.filter((items: any) => items.id == event)[0]?.currency_code;
  }
}
