<div class="page-bar" *ngIf="!mode">
    <div class="page-title-breadcrumb">
        <div class="pull-left">
            <div class="page-title">Step 2 : Hotel Overview</div>
        </div>
        <ol class="breadcrumb page-breadcrumb pull-right">
            <li>
                <a class="parent-item" href="/"><i class="fa fa-home"></i>&nbsp;Home</a>&nbsp;<i
                    class="fa fa-angle-right"></i>
            </li>
            <li class="active">Hotel Overview</li>
        </ol>
    </div>
</div>

<div class="row">
    <div class="col-12" *ngIf="!mode">
        <div class="card-box">
            <div class="card-head">
                <header>Progress Bar</header>
            </div>
            <div class="card-body">
                <div class="progressbar-xs progress-rounded progress-striped progress ng-isolate-scope active">
                    <div class="progress-bar progress-bar-success width-50" role="progressbar" aria-valuenow="65"
                        aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <div class="card-box">
            <div class="card-head">
                <header>Hotel Overview</header>
            </div>
            <div class="card-body">
                <div class="form-group">
                    <form [formGroup]="overviewForm" (ngSubmit)="onSubmit()">
                        <textarea matInput [froalaEditor]="frolaKey" formControlName="hotel_overview"> </textarea>
                        <div class="d-flex justify-content-end mt-2">
                            <button type="button" class="btn btn-primary mr-2" (click)="goToBack()">BACK</button>
                            <button type="submit" class="btn btn-default mr-2"
                                [disabled]="!overviewForm.valid">Save</button>
                            <button type="button" class="btn btn-primary" (click)="confirmationDialog()">NEXT</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>