<!-- image model-->
<!-- The Modal -->
<div id="imageModel" class="image-modal">

    <!-- The Close Button -->
    <span class="image-modal-close">&times;</span>
    <span class="image-modal-left" *ngIf="displayImgPrevButton" (click)="loadImageBaseOnIndex('prev')"><i class="fa fa-angle-left" aria-hidden="true"></i></span>
    <span class="image-modal-right" *ngIf="displayImgNextButton" (click)="loadImageBaseOnIndex('next')"><i class="fa fa-angle-right" aria-hidden="true"></i></span>

    <!-- Modal Content (The Image) -->
    <img *ngIf="images[currentImageIndex].indexOf('.mp4') == -1 && images[currentImageIndex].indexOf('video') == -1" [src]="images[currentImageIndex]" class="image-modal-content">
    <video *ngIf="images[currentImageIndex].indexOf('.mp4') !== -1 || images[currentImageIndex].indexOf('video') !== -1" [src]="images[currentImageIndex]" width="450" height="240" controls class="image-modal-content"></video>
    <!-- Modal Caption (Image Text) -->
    <div id="caption"></div>
</div>