import { Injectable } from '@angular/core';
import { mastersApi } from '../../_constants/api-list';
import { environment } from '../../../environments/environment';
import { HttpClientService } from '../index';

@Injectable({
    providedIn: 'root',
})
export class BeddingTypeService {

    constructor(
        private httpClientService: HttpClientService
    ) { }

    /**
     * Save bedding type 
     * @param requestData request data
     * @returns Return response from api
     */
    saveBeddingType(requestData: any) {
        let url = environment.origin + mastersApi.SAVE_BEDDING_TYPE;
        return this.httpClientService.post(url, requestData);
    }

    /**
     * Get bedding type
     * @param id id 
     * @returns Return response from api
     */
    getBeddingType(id?: any) {
        let url = environment.origin + mastersApi.GET_BEDDING_TYPE;
        if (id) {
            url += `/${id}`;
        }
        return this.httpClientService.get(url);
    }

    /**
     * Delete bedding type
     * @param id id
     * @returns Return response from api
     */
    deleteBeddingType(id?: any) {
        let url = environment.origin + mastersApi.DELETE_BEDDING_TYPE + id;
        return this.httpClientService.delete(url);
    }
}
