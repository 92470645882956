import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { PromotionsRoutingModule } from "./promotions-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ListPromotionsComponent } from "./list-promotions/list-promotions.component";
import { MatGoogleMapsAutocompleteModule } from "@angular-material-extensions/google-maps-autocomplete";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { PropertyRoutingModule } from "../property/property-routing.module";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatRadioModule } from "@angular/material/radio";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { AddPromotionComponent } from "./add-promotion/add-promotion.component";

@NgModule({
  declarations: [AddPromotionComponent, ListPromotionsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PromotionsRoutingModule,
    MatGoogleMapsAutocompleteModule,
    MatBottomSheetModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    PropertyRoutingModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
  ],
  exports: [AddPromotionComponent],
})
export class PromotionsModule {}
