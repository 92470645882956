<div class="sidebar-container">
  <div class="sidemenu-container navbar-collapse collapse fixed-menu">
    <div id="remove-scroll">
      <ul class="sidemenu page-header-fixed p-t-20" data-keep-expanded="false" data-auto-scroll="true"
        data-slide-speed="200">
        <li class="sidebar-toggler-wrapper hide">
          <div class="sidebar-toggler">
            <span></span>
          </div>
        </li>
        <li class="nav-item start">
          <a class="nav-link nav-toggle">
            <i class="fa fa-tachometer" aria-hidden="true"></i>
            <span class="title">Masters</span>
            <span class="selected"></span>
            <span class="arrow"></span>
          </a>
          <ul class="sub-menu">
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/masters/room-type']">
                <span class="title">Room Type</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/masters/property-facilities-category']">
                <span class="title">Property Facilities Category</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/masters/property-facilities']">
                <span class="title">Property Facilities</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/masters/bedding-type']">
                <span class="title">Bedding Type</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/masters/room-facilities']">
                <span class="title">Room Facilities</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/masters/inclusions']">
                <span class="title">Inclusions</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/masters/meal-type']">
                <span class="title">Meal Type</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/masters/service']">
                <span class="title">Services</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/masters/policy-type']">
                <span class="title">Policy Type Master</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/masters/timezone']">
                <span class="title">Timezone</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/masters/country']">
                <span class="title">Country</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/masters/province']">
                <span class="title">Province</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/masters/city']">
                <span class="title">City</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/masters/hotel-categories/list']">
                <span class="title">Hotel Categories</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/masters/interests/list']">
                <span class="title">Interests</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/masters/style/list']">
                <span class="title">Style</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/masters/chargeable-facility/list']">
                <span class="title">Chargeable Facility</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/masters/spa-services/list']">
                <span class="title">SPA Services</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/masters/special-instruction/list']">
                <span class="title">Special Instructions</span>
                <span class="selected"></span>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item start">
          <a class="nav-link nav-toggle">
            <i class="fa fa-file-text-o" aria-hidden="true"></i>
            <span class="title">Reports</span>
            <span class="selected"></span>
            <span class="arrow"></span>
          </a>
          <ul class="sub-menu">
            <li class="nav-item">
              <a class="nav-link">
                <span class="title">Daily Arrival</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link">
                <span class="title">Cancellation</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link">
                <span class="title">Inventory</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link">
                <span class="title">Reservation</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link">
                <span class="title">Rates</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link">
                <span class="title">Stop Sell</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link">
                <span class="title">Room Availability</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link">
                <span class="title">Sales Report</span>
                <span class="selected"></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link">
                <span class="title">Rooms Utilization</span>
                <span class="selected"></span>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link nav-toggle">
            <i class="fa fa-list" aria-hidden="true"></i>
            <span class="title">Operations</span>
            <span class="arrow"></span>
          </a>
          <ul class="sub-menu">
            <li class="nav-item">
              <a class="nav-link nav-toggle">
                <i class="fa fa-university"></i> Property
                <span class="arrow"></span>
              </a>
              <ul class="sub-menu">
                <li class="nav-item">
                  <a [routerLink]="['/property/step-1-add-property']" class="nav-link nav-toggle">
                    <i class="fa fa-plus"></i> Add New Property
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" [routerLink]="['/property/list-property']">
                    <i class="fa fa-eye"></i> View Property</a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link nav-toggle">
                <i class="fa fa-tags"></i> Promotions
                <span class="arrow"></span>
              </a>
              <ul class="sub-menu">
                <li class="nav-item">
                  <a [routerLink]="['/promotions/add-promotion']" class="nav-link">
                    <i class="fa fa-plus"></i> Add Promotion</a>
                </li>
                <li class="nav-item">
                  <a [routerLink]="['/promotions/list-promotions']" class="nav-link">
                    <i class="fa fa-eye"></i> View Promotions</a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link nav-toggle">
                <i class="fa fa-paste"></i> Policies
                <span class="arrow"></span>
              </a>
              <ul class="sub-menu">
                <li class="nav-item">
                  <a [routerLink]="['/policies/add-policy']" class="nav-link">
                    <i class="fa fa-plus"></i> Add Policy</a>
                </li>
                <li class="nav-item">
                  <a [routerLink]="['/policies/list-policies']" class="nav-link">
                    <i class="fa fa-eye"></i> View Policies</a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link nav-toggle">
                <i class="fa fa-bookmark"></i> Booking
                <span class="arrow"></span>
              </a>
              <ul class="sub-menu">
                <li class="nav-item">
                  <a class="nav-link">
                    <i class="fa fa-eye"></i> View Bookings</a>
                </li>
                <li class="nav-item">
                  <a [routerLink]="['/booking/search-booking']" class="nav-link">
                    <i class="fa fa-search"></i>Search Bookings</a>
                </li>
              </ul>
            </li>

            <li class="nav-item">
              <a [routerLink]="['/report/manage-reports']" class="nav-link">
                <i class="fa fa-file-text-o"></i> Reports
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link nav-toggle">
            <i class="fa fa-refresh" aria-hidden="true"></i>
            <span class="title">Hotel Sync Tool</span>
            <span class="arrow"></span>
          </a>
          <ul class="sub-menu">
            <li class="nav-item">
              <a [routerLink]="['/hotel-sync-tool/hotel-sync-home']" class="nav-link nav-toggle">
                <i class="fa fa-home"></i> Home
              </a>
            </li>

            <li class="nav-item" *ngFor="let channel of channels">
              <a class="nav-link" [routerLink]="[channel.status==1?channel.link:'/hotel-sync-tool/manage-channels']">
                <i class="fa fa-file-pdf-o"></i>{{channel.channelName | uppercase}}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/hotel-sync-tool/manage-channels']">
                <i class="fa fa-cog" aria-hidden="true"></i> Manage Channels</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>