<div class="page-bar" *ngIf="!mode">
    <div class="page-title-breadcrumb">
        <div class="pull-left">
            <div class="page-title">Step 6 : Room Facilities & Inclusions</div>
        </div>
        <ol class="breadcrumb page-breadcrumb pull-right">
            <li>
                <a class="parent-item" href="/"><i class="fa fa-home"></i>&nbsp;Home</a>&nbsp;<i
                    class="fa fa-angle-right"></i>
            </li>
            <li class="active">Room Facilities & Inclusions</li>
        </ol>
    </div>
</div>

<div class="row">
    <div class="col-sm-12">
        <form id="myform">
            <div class="card-box" *ngIf="!mode">
                <div class="card-head">
                    <header>Progress Bar</header>
                </div>
                <div class="card-body">
                    <div class="progressbar-xs progress-rounded progress-striped progress ng-isolate-scope active">
                        <div class="progress-bar progress-bar-success width-50" role="progressbar" aria-valuenow="65"
                            aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
            </div>
            <div *ngIf="mode" class="card-box">
                <div class="card-head">
                    <header>{{ propertyDetails?.name }}</header>
                </div>
            </div>
            <fieldset [disabled]="mode == 'view'">
                <!-- ROOM FACILITIES VIEW -->
                <div class="card-box">
                    <div class="card-body grid-div">
                        <label class="category-label"><b>Room Facilities</b></label><br>
                        <div *ngFor="let roomfacility of listOfRoomFacilities">
                            <div class="checkbox checkbox-black d-inline-block"
                                [ngClass]="mode == 'view' ? 'disabled' : ''">
                                <input [id]="'chk' + roomfacility.id" [value]="roomfacility.id" type="checkbox"
                                    (change)="editorModal(roomfacility, 'roomfacility')"
                                    [checked]="roomfacility | isDisabledInterest: propertyDetails?.room_facilities : 'roomfacility'"
                                    [disabled]="mode == 'view'" />
                                <label [id]="'lbl' + roomfacility.id" [for]="'chk' + roomfacility.id">
                                    {{ roomfacility?.name }}
                                </label>
                                <a class="pl-2" (click)="editorModal(roomfacility, 'roomfacility')"
                                    [hidden]="mode !== 'view'">
                                    <i class="fa fa-eye" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- INCLUSIONS VIEW -->
                <div class="card-box">
                    <div class="card-body grid-div">
                        <label class="category-label"><b>Inclusions</b></label><br>
                        <div *ngFor="let inclusions of listOfInclusions">
                            <div class="checkbox checkbox-black d-inline-block"
                                [ngClass]="mode == 'view' ? 'disabled' : ''">
                                <input [id]="'inc' + inclusions.id" [value]="inclusions.id" type="checkbox"
                                    (change)="editorModal(inclusions, 'inclusions')"
                                    [checked]="inclusions | isDisabledInterest: propertyDetails?.inclusions : 'inclusions'"
                                    [disabled]="mode == 'view'" />
                                <label [id]="'lbls' + inclusions.id" [for]="'inc' + inclusions.id">
                                    {{ inclusions?.name }}
                                </label>
                                <a class="pl-2" (click)="editorModal(inclusions, 'inclusions')"
                                    [hidden]="mode !== 'view'">
                                    <i class="fa fa-eye" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-between" style="padding: 10px">
                    <a class="btn yellow btn-outline custom-btn" (click)="goToBack()">Back</a>
                    <a class="btn btn-round btn-primary custom-btn" (click)="goToNext()">Next</a>
                </div>
            </fieldset>
        </form>
    </div>
</div>