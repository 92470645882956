export class step3 {
      id:number;
      name: string;
      description: string;
      room_type: number;
      total_room: number;
      max_capacity: number;
      total_bedroom_per_unit: number;
      max_child: number;
      max_adult: number;
      max_infant: number;
      buffer_room: number;
      default_images: string;
      available_room: number;
      bedding: [BeddingDetailModel];
      images: [
        string
      ];
      inclusions: [
        string
      ];
      facilities: [
        string
      ];
}

export class BeddingDetailModel{
      bedding_config: number;
          bedding_type: string;
}

export class dynamicHtml{}
// export class CorrespondenceDetail{
//       id: string;
//       addressLine1: string;
//       addressLine2: string;
//       country: string;
//       city: string;
//       province: string;
//       pincode: string;
//       contact_num: string;
//       email: string;
//       firstname: string;
//       lastname: string;
// }
