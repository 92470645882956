import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { PreventAccess, GuardService } from './_guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'dashboard',
        canActivate: [GuardService],
        loadChildren: () =>
          import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'property',
        canActivate: [GuardService],
        loadChildren: () =>
          import('./property/property.module').then(m => m.PropertyModule)
      },
      {
        path: 'promotions',
        canActivate: [GuardService],
        loadChildren: () =>
          import('./promotions/promotions.module').then(m => m.PromotionsModule)
      },
      {
        path: 'booking',
        canActivate: [GuardService],
        loadChildren: () =>
          import('./booking/booking.module').then(m => m.BookingModule)
      },
      {
        path: 'policies',
        canActivate: [GuardService],
        loadChildren: () =>
          import('./policies/policies.module').then(m => m.PoliciesModule)
      },
      {
        path: 'report',
        canActivate: [GuardService],
        loadChildren: () =>
          import('./report/report.module').then(m => m.ReportModule)
      },
      {
        path: 'hotel-sync-tool',
        canActivate: [GuardService],
        loadChildren: () =>
          import('./hotel-sync-tool/hotel-sync-tool.module').then(m => m.HotelSyncToolModule)
      },
      {
        path: 'masters',
        canActivate: [GuardService],
        loadChildren: () => import('./masters/masters.module').then(m => m.MastersModule)
      },
    ]
  },
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    canActivate: [PreventAccess],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
