<div class="mr-auto" *ngIf="mode != undefined">
  <button
    class="btn btn-outline-primary btn-circle m-b-10 mr-2"
    (click)="goToBackButton()"
  >
    Go Back
  </button>
  <button
    *ngIf="isViewMode"
    class="btn btn-outline-primary btn-circle m-b-10 mr-2"
    (click)="editPolicy()"
  >
    Edit Promotion
  </button>
</div>
<div class="page-bar">
  <div class="page-title-breadcrumb">
    <div class="pull-left">
      <div class="page-title">
        {{ mode ? (mode === "edit" ? "Edit" : "View") : "Add" }} Policy
      </div>
    </div>
    <ol class="breadcrumb page-breadcrumb pull-right">
      <li>
        <a class="parent-item" href="/"><i class="fa fa-home"></i>&nbsp;Home</a
        >&nbsp;<i class="fa fa-angle-right"></i>
      </li>
      <li class="active">
        {{ mode ? (mode === "edit" ? "Edit" : "View") : "Add" }} Policy
      </li>
    </ol>
  </div>
</div>
<form [formGroup]="policyForm">
  <fieldset [disabled]="isViewMode">
    <div class="card-box">
      <div class="card-head">
        <header>Policy Information</header>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="form_label text-red">Policy Type</label>
              <select
                placeholder="Select policy type"
                class="form-control"
                formControlName="policy_type"
                required
                id="policy-type"
                (change)="policyTypeChanged($event.target.value)"
              >
                <option
                  *ngFor="let policyType of policyTypes"
                  value="{{ policyType.id }}"
                >
                  {{ policyType.name }}
                </option>
              </select>
              <span
                *ngIf="
                  (policyForm.controls['policy_type'].dirty ||
                    policyForm.controls['policy_type'].touched) &&
                  policyForm.controls['policy_type'].hasError('required')
                "
                class="text-red"
                >Please select policy type.
              </span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="form_label text-red">From Date</label>
              <div
                class="form-control mat-datepicker"
                [ngClass]="{
                  'ng-pristine ng-invalid ng-touched':
                    (policyForm.controls['from_date'].dirty ||
                      policyForm.controls['from_date'].touched) &&
                    policyForm.controls['from_date'].hasError('required')
                }"
              >
                <input
                  readonly
                  matInput
                  [min]="minDateFrom"
                  [matDatepicker]="pickerFrom"
                  formControlName="from_date"
                  class="date-input"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="pickerFrom"
                ></mat-datepicker-toggle>
                <mat-datepicker #pickerFrom></mat-datepicker>
              </div>
              <span
                *ngIf="
                  (policyForm.controls['from_date'].dirty ||
                    policyForm.controls['from_date'].touched) &&
                  policyForm.controls['from_date'].hasError('required')
                "
                class="text-red"
                >Please select from date.
              </span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="form_label text-red">To Date</label>
              <div
                class="form-control mat-datepicker"
                [ngClass]="{
                  'ng-pristine ng-invalid ng-touched':
                    (policyForm.controls['to_date'].dirty ||
                      policyForm.controls['to_date'].touched) &&
                    policyForm.controls['to_date'].hasError('required')
                }"
              >
                <input
                  readonly
                  matInput
                  [min]="minDateTo"
                  [matDatepicker]="pickerTo"
                  formControlName="to_date"
                  class="date-input"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="pickerTo"
                ></mat-datepicker-toggle>
                <mat-datepicker #pickerTo></mat-datepicker>
              </div>
              <span
                *ngIf="
                  (policyForm.controls['to_date'].dirty ||
                    policyForm.controls['to_date'].touched) &&
                  policyForm.controls['to_date'].hasError('required')
                "
                class="text-red"
                >Please select to date.
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form_label text-red">Policy Name</label>
              <input
                type="text"
                formControlName="policy_name"
                class="form-control"
              />
              <span
                *ngIf="
                  (policyForm.controls['policy_name'].dirty ||
                    policyForm.controls['policy_name'].touched) &&
                  policyForm.controls['policy_name'].hasError('required')
                "
                class="text-red"
                >Policy name is required.
              </span>
              <span
                *ngIf="
                  (policyForm.controls['policy_name'].dirty ||
                    policyForm.controls['policy_name'].touched) &&
                  policyForm.controls['policy_name'].hasError('pattern')
                "
                class="text-red"
                >Enter valid policy name (Some special characters are not supported).
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form_label text-red">Policy Description</label>
              <span class="note_label"
                >(Max 4000 characters) characters left:
                <b>{{
                  4000 -
                    (this.policyForm.value.policy_description
                      ? this.policyForm.value.policy_description.length
                      : 0)
                }}</b></span
              >
              <textarea
                required
                maxlength="4000"
                class="form-control"
                name="policy_description"
                formControlName="policy_description"
              ></textarea>
              <span
                *ngIf="
                  (policyForm.controls['policy_description'].dirty ||
                    policyForm.controls['policy_description'].touched) &&
                  policyForm.controls['policy_description'].hasError('required')
                "
                class="text-red"
                >Policy description is required.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Configure the Charges FOR CANCELLATION POLICY-->
    <div
      class="card-box"
      *ngIf="
        selectedPolicyType && selectedPolicyType.name === 'Cancellation Policy'
      "
    >
      <div class="card-head">
        <header>Configure the Charges</header>
      </div>
      <div class="card-body" formGroupName="cancellation_charges">
        <div class="form-group">
          Cancellation made up to
          <input
            type="tel"
            formControlName="days_before"
            class="form-control min-width"
          />
          days before check-in date will apply charges defined below.
          <div
            *ngIf="
              (cancellationChargesForm.controls['days_before'].dirty ||
                cancellationChargesForm.controls['days_before'].touched) &&
              cancellationChargesForm.controls['days_before'].hasError(
                'required'
              )
            "
            class="text-red"
          >
            Days before is required.
          </div>
          <div
            *ngIf="
              (cancellationChargesForm.controls['days_before'].dirty ||
                cancellationChargesForm.controls['days_before'].touched) &&
              cancellationChargesForm.controls['days_before'].hasError('min')
            "
            class="text-red"
          >
            Minimum value is 1.
          </div>
          <div
            *ngIf="
              (cancellationChargesForm.controls['days_before'].dirty ||
                cancellationChargesForm.controls['days_before'].touched) &&
              cancellationChargesForm.controls['days_before'].hasError('pattern')
            "
            class="text-red"
          >
            Decimal value is not allowed.
          </div>
        </div>
        <div class="form-group">
          <label class="form_label text-red">Set Up Cancellation Charges</label>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <div class="extra-radio">
                  <div class="radio p-0">
                    <input
                      [(checked)]="isAmountChecked"
                      type="radio"
                      name="optionsRadios"
                      id="optionsRadios1"
                      value="option1"
                      (change)="onCancellationChargesType('amount')"
                    />
                    <label for="optionsRadios1"> Amount in $ </label>
                  </div>
                </div>
                <input
                  name="amount"
                  type="number"
                  formControlName="amount"
                  class="form-control"
                />
                <span
                  *ngIf="
                    (cancellationChargesForm.controls['amount'].dirty ||
                      cancellationChargesForm.controls['amount'].touched) &&
                    cancellationChargesForm.controls['amount'].hasError(
                      'required'
                    )
                  "
                  class="text-red"
                >
                  Amount is required.
                </span>
                <span
                  *ngIf="
                    (cancellationChargesForm.controls['amount'].dirty ||
                      cancellationChargesForm.controls['amount'].touched) &&
                    cancellationChargesForm.controls['amount'].hasError('min')
                  "
                  class="text-red"
                >
                  Value should be greater than 0.
                </span>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <div class="extra-radio">
                  <div class="radio p-0">
                    <input
                      type="radio"
                      name="optionsRadios"
                      id="optionsRadios2"
                      value="option2"
                      [(checked)]="isPercentChecked"
                      (change)="onCancellationChargesType('percent')"
                    />
                    <label for="optionsRadios2">
                      Percentage Of total amount
                    </label>
                  </div>
                </div>
                <input
                  name="percent"
                  type="number"
                  formControlName="percent"
                  class="form-control"
                />
                <span
                  *ngIf="
                    (cancellationChargesForm.controls['percent'].dirty ||
                      cancellationChargesForm.controls['percent'].touched) &&
                    cancellationChargesForm.controls['percent'].hasError(
                      'required'
                    )
                  "
                  class="text-red"
                >
                  Percentage is required.
                </span>
                <span
                  *ngIf="
                    (cancellationChargesForm.controls['percent'].dirty ||
                      cancellationChargesForm.controls['percent'].touched) &&
                    (cancellationChargesForm.controls['percent'].hasError(
                      'min'
                    ) ||
                      cancellationChargesForm.controls['percent'].hasError(
                        'max'
                      ))
                  "
                  class="text-red"
                >
                  Value should be between 1 to 100.
                </span>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <div class="extra-radio">
                  <div class="radio p-0">
                    <input
                      type="radio"
                      name="optionsRadios"
                      id="optionsRadios3"
                      value="option3"
                      [(checked)]="isNightsChecked"
                      (change)="onCancellationChargesType('nights')"
                    />
                    <label for="optionsRadios3"> No. of Nights </label>
                  </div>
                </div>
                <input
                  name="nights"
                  type="number"
                  formControlName="nights"
                  class="form-control"
                />
                <span
                  *ngIf="
                    (cancellationChargesForm.controls['nights'].dirty ||
                      cancellationChargesForm.controls['nights'].touched) &&
                    cancellationChargesForm.controls['nights'].hasError(
                      'required'
                    )
                  "
                  class="text-red"
                >
                  No of nights is required.
                </span>
                <span
                  *ngIf="
                    (cancellationChargesForm.controls['nights'].dirty ||
                      cancellationChargesForm.controls['nights'].touched) &&
                    cancellationChargesForm.controls['nights'].hasError('min')
                  "
                  class="text-red"
                >
                  Value should be greater than 0.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Configure the Charges FOR TAX POLICY-->
    <div
      class="card-box"
      *ngIf="selectedPolicyType && selectedPolicyType.name === 'Tax Policy'"
    >
      <div class="card-head">
        <header>Configure the Charges</header>
      </div>
      <div class="card-body">
        <p style="font-size: 12px;">* Drag and drop the item to re-order.</p>

        <div class="tax-row" cdkDropList  (cdkDropListDropped)="dropTaxForm($event)" formArrayName="tax_charges">
          <div
            style="background: white;"
            cdkDrag
            class="row"
            *ngFor="
              let item of taxChargesForm.controls;
              let i = index;
              let last = last;
              let first = first
            "
            [formGroupName]="i"
          >
            <div class="col-md-3">
              <div class="form-group" >
                <label class="min-height form_label text-red"
                  >Calculation Order</label>
                <input
                disabled
                [value]="i+1"
                type="text"
                  name=""
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="min-height form_label text-red"
                  >Tax description</label
                >
                <input
                  formControlName="tax_description"
                  type="text"
                  class="form-control"
                />
                <span
                  *ngIf="
                    (item.controls['tax_description'].dirty ||
                      item.controls['tax_description'].touched) &&
                    item.controls['tax_description'].hasError('required')
                  "
                  class="text-red"
                >
                  Tax description is required.
                </span>

                <span
                  *ngIf="
                    (item.controls['tax_description'].dirty ||
                      item.controls['tax_description'].touched) &&
                    item.controls['tax_description'].hasError('unique')
                  "
                  class="text-red"
                >
                  Tax description must be unique.
                </span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label class="min-height form_label text-red"
                  >Value (in %)</label
                >
                <input
                  formControlName="percent"
                  type="number"
                  class="form-control"
                />
                <span
                  *ngIf="
                    (item.controls['percent'].dirty ||
                      item.controls['percent'].touched) &&
                    item.controls['percent'].hasError('required')
                  "
                  class="text-red"
                >
                  Tax percent value is required.
                </span>
                <span
                  *ngIf="
                    (item.controls['percent'].dirty ||
                      item.controls['percent'].touched) &&
                    item.controls['percent'].hasError('min')
                  "
                  class="text-red"
                >
                  Value must be greater than 0.
                </span>
                <span
                  *ngIf="
                    (item.controls['percent'].dirty ||
                      item.controls['percent'].touched) &&
                    item.controls['percent'].hasError('max')
                  "
                  class="text-red"
                >
                  Value must be less than 100.
                </span>
              </div>
            </div>

            <div class="col-md-2" *ngIf="!isViewMode">
              <label class="min-height"></label>
              <button
                *ngIf="!first"
                (click)="removeTaxChargesFormAtIndex(i)"
                class="btn red btn-outline btn-circle m-b-10 mr-2"
              >
                <i aria-hidden="true" class="fa fa-trash-o"></i>
              </button>
              <button
                *ngIf="last"
                (click)="addTaxChargesForm()"
                class="btn btn-outline black-bgcolor btn-circle m-b-10"
              >
                <i class="fa fa-plus" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Configure the Charges FOR PAYMENT POLICY-->
    <div
      class="card-box payment-box"
      *ngIf="selectedPolicyType && selectedPolicyType.name === 'Payment Policy'"
    >
      <div class="card-head">
        <header>Configure the charges</header>
      </div>
      <div class="card-body" formGroupName="payment_charges">
        <div class="row">
          <div
            style="margin-left: 35px"
            class="col-md-12 checkbox checkbox-black d-inline-block"
          >
            <input
              id="instantBooking"
              type="checkbox"
              formControlName="instant_booking_charge"
            />
            <label for="instantBooking">
              <b>Amount will be charged on instant booking</b>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label><b>The credit card will be charged</b></label>
          </div>
          <div class="col-md-3">
            <div class="radio">
              <input
                [(checked)]="isCreditCardFullAmountChecked"
                (change)="onPaymentChargesType('fullAmount')"
                type="radio"
                name="credit_card_charge"
                id="full-amu"
                value="option1"
              />
              <label for="full-amu"> Full Amount </label>
            </div>
            <div class="form-group">
              <label class="form_label mt-1 text-red"
                >Full Amount will be charged</label
              >
            </div>
          </div>

          <div class="col-md-3">
            <div class="radio radio">
              <input
                [(checked)]="isCreditCardNightsChecked"
                (change)="onPaymentChargesType('nights')"
                type="radio"
                name="credit_card_charge"
                id="no-night"
                value="option1"
              />
              <label for="no-night"> No.of night(s) </label>
            </div>
            <div class="form-group">
              <input
                type="number"
                formControlName="credit_card_nights"
                class="form-control"
              />
              <span
                *ngIf="
                  (paymentChargesForm.controls['credit_card_nights'].dirty ||
                    paymentChargesForm.controls['credit_card_nights']
                      .touched) &&
                  paymentChargesForm.controls['credit_card_nights'].hasError(
                    'required'
                  )
                "
                class="text-red"
                >This value is required.
              </span>
              <span
                *ngIf="
                  (paymentChargesForm.controls['credit_card_nights'].dirty ||
                    paymentChargesForm.controls['credit_card_nights']
                      .touched) &&
                  paymentChargesForm.controls['credit_card_nights'].hasError(
                    'min'
                  )
                "
                class="text-red"
                >Value should be greater than 0.
              </span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="radio radio">
              <input
                [(checked)]="isCreditCardPercentChecked"
                (change)="onPaymentChargesType('percent')"
                type="radio"
                name="credit_card_charge"
                id="percent"
                value="option1"
              />
              <label for="percent"> Percentage of Amount </label>
            </div>
            <div class="form-group">
              <input
                type="number"
                formControlName="credit_card_percent"
                class="form-control"
              />
              <span
                *ngIf="
                  (paymentChargesForm.controls['credit_card_percent'].dirty ||
                    paymentChargesForm.controls['credit_card_percent']
                      .touched) &&
                  paymentChargesForm.controls['credit_card_percent'].hasError(
                    'required'
                  )
                "
                class="text-red"
                >This value is required.
              </span>
              <span
                *ngIf="
                  (paymentChargesForm.controls['credit_card_percent'].dirty ||
                    paymentChargesForm.controls['credit_card_percent']
                      .touched) &&
                  paymentChargesForm.controls['credit_card_percent'].hasError(
                    'min'
                  )
                "
                class="text-red"
                >Value should be greater than 0.
              </span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="radio radio">
              <input
                [(checked)]="isCreditCardAmountChecked"
                (change)="onPaymentChargesType('amount')"
                type="radio"
                name="credit_card_charge"
                id="amount"
                value="option1"
              />
              <label for="amount"> Amount ($) </label>
            </div>
            <div class="form-group">
              <input
                type="number"
                formControlName="credit_card_amount"
                class="form-control"
              />
              <span
                *ngIf="
                  (paymentChargesForm.controls['credit_card_amount'].dirty ||
                    paymentChargesForm.controls['credit_card_amount']
                      .touched) &&
                  paymentChargesForm.controls['credit_card_amount'].hasError(
                    'required'
                  )
                "
                class="text-red"
                >This value is required.
              </span>
              <span
                *ngIf="
                  (paymentChargesForm.controls['credit_card_amount'].dirty ||
                    paymentChargesForm.controls['credit_card_amount']
                      .touched) &&
                  paymentChargesForm.controls['credit_card_amount'].hasError(
                    'min'
                  )
                "
                class="text-red"
                >Value should be greater than 0.
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label><b>The amount once paid is refundable</b></label>
          </div>
          <div class="col-md-2">
            <div class="radio">
              <input
                name="amount_refundable"
                type="radio"
                id="yes"
                (change)="onAmountRefundableChange()"
                [(checked)]="isAmountRefundable"
              />
              <label for="yes">Yes</label>
            </div>
          </div>
          <div class="col-md-2">
            <div class="radio">
              <input
                name="amount_refundable"
                type="radio"
                id="no"
                (change)="onAmountRefundableChange()"
                [(checked)]="!isAmountRefundable"
              />
              <label for="no">No</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Configure the Charges FOR CHILD POLICY-->
    <div
      class="card-box"
      *ngIf="selectedPolicyType && selectedPolicyType.name === 'Child Policy'"
    >
      <div class="card-head">
        <header>Configure the charges</header>
      </div>
      <div class="card-body" formGroupName="child_charges">
        <div class="policy-childinfo">
          <div class="d-flex">
            <label><b>Allow Children</b></label>
            <div class="child-content">
              <div class="radio">
                <input
                  type="radio"
                  name="optionsRadios"
                  id="yes"
                  value="option1"
                  [(checked)]="isAllowChild"
                  (change)="onToggleAllowChild()"
                />
                <label for="yes"> Yes </label>
              </div>
              <div class="radio">
                <input
                  type="radio"
                  name="optionsRadios"
                  id="No"
                  value="option1"
                  [(checked)]="!isAllowChild"
                  (change)="onToggleAllowChild()"
                />
                <label for="No"> No </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form_label">Maximum Child age (years)</label>
                <input
                  type="number"
                  formControlName="max_child_age"
                  class="form-control"
                />
                <span
                  *ngIf="
                    (childChargesForm.controls['max_child_age'].dirty ||
                      childChargesForm.controls['max_child_age'].touched) &&
                    childChargesForm.controls['max_child_age'].hasError(
                      'required'
                    )
                  "
                  class="text-red"
                  >This field is required.
                </span>
                <span
                  *ngIf="
                    (childChargesForm.controls['max_child_age'].dirty ||
                      childChargesForm.controls['max_child_age'].touched) &&
                    childChargesForm.controls['max_child_age'].hasError('min')
                  "
                  class="text-red"
                  >Value should be greater than 0.
                </span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form_label"
                  >Child under age (years) will not be charged</label
                >
                <input
                  type="number"
                  formControlName="charge_not_apply_age"
                  class="form-control"
                />
                <span
                  *ngIf="
                    (childChargesForm.controls['charge_not_apply_age'].dirty ||
                      childChargesForm.controls['charge_not_apply_age']
                        .touched) &&
                    childChargesForm.controls['charge_not_apply_age'].hasError(
                      'required'
                    )
                  "
                  class="text-red"
                  >This field is required.
                </span>

                <span
                  *ngIf="
                    (childChargesForm.controls['charge_not_apply_age'].dirty ||
                      childChargesForm.controls['charge_not_apply_age']
                        .touched) &&
                    childChargesForm.controls['charge_not_apply_age'].hasError(
                      'min'
                    )
                  "
                  class="text-red"
                  >Value should be greater than 0.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>

  <!-- HOTEL LISTING -->
  <div class="card-box pol-can">
    <div class="card-head">
      <header>Hotel Selection</header>
    </div>
    <div class="card-body">
      <label>
        <b>Filter By Initial Alphabet</b>
      </label>
      <div class="row">
        <div
          class="col-auto mb-1 pr-1"
          *ngFor="let item of alphabetList"
          [ngClass]="{
            'pl-2': item.name !== 'A' || item.name !== 'X',
            'pl-0': item.name === 'A' || item.name === 'X'
          }"
        >
          <button
            [ngClass]="{
              'btn-primary': currentAlphabet
                ? currentAlphabet.id === item.id
                : false,
              'btn-outline-primary': currentAlphabet
                ? currentAlphabet.id !== item.id
                : true
            }"
            class="btn mr-1"
            (click)="sortByAlphabet(item)"
          >
            {{ item.name }}
          </button>
        </div>
      </div>
      <table
        mat-table
        [dataSource]="displayHotelList"
        matSort
        (matSortChange)="sortHotels($event)"
      >
        <ng-container matColumnDef="checked">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            title="Sort By Selection"
          >
            Selection
          </th>
          <td mat-cell *matCellDef="let element">
            <input
              [disabled]="isViewMode"
              (change)="onHotelCheckChange(element)"
              class="checkbox checkbox-black"
              type="checkbox"
              name="room"
              [(checked)]="element.checked"
            />
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            title="Sort By Hotel Name"
          >
            Hotel Name
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="cityName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            title="Sort By City Name"
          >
            City Name
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.cityName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="countryName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            title="Sort By Country Name"
          >
            Country Name
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.countryName }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[10, 20, 30]"
        (page)="onPageChange($event)"
      ></mat-paginator>
    </div>
    <div style="margin-left: 20px;" *ngIf="!isViewMode">
      <button
        (click)="onSelectAllHotelsChanged()"
        type="button"
        class="btn btn-outline black-bgcolor btn-circle m-b-10 mr-2"
      >
      {{this.selectAllHotels?'Deselect':'Select' }} all hotels
      </button>

      <button
        (click)="onSelectCurrentPageHotel()"
        type="button"
        class="btn btn-outline red btn-circle m-b-10 mr-2"
      >
      {{this.selectCurrentPageHotels?'Deselect':'Select' }} hotels of this page
      </button>
      <!-- <header>
         <div class="checkbox checkbox-black d-inline-block">
          <input
            id="checkboxbg8"
            type="checkbox"
            [(checked)]="selectAllHotels"
            (change)="onSelectAllHotelsChanged()"
          />
          <label for="checkboxbg8">Apply on all hotels</label>
        </div>
        <div class="checkbox checkbox-black d-inline-block">
          <input
            id="checkboxbg123"
            type="checkbox"
            [(checked)]="selectCurrentPageHotels"
            (change)="onSelectCurrentPageHotel()"
          />
          <label for="checkboxbg123">Select hotels of this page</label>
        </div>
      </header> -->
    </div>
  </div>
  <div *ngIf="!isViewMode" class="d-flex" style="justify-content: flex-end">
    <button
      data-toggle="modal"
      data-target="#confirmation"
      data-backdrop="static"
      data-keyboard="false"
      class="btn btn-round btn-default custom-btn"
    >
      Submit
    </button>
  </div>
</form>
<div *ngIf="showLoader" class="d-flex justify-content-center position-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div class="modal fade" id="confirmation">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h2>Are you sure?</h2>
        <button
          type="button"
          id="closeModal"
          #closeBtnDelete
          class="close"
          data-dismiss="modal"
        >
          &times;
        </button>
      </div>
      <div class="modal-body delate-popup text-center">
        <form>
          <div class="form-group">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <h2>Submit this form !</h2>
          </div>
          <div class="form-group">
            <button
              (click)="onSubmit()"
              type="submit"
              data-dismiss="modal"
              class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn btn-warning mr-2"
            >
              Submit
            </button>
            <button
              type="button"
              class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn btn-danger"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
