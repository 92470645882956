import { Component, OnInit } from '@angular/core';
import { token } from './_constants/constants';
import { HotelSyncToolService } from './_services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'travel-admin-regular';
  token: any;

  constructor(
    private hotelSyncToolService: HotelSyncToolService
  ) { }

  ngOnInit() {
    this.token = localStorage.getItem('token');
    this.loadData();
  }

  loadData() {
    this.hotelSyncToolService.reloadAcceptedHotels.subscribe((res) => {
      if (res) {
        this.token = '';
      }
    });
  }
}
