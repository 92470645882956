import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { PropertyService } from '../property-service.service';

@Component({
  selector: 'app-view-property',
  templateUrl: './view-property.component.html',
  styleUrls: ['./view-property.component.scss'],
})
export class ViewPropertyComponent implements OnInit {

  propertyId = this.route.snapshot.paramMap.get('propertyId');
  mode = this.route.snapshot.paramMap.get('mode');
  viewOption = 'propertyInformation';
  propertyDetails: any = {};
  isResponse = false;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private spinner: NgxSpinnerService,
    private propertyService: PropertyService
  ) { }

  ngOnInit(): void {
    if (this.mode && this.propertyId) {
      this.getPropertyDetailsById();
    }
  }

  getPropertyDetailsById(): void {
    this.spinner.show();
    this.propertyService.getHotelPropertyDetailsStep1(this.propertyId)
      .subscribe((response: any) => {
        if (response['success']) {
          this.propertyDetails = response.data;
          this.propertyDetails['propertyId'] = this.propertyId;
          this.propertyDetails['mode'] = this.mode;
          this.isResponse = true;
          if (Object.keys(this.propertyDetails.stopSales).length > 0) {
            this.propertyDetails['stopsSells'] = true;
          }
          if (
            this.propertyDetails.room &&
            this.propertyDetails.room.length > 0
          ) {
            for (const iterator of this.propertyDetails.room) {
              if (iterator.rates.length > 0) {
                this.propertyDetails['isShowRates'] = true;
              }
            }
          }
        }
        this.spinner.hide();
      }, (error) => {
        console.log(error);
        this.spinner.hide();
      });
  }

  changeTab(tabName: string): void {
    this.propertyService.reloadPrevTab.next(true);
    document.getElementById(tabName).click();
  }

  goToBackButton(): void {
    this.location.back();
  }
}
