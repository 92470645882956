import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { NoopAnimationsModule, BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatGoogleMapsAutocompleteModule } from "@angular-material-extensions/google-maps-autocomplete";
import { AgmCoreModule } from "@agm/core";
import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CommonsModule } from "./commons/commons.module";
import { PromotionsModule } from "./promotions/promotions.module";
import { BookingModule } from "./booking/booking.module";
import { ReportModule } from "./report/report.module";
import { PoliciesModule } from "./policies/policies.module";
import { UploaderComponent } from "./uploader/uploader.component";
import { DefaultLayoutComponent } from "./default-layout/default-layout.component";
import { RouterModule } from "@angular/router";

const config = {
  apiKey: "AIzaSyCa6_sRBmCLJzi8GYmma4bVitTZnfAPwc8",
  authDomain: "hotel-admin-875f5.firebaseapp.com",
  projectId: "hotel-admin-875f5",
  storageBucket: "hotel-admin-875f5.appspot.com",
  messagingSenderId: "457474291904",
  appId: "1:457474291904:web:50b0d1cb3e54d2f0a91525",
  measurementId: "G-KX38ECV3L0"
};

@NgModule({
  declarations: [
    AppComponent,
    UploaderComponent,
    DefaultLayoutComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    CommonsModule,
    NgxSpinnerModule,
    PromotionsModule,
    BookingModule,
    ReportModule,
    PoliciesModule,
    NoopAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
    }),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyApa4-zkv_t7-EzGOYDzT_koCXcnbj5pZs",
      libraries: ["places"],
    }),
    MatGoogleMapsAutocompleteModule,
    AngularFireModule.initializeApp(config),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule,
  ],
  providers: [
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
