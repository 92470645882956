import {
  Component,
  OnInit,
  Injector,
  ViewChild,
  ElementRef,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  MatBottomSheetRef,
  MatBottomSheet,
} from '@angular/material/bottom-sheet';
import { PropertyService } from '../../property-service.service';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { FacilityModalComponent } from '../..';
import { MatDialog } from '@angular/material/dialog';
import { MastersService } from 'src/app/masters/masters.service';
import { PropertyFacilityService } from 'src/app/_services';

@Component({
  selector: 'app-add-property-step2',
  templateUrl: './add-property-step2.component.html',
  styleUrls: ['./add-property-step2.component.css'],
})
export class AddPropertyStep2Component implements OnInit {
  @ViewChild('closeBtnAdd') closeBtnAdd: ElementRef;
  @ViewChild('closeBtnAdd1') closeBtnAdd1: ElementRef;
  task: AngularFireUploadTask;
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  allFacility: any;
  errorStatus: boolean = true;
  @ViewChild('f')
  private form;
  public remainsavecount: number = 0;
  public hotelId: any;
  public isFormSubmit: Boolean = false;
  public saveFacility: any = [];
  public facId: any;
  public uncheckFacId: any;
  public currentImageIndex: number;
  public imagesArr: any = [];
  public openImageModal = false;
  public facName: any;
  public modalDesc: string = '';
  public hotelName: any = '';
  @Input() propertyDetails: any = {};
  @Output() changeNextTab: EventEmitter<any> = new EventEmitter<any>();
  isHovering: boolean;
  public editMode: Boolean = false;
  public urlSaved: any = [];
  files: File[] = [];
  public urls: any = [];
  public deleteUrlId: any = [];
  public mode: string;
  public backhotelid: any;
  propertyFacilityCategory: any = [];
  queryParameter: any;

  constructor(
    public storage: AngularFireStorage,
    private router: Router,
    public propertyService: PropertyService,
    public mastersService: MastersService,
    private propertyFacilityService: PropertyFacilityService,
    private activatedRoute: ActivatedRoute,
    private injector: Injector,
    public _bottomSheet: MatBottomSheet,
    private spinner: NgxSpinnerService,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getPropertyFacilitiesCategory();
    window.scrollTo(0, 0);
    if (
      Object.keys(this.propertyDetails).length > 0 &&
      this.propertyDetails.propertyId &&
      this.propertyDetails.mode
    ) {
      this.mode = this.propertyDetails.mode;
      this.hotelId = this.propertyDetails.propertyId;
    } else {
      this.hotelId = this.activatedRoute.snapshot.paramMap.get('hotelId');
    }
    if (this.activatedRoute.snapshot.queryParams.inventory_allocation) {
      this.queryParameter = {
        queryParams: {
          inventory_allocation: this.activatedRoute.snapshot.queryParams.inventory_allocation
        }
      }
    }
    this.getAllFacility();
  }

  getStep1PropertyDetails(id) {
    this.spinner.show();
    this.propertyService
      .getHotelPropertyDetailsStep1(id?.trim())
      .subscribe((data) => {
        if (data['success']) {
          this.propertyDetails = data['data'];
          this.setPropertyDetails(this.propertyDetails);
          this.hotelName = data['data']['name'];
          if (data['data']['facilities'].length > 0) {
            this.backhotelid = id;
            let checkedAlreadyFac = data['data']['facilities'];
            for (let i = 0; i < checkedAlreadyFac.length; i++) {
              this.setcheckdata(checkedAlreadyFac[i]['facilityId']);
              let obj = {
                facilityId: checkedAlreadyFac[i]['facilityId'].toString(),
                description: checkedAlreadyFac[i]['description'],
                images: checkedAlreadyFac[i]['images'],
              };
              this.saveFacility.push(obj);
            }
          }
        }
        this.spinner.hide();
      });
  }

  getPropertyFacilitiesCategory() {
    this.propertyFacilityService.getPropertyFacilityCategory().subscribe((response: any) => {
      if (response.success) {
        this.propertyFacilityCategory = response.data;
      }
    });
  }

  getAllFacility() {
    this.spinner.show();
    this.propertyService.getAllFacilities().subscribe((res: any) => {
      if (res['success']) {
        this.allFacility = res['data'].sort((a, b) => this.changeOrder(a, b));
        this.getStep1PropertyDetails(this.hotelId);
      }
      this.spinner.hide();
    });
  }

  /**
   * change array into alphabet order
   * @param a params
   * @param b params
   * @returns return alphabet order
   */
  changeOrder(a: any, b: any) {
    if (a.name < b.name) { return -1; }
    if (a.name > b.name) { return 1; }
    return 0;
  }

  setPropertyDetails(data: any) {
    this.hotelName = data['name'];
    if (data['facilities'].length > 0) {
      let checkedAlreadyFac = data['facilities'];
      for (let i = 0; i < checkedAlreadyFac.length; i++) {
        setTimeout(() => {
          this.setcheckdata(checkedAlreadyFac[i]['facilityId']);
        }, 10);
        let obj = {
          facilityId: checkedAlreadyFac[i]['facilityId'].toString(),
          description: checkedAlreadyFac[i]['description'],
          images: checkedAlreadyFac[i]['images'],
        };
        this.saveFacility.push(obj);
      }
    }
  }
  saveData() {
    if (this.urls.length > 0) {
      this.errorStatus = true;
      this.saveFacility.push({
        facilityId: this.facId,
        description: this.modalDesc,
        images: this.urls,
      });
      this.remainsavecount += 1;
      this.setcheckdata(this.facId);
      this.modalDesc = '';
      this.urls = [];
      this.files = [];
      this.closeBtnAdd.nativeElement.click();
      $('#facilities-modal').hide();
      this.removemodal('filenull');
    } else this.errorStatus = false;
  }

  saveerror: boolean = true;
  step2AddProperty() {
    this.isFormSubmit = true;
    const toaster = this.injector.get(ToastrService);
    if (this.saveFacility.length > 0) {
      this.saveerror = true;
      let obj = {
        facilities: this.saveFacility,
      };

      this.spinner.show();
      this.propertyService
        .addPropertyStep2(this.hotelId, obj)
        .subscribe((data) => {
          if (data['success']) {
            this.remainsavecount = 0;
            toaster.success(data['message'], 'Success', {
              timeOut: 3000,
            });

            this.propertyDetails.facilities = obj.facilities;
            this.goNext();
          } else {
            toaster.success(data['message'], 'Error', {
              timeOut: 3000,
            });
          }
          this.spinner.hide();
        });
    } else this.saveerror = false;
  }

  async updateFac() {
    let ind = this.saveFacility.findIndex(
      (item) => item['facilityId'] === this.facId
    );
    if (ind !== -1) {
      this.saveFacility.splice(ind, 1);
      if (this.deleteUrlId.length > 0) {
        for (let i = 0; i < this.deleteUrlId.length; i++) {
          await this.deleteFile(this.deleteUrlId[i]);
        }
      }
      await this.urls.push(...this.urlSaved);
      if (this.urls.length > 0) {
        this.errorStatus = true;
        await this.saveFacility.push({
          facilityId: this.facId,
          description: this.modalDesc,
          images: this.urls,
        });
        this.urlSaved = [];
        this.deleteUrlId = [];
        this.setcheckdata(this.facId);
        this.modalDesc = '';
        this.urls = [];
        this.files = [];
        this.editMode = false;
        this.closeBtnAdd.nativeElement.click();
        $('#facilities-modal').hide();
        this.removemodal('filenull');
      } else this.errorStatus = false;
    }
  }

  deleteFile(imagePath) {
    this.storage.storage.refFromURL(imagePath).delete();
  }

  async deleteFac() {
    let ind = this.saveFacility.findIndex(
      (item) => item['facilityId'] === this.facId
    );
    if (ind !== -1) {
      if (this.deleteUrlId.length > 0) {
        for (let i = 0; i < this.deleteUrlId.length; i++) {
          await this.deleteFile(this.deleteUrlId[i]);
        }
      }
      this.urlSaved = [];
      this.urls = [];
      this.deleteUrlId = [];
      this.saveFacility.splice(ind, 1);
      this.modalDesc = '';
      $('#lbl' + this.facId).css('color', '#000000de');
      this.urls = [];
      this.files = [];
      this.urlSaved = [];
      this.editMode = false;
      this.closeBtnAdd.nativeElement.click();
      $('#facilities-modal').hide();
      this.removemodal('filenull');
    }
  }

  onChecked(checked, id, isFrom?: string) {
    if (checked === false) {
      this.facId = id;
      let ind = this.saveFacility.findIndex(
        (item) => item['facilityId'] === id
      );
      if (ind > -1) {
        if (isFrom == 'viewIcon') {
          this.editMode = true;
          this.modalDesc = this.saveFacility[ind]['description'];
          this.urlSaved = this.saveFacility[ind]['images'];
          let ind1 = this.allFacility.findIndex((item) => item['id'] === id);
          this.facName = this.allFacility[ind1]['name'];
          document.getElementById('openModalButton').click();
          if (this.mode) this.removemodal();
        } else {
          this.uncheckFacId = id;
          window.scrollTo(0, 0);
          document.getElementById('uncheckModalButton').click();
          if (this.mode) this.removemodal();
        }
      }
    } else {
      if (isFrom == 'viewIcon') {
        this.editMode = true;
        let ind = this.saveFacility.findIndex(
          (item) => item['facilityId'] === id
        );
        this.modalDesc = this.saveFacility[ind]['description'];
        this.urlSaved = this.saveFacility[ind]['images'];
        let ind1 = this.allFacility.findIndex((item) => item['id'] === id);
        this.facName = this.allFacility[ind1]['name'];
        this.facId = id;
      } else {
        this.facId = id;
        let ind = this.allFacility.findIndex((item) => item['id'] === id);
        this.facName = this.allFacility[ind]['name'];
      }

      document.getElementById('openModalButton').click();
      if (this.mode) {
        $('.modal-backdrop').remove();
      }
      //this.removemodal();
    }
  }

  onClose() {
    if (this.editMode === true) {
      let ind = this.saveFacility.findIndex(
        (item) => item['facilityId'] === this.facId
      );
      if (ind !== -1) {
        this.setcheckuncheck(this.facId, true);
        this.modalDesc = '';
        this.removemodal('filenull');
        this.urls = [];
        this.urlSaved = [];
        this.files = [];
        this.editMode = false;
      }
    } else {
      let ind = this.saveFacility.findIndex(
        (item) => item['facilityId'] === this.facId
      );
      if (ind === -1) {
        this.setcheckuncheck(this.facId, false);
        this.modalDesc = '';
        this.removemodal('filenull');
        this.urls = [];
        this.files = [];
      }
    }
  }

  @ViewChild('closeBtnConfirm') closeBtnConfirm: ElementRef;
  sameNameFiles: File[] = [];
  onDrop(files: FileList) {
    let status = false;
    for (let i = 0; i < files.length; i++) {
      if (this.files.length > 0) {
        let index = this.files.findIndex(
          (item) => item.name === files.item(i).name
        );
        let indexUrl = this.urls.findIndex(
          (item) =>
            this.storage.storage.refFromURL(item).name.substring(14) ===
            files.item(i).name
        );
        var imagelistindex = this.urlSaved.findIndex(
          (item) =>
            this.storage.storage.refFromURL(item).name.substring(14) ===
            files.item(i).name
        );
        if (index > -1) {
          if (indexUrl == -1) {
            this.files.splice(index, 1);
            index = this.files.findIndex(
              (item) => item.name === files.item(i).name
            );
          }
        }
        if (imagelistindex > -1) {
          this.sameNameFiles.push(files.item(i));
        }
        if (index !== -1) {
          this.sameNameFiles.push(files.item(i));
        } else {
          if (this.sameNameFiles.length == 0) this.files.push(files.item(i));
        }
      } else {
        var imagelistindex = this.urlSaved.findIndex(
          (item) =>
            this.storage.storage.refFromURL(item).name.substring(14) ===
            files.item(i).name
        );
        if (imagelistindex > -1) {
          this.sameNameFiles.push(files.item(i));
        } else this.files.push(files.item(i));
      }
      if (i === files.length - 1 && this.sameNameFiles.length > 0) {
        status = true;
      }
    }
    if (this.sameNameFiles.length > 0 && status === true) {
      $('#confirmModalButton').click();
      status = false;
      setTimeout(() => {
        if (this.mode && this.mode === 'edit') {
          $('.modal-backdrop').remove();
        }
      }, 500);
    }
    // this.urlSaved = list;
  }

  onImgUpload(url) {
    this.urls.push(url);
    this.myInputVariable.nativeElement.value = '';
  }

  onImgDelete(str) {
    this.deleteFile(str);
    if (this.urls.indexOf(str) !== -1) {
      let indexUrl = this.urls.indexOf(str);
      this.urls.splice(indexUrl, 1);
    }
  }

  removeImg(p) {
    this.deleteUrlId.push(this.urlSaved[p]);
    this.urlSaved.splice(p, 1);
  }

  yesUncheck1() {
    for (let i = 0; i < this.sameNameFiles.length; i++) {
      this.files.push(this.sameNameFiles[i]);
    }
    this.sameNameFiles = [];
    this.closeBtnConfirm.nativeElement.click();
    this.removemodal();
  }

  noUncheck1() {
    this.closeBtnConfirm.nativeElement.click();
    this.removemodal();
    this.sameNameFiles = [];
    this.myInputVariable.nativeElement.value = '';
  }

  removemodal(setfilenull?: string) {
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    if (setfilenull == 'filenull') $('#fileControl').val('');
  }

  setcheckdata(facilityid) {
    $('#chk' + facilityid).prop('checked', 'checked');
    $('#lbl' + facilityid).css('color', 'blue');
    // $('#chk' + facilityid).removeAttr('disabled');
    $('#viewFacilityIcon' + facilityid).removeAttr('hidden');
  }
  setcheckuncheck(id, value) {
    $('#chk' + id).prop('checked', value);
  }

  yesUncheck() {
    let ind = this.saveFacility.findIndex(
      (item) => item['facilityId'] === this.uncheckFacId
    );
    if (ind !== -1) {
      this.saveFacility.splice(ind, 1);
      this.closeBtnAdd1.nativeElement.click();
      this.facName = '';
      this.uncheckFacId = '';
      this.modalDesc = '';
      this.removemodal('filenull');
      this.urls = [];
      this.files = [];
    }
  }

  noUncheck() {
    this.setcheckuncheck(this.uncheckFacId, true);
  }

  openImageAsModal(index: number): void {
    this.currentImageIndex = index;
    this.imagesArr = this.urlSaved;
    this.openImageModal = true;
  }

  closeImageModal(event) {
    this.openImageModal = false;
  }

  facilityEditorModal(tes: any, item: any, icon?: any) {
    const detailsData = this.propertyDetails?.facilities.filter(data => data.facilityId == item.id);
    const dialogRef = this.matDialog.open(FacilityModalComponent, {
      width: '40%',
      height: '650px',
      disableClose: true,
      data: {
        item,
        hotel_id: this.hotelId,
        details: detailsData,
        mode: this.mode,
        accepted_hotel_id: this.propertyDetails.id,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (this.mode !== 'view') {
        this.getAllFacility();
        this.spinner.hide();
      }
    });
  }

  goNext() {
    if (this.mode && this.mode === "edit") {
      this.changeNextTab.emit("roomRate");
    } else {
      this.router.navigate(["/property/step-4-add-property", this.hotelId], this.queryParameter);
    }
  }

  remainsavecounterror = true;
  gotoBack(): void {
    if (this.remainsavecount == 0) {
      this.remainsavecounterror = true;
      if (this.mode && this.mode === 'edit') {
        this.changeNextTab.emit('roomType');
      } else {
        this.router.navigate(['/property/step-3-add-property', this.hotelId], this.queryParameter);
      }
    } else this.remainsavecounterror = false;
  }
}

@Component({
  selector: 'bottom-sheet-overview-example-sheet',
  template:
    "<div class='row'><div class='col-md-8' style='font-size:14px'>Do you want to uncheck this facility, all data will be lost?</div><div class='col-md-2'><button type='button' data-dismiss='modal'      class='float-right btn btn-round btn-default custom-btn' (click)='noUncheck()'>No</button></div><div class='col-md-2'><button type='button' (click)='yesUncheck()'      class='float-left btn btn-round btn-default custom-btn'>Yes</button></div></div>",
})
export class BottomSheetOverviewExampleSheet {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetOverviewExampleSheet>
  ) { }
  noUncheck() {
    this._bottomSheetRef.dismiss();
  }

  yesUncheck() {
    this._bottomSheetRef.dismiss();
  }
}
