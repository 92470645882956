import { Component, ChangeDetectorRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AbstractBaseComponent, ConfirmDialogComponent } from '../../../shared';
import { PropertyService } from '../../property-service.service';

@Component({
    selector: 'app-hotel-overview',
    templateUrl: './hotel-overview.component.html',
    styleUrls: ['./hotel-overview.component.scss']
})

export class HotelOverviewComponent extends AbstractBaseComponent implements OnInit {

    @Input() propertyDetails: any = {};
    @Output() changeNextTab: EventEmitter<any> = new EventEmitter<any>();
    overviewForm: FormGroup;
    mode: string;
    hotelId: any;
    frolaKey: any;

    constructor(
        private toastr: ToastrService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private spinner: NgxSpinnerService,
        private propertyService: PropertyService,
        private formBuilder: FormBuilder,
        public changeDetectorRef: ChangeDetectorRef,
        public matDialog: MatDialog
    ) {
        super();
    }

    ngOnInit() {
        this.initialization();
    }

    initialization() {
        this.frolaKey = this.froalaUploads();
        this.getData();
        this.initialData();
        this.getPropertyDetailsById();
    }

    initialData(data?: any) {
        this.overviewForm = this.formBuilder.group({
            hotel_overview: [data?.hotel_overview, Validators.required]
        });
    }

    async getData() {
        this.mode = this.propertyDetails.mode || this.activatedRoute.snapshot.paramMap.get('mode');
        this.hotelId = await this.activatedRoute.snapshot.paramMap.get('propertyId') || this.activatedRoute.snapshot.params.hotelId;
        setTimeout(() => {
            if (this.propertyDetails?.hotelOverview) {
                this.initialData(this.propertyDetails?.hotelOverview);
            }
        }, 500);
    }

    /**
     * confirmation dialog for delete property
     */
    confirmationDialog() {
        const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
            width: '350px',
            height: '370px',
            disableClose: true,
            data: {
                title: '',
                message: 'Do you want to do Inventory Allocation Also?',
                okBtnLabel: 'Yes',
                cancelBtnLabel: 'No'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.goToNext(result);
        });
    }

    goToNext(result?: any) {
        if (this.mode && this.mode === 'edit') {
            this.changeNextTab.emit('interestSettings');
            this.router.navigate(['property', 'view-property', this.hotelId, this.mode], { queryParams: { inventory_allocation: result ? 'yes' : 'no' } });
        } else {
            this.router.navigate(['property', 'interests-settings', this.hotelId], { queryParams: { inventory_allocation: result ? 'yes' : 'no' } });
        }
    }

    goToBack() {
        if (this.mode && this.mode === 'edit') {
            this.changeNextTab.emit('propertyInformation');
        } else {
            this.router.navigateByUrl('property/step-1-add-property', {
                state: { data: this.hotelId },
            });
        }
    }

    onSubmit() {
        this.overviewForm.value.hotelId = this.hotelId;
        if (this.propertyDetails?.hotelOverview?.id) {
            this.overviewForm.value.id = this.propertyDetails?.hotelOverview.id;
        }
        this.spinner.show();
        this.propertyService.hotelOverviewSave(this.overviewForm.value).subscribe((response: any) => {
            if (response.success) {
                this.toastr.success(response['message'], 'Success', {
                    timeOut: 3000,
                });
                this.initialization();
                if (!this.mode) {
                    this.router.navigate(['property', 'interests-settings', this.hotelId]);
                }
            } else {
                this.toastr.error(response['message'], 'Success', {
                    timeOut: 3000,
                });
            }
            this.spinner.hide();
        });
    }

    getPropertyDetailsById(): void {
        this.spinner.show();
        this.hotelId = this.activatedRoute.snapshot.paramMap.get('propertyId') || this.activatedRoute.snapshot.params.hotelId;
        this.propertyService.getHotelPropertyDetailsStep1(this.hotelId).subscribe((response: any) => {
            if (response['success']) {
                this.spinner.hide();
                this.propertyDetails = response.data;
            }
        });
    }
}
