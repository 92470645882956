export const HotelApi = {
    GET_HOTEL_LIST: 'get-hotel-list',
    DELETE_PROPERTY: 'admin-hotel/property/delete/',
    ACCEPT_HOTEL: 'accept-reject-hotel',
    GET_HOTEL_LIST_DOTW: 'get-hotel-list-dotw',
    CHANNEL: 'channel',
    UPDATE_CHANNEL: 'channel/',
    GET_TOWN_CODE_LOOKUP: 'demo',
    GET_COUNTRY: 'country/get',
    GET_CITIES: 'cities/get/',
    DASHBOARD_COUNT: 'count_sync_hotel',
    ROOM_TYPE_MAPPING: 'room-type-mapping/save',
    GET_ROOM_TYPE_MAPPING: 'room-type-mapping/get/',
    HOTEL_CATEGORY_MAPPING: 'hotel-category-mapping/save',
    DOTALINK_GET_RATE: 'dotalink_get_rate',
    ROOM_TYPE_DATA_SAVE: 'room-type-data/save',
    ROOM_TYPE_DATA_GET: 'room-type-data/get/',
    ROOM_TYPE_RATE_MAPPING_SAVE: 'room-type-rate-mapping/save',
    ROOM_TYPE_RATE_MAPPING_GET: 'room-type-rate-mapping/get/',
    excelEmail: 'excel-email',
    GET_DOTW_HOTEL_ROOM_DETAILS: 'dotw/room-type/get',
    SAVE_ROOM_TYPE: 'hotel-room-type-data/save',
    SAVE_DOTW_HOTEL_ROOM_DETAILS: 'room-type-data/save-frm-dotw',
    GET_SCRAPPED_DATA: 'scrap-hotel-data',
};

export const mastersApi = {
    // ROOM FACILITIES
    SAVE_FACILITIES: 'room-facilities/save',
    GET_FACILITIES: 'room-facilities/get',
    DELETE_FACILITIES: 'room-facilities/delete/',

    // INCLUSIONS
    SAVE_INCLUSIONS: 'inclusions/save',
    GET_INCLUSIONS: 'inclusions/get',
    DELETE_INCLUSIONS: 'inclusions/delete/',

    // INTERESTS
    SAVE_INTERESTS: 'interest/save',
    GET_INTERESTS: 'interest/get',
    DELETE_INTERESTS: 'interest/delete/',

    // STYLE
    SAVE_STYLE: 'style/save',
    GET_STYLE: 'style/get',
    DELETE_STYLE: 'style/delete/',

    // HOTEL CATEGORY
    SAVE_HOTEL_CATEGORY: 'hotel-category/save',
    GET_HOTEL_CATEGORY: 'hotel-category/get',
    DELETE_HOTEL_CATEGORY: 'hotel-category/delete/',

    // CHARGEABLE FACILITIES
    SAVE_CHARGEABLE_FACILITIES: 'chargeable-facility/save',
    GET_CHARGEABLE_FACILITIES: 'chargeable-facility/get',
    DELETE_CHARGEABLE_FACILITIES: 'chargeable-facility/delete/',

    // Service
    SAVE_SERVICE: 'service/save',
    GET_SERVICE: 'service/get',
    DELETE_SERVICE: 'service/delete/',

    // POLICY TYPE
    SAVE_POLICY_TYPE: 'policy-type/save',
    GET_POLICY_TYPE: 'policy-type/get',
    DELETE_POLICY_TYPE: 'policy-type/delete/',

    // BEDDING TYPE
    SAVE_BEDDING_TYPE: 'bedding-type/save',
    GET_BEDDING_TYPE: 'bedding-type/get',
    DELETE_BEDDING_TYPE: 'bedding-type/delete/',

    // MEAL TYPE
    SAVE_MEAL_TYPE: 'meal-type/save',
    GET_MEAL_TYPE: 'meal-type/get',
    DELETE_MEAL_TYPE: 'meal-type/delete/',

    // ROOM TYPE
    SAVE_ROOM_TYPE: 'room-type/save',
    GET_ROOM_TYPE: 'room-type/get',
    DELETE_ROOM_TYPE: 'room-type/delete/',

    // PROMOTION TYPE
    SAVE_PROMOTION_TYPE: 'promotion-type/save',
    GET_PROMOTION_TYPE: 'promotion-type/get',
    DELETE_PROMOTION_TYPE: 'promotion-type/delete/',

    // PROPERTY FACILITY CATEGORY
    SAVE_PROPERTY_FACILITY_CATEGORY: 'property-facilities-category/save',
    GET_PROPERTY_FACILITY_CATEGORY: 'property-facilities-category/get',
    DELETE_PROPERTY_FACILITY_CATEGORY: 'property-facilities-category/delete/',

    // PROPERTY FACILITY
    SAVE_PROPERTY_FACILITY: 'property-facilities/save',
    GET_PROPERTY_FACILITY: 'property-facilities/get',
    DELETE_PROPERTY_FACILITY: 'property-facilities/delete/',

    // SPA SERVICES
    SAVE_SPA_SERVICES: 'spa-services/save',
    GET_SPA_SERVICES: 'spa-services/get',
    DELETE_SPA_SERVICES: 'spa-services/delete/',

    // SPECIAL INSTRUCTIONS
    SAVE_INSTRUCTIONS: 'special-instructions/save',
    GET_INSTRUCTIONS: 'special-instructions/get',
    DELETE_INSTRUCTIONS: 'special-instructions/delete',
}

export const deleteDailog = {
    width: '350px',
    height: '380px',
    data: {
        title: 'Confirmation',
        message: 'This will delete data permanently and cannot be reversed. Do you still wish to continue?',
        okBtnLabel: 'Yes, Delete it!',
        cancelBtnLabel: 'Cancel'
    }
}