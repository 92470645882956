import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HotelApi } from '../_constants/api-list';
import { environment } from '../../environments/environment';
import { HttpClientService } from './index';

@Injectable({
  providedIn: 'root',
})
export class HotelSyncToolService {
  reloadAcceptedHotels: EventEmitter<boolean> = new EventEmitter(false);
  hideHeaderFooter: EventEmitter<boolean> = new EventEmitter(false);

  constructor(
    private http: HttpClient,
    private httpClientService: HttpClientService
  ) { }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  /**
   * @description
   *  Get Property List from Api saga.
   */
  getHotelList(params: any) {
    const param = this.prepareReqUrl(params, 'saga');
    return this.http.get(environment.originDST + HotelApi.GET_HOTEL_LIST + param, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  private prepareReqUrl(param, type) {
    let url = '';
    if (type === 'dotw') {
      if (param.accept_reject && param.country_code && param.city_code) {
        url = `?accept_reject=${param.accept_reject}&country_code=${param.country_code}&city_code=${param.city_code}`;
      } else if (param.accept_reject && param.country_code) {
        url = `?accept_reject=${param.accept_reject}&country_code=${param.country_code}`;
      } else if (param.accept_reject && param.city_code) {
        url = `?accept_reject=${param.accept_reject}&city_code=${param.city_code}`;
      } else if (param.country_code && param.city_code && param.filter_rating) {
        url = `?country_code=${param.country_code}&city_code=${param.city_code}&rating=${param.filter_rating}`;
      } else if (param.country_code && param.filter_rating) {
        url = `?country_code=${param.country_code}&rating=${param.filter_rating}`;
      } else if (param.country_code && param.city_code) {
        url = `?country_code=${param.country_code}&city_code=${param.city_code}`;
      } else if (param.accept_reject) {
        url = `?accept_reject=${param.accept_reject}`;
      } else if (param.country_code) {
        url = `?country_code=${param.country_code}`;
      } else if (param.city_code) {
        url = `?city_code=${param.city_code}`;
      }
    } else {
      if (param.town_code && param.accept_reject) {
        url = `?town_code=${param.town_code}&accept_reject=${param.accept_reject}`;
      } else if (param.town_code) {
        url = `?town_code=${param.town_code}`;
      } else if (param.accept_reject) {
        url = `?accept_reject=${param.accept_reject}`;
      }
    }
    return url;
  }

  /**
   * @description
   *  Get Property List from Api.
   */
  getHotelListDotw(params: any) {
    const param = this.prepareReqUrl(params, 'dotw');
    const url = environment.originDST + HotelApi.GET_HOTEL_LIST_DOTW + param;
    return this.http.get(url, this.httpOptions).pipe(retry(1), catchError(this.handleError));
  }

  /**
   * @description
   *  delete Property on base in id.
   */
  deletePropertyById(propertyId: string) {
    return this.http
      .delete(
        environment.origin + HotelApi.DELETE_PROPERTY + propertyId,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * @description
   *  get lookup for town code (SAGA).
   */
  getSagaTownCode() {
    return this.http
      .get(
        environment.originDST + HotelApi.GET_TOWN_CODE_LOOKUP,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }
  /**
   * @description
   *  get lookup for country code (DOTW).
   */
  getCountry() {
    const url = environment.originDST + HotelApi.GET_COUNTRY;
    return this.http.get(url, this.httpOptions).pipe(retry(1), catchError(this.handleError));
  }

  getCities(countryCode: string) {
    const url = environment.originDST + HotelApi.GET_CITIES + countryCode;
    return this.http.get(url, this.httpOptions).pipe(retry(1), catchError(this.handleError));
  }

  /**
   * @description
   *  accept hotel.
   */
  acceptHotel(data: any) {
    return this.http
      .post(
        environment.originDST + HotelApi.ACCEPT_HOTEL,
        JSON.stringify(data),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * Export excel file
   * @param requestData request data
   * @returns response from api
   */
  exportExcel(requestData: any) {
    const url = environment.originDST + HotelApi.excelEmail;
    return this.httpClientService.post(url, requestData);
  }

  /**
   * @description
   *  add channel from api.
   */
  public addChannel(paramChannel) {
    return this.http
      .post(
        environment.originDST + HotelApi.CHANNEL,
        paramChannel,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }
  /**
   * @description
   *  update channel from api.
   */
  public updateChannel(paramChannel, channelId) {
    return this.http
      .put(
        environment.originDST + HotelApi.UPDATE_CHANNEL + parseInt(channelId),
        JSON.stringify(paramChannel),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * @description
   *  get all channels from api.
   */
  public getAllChannel() {
    return this.http
      .get(environment.originDST + HotelApi.CHANNEL, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * @description
   *  get all channel by id.
   */
  public getAllChannelById(channelId) {
    return this.http
      .get(
        environment.originDST + HotelApi.UPDATE_CHANNEL + channelId,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }
  /**
   * @description
   *  delete channel by id.
   */
  public deleteChannelById(channelId) {
    return this.http
      .delete(
        environment.originDST + HotelApi.UPDATE_CHANNEL + channelId,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  private handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  dashboardCount() {
    return this.http
      .get(
        environment.originDST + HotelApi.DASHBOARD_COUNT,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  markAsFeatured(requestData: any) {
    const url = environment.originDST + 'accept-reject-hotel/feature';
    return this.http.post(url, requestData, this.httpOptions).pipe(retry(1), catchError(this.handleError));
  }

  markAsFeaturedFromHotel(requestData: any) {
    const url = environment.origin + 'admin-hotel/property/featured';
    return this.http.post(url, requestData, this.httpOptions).pipe(retry(1), catchError(this.handleError));
  }

  getRoomRates(reqestData: any) {
    return this.http.post(environment.originDST + HotelApi.DOTALINK_GET_RATE, reqestData, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }
}
