<div class="page-bar" *ngIf="!mode">
  <div class="page-title-breadcrumb">
    <div class="pull-left">
      <div class="page-title">Step 6 : Room Rate</div>
    </div>
    <ol class="breadcrumb page-breadcrumb pull-right">
      <li>
        <a class="parent-item" href="/"><i class="fa fa-home"></i>&nbsp;Home</a>&nbsp;<i class="fa fa-angle-right"></i>
      </li>
      <li class="active">Room Rate</li>
    </ol>
  </div>
</div>
<div class="row" [ngClass]="{ 'pt-3': mode }">
  <div class="col-sm-12">
    <div class="card-box" *ngIf="!mode">
      <div class="card-head">
        <header>Progress Bar</header>
      </div>
      <div class="card-body">
        <div class="progressbar-xs progress-rounded progress-striped progress ng-isolate-scope active">
          <div class="progress-bar progress-bar-success width-75" role="progressbar" aria-valuenow="65"
            aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    </div>
    <mat-accordion *ngIf="roomData.length > 0">
      <mat-expansion-panel *ngFor="let item of roomData; let i = index" class="mb-1">
        <mat-expansion-panel-header (click)="collapseRoom(item)">
          <mat-panel-title>
            <mat-checkbox [disabled]="true" [checked]="item.rates?.length > 0">{{item.name}}</mat-checkbox>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <form [formGroup]="ratingFormGroup" (ngSubmit)="onSubmit(item.id)">
            <fieldset [disabled]="mode == 'view'">
              <h3 class="left-title">Room Rates</h3>
              <div class="date-calender">
                <div class="form-group">
                  <label class="form_label">Date</label>
                  <div class="date-input d-flex align-items-center">
                    <div class="pr-4 ml-3 mr-4">
                      <div class="row">
                        <span class="text-red">From</span>
                      </div>
                      <div class="row pr-4">
                        <mat-form-field>
                          <mat-label>Choose a date</mat-label>
                          <input matInput [matDatepicker]="picker" [min]="minformDate" formControlName="date_from"
                            name="date_from" />
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="row">
                        <span
                          *ngIf="(ratingFormGroup.controls['date_from'].dirty || ratingFormGroup.controls['date_from'].touched) && ratingFormGroup.controls['date_from'].hasError('required')"
                          class="text-red">Please enter From Date</span>
                      </div>
                      <div class="row">
                        <span
                          *ngIf="(ratingFormGroup.controls['date_from'].dirty || ratingFormGroup.controls['date_from'].touched) && ratingFormGroup.controls['date_from'].hasError('matDatepickerMin')"
                          class="text-red">Older date not allowed</span>
                        <!-- *ngIf="
                      date_from.errors?.matDatepickerMin &&
                      (date_from.dirty || date_from.touched)
                    " -->
                      </div>
                      <div *ngIf="checkDate" class="error-msg row">
                        <div>To Date should be greater Then From Date</div>
                      </div>
                    </div>
                    <div class="pr-4 ml-3 mr-4">
                      <div class="row"><span class="text-red">To</span></div>
                      <div class="row">
                        <mat-form-field>
                          <mat-label>Choose a date</mat-label>
                          <input matInput [matDatepicker]="picker1" [min]="mintoDate" formControlName="date_to"
                            name="date_to" />
                          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                          <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="row">
                        <span
                          *ngIf="(ratingFormGroup.controls['date_to'].dirty || ratingFormGroup.controls['date_to'].touched) && ratingFormGroup.controls['date_to'].hasError('required')"
                          class="text-red">Please enter To Date</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bottom-div">
                <div class="row">
                  <div class="col-md-5">
                    <div class="row">
                      <div class="col-md-12 padding-left">
                        <label class="form_label text-red "><b>Commission</b></label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 padding-left">
                        <mat-radio-group aria-label="Select an option" (change)="rateCalculation()"
                          formControlName="commission_type">
                          <mat-radio-button class="mr-2" value="fix">Fix</mat-radio-button>
                          <mat-radio-button value="percentage">Percentage</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 padding-left">
                        <p class="red-text">
                          Commission % OR Fix amount will be applied per
                          night.
                        </p>
                        <p class="desc">
                          You have set weekend rates to apply to Friday, Saturday
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group">
                        <label class="form_label text-red">Currency</label>
                        <select class="form-control step3input" formControlName="currency_id"
                          (change)="changeCurrency($event.target.value)">
                          <option value="">Select Currency</option>
                          <option *ngFor="let currency of currencyList" [value]="currency.id">
                            {{ currency.currency_code }} - {{ currency.name }}
                          </option>
                        </select>
                        <span
                          *ngIf="(ratingFormGroup.controls['currency_id'].dirty || ratingFormGroup.controls['currency_id'].touched) && ratingFormGroup.controls['currency_id'].hasError('required')"
                          class="text-red">Please select currency</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <label class="text-red">Weekday</label>
                            <input type="number" min="1" (keyup)="rateCalculation()"
                              formControlName="commission_weekday" name="commission_weekday" class="form-control" />
                            <span
                              *ngIf="(ratingFormGroup.controls['commission_weekday'].dirty || ratingFormGroup.controls['commission_weekday'].touched) && ratingFormGroup.controls['commission_weekday'].hasError('required')"
                              class="text-red">Please enter Weekday commision</span>
                          </div>
                          <div class="col-md-6">
                            <label class="text-red">Weekend</label>
                            <input type="number" min="1" (keyup)="rateCalculation()"
                              formControlName="commission_weekend" name="commission_weekend" class="form-control" />
                            <span
                              *ngIf="(ratingFormGroup.controls['commission_weekend'].dirty || ratingFormGroup.controls['commission_weekend'].touched) && ratingFormGroup.controls['commission_weekend'].hasError('required')"
                              class="text-red">Please enter Weekend commision</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4"></div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-6 text-center mb-2">
                        <b>Net Rate</b>
                      </div>
                      <div class="col-md-6 text-center mb-2">
                        <b>Cost Rate</b>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4"></div>
                  <div class="col-md-8 dolar-sign label-tag">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6 text-center">
                            <div class="form-group">
                              <label class="min-height">Weekday</label>
                            </div>
                          </div>
                          <div class="col-md-6 text-center">
                            <div class="form-group">
                              <label class="min-height">Weekend</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6 text-center">
                            <div class="form-group">
                              <label class="min-height">Weekday</label>
                            </div>
                          </div>
                          <div class="col-md-6 text-center">
                            <div class="form-group">
                              <label class="min-height">Weekend</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label class="form_label text-red"><b>1 Adult - 0 Child - 0 Infant</b></label>
                  </div>
                  <div class="col-md-8 dolar-sign">
                    <b>{{selectedCurrency}}</b>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()" name="net_rate_weekday"
                                formControlName="net_rate_weekday" class="form-control" />
                              <span
                                *ngIf="(ratingFormGroup.controls['net_rate_weekday'].dirty || ratingFormGroup.controls['net_rate_weekday'].touched) && ratingFormGroup.controls['net_rate_weekday'].hasError('required')"
                                class="text-red">Please enter
                                Weekday</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()" name="net_rate_weekend"
                                formControlName="net_rate_weekend" class="form-control" />
                              <span
                                *ngIf="(ratingFormGroup.controls['net_rate_weekend'].dirty || ratingFormGroup.controls['net_rate_weekend'].touched) && ratingFormGroup.controls['net_rate_weekend'].hasError('required')"
                                class="text-red">Please enter Weekend</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" readonly formControlName="cost_rate_weekday"
                                name="cost_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" readonly formControlName="cost_rate_weekend"
                                name="cost_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label><b>Per Adult</b></label>
                  </div>
                  <div class="col-md-8 dolar-sign">
                    <b>{{selectedCurrency}}</b>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input readonly type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_adult_net_rate_weekday" name="per_adult_net_rate_weekday"
                                class="form-control" />
                              <span
                                *ngIf="(ratingFormGroup.controls['per_adult_net_rate_weekday'].dirty || ratingFormGroup.controls['per_adult_net_rate_weekday'].touched) && ratingFormGroup.controls['per_adult_net_rate_weekday'].hasError('required')"
                                class="text-red">Please enter Weekday</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input readonly type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_adult_net_rate_weekend" name="per_adult_net_rate_weekend"
                                class="form-control" />
                              <span
                                *ngIf="(ratingFormGroup.controls['per_adult_net_rate_weekend'].dirty || ratingFormGroup.controls['per_adult_net_rate_weekend'].touched) && ratingFormGroup.controls['per_adult_net_rate_weekend'].hasError('required')"
                                class="text-red">Please enter Weekend</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" readonly formControlName="per_adult_cost_rate_weekday"
                                name="per_adult_cost_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" readonly formControlName="per_adult_cost_rate_weekend"
                                name="per_adult_cost_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label><b>Per Child</b></label>
                  </div>
                  <div class="col-md-8 dolar-sign">
                    <b>{{selectedCurrency}}</b>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_child_net_rate_weekday" name="per_child_net_rate_weekday"
                                class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_child_net_rate_weekend" name="per_child_net_rate_weekend"
                                class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" readonly formControlName="per_child_cost_rate_weekday"
                                name="per_child_cost_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" readonly formControlName="per_child_cost_rate_weekend"
                                name="per_child_cost_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label><b>Per Adult Minimum Rate</b></label>
                  </div>
                  <div class="col-md-8 dolar-sign">
                    <b>{{selectedCurrency}}</b>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_adult_min_net_rate_weekday" name="per_adult_min_net_rate_weekday"
                                class="form-control" />
                              <span
                                *ngIf="(ratingFormGroup.controls['per_adult_min_net_rate_weekday'].dirty || ratingFormGroup.controls['per_adult_min_net_rate_weekday'].touched) && ratingFormGroup.controls['per_adult_min_net_rate_weekday'].hasError('required')"
                                class="text-red">Please enter Weekday</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_adult_min_net_rate_weekend" name="per_adult_min_net_rate_weekend"
                                class="form-control" />
                              <span
                                *ngIf="(ratingFormGroup.controls['per_adult_min_net_rate_weekend'].dirty || ratingFormGroup.controls['per_adult_min_net_rate_weekend'].touched) && ratingFormGroup.controls['per_adult_min_net_rate_weekend'].hasError('required')"
                                class="text-red">Please enter Weekend</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" readonly formControlName="per_adult_min_cost_rate_weekday"
                                name="per_adult_min_cost_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" readonly formControlName="per_adult_min_cost_rate_weekend"
                                name="per_adult_min_cost_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label><b>Per Child Minimum Rate</b></label>
                  </div>
                  <div class="col-md-8 dolar-sign">
                    <b>{{selectedCurrency}}</b>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_child_min_net_rate_weekday" name="per_child_min_net_rate_weekday"
                                class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_child_min_net_rate_weekend" name="per_child_min_net_rate_weekend"
                                class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" readonly formControlName="per_child_min_cost_rate_weekday"
                                name="per_child_min_cost_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" readonly formControlName="per_child_min_cost_rate_weekend"
                                name="per_child_min_cost_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label><b>Per Infant</b></label>
                  </div>
                  <div class="col-md-8 dolar-sign">
                    <b>{{selectedCurrency}}</b>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_infant_net_rate_weekday" name="per_infant_net_rate_weekday"
                                class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_infant_net_rate_weekend" name="per_infant_net_rate_weekend"
                                class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" readonly formControlName="per_infant_cost_rate_weekday"
                                name="per_infant_cost_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" readonly formControlName="per_infant_cost_rate_weekend"
                                name="per_infant_cost_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label><b>Extra Adult</b></label>
                  </div>
                  <div class="col-md-8 dolar-sign">
                    <b>{{selectedCurrency}}</b>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="extra_adult_net_rate_weekday" name="extra_adult_net_rate_weekday"
                                class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="extra_adult_net_rate_weekend" name="extra_adult_net_rate_weekend"
                                class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input min="1" readonly type="number" formControlName="extra_adult_cost_rate_weekday"
                                name="extra_adult_cost_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input readonly type="number" min="1" formControlName="extra_adult_cost_rate_weekend"
                                name="extra_adult_cost_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label><b>Extra Child</b></label>
                  </div>
                  <div class="col-md-8 dolar-sign">
                    <b>{{selectedCurrency}}</b>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="extra_child_net_rate_weekday" name="extra_child_net_rate_weekday"
                                class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="extra_child_net_rate_weekend" name="extra_child_net_rate_weekend"
                                class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input min="1" readonly type="number" formControlName="extra_child_cost_rate_weekday"
                                name="extra_child_cost_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input min="1" readonly type="number" formControlName="extra_child_cost_rate_weekend"
                                name="extra_child_cost_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label><b>Extra Infant</b></label>
                  </div>
                  <div class="col-md-8 dolar-sign">
                    <b>{{selectedCurrency}}</b>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="extra_infant_net_rate_weekday" name="extra_infant_net_rate_weekday"
                                class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="extra_infant_net_rate_weekend" name="extra_infant_net_rate_weekend"
                                class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input min="1" readonly type="number" formControlName="extra_infant_cost_rate_weekday"
                                name="extra_infant_cost_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" readonly min="1" formControlName="extra_infant_cost_rate_weekend"
                                name="extra_infant_cost_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label><b>Per Adult Breakfast</b></label>
                  </div>
                  <div class="col-md-8 dolar-sign">
                    <b>{{selectedCurrency}}</b>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_adult_breakfast_net_rate_weekday"
                                name="per_adult_breakfast_net_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_adult_breakfast_net_rate_weekend"
                                name="per_adult_breakfast_net_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input min="1" readonly type="number"
                                formControlName="per_adult_breakfast_cost_rate_weekday"
                                name="per_adult_breakfast_cost_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" readonly min="1"
                                formControlName="per_adult_breakfast_cost_rate_weekend"
                                name="per_adult_breakfast_cost_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label><b>Per Child Breakfast</b></label>
                  </div>
                  <div class="col-md-8 dolar-sign">
                    <b>{{selectedCurrency}}</b>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_child_breakfast_net_rate_weekday"
                                name="per_child_breakfast_net_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_child_breakfast_net_rate_weekend"
                                name="per_child_breakfast_net_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input min="1" readonly type="number"
                                formControlName="per_child_breakfast_cost_rate_weekday"
                                name="per_child_breakfast_cost_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" readonly min="1"
                                formControlName="per_child_breakfast_cost_rate_weekend"
                                name="per_child_breakfast_cost_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label><b>Per Adult Half Board</b></label>
                  </div>
                  <div class="col-md-8 dolar-sign">
                    <b>{{selectedCurrency}}</b>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_adult_halfboard_net_rate_weekday"
                                name="per_adult_halfboard_net_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_adult_halfboard_net_rate_weekend"
                                name="per_adult_halfboard_net_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input min="1" readonly type="number"
                                formControlName="per_adult_halfboard_cost_rate_weekday"
                                name="per_adult_halfboard_cost_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" readonly min="1"
                                formControlName="per_adult_halfboard_cost_rate_weekend"
                                name="per_adult_halfboard_cost_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label><b>Per Child Half Board</b></label>
                  </div>
                  <div class="col-md-8 dolar-sign">
                    <b>{{selectedCurrency}}</b>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_child_halfboard_net_rate_weekday"
                                name="per_child_halfboard_net_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_child_halfboard_net_rate_weekend"
                                name="per_child_halfboard_net_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input min="1" readonly type="number"
                                formControlName="per_child_halfboard_cost_rate_weekday"
                                name="per_child_halfboard_cost_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" readonly min="1"
                                formControlName="per_child_halfboard_cost_rate_weekend"
                                name="per_child_halfboard_cost_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label><b>Per Adult Full Board</b></label>
                  </div>
                  <div class="col-md-8 dolar-sign">
                    <b>{{selectedCurrency}}</b>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_adult_fullboard_net_rate_weekday"
                                name="per_adult_fullboard_net_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_adult_fullboard_net_rate_weekend"
                                name="per_adult_fullboard_net_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input min="1" readonly type="number"
                                formControlName="per_adult_fullboard_cost_rate_weekday"
                                name="per_adult_fullboard_cost_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" readonly min="1"
                                formControlName="per_adult_fullboard_cost_rate_weekend"
                                name="per_adult_fullboard_cost_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label><b>Per Child Full Board</b></label>
                  </div>
                  <div class="col-md-8 dolar-sign">
                    <b>{{selectedCurrency}}</b>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_child_fullboard_net_rate_weekday"
                                name="per_child_fullboard_net_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_child_fullboard_net_rate_weekend"
                                name="per_child_fullboard_net_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input min="1" readonly type="number"
                                formControlName="per_child_fullboard_cost_rate_weekday"
                                name="per_child_fullboard_cost_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" readonly min="1"
                                formControlName="per_child_fullboard_cost_rate_weekend"
                                name="per_child_fullboard_cost_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label><b>Per Adult All Inclusive</b></label>
                  </div>
                  <div class="col-md-8 dolar-sign">
                    <b>{{selectedCurrency}}</b>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_adult_allinclusive_net_rate_weekday"
                                name="per_adult_allinclusive_net_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_adult_allinclusive_net_rate_weekend"
                                name="per_adult_allinclusive_net_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input min="1" readonly type="number"
                                formControlName="per_adult_allinclusive_cost_rate_weekday"
                                name="per_adult_allinclusive_cost_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" readonly min="1"
                                formControlName="per_adult_allinclusive_cost_rate_weekend"
                                name="per_adult_allinclusive_cost_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label><b>Per Child All Inclusive</b></label>
                  </div>
                  <div class="col-md-8 dolar-sign">
                    <b>{{selectedCurrency}}</b>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_child_allinclusive_net_rate_weekday"
                                name="per_child_allinclusive_net_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" (keyup)="rateCalculation()"
                                formControlName="per_child_allinclusive_net_rate_weekend"
                                name="per_child_allinclusive_net_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input min="1" readonly type="number"
                                formControlName="per_child_allinclusive_cost_rate_weekday"
                                name="per_child_allinclusive_cost_rate_weekday" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" readonly min="1"
                                formControlName="per_child_allinclusive_cost_rate_weekend"
                                name="per_child_allinclusive_cost_rate_weekend" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div formArrayName="chargeable_facility"
                  *ngFor="let facilityArr of ratingFormGroup.get('chargeable_facility')['controls']; let i = index">
                  <div class="row" [formGroupName]="i">
                    <div class="col-md-4">
                      <label><b>{{ratingFormGroup.get('chargeable_facility')['controls'][i]['controls'].labelName.value}}</b></label>
                    </div>
                    <div class="col-md-8 dolar-sign">
                      <b>{{selectedCurrency}}</b>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <input type="number" min="1" (keyup)="rateCalculation(i)"
                                  formControlName="net_rate_weekday" name="net_rate_weekday" class="form-control"
                                  [readonly]="!ratingFormGroup.value.commission_weekday || !ratingFormGroup.value.commission_weekend" />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <input type="number" min="1" (keyup)="rateCalculation(i)"
                                  [readonly]="!ratingFormGroup.value.commission_weekday || !ratingFormGroup.value.commission_weekend"
                                  formControlName="net_rate_weekend" name="net_rate_weekend" class="form-control" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <input min="1" readonly type="number" formControlName="cost_rate_weekday"
                                  name="cost_rate_weekday" class="form-control" />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <input type="number" readonly min="1" formControlName="cost_rate_weekend"
                                  name="cost_rate_weekend" class="form-control" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <h3 class="left-title">Room Allocation</h3>
              <div class="bottom-div">
                <div class="row">
                  <div class="col-md-4">
                    <label class="text-red"><b>Allocation</b></label>
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <b>Weekdays</b>
                              <input type="number" min="1" [max]="max_room" (keyup)="validateMaxRoom($event)"
                                formControlName="allocation_weekday" name="allocation_weekday" class="form-control"
                                required />
                              <span
                                *ngIf="(ratingFormGroup.controls['allocation_weekday'].dirty || ratingFormGroup.controls['allocation_weekday'].touched) && ratingFormGroup.controls['allocation_weekday'].hasError('required')"
                                class="text-red">Please enter Weekdays</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <b>Weekends</b>
                              <input type="number" min="1" [max]="max_room" (keyup)="validateMaxRoom($event)"
                                formControlName="allocation_weekend" name="allocation_weekend" class="form-control"
                                required />
                              <span
                                *ngIf="(ratingFormGroup.controls['allocation_weekend'].dirty || ratingFormGroup.controls['allocation_weekend'].touched) && ratingFormGroup.controls['allocation_weekend'].hasError('required')"
                                class="text-red">Please enter Weekend</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label class="text-red"><b>Minimum Stay</b></label>
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" formControlName="min_stay" name="min_stay"
                                class="form-control" required />
                              <span
                                *ngIf="(ratingFormGroup.controls['min_stay'].dirty || ratingFormGroup.controls['min_stay'].touched) && ratingFormGroup.controls['min_stay'].hasError('required')"
                                class="text-red">Please enter Minimum Stay</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label class="text-red"><b>Maximum room per booking</b></label>
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" [max]="max_room" formControlName="max_room_per_booking"
                                (keyup)="validateMaxRoom($event)" id="max_room_per_booking" required
                                name="max_room_per_booking" class="form-control" />
                              <span
                                *ngIf="(ratingFormGroup.controls['max_room_per_booking'].dirty || ratingFormGroup.controls['max_room_per_booking'].touched) && ratingFormGroup.controls['max_room_per_booking'].hasError('required')"
                                class="text-red">Please enter Maximum room per booking</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label class="text-red"><b>Number of units before which notification is needed</b></label>
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" formControlName="notification_after_units" required
                                name="notification_after_units" class="form-control" />
                              <span
                                *ngIf="(ratingFormGroup.controls['notification_after_units'].dirty || ratingFormGroup.controls['notification_after_units'].touched) && ratingFormGroup.controls['notification_after_units'].hasError('required')"
                                class="text-red">Please enter value</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label class="text-red"><b>Cut off days</b></label>
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="number" min="1" formControlName="cut_of_day" name="cut_of_day"
                                class="form-control" required />
                              <span
                                *ngIf="(ratingFormGroup.controls['cut_of_day'].dirty || ratingFormGroup.controls['cut_of_day'].touched) && ratingFormGroup.controls['cut_of_day'].hasError('required')"
                                class="text-red">Please enter Cut off days</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <span class="text-red d-flex justify-content-end mt-4 p-2" *ngIf="!ratingFormGroup.valid">
                {{validationmsg}}
              </span>
              <div class="d-flex justify-content-end mt-4" *ngIf="!isDataFound">
                <button type="submit" class="btn btn-round btn-default custom-btn" [disabled]="!ratingFormGroup.valid">
                  Save
                </button>
              </div>
              <div class="d-flex justify-content-end row mb-3 mt-4" *ngIf="ratingFormGroup.value.id && mode === 'edit'">
                <button type="submit" class="btn btn-round btn-default custom-btn" [disabled]="!ratingFormGroup.valid">
                  Update
                </button>
                &nbsp;&nbsp;
                <button type="button" (click)="deleteRoom(item.id)" class="btn btn-round btn-default custom-btn">
                  Delete
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="d-flex justify-content-between" style="padding: 10px" *ngIf="!mode || mode !== 'view'">
      <a class="btn yellow btn-outline custom-btn" (click)="goToBack()">Back</a>
      <a data-toggle="modal" data-target="#facilities-modal" class="btn btn-round btn-primary custom-btn"
        *ngIf="isgonext" (click)="removeBackdropClass()">Next</a>
    </div>
  </div>
</div>

<button type="button" id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#facilities-modal">
  Open Modal
</button>

<div class="modal fade" id="facilities-modal">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content" style="width: auto">
      <div class="modal-header">
        <h2>Do you want to manage stop sell now?</h2>
        <button type="button" id="closeModal" hidden class="close" #closeBtnAdd data-dismiss="modal">
          &times;
        </button>
      </div>

      <div class="modal-body">
        <div class="row justify-content-end">
          <div class="col-6">
            <button type="button" (click)="noFun()" class="float-right btn btn-round btn-default custom-btn">
              No
            </button>
          </div>
          <div class="col-6">
            <button type="button" (click)="yesFun()" class="float-left btn btn-round btn-default custom-btn">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>