import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { token } from '../_constants/constants';

@Injectable({
    providedIn: 'root',
})

export class GuardService implements CanActivate {

    constructor(
        private router: Router,
    ) { }

    async canActivate() {
        const localToken = localStorage.getItem('token');
        
        if (localToken === token) {
            return true;
        } else {
            location.href = '/auth/login';
            localStorage.clear();
            return false;
        }
    }
}
