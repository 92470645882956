<!-- <div *ngIf="propertyDetails && propertyDetails.length > 0"> -->
<div class="page-bar">
  <div class="page-title-breadcrumb">
    <div class="pull-left">
      <div class="page-title">List Property</div>
    </div>
    <ol class="breadcrumb page-breadcrumb pull-right">
      <li>
        <i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="index.php">Home</a>&nbsp;<i
          class="fa fa-angle-right"></i>
      </li>
      <li class="active">List Property</li>
    </ol>
  </div>
</div>
<!-- <div class="form-group">
  <label class="form_label">Filter</label>
  <input class="form-control" type="text" (keyup)="applyFilter($event)" placeholder="Ex. ium">
</div> -->

<div class="row">
  <div class="col-sm-12">
    <div class="card-box">
      <div class="card-head">
        <header>Filters</header>
      </div>
      <div class="card-body ml-3">
        <div class="row">
          <button class="btn btn-outline-primary btn-circle m-b-10 mr-2" (click)="manageFilters('country')">
            Country
          </button>
          <button class="btn btn-outline-primary btn-circle m-b-10 mr-2" (click)="manageFilters('price')">
            Price
          </button>
          <button class="btn btn-outline-primary btn-circle m-b-10 mr-2" (click)="manageFilters('starCategory')"
            disabled>
            Star Category
          </button>
          <button class="btn btn-outline-primary btn-circle m-b-10 mr-2" (click)="manageFilters('propertyFacility')">
            Property Facilities
          </button>
          <button class="btn btn-outline-primary btn-circle m-b-10 mr-2" (click)="manageFilters('distance')" disabled>
            Distance City Center
          </button>
          <button class="btn btn-outline-primary btn-circle m-b-10 mr-2" (click)="manageFilters('available')" disabled>
            Availability
          </button>
          <button class="btn btn-outline-primary btn-circle m-b-10 mr-2" disabled>
            Reservation Policy
          </button>
          <button class="btn btn-outline-primary btn-circle m-b-10 mr-2" disabled>
            Hotel Type
          </button>
          <button class="btn btn-outline-primary btn-circle m-b-10 mr-2" (click)="manageFilters('bedType')">
            Bed Preferences
          </button>
          <button class="btn btn-outline-primary btn-circle m-b-10 mr-2" (click)="manageFilters('roomType')">
            Room Facilities
          </button>
          <button class="btn btn-outline-primary btn-circle m-b-10 mr-2" (click)="manageFilters('area')" disabled>
            Area
          </button>
          <button class="btn btn-outline-primary btn-circle m-b-10" disabled>
            Deals
          </button>
          <div class="ml-auto" *ngIf="displayFilters">
            <span><button type="button" class="close" (click)="manageFilters('close')">
                &times;
              </button></span>
          </div>
        </div>
        <form>
          <div class="row" *ngIf="displayFilters">
            <div class="col-md-2" *ngIf="showHideFilters.displayCountryFilter">
              <div class="form-group">
                <label class="form_label">Country</label>
                <select class="form-control" name="country" [(ngModel)]="filterCriteria.country"
                  (ngModelChange)="getProvinceById($event)">
                  <option value="">Select country</option>
                  <option *ngFor="let item of propertyList.countryList" value="{{ item.id }}">
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-2" *ngIf="showHideFilters.displayCountryFilter">
              <div class="form-group">
                <label class="form_label">Province</label>
                <select id="province" name="province" class="form-control" disabled
                  [(ngModel)]="filterCriteria.province" (ngModelChange)="getCityById($event)">
                  <option value="">Select province</option>
                  <option *ngFor="let item of propertyList.provinceList" value="{{ item.id }}">
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-2" *ngIf="showHideFilters.displayCountryFilter">
              <div class="form-group">
                <label class="form_label">City</label>
                <select id="city" name="city" class="form-control" [(ngModel)]="filterCriteria.city" disabled>
                  <option value="">Select city</option>
                  <option *ngFor="let item of propertyList.cityList" value="{{ item.id }}">
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-4" *ngIf="showHideFilters.displayPriceFilter">
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="form_label mb-2">Min Price</label>
                  <input class="form-control" name="minPrice" type="number" [min]="defaultMinPrice"
                    [max]="defaultMaxPrice" [(ngModel)]="filterCriteria.minPrice"
                    (ngModelChange)="updateMaxPriceRange()" />
                  <!-- <label class="form_label">{{
                    filterCriteria.minPrice
                  }}</label> -->
                </div>

                <!-- <div class="form-group">
                  <label class="form_label text-red">Hotel Name </label>
                  <input [(ngModel)]="step1modal.name"  class="form-control" name="Name" #Name="ngModel" [pattern]="hotelnamePattern"
                      type="text" required>
                  <span *ngIf="Name.errors?.required && (Name.dirty || Name.touched) " class="text-red">Please enter
                      Name</span>
                      <span *ngIf="Name.errors?.pattern &&  mode!='view'" class="text-red">Please enter valid Hotel Name
                      </span>
              </div> -->

                <div class="col-md-6 form-group">
                  <label class="form_label mb-2">Max Price</label>
                  <input class="form-control" name="maxPrice" type="number" [min]="defaultMinPrice"
                    [max]="defaultMaxPrice" [(ngModel)]="filterCriteria.maxPrice"
                    (ngModelChange)="updateMinPriceRange()" />
                  <!-- <label class="form_label">{{
                    filterCriteria.maxPrice
                  }}</label> -->
                </div>
              </div>
            </div>
            <div class="col-md-auto" *ngIf="showHideFilters.displayStarCategoryFilter">
              <!-- <div class="form-group"> -->
              <div class="row">
                <label class="form_label font-bold">Star Category</label>
              </div>
              <!-- <p class="font-weight-bold mb-3"><span>Please rate your overall experience with MyTAXPrepOffice.</span></p> -->
              <div class="rating-star-wrap" *ngIf="ratings < 1">
                <div class="rating-star row">
                  <span><img src="assets/img/star_blank.svg" alt="star" (click)="ratings = 1" /></span>
                  <span><img src="assets/img/star_blank.svg" alt="star" (click)="ratings = 2" /></span>
                  <span><img src="assets/img/star_blank.svg" alt="star" (click)="ratings = 3" /></span>
                  <span><img src="assets/img/star_blank.svg" alt="star" (click)="ratings = 4" /></span>
                  <span><img src="assets/img/star_blank.svg" alt="star" (click)="ratings = 5" /></span>
                </div>
              </div>
              <div class="rating-star-wrap" *ngIf="ratings >= 1 && ratings < 2">
                <div class="rating-star row">
                  <span><img src="assets/img/star_filled.svg" alt="star" (click)="ratings = 1" /></span>
                  <span><img src="assets/img/star_blank.svg" alt="star" (click)="ratings = 2" /></span>
                  <span><img src="assets/img/star_blank.svg" alt="star" (click)="ratings = 3" /></span>
                  <span><img src="assets/img/star_blank.svg" alt="star" (click)="ratings = 4" /></span>
                  <span><img src="assets/img/star_blank.svg" alt="star" (click)="ratings = 5" /></span>
                </div>
              </div>
              <div class="rating-star-wrap" *ngIf="ratings >= 2 && ratings < 3">
                <div class="rating-star row">
                  <span><img src="assets/img/star_filled.svg" alt="star" (click)="ratings = 1" /></span>
                  <span><img src="assets/img/star_filled.svg" alt="star" (click)="ratings = 2" /></span>
                  <span><img src="assets/img/star_blank.svg" alt="star" (click)="ratings = 3" /></span>
                  <span><img src="assets/img/star_blank.svg" alt="star" (click)="ratings = 4" /></span>
                  <span><img src="assets/img/star_blank.svg" alt="star" (click)="ratings = 5" /></span>
                </div>
              </div>
              <div class="rating-star-wrap" *ngIf="ratings >= 3 && ratings < 4">
                <div class="rating-star row">
                  <span><img src="assets/img/star_filled.svg" alt="star" (click)="ratings = 1" /></span>
                  <span><img src="assets/img/star_filled.svg" alt="star" (click)="ratings = 2" /></span>
                  <span><img src="assets/img/star_filled.svg" alt="star" (click)="ratings = 3" /></span>
                  <span><img src="assets/img/star_blank.svg" alt="star" (click)="ratings = 4" /></span>
                  <span><img src="assets/img/star_blank.svg" alt="star" (click)="ratings = 5" /></span>
                </div>
              </div>
              <div class="rating-star-wrap" *ngIf="ratings >= 4 && ratings < 5">
                <div class="rating-star row">
                  <span><img src="assets/img/star_filled.svg" alt="star" (click)="ratings = 1" /></span>
                  <span><img src="assets/img/star_filled.svg" alt="star" (click)="ratings = 2" /></span>
                  <span><img src="assets/img/star_filled.svg" alt="star" (click)="ratings = 3" /></span>
                  <span><img src="assets/img/star_filled.svg" alt="star" (click)="ratings = 4" /></span>
                  <span><img src="assets/img/star_blank.svg" alt="star" (click)="ratings = 5" /></span>
                </div>
              </div>
              <div class="rating-star-wrap" *ngIf="ratings >= 5">
                <div class="rating-star row">
                  <span><img src="assets/img/star_filled.svg" alt="star" (click)="ratings = 1" /></span>
                  <span><img src="assets/img/star_filled.svg" alt="star" (click)="ratings = 2" /></span>
                  <span><img src="assets/img/star_filled.svg" alt="star" (click)="ratings = 3" /></span>
                  <span><img src="assets/img/star_filled.svg" alt="star" (click)="ratings = 4" /></span>
                  <span><img src="assets/img/star_filled.svg" alt="star" (click)="ratings = 5" /></span>
                </div>
              </div>
              <!-- </div> -->
            </div>
            <!-- <div class="col-md-auto" *ngIf="showHideFilters.displayStarFilter">
                            <div class="form-group">
                                <label class="form_label">Star Category</label>
                                <div class="row">
                                    <div class="d-flex room-checkbox " *ngFor="let item of propertyList.starList">
                                        <div class="checkbox checkbox-black d-inline-block">
                                            <input id="starchk_{{item.id}}" name="star{{item.id}}" type="checkbox" (click)="bindStarCategory(item)"  />
                                            <label for="starchk_{{item.id}}">
                                        {{item.name}}
                                      </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
            <div class="col-md-auto" *ngIf="showHideFilters.displayPropertyFacilityFilter">
              <div class="form-group">
                <label class="form_label">Property Facilities</label>
                <div class="row">
                  <div class="col-md-3 d-flex room-checkbox" *ngFor="let item of propertyList.propertFacilityList">
                    <div class="checkbox checkbox-black d-inline-block">
                      <input id="chk_{{ item.id }}" name="propertyFacility_{{ item.id }}" type="checkbox"
                        (click)="bindPropertyFacility(item)" value="item.id" />
                      <label for="chk_{{ item.id }}">
                        {{ item.name }}
                      </label>
                    </div>
                  </div>
                </div>
                <!-- <input type="date" name="" [(ngModel)]="filterCriteria.priceFacility" class="form-control" /> -->
              </div>
            </div>
            <div class="col-md-2" *ngIf="showHideFilters.displayDistanceFilter">
              <div class="row">
                <div class="col-md-10 pr-0">
                  <div class="form-group">
                    <label class="form_label">Distance City Center</label>
                    <input class="cusror-pointer" class="form-control" name="distance" type="number"
                      [(ngModel)]="filterCriteria.distance" />
                  </div>
                </div>
                <div class="col-md-2 pl-1 pt-4">
                  <label class="font-bold">KM</label>
                </div>
              </div>
            </div>
            <div class="col-md-2" *ngIf="showHideFilters.displayAvailabilityFilter">
              <div class="form-group">
                <label class="form_label">Available From</label>
                <input type="date" name="availableFrom" [(ngModel)]="filterCriteria.availableFrom"
                  class="form-control" />
              </div>
            </div>
            <div class="col-md-2" *ngIf="showHideFilters.displayAvailabilityFilter">
              <div class="form-group">
                <label class="form_label">Available To</label>
                <input type="date" name="availableTo" [(ngModel)]="filterCriteria.availableTo" class="form-control" />
              </div>
            </div>
            <div class="col-md-auto" *ngIf="showHideFilters.displayBedTypeFilter">
              <div class="form-group">
                <label class="form_label">Bed Preferences</label>
                <div class="row">
                  <div class="col-md-3 d-flex room-checkbox" *ngFor="let item of propertyList.beddingList">
                    <div class="checkbox checkbox-black d-inline-block">
                      <input id="bedchk_{{ item.id }}" name="bed_{{ item.id }}" type="checkbox"
                        (click)="bindBedType(item)" value="item.id" />
                      <label for="bedchk_{{ item.id }}">
                        {{ item.name }}
                      </label>
                    </div>
                  </div>
                </div>
                <!-- <input type="date" name="" [(ngModel)]="filterCriteria.priceFacility" class="form-control" /> -->
              </div>
            </div>
            <div class="col-md-auto" *ngIf="showHideFilters.displayRoomTypeFilter">
              <div class="form-group">
                <label class="form_label">Room Facilities</label>
                <div class="row">
                  <div class="col-md-3 d-flex room-checkbox" *ngFor="let item of propertyList.roomFacilityList">
                    <div class="checkbox checkbox-black d-inline-block">
                      <input id="roomchk_{{ item.id }}" name="room_{{ item.id }}" type="checkbox"
                        (click)="bindRoomType(item)" value="item.id" />
                      <label for="roomchk_{{ item.id }}">
                        {{ item.name }}
                      </label>
                    </div>
                  </div>
                </div>
                <!-- <input type="date" name="" [(ngModel)]="filterCriteria.priceFacility" class="form-control" /> -->
              </div>
            </div>
            <div class="col-md-2" *ngIf="showHideFilters.displayAreaFilter">
              <div class="form-group">
                <label class="form_label">Area</label>
                <input type="text" name="area" [(ngModel)]="filterCriteria.area" class="form-control"
                  (keydown)="onlyNumeric($event)" />
              </div>
            </div>
          </div>
          <div class="row" *ngIf="displayFilters">
            <div class="ml-auto">
              <button class="btn btn-outline-primary btn-circle m-b-10 mr-2" (click)="manageFilters('close')">
                Reset
              </button>
              <button class="btn btn-outline-primary btn-circle m-b-10 mr-2" (click)="applyFilter()">
                Apply
              </button>
            </div>
          </div>
        </form>
      </div>
      <div>
        <mat-form-field class="search-box" appearance="standard">
          <mat-label>Search</mat-label>
          <input matInput (keyup)="searchHotel($event)" #input>
        </mat-form-field>
      </div>
    </div>
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="displayPropertyList" matSort (matSortChange)="sortData($event)">
        <!-- Position Column -->
        <!-- <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container> -->

        <!-- Name Column -->

        <ng-container matColumnDef="checked">
          <th mat-header-cell *matHeaderCellDef mat-sort-header title="Sort By Selection">
            Selection
          </th>
          <td mat-cell *matCellDef="let element">
            <input (change)="onHotelCheckChange(element)" class="checkbox checkbox-black" type="checkbox" name="room"
              [(checked)]="element.checked" />
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header title="Sort By HotelName">
            Hotel Name
          </th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <!-- country Column -->
        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef mat-sort-header title="Sort By Country">
            Country , City
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.countryCityName }}
          </td>
        </ng-container>

        <!-- Operational Contact Column -->
        <ng-container matColumnDef="operationalContact">
          <th mat-header-cell *matHeaderCellDef mat-sort-header title="Sort By Operational Contact">
            Operational Contact
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.operationalContact }}
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
          <td mat-cell *matCellDef="let element" class="text-center pt-2">
            <button class="btn btn-outline black-bgcolor btn-circle m-b-10 mr-2"
              (click)="editProperty(element.id, 'view')">
              <i class="fa fa-eye" aria-hidden="true"></i>
            </button>
            <button type="button" class="btn btn-outline blue-bgcolor btn-circle m-b-10 mr-2"
              (click)="editProperty(element.id, 'edit')">
              <i aria-hidden="true" class="fa fa-pencil"></i>
            </button>
            <button type="button" class="btn red btn-outline btn-circle m-b-10 mr-2" data-toggle="modal"
              data-target="#delete-property" data-backdrop="static" (click)="deleteProperty(element.id)"
              data-keyboard="false">
              <i aria-hidden="true" class="fa fa-trash-o"></i>
            </button>
            <button type="button" class="btn green btn-outline btn-circle m-b-10 mr-2" (click)="markAsFeatured(element)">
              <i *ngIf="!element.is_featured" aria-hidden="true" class="fa fa-bookmark-o bookmark"></i>
              <i *ngIf="element.is_featured" aria-hidden="true" class="fa fa-bookmark bookmark"></i>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 20, 30]"></mat-paginator>
      <div style="margin-left: 20px">
        <button (click)="onSelectCurrentPageHotel()" type="button" class="btn btn-outline black btn-circle m-b-10 mr-2">
          {{ this.selectCurrentPageHotels ? "Deselect" : "Select" }} hotels of
          this page
        </button>

        <button data-toggle="modal" data-target="#delete-multiple-property" data-backdrop="static" data-keyboard="false"
          *ngIf="selectCurrentPageHotels" type="button" class="btn btn-outline red btn-circle m-b-10 mr-2">
          Delete selected hotels
        </button>
      </div>
    </div>

    <div class="modal fade" id="delete-multiple-property">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h2>Are you sure?</h2>
            <button type="button" id="closeModal" #closeBtnDelete class="close" data-dismiss="modal" hidden>
              &times;
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body delate-popup text-center">
            <form (ngSubmit)="deleteSelectedHotels()">
              <div class="form-group">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <h2>These properties will permanently deleted !</h2>
              </div>
              <div class="form-group">
                <button type="submit"
                  class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn btn-warning mr-2">
                  Yes, delete it!
                </button>
                <button type="button"
                  class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn btn-danger"
                  data-dismiss="modal">
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <!-- Modal Starts Here-->
    <div class="modal fade" id="delete-property">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h2>Are you sure?</h2>
            <button type="button" id="closeModal" #closeBtnDelete class="close" data-dismiss="modal" hidden>
              &times;
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body delate-popup text-center">
            <form>
              <div class="form-group">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <h2>It will permanently deleted !</h2>
              </div>
              <div class="form-group">
                <button type="submit" data-dismiss="modal" (click)="deleteProperty()"
                  class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn btn-warning mr-2">
                  Yes, delete it!
                </button>
                <button type="button"
                  class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn btn-danger"
                  data-dismiss="modal">
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal Ends Here-->
<!-- loader-->
<!-- <div *ngIf="showLoader" class="d-flex justify-content-center position-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div> -->