import { Component, OnInit } from "@angular/core";
import { HotelSyncToolService } from "../../_services/hotel-sync-tool.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  channels = [];
  constructor(private hotelSyncToolService: HotelSyncToolService) {}

  ngOnInit(): void {
    // this.getAllChannel();
  }

  public getAllChannel() {
    this.hotelSyncToolService.getAllChannel().subscribe(
      (result: any) => {
        this.channels = result.data;
        this.channels = this.channels.filter((item: any) => {
          return item.status === 1;
        });
        this.channels.map((item: any) => {
          item.channelName = item.channelName.toLowerCase();
          item.link = `/hotel-sync-tool/${item.channelName.toLowerCase()}`;
        });
      },
      (error) => {}
    );
  }
}
